import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Input,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  _add_new_support_ticket,
  _list_active_department,
} from "../../../DAL/support_tickets/support_tickets";
import { get_local_preview } from "../../../utils/utils";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";

export default function AddSupportTickets({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    department_id: "",
    subject: "",
    description: "",
    file: "",
    image: "",
    status: true,
  });
  const [departments, setDepartments] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleUpdatePassword();
  };
  console.log(inputs);
  const handleUpdatePassword = async () => {
    // upload image
    if (inputs.image) {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.image);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code == 200) {
        const { department_id, subject, description, status } = inputs;
        const postData = {
          department_id,
          subject,
          description,
          image: image_resp.file_name,
        };
        const add_customer_resp = await _add_new_support_ticket(postData);
        if (add_customer_resp.code == 200) {
          onCloseDrawer();
          enqueueSnackbar(`Support Ticket added successfully`, {
            variant: "success",
          });
          handleRefresh();
        } else {
          enqueueSnackbar(add_customer_resp.message, { variant: "error" });
        }
        setIsLoading(false);
      } else {
        enqueueSnackbar(image_resp.message, { variant: "error" });
        setIsLoading(false);
        return;
      }
    } else {
      const { department_id, subject, description, status, image } = inputs;
      const postData = {
        department_id,
        subject,
        description,
        image,
      };

      const add_customer_resp = await _add_new_support_ticket(postData);
      if (add_customer_resp.code == 200) {
        onCloseDrawer();
        enqueueSnackbar(`Support Ticket added successfully`, {
          variant: "success",
        });
        handleRefresh();
      } else {
        enqueueSnackbar(add_customer_resp.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  const handleClickRemove = (country) => {
    setInputs({
      ...inputs,
      image: "",
    });
  };

  const getDepartments = async () => {
    const add_customer_resp = await _list_active_department();
    if (add_customer_resp.code == 200) {
      setDepartments(add_customer_resp.department);
    } else {
      enqueueSnackbar(add_customer_resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setInputs({
      department_id: "",
      subject: "",
      description: "",
      file: "",
      status: true,
    });
    setIsLoading(false);

    if (isOpenDrawer) {
      getDepartments();
    }
  }, [isOpenDrawer]);
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 350, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Create Support Ticket
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}
              <TextField
                fullWidth
                label="Subject"
                required
                name="subject"
                value={inputs.subject}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Description"
                placeholder="Please add your details here"
                required
                name="description"
                multiline
                rows={5}
                value={inputs.description}
                onChange={handleChange}
              />

              <FormControl fullWidth required>
                <InputLabel
                  shrink={!!inputs.department_id}
                  variant="standard"
                  htmlFor="uncontrolled-native"
                >
                  Please choose a category
                </InputLabel>
                <NativeSelect
                  value={inputs.department_id}
                  onChange={handleChange}
                  inputProps={{
                    name: "department_id",
                    id: "uncontrolled-native",
                  }}
                  required
                >
                  {/* {inputs.help_article_category === "" && (
                    <option value=""></option>
                  )} */}
                  {inputs.department_id === "" && <option value=""></option>}
                  {departments.map((department) => (
                    <option value={department._id}>{department.title}</option>
                  ))}
                </NativeSelect>
              </FormControl>

              {inputs.image ? (
                <img src={get_local_preview(inputs.image)} alt="" />
              ) : (
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    value=""
                    name="image"
                    onChange={handleChange}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              )}

              {inputs.image && (
                <Button
                  onClick={handleClickRemove}
                  className="mt-1"
                  style={{ color: "Red" }}
                  fullWidth
                >
                  Remove
                </Button>
              )}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Create Support Ticket
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
