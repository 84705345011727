import { invokeApi } from "src/bl_libs/invokeApi";

export const _transaction_list = async (postData, page, limit) => {
  let requestObj = {
    path: `api/plan/list_all_transaction?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _transaction_list_by_admin = async (postData, page, limit) => {
  let requestObj = {
    path: `api/transaction_by_admin/list_user_transaction_by_admin?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _transaction_list_members = async (postData) => {
  let requestObj = {
    path: `api/customer/customer_list_for_transaction_filter`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _select_members = async () => {
  let requestObj = {
    path: `api/plan/list_member_of_transaction`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_transaction_by_admin = async (id) => {
  let requestObj = {
    path: `api/transaction_by_admin/delete_user_transaction_by_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
