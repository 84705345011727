import * as React from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function BuilderMenuModal({
  open,
  setOpen,
  onchange,
  menuInputs,
  setMenuInputs,
  imageBtn,
  addMenuButton,
  handleAddMenu,
  isEdit,
  setIsEdit,
  editData,
}) {
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    setMenuInputs({
      title: "",
      background_color: "",
      image: null,
      button_items: [],
    });
  };
  return (
    <div style={{ width: "94%", margin: "auto" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>{isEdit ? "Edit Builder Menu Data" : "Add Builder Menu Data"}</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form className="mt-3">
                  <div className="mb-3">
                    <p>Title</p>
                    <GeneralCkeditor
                      inputs={menuInputs}
                      setInputs={setMenuInputs}
                      name="title"
                      value={menuInputs.title}
                      onChange={(e) =>
                        setMenuInputs((prev) => {
                          return { ...prev, title: e.target.value };
                        })
                      }
                    />

                    <TextField
                      label="Background Color"
                      name=""
                      required
                      fullWidth
                      style={{ marginTop: "1rem" }}
                      value={menuInputs.background_color}
                      onChange={(e) =>
                        setMenuInputs((prev) => {
                          return { ...prev, background_color: e.target.value };
                        })
                      }
                    />
                  </div>
                  {menuInputs.button_items.map((btn_item, i) => (
                    <>
                      <div className="row mt-2">
                        <div className="col-12 col-sm-12 col-md-4 mb-3 mb-sm-3 mb-md-3">
                          <TextField
                            label="Button Text"
                            required
                            value={btn_item?.text}
                            // style={{ width: "33%" }}
                            fullWidth
                            onChange={(e) =>
                              setMenuInputs((prev) => {
                                let tempButtons = prev.button_items;
                                tempButtons[i].text = e.target.value;
                                return {
                                  ...prev,
                                  button_items: tempButtons,
                                };
                              })
                            }
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-4  mb-3 mb-sm-3 mb-md-3">
                          <TextField
                            label="Button link"
                            required
                            value={btn_item?.link}
                            // style={{ width: "33%" }}
                            fullWidth
                            onChange={(e) =>
                              setMenuInputs((prev) => {
                                let tempButtons = prev.button_items;
                                tempButtons[i].link = e.target.value;
                                return {
                                  ...prev,
                                  button_items: tempButtons,
                                };
                              })
                            }
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-4  mb-3 mb-sm-3 mb-md-3">
                          <TextField
                            label="Button Background Color"
                            required
                            value={btn_item?.background_color}
                            // style={{ width: "33%" }}
                            fullWidth
                            onChange={(e) =>
                              setMenuInputs((prev) => {
                                let tempButtons = prev.button_items;
                                tempButtons[i].background_color =
                                  e.target.value;
                                return {
                                  ...prev,
                                  button_items: tempButtons,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                    </>
                  ))}

                  <Button
                    className="mt-3"
                    onClick={() => {
                      setMenuInputs((prev) => {
                        return {
                          ...prev,
                          button_items: [
                            ...prev.button_items,
                            { text: "", link: "" },
                          ],
                        };
                      });
                    }}
                  >
                    Add Button
                  </Button>

                  <div className="d-flex flex-column justify-content-center align-items-center text-align-center mt-4">
                    <img
                      style={{ width: "15rem", height: "8rem" }}
                      src={
                        menuInputs.local_image
                          ? menuInputs.local_image
                          : s3baseUrl + "/" + menuInputs.image
                      }
                      alt=""
                    />
                    <label htmlFor="icon-button-file" className="mt-3">
                      <Input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        name="image"
                        onChange={(e) =>
                          setMenuInputs((prev) => {
                            return {
                              ...prev,
                              image: e.target.files[0],
                              local_image: URL.createObjectURL(
                                e.target.files[0]
                              ),
                            };
                          })
                        }
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-3 mb-3"
                      >
                        Upload Image
                      </Button>
                    </label>
                  </div>
                  <div className="text-end">
                    {/* {RowData ? (
                      <>
                        <Button variant="contained" >
                          Edit Data
                        </Button>
                      </>
                    ) : (
                      <>
                       
                      </>
                    )} */}
                    {isEdit === false ? (
                      <>
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={handleAddMenu}
                        >
                          Add Data
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={editData}
                      >
                        Edit Data
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
