import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  _add_customer_by_admin,
  _update_customer_password,
} from "src/DAL/customer/customer";
// ------------------------------------- ---------------------------------

// -----------------------------------------------------------------------

export default function AddCustomer({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    business_name: "",
    business_type: "",
    post_code: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputs.contact_number) {
      enqueueSnackbar("Please add Contact number", { variant: "error" });
      return;
    }
    setIsLoading(true);

    const postData = {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      contact_number: inputs.contact_number,
      business_name: inputs.business_name,
      business_type: businessType,
      password: inputs.password,
      post_code: inputs.post_code,
      type: 1,
    };

    const add_customer_resp = await _add_customer_by_admin(postData);
    console.log(add_customer_resp, "customeradded");
    if (add_customer_resp.code == 200) {
      onCloseDrawer();
      enqueueSnackbar(`Customer added successfully`, {
        variant: "success",
      });
      handleRefresh();
      setSelectedOptions([]); // Reset the selected business types
      setBusinessType([]); // Reset the business type
    } else {
      enqueueSnackbar(add_customer_resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const handleSelectChange = (selectedOptions) => {
    // setBusinessType([...selectedOptions]);
    console.log(businessType, "businessType");
    setSelectedOptions(selectedOptions);
    console.log(selectedOptions, "selectedOptions");
    let newArray = [];
    newArray = selectedOptions.map((option) => {
      return option.value;
    });
    setBusinessType(newArray);
    console.log(newArray, "newArray");
  };

  const handleChangePhoneNumber = (phone) => {
    setInputs({
      ...inputs,
      contact_number: phone,
    });
  };

  useEffect(() => {
    setInputs({
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      contact_number: "",
      business_name: "",
      business_type: "",
      post_code: "",
    });
    setIsLoading(false);
  }, [isOpenDrawer]);

  const options = [
    { value: "Fitness", label: "Fitness" },
    { value: "Motivational", label: "Motivational" },
    { value: "Travelling", label: "Travelling" },
    { value: "Education", label: "Education" },
    { value: "Business", label: "Business" },
  ];

  // const phoneInputStyles = {
  //   height: "48px", // Adjust the height value as needed
  //   width: "100%",
  //   boxSizing: "border-box",
  //   border: "1px solid #bdbdbd",
  //   borderRadius: "4px",
  //   padding: "8px 12px",
  // };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add Customer
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              <TextField
                fullWidth
                label="First Name"
                required
                name="first_name"
                value={inputs.first_name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Last Name"
                required
                name="last_name"
                value={inputs.last_name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Email"
                required
                name="email"
                type="email"
                value={inputs.email}
                onChange={handleChange}
              />

              <Typography variant="subtitle2" color="textSecondary">
                Phone Number *
                <PhoneInput
                  country={"pk"}
                  required
                  className="mt-0"
                  value={inputs.contact_number}
                  onChange={(value) => {
                    if (value.length > 3) {
                      handleChangePhoneNumber(value);
                    }
                  }}
                  inputStyle={{
                    height: 50,
                    width: "100%",
                  }}
                />
              </Typography>
              <TextField
                fullWidth
                label="Password"
                required
                name="password"
                value={inputs.password}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Post Code"
                required
                name="post_code"
                value={inputs.post_code}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Business Name"
                required
                name="business_name"
                value={inputs.business_name}
                onChange={handleChange}
              />

              <Typography variant="subtitle2" color="textSecondary">
                Business Type
                <Select
                  options={options}
                  value={selectedOptions}
                  isMulti
                  name="business_type"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectChange}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      minHeight: 50,
                    }),
                  }}
                />
              </Typography>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Add Customer
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
