import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { _customer_gallery } from "src/DAL/customer/customer";
import { s3baseUrl } from "src/config/config";
import { get_short_string } from "src/utils/utils";
import { formatSizeUnits } from "src/utils/utils";
import Loader from "src/components/Loader/Loader";

export default function GalleryModal({ open, setOpen, ImageCustomer }) {
  const [customersImages, setCustomersImage] = useState([]);
  const [isloading, setIsloading] = useState(false);
  console.log(ImageCustomer, "ImageCustomer");
  const handleClose = () => setOpen(false);
  console.log(ImageCustomer?.user_id?._id, "ImageCustomer?.user_id?._id");

  const get_customer_gallery = async () => {
    setIsloading(true);
    const postData = {
      user_id: ImageCustomer?.user_id?._id,
    };
    const response = await _customer_gallery(postData);
    console.log(response?.customer?.gallery?.images, "rrrrrrrrr");
    setCustomersImage(response?.customer?.gallery?.images);
    setIsloading(false);
  };

  useEffect(() => {
    if (open) {
      get_customer_gallery();
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>Gallery</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="card-main mt-2 ">
            <div className="row g-0 ">
              {isloading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",
                    textAlign: "center",
                    height: "200px",
                  }}
                >
                  <Loader />
                </Box>
              ) : (
                <>
                  <>
                    {customersImages?.length > 0 ? (
                      customersImages?.map((data) => {
                        return (
                          <div className="col-lg-3 col-md-6 col-sm-12  ">
                            <div className="card mx-1 my-1  galleryCard ">
                              <img
                                src={`${s3baseUrl}/${data?.file_path}`}
                                alt="No Img"
                              />
                              <div className=" px-3 card-text-section ">
                                <p>{get_short_string(data?.file_name, 20)}</p>
                                <p>{formatSizeUnits(data?.file_size)}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <>
                      <span className="NoImage-text">No Images !!</span>
                      </>
                    )}
                  </>
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
