import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { _update_customer_password } from "src/DAL/customer/customer";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UpdateCustomerPassword({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
  setSelectedRow,
}) {
  const customer_name = selectedRow?.first_name + " " + selectedRow?.last_name;
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    password: "",
    confirm_password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleUpdatePassword();
  };

  const handleUpdatePassword = async () => {
    const postData = {
      password: inputs.password,
    };

    const update_password_resp = await _update_customer_password(
      selectedRow._id,
      postData
    );
    if (update_password_resp.code == 200) {
      onCloseDrawer();
      enqueueSnackbar(`"${customer_name}" password updated successfully`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(update_password_resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setInputs({
      password: "",
      confirm_password: "",
    });
    setIsLoading(false);
  }, [isOpenDrawer]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Update Password
          </Typography>
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  Customer :
                </Typography>
                <Typography className="ml-1">{customer_name}</Typography>
              </div>
              <TextField
                fullWidth
                label="Password"
                required
                name="password"
                value={inputs.password}
                onChange={handleChange}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update Password
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
