import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Stack,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function SecureWebsiteModal({
  open,
  setOpen,
  item,
  setItem,
  selectedData,
  selectedIndex,
  setSelectedData,
}) {
  const [menuDataObj, setMenuDataObj] = useState({
    image: "",
    title: "",
    main_image: "",
  });

  const handleChange = (e) => {
    setMenuDataObj((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if (selectedData) {
      setMenuDataObj(selectedData);
    }
  }, [open]);
  const handleImageUpload = (e) => {
    setMenuDataObj((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      };
    });
  };

  const handleMainImageUpload = (e) => {
    setMenuDataObj((prev) => {
      return {
        ...prev,
        local_image2: URL.createObjectURL(e.target.files[0]),
        main_image: e.target.files[0],
      };
    });
  };

  const handleAddData = () => {
    let newArr = item.menu_items;
    newArr.push(menuDataObj);
    setItem({ ...item, menu_items: newArr });
    handleClose();
  };

  const handleEditData = () => {
    let newArr = item.menu_items;
    newArr[selectedIndex] = menuDataObj;

    setItem({ ...item, menu_items: newArr });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData({ title: "", main_image: "", image: "" });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {selectedData?.image &&
              selectedData?.title &&
              selectedData.main_image !== ""
                ? "Edit Secure Website Menu Item Data"
                : "Secure Website Menu Item Data"}
            </p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid className="mt-3">
            <>
              <TextField
                label="Title"
                // style={{ width: "40%" }}
                fullWidth
                name="title"
                value={menuDataObj?.title}
                onChange={handleChange}
              />
              {/* <p>Title</p>
              <GeneralCkeditor
                inputs={menuDataObj}
                name="title"
                value={menuDataObj?.title}
                setInputs={setMenuDataObj}
                onChange={handleChange}
              /> */}

              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                // alignItems="center"
                justifyContent="space-evenly"
                spacing={2}
                margin={2}
              >
                <div className="text-center mt-3">
                  <img
                    style={{
                      width: "15rem",
                      height: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={
                      menuDataObj?.local_image
                        ? menuDataObj?.local_image
                        : s3baseUrl + "/" + selectedData?.image
                    }
                    alt=""
                  />
                  <label htmlFor="icon-button-file">
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      name="image"
                      onChange={handleImageUpload}
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3"
                    >
                      {selectedData?.image !== ""
                        ? "Change Image"
                        : "Upload MenuItem Image"}
                    </Button>
                  </label>
                </div>

                <div className="text-center mt-3">
                  <img
                    style={{
                      width: "15rem",
                      height: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={
                      menuDataObj?.local_image2
                        ? menuDataObj?.local_image2
                        : s3baseUrl + "/" + selectedData?.main_image
                    }
                    alt=""
                  />
                  <label htmlFor="icon-button-file-2">
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file-2"
                      type="file"
                      name="main_image"
                      onChange={handleMainImageUpload}
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3"
                    >
                      {selectedData?.main_image !== ""
                        ? "Change Main Image"
                        : "Upload MenuItem Main Image"}
                    </Button>
                  </label>
                </div>
              </Stack>
            </>
          </Grid>

          <div className="text-end mt-3">
            {selectedData?.image &&
            selectedData?.title &&
            selectedData?.main_image !== "" ? (
              <>
                <Button variant="contained" onClick={handleEditData}>
                  Edit Data
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" onClick={handleAddData}>
                  Add Data
                </Button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
