import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import Page from "src/components/Page";
import { s3baseUrl } from "src/config/config";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { _add_header_data } from "src/DAL/Website/header";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import GalleryModal from "../GalleryDialog/Gallery";
import MenuItemModal from "../MenuItemsDialog/MenuItem";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import CustomConfirmation from "src/components/CustomConfirmation";

function Header() {
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState({
    logo: "",
    logo_link: "",
    sign_in_text: "",
    sign_in_link: "",
    get_started_text: "",
    get_started_link: "",
  });
  // console.log(inputs.logo, "loggggggg");
  // const [item, setItem] = React.useState([]);

  const [headerData, setHeaderData] = React.useState({});
  const [data, setData] = React.useState("");
  const [HeaderItemIndex, setHeaderItemIndex] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  // console.log(item, "headererererrer");

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    setInputs((prev) => {
      return { ...prev, logo: file };
    });

    // if (image_resp.code == 200) {
    //   setInputs((prev) => {
    //     return { ...prev, logo: image_resp.file_name };
    //   });
    // }
  };

  const newArray = headerData.menu_items?.map((obj) => {
    const { _id, ...rest } = obj;
    return rest;
  });
  console.log(newArray, "tfsyafdtyasf");

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  // console.log(newArray,"ctysfdyusagysv")

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(inputs.logo, "inputs.log");
    // console.log(item);

    // console.log(postData,"posttttt")
    let newImage = "";
    if (typeof inputs?.logo === "string") {
      newImage = inputs?.logo;
      // const postData = {
      //   logo: inputs.logo,
      //   logo_link: inputs?.logo_link,
      //   menu_items: newArray,
      //   sign_in_button: {
      //     text: inputs.sign_in_text,
      //     link: inputs.sign_in_link,
      //   },
      //   get_started_button: {
      //     text: inputs.get_started_text,
      //     link: inputs.get_started_link,
      //   },
      // };
      // const result = await _add_header_data(postData);
      // console.log(postData, "fsdtyafdyu");

      // if (result.code === 200) {
      //   enqueueSnackbar(`data added successfully`, {
      //     variant: "success",
      //   });
      //   headerDetailList();
      // } else {
      //   enqueueSnackbar(`data not added`, {
      //     variant: "error",
      //   });
      // }
    } else {
      const formData = new FormData();

      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs?.logo);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code === 200) {
        newImage = image_resp.file_name;
      }
    }

    const postData = {
      logo: newImage,
      logo_link: inputs?.logo_link,
      menu_items: newArray,
      sign_in_button: {
        text: inputs.sign_in_text,
        link: inputs.sign_in_link,
      },
      get_started_button: {
        text: inputs.get_started_text,
        link: inputs.get_started_link,
      },
    };
    console.log(postData, "fsdtyafdyu");

    const result = await _add_header_data(postData);

    if (result.code === 200) {
      enqueueSnackbar(`data added successfully`, {
        variant: "success",
      });
      headerDetailList();
    } else {
      enqueueSnackbar(`data not added`, {
        variant: "error",
      });
    }
  };

  const headerDetailList = async () => {
    setLoader(true);
    const headerDetail = await _landing_page_detail();
    if (headerDetail.code === 200) {
      setLoader(false);
      setHeaderData(headerDetail?.data?.header);
      setInputs((prev) => {
        return {
          ...prev,
          sign_in_text: headerDetail?.data?.header.sign_in_button.text,
        };
      });
      setInputs((prev) => {
        return {
          ...prev,
          sign_in_link: headerDetail?.data?.header.sign_in_button.link,
        };
      });
      setInputs((prev) => {
        return {
          ...prev,
          get_started_text: headerDetail?.data?.header.get_started_button.text,
        };
      });
      setInputs((prev) => {
        return {
          ...prev,
          get_started_link: headerDetail?.data?.header.get_started_button.link,
        };
      });
      setInputs((prev) => {
        return { ...prev, logo: headerDetail?.data?.header.logo };
      });
      setInputs((prev) => {
        return { ...prev, logo_link: headerDetail?.data?.header.logo_link };
      });
    }
  };

  const handleEdit = (row, i) => {
    console.log(row, "ftsayf");
    setData(row);
    setHeaderItemIndex(i);
    setOpen(true);
  };
  const handleConfirmDelete = (i) => {
    let obj = headerData;
    obj.menu_items.splice(i, 1);
    setHeaderData({ ...obj });
    setOpenDelete(false);
  };
  console.log(headerData, "dtsyadftys");
  const handleDelete = (row, i) => {
    setHeaderItemIndex(i);
    setOpenDelete(true);

    // headerDetailList();
  };

  useEffect(() => {
    headerDetailList();
  }, []);

  const handleMenuDialog = () => {
    setOpen(true);
  };
  return (
    <>
      <div style={{ width: "94%", margin: "auto" }}>
        {loader ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <form>
              <Page title="Add Header" className="mt-5">
                <MenuItemModal
                  open={open}
                  setOpen={setOpen}
                  item={headerData}
                  setItem={setHeaderData}
                  data={data}
                  HeaderDataIndex={HeaderItemIndex}
                  setData={setData}
                />

                <h3 className="pb-3 text-center">Header Data</h3>

                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className=" row col-12">
                    {/* Sign in button */}
                    <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                      <TextField
                        label="Sign In Button Name"
                        required
                        fullWidth
                        value={inputs.sign_in_text}
                        name="sign_in_text"
                        // style={{ width: "55%" }}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Sign In Button Link"
                        value={inputs.sign_in_link}
                        name="sign_in_link"
                        required
                        fullWidth
                        // style={{ width: "55%" }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row col-12 mt-4">
                    {/* Get Started button */}
                    <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                      <TextField
                        label="Get Started Button Name"
                        required
                        fullWidth
                        // style={{ width: "55%" }}
                        name="get_started_text"
                        value={inputs.get_started_text}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label="Get Started Button Link"
                        required
                        fullWidth
                        // style={{ width: "55%" }}
                        name="get_started_link"
                        value={inputs.get_started_link}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-md-6 mt-4">
                      <TextField
                        label="Logo Link"
                        required
                        fullWidth
                        // style={{ width: "55%" }}
                        name="logo_link"
                        value={inputs.logo_link}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    style={{
                      width: "17rem",
                      height: "9rem",
                      // marginLeft: "27rem",
                      marginTop: "1rem",
                    }}
                    src={
                      typeof inputs.logo === "string"
                        ? s3baseUrl + "/" + inputs.logo
                        : URL.createObjectURL(inputs.logo)
                    }
                    alt="preview"
                  />

                  <label htmlFor="icon-button-file">
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      value=""
                      name="logo"
                      onChange={handleImageUpload}
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3 "
                    >
                      {inputs.logo !== "" ? "Change Logo" : "Upload Logo"}
                    </Button>
                  </label>
                </div>
              </Page>
              <div className="mt-3" onClick={handleMenuDialog}>
                <Button>Add Menu Item</Button>
              </div>
              <Table className="mt-5">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {headerData?.menu_items?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>{val.title}</TableCell>
                          <TableCell>{val.link}</TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleEdit(val, i)}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleDelete(val, i)}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>

              <div className="text-end pb-4">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to Delete ?"}
              handleAgree={() => handleConfirmDelete(HeaderItemIndex)}
            />
          </>
        )}
      </div>
    </>
  );
}
export default Header;
