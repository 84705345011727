import { invokeApi } from "src/bl_libs/invokeApi";

export const _subscription_listing = async (postData, page, limit) => {
  let requestObj = {
    path: `api/plan/list_all_subscription_with_filter?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _edit_subscription = async (id, postData) => {
  let requestObj = {
    path: `api/plan/edit_custom_subscription/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _renew_subscription = async (id, postData) => {
  let requestObj = {
    path: `api/plan/renew_customer_subscription/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _cancel_subscription = async (id, postData) => {
  let requestObj = {
    path: `api/plan/cancel_customer_subscription/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _customer_listing = async () => {
  let requestObj = {
    path: `api/customer/customer_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
