import {
  Box,
  Button,
  CircularProgress,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _dynamic_website } from "src/DAL/Website/DynamicWebsite";
import { _website_builder } from "src/DAL/Website/WebsiteBuilder";
import { _website_create } from "src/DAL/Website/WebsiteCreate";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import MenuItemModal from "../MenuItemsDialog/MenuItem";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { s3baseUrl } from "src/config/config";
import DynamicModal from "../DynamicWebDialog/DynamicWebDialog";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function DynamicWebsiteBuilder() {
  const [mainTitle, setMainTitle] = useState({
    title: "",
  });
  const [inputs, setInputs] = useState({
    link: "",
    text: "",
  });
  const [discoverBtnImg, setDiscoverBtnImg] = useState({
    image: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  const [menuItemData, setMenuItemData] = useState({});

  const handleTitleChange = (e) => {
    setMainTitle((prev) => {
      return { ...prev, title: e.target.value };
    });
  };
  console.log(discoverBtnImg, "dtsydfstyad");
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  //   const handleTitleChange=(e)=>{
  // setMainTitle((prev)=>{
  //   return{...prev,title:e.target.value}
  // })
  //   }

  console.log(menuItemData, "tfsaydftsy");
  const handleBtnImageUpload = (e) => {
    const file = e.target.files[0];
    setDiscoverBtnImg((prev) => {
      return { ...prev, local_image: URL.createObjectURL(file), image: file };
    });
    // console.log(file, "fileeeee");
    // setDiscoverBtnImg(file);
  };

  const handleAddMenu = () => {
    setOpen(true);
  };

  const getDynamicWebsiteDetail = async () => {
    setLoading(true);
    const resp = await _landing_page_detail();
    if (resp.code === 200) {
      setLoading(false);
      setMenuItemData(resp?.data?.dynamic_website_builder);
      setInputs(resp?.data?.dynamic_website_builder?.discover_more_button);
      setMenuItemData(resp?.data?.dynamic_website_builder);
      setMainTitle(resp?.data?.dynamic_website_builder);
      setDiscoverBtnImg((prev) => {
        return {
          ...prev,
          image:
            resp?.data?.dynamic_website_builder?.discover_more_button?.image,
        };
      });
    }
  };

  useEffect(() => {
    getDynamicWebsiteDetail();
  }, []);

  console.log(discoverBtnImg, "ctsadftys");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newObj = discoverBtnImg;
    delete newObj.local_image;

    setDiscoverBtnImg(newObj);
    console.log(newObj, "ascskacjaklcjalkcjas");
    let image_paths = "";
    let tempMenuDataArr = [...menuItemData.menu_items];

    if (typeof newObj?.image === "string") {
      image_paths = newObj?.image;
      const postData = {
        title: mainTitle.title,
        discover_more_button: {
          text: inputs.text,
          link: inputs.link,
          image: newObj?.image,
        },
        menu_items: [...tempMenuDataArr],
      };
      console.log(postData, "tsfgdyusgfu");
    } else {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", newObj?.image);

      const image_resp = await _upload_file_on_s3(formData);

      if (image_resp.code === 200) {
        image_paths = image_resp.file_name;
      }
    }

    for (let i = 0; i < tempMenuDataArr.length; i++) {
      delete tempMenuDataArr[i]._id;

      if (
        typeof tempMenuDataArr[i].image === "string" &&
        (tempMenuDataArr[i].image === "" || tempMenuDataArr[i].image !== "")
      ) {
        continue;
      } else {
        const formData = new FormData();
        formData.append("project_name", "upload_s3_files");
        formData.append("upload_file", tempMenuDataArr[i].image);
        const image_resp = await _upload_file_on_s3(formData);
        if (image_resp.code === 200) {
          tempMenuDataArr[i].image = image_resp.file_name;
        } else {
          tempMenuDataArr[i].image = "";
        }
        delete tempMenuDataArr[i].local_image;
      }
      // setMenuItemData(tempMenuDataArr);
    }

    const postData = {
      title: mainTitle?.title,
      discover_more_button: {
        text: inputs.text,
        link: inputs.link,
        image: image_paths,
      },
      menu_items: [...tempMenuDataArr],
    };

    const result = await _dynamic_website({ ...postData });

    if (result.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      getDynamicWebsiteDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }

    // console.log(tempMenuDataArr, "cstdf");
    // const formData = new FormData();
    // formData.append("project_name", "upload_s3_files");
  };

  const handleConfirmDelete = (i) => {
    setMenuItemData((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((ele, index) => index !== i),
      };
    });

    setOpenDelete(false);
  };

  const handleDelete = (i) => {
    setSelectedIndex(i);
    setOpenDelete(true);
  };

  const handleEdit = (row, i) => {
    console.log(row, "tfsuadfgsty", i);
    setOpen(true);
    setSelectedData(row);
    setSelectedIndex(i);
  };

  return (
    <>
      <div style={{ width: "94%", margin: "auto", marginTop: "3rem" }}>
        {loading ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <DynamicModal
              open={open}
              setOpen={setOpen}
              data={menuItemData}
              setData={setMenuItemData}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              selectedIndex={selectedIndex}
            />

            <div>
              <h3 className="text-center pb-3">Dynamic Website Builder</h3>
              <form>
                <div className="">
                  <p>Title</p>
                  <GeneralCkeditor
                    inputs={mainTitle}
                    setInputs={setMainTitle}
                    value={mainTitle?.title}
                    name="title"
                    onChange={handleTitleChange}
                  />
                </div>

                <div className="row mt-3 align-items-center">
                  <div className="col-12 col-sm-12 col-md-6 mb-4 mb-sm-3 mb-md-0">
                    <TextField
                      label="button_items_text"
                      name="text"
                      required
                      value={inputs?.text}
                      // style={{ width: "33%" }}
                      fullWidth
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6">
                    <TextField
                      label="button_items_link"
                      name="link"
                      required
                      value={inputs?.link}
                      // style={{ width: "33%" }}
                      fullWidth
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="text-center mt-3">
                  <img
                    style={{
                      width: "13rem",
                      height: "8rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={
                      discoverBtnImg?.local_image
                        ? discoverBtnImg.local_image
                        : s3baseUrl + "/" + discoverBtnImg?.image
                    }
                    alt=""
                  />
                  <label htmlFor="icon-button-file">
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      name="discoverBtnImg"
                      onChange={handleBtnImageUpload}
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button mt-3"
                    >
                      {discoverBtnImg.local_image
                        ? "Change Button Image"
                        : discoverBtnImg.image
                        ? "Change Button Image"
                        : " Upload Button Image"}
                    </Button>
                  </label>
                </div>

                <div className="mt-3 pb-5">
                  <Button onClick={handleAddMenu}>Add Menu data</Button>
                </div>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {menuItemData?.menu_items?.map((val, i) => {
                        console.log(val.image, "stfdysat");
                        return (
                          <>
                            <TableRow>
                              <TableCell>{val.title}</TableCell>
                              <TableCell>
                                {/* {console.log(URL.createObjectURL(val.image),"fstyfcty")} */}
                                <img
                                  style={{ width: "4rem" }}
                                  src={
                                    val.local_image
                                      ? val.local_image
                                      : s3baseUrl + "/" + val.image
                                  }
                                  alt=""
                                />
                              </TableCell>
                              <TableCell>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: val?.description,
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Icon
                                  onClick={() => handleEdit(val, i)}
                                  style={{ color: "green", cursor: "pointer" }}
                                  icon={"fa-regular:edit"}
                                />
                              </TableCell>
                              <TableCell>
                                <Icon
                                  onClick={() => handleDelete(i)}
                                  style={{ color: "red", cursor: "pointer" }}
                                  icon={"ic:baseline-delete"}
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="text-end">
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>

            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are You sure you want to Delete ?"}
              handleAgree={() => handleConfirmDelete(selectedIndex)}
            />
          </>
        )}
      </div>
    </>
  );
}
