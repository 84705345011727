import { invokeApi } from "src/bl_libs/invokeApi";

export const _find_user_profile = async (postData) => {
  let requestObj = {
    path: `api/admin/search`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
