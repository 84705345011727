/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import closeFill from "@iconify/icons-eva/close-fill";
import Scrollbar from "src/components/Scrollbar";
import { _user_activity_list } from "src/DAL/customer/customer";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import LinkIcon from "@mui/icons-material/Link";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import PageviewIcon from "@mui/icons-material/Pageview";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useSnackbar } from "notistack";

function ActivityModal({
  isDrawerOpenUserActivity,
  handleCloseUserActivity,
  selectedRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [userActivityList, setUserActivityList] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectedRow_id = selectedRow?.user_id?._id;
  console.log(selectedRow_id, "rooowwIddd");

  const activity_icons_and_types = [
    {
      type: "PROJECT_ADDED",
      number: 0,
      icon: (
        <AddCircleOutlineIcon
          style={{ color: "#46b823", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PROJECT_DELETED",
      number: 1,
      icon: (
        <DeleteOutlineIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PROJECT_PUBLISHED",
      number: 2,
      icon: (
        <PublishIcon
          style={{ color: "#46b823", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PROJECT_UNPUBLISHED",
      number: 3,
      icon: (
        <UnpublishedIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PROJECT_DOMAIN_ADDED",
      number: 4,
      icon: (
        <AddToPhotosIcon
          style={{ color: "#46b823", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PROJECT_DOMAIN_UNLINKED",
      number: 5,
      icon: (
        <LinkOffIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PROJECT_DOMAIN_UNLINKED",
      number: 6,
      icon: (
        <LinkOffIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_ADDED",
      number: 7,
      icon: (
        <AddCircleOutlineIcon
          style={{ color: "#46b823", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_DELETED",
      number: 8,
      icon: (
        <DeleteOutlineIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_PUBLISHED",
      number: 9,
      icon: (
        <PageviewIcon
          style={{ color: "#46b823", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_UNPUBLISHED",
      number: 10,
      icon: (
        <RemoveRedEyeIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_SLUG",
      number: 11,
      icon: (
        <LinkIcon style={{ color: "#e6312c", width: "30px", height: "30px" }} />
      ),
    },
    {
      type: "PAGE_RESTORED",
      number: 12,
      icon: (
        <RestorePageIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_VERSION_DELETED",
      number: 13,
      icon: (
        <DeleteIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_GROUP_ADDED",
      number: 14,
      icon: (
        <AddCircleOutlineIcon
          style={{ color: "#46b823", width: "30px", height: "30px" }}
        />
      ),
    },
    {
      type: "PAGE_GROUP_DELETED",
      number: 15,
      icon: (
        <DeleteIcon
          style={{ color: "#e6312c", width: "30px", height: "30px" }}
        />
      ),
    },
  ];

  const get_activity_type = (type) => {
    var activity_type = activity_icons_and_types.find(
      (activity) => activity.number === type
    );
    return {
      type: activity_type.type
        .replace(/_/g, " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" "),
      icon: activity_type.icon,
    };
  };

  const userActivityListing = async (dates) => {
    setLoading(true);
    const postData = {
      date: dates,
    };
    try {
      const response = await _user_activity_list(selectedRow_id, postData);
      if (response.code === 200) {
        setLoading(false);
        var activities_array = [];
        if (!!response.data && response.data.activities.length > 0) {
          activities_array = response.data.activities ?? [];
        }
        setUserActivityList(activities_array);
      } else {
        console.log(response.message, "activityListingError");
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching activity listings:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRow_id) {
      userActivityListing();
    }
  }, [selectedRow_id]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isDrawerOpenUserActivity}
        onClose={handleCloseUserActivity}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            User Activties
          </Typography>
          <IconButton onClick={handleCloseUserActivity}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack direction="column" sx={{ py: 1.5 }}>
            {/* <Typography sx={{ mr: 1, mb: 2.5 }} variant="subtitle1">
              User Activity List
            </Typography> */}
            <div>
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {userActivityList && userActivityList.length > 0 ? (
                    userActivityList?.map((row, index) => {
                      return (
                        <>
                          {" "}
                          <div key={index} className="p-1 activity-card">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div className="me-2 ms-1">
                                {get_activity_type(row.activity_type).icon}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {get_activity_type(row.activity_type).type}
                                  </span>
                                </div>
                                <span style={{ fontSize: "14px" }}>
                                  {row.message}
                                </span>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    // justifyContent: "center",
                                  }}
                                >
                                  <Icon
                                    color="gray"
                                    icon="fluent:shifts-activity-16-regular"
                                  />
                                  <span
                                    style={{
                                      color: "gray",
                                      marginLeft: "3px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {" "}
                                    {moment(row.date_time)
                                      .add(5, "hours")
                                      .fromNow()}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ width: "100%", height: "60vh" }}
                    >
                      <Typography>User's Activity Not Found</Typography>
                    </Box>
                  )}
                </>
              )}
            </div>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

export default ActivityModal;
