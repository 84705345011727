/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import {
  _plan_detail,
  _plan_list_add,
  _plan_list_edit,
} from "src/DAL/plans/plan";
import { convertFileSizeToBytes, formatSizeUnits } from "src/utils/utils";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";

function AddEditPlans() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const prev = state;
  // console.log(prev, "staatteeeedataaaaaaaaaa");
  const { enqueueSnackbar } = useSnackbar();
  const [isAdd, setIsAdd] = useState("Add");
  const [isLoading, setIsLoading] = useState(false);

  const sizeWithUnit = formatSizeUnits(prev?.assert_size);
  const [inputs, setInputs] = useState({
    product_name: "",
    product_description: "",
    amount: "",
    currency: "USD",
    interval_time: "day",
    custom_domain_status: false,
    status: true,
    assert_size: "",
    assert_size_unit: "MB",
    pages_version_count: "",
    pages_count: "",
    projects_version_count: "",
    projects_count: "",
    allowed_ai_token: "",
    allowed_pexel_requests: "",
    paid_templates_for_free: "",
    allowed_subscriber: "",
    book_a_call_status: true,
    funnel_status: true,
    stripe_payment_status: true,
    free_trial_status: false,
    free_trial_days: 0,
    notice_before_free_trial_end: 0,
    free_trial_grace_period_days: 0,
    plan_features: "",
  });

  const handleBack = () => {
    navigate(-1);
  };

  // const handleChange = (e) => {
  //   setInputs({
  //     ...inputs,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (/^\d+$/.test(value) || value === "") {
      const updatedValue = value === "" ? "" : parseInt(value, 10);
      setInputs({
        ...inputs,
        [name]: updatedValue,
      });
    } else {
      setInputs({
        ...inputs,
        [name]: value,
      });
    }
  };

  // const handleChangeStatus = (e) => {
  //   setInputs({
  //     ...inputs,
  //     [e.target.name]: !inputs[e.target.name],
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      product_name: inputs.product_name,
      product_description: inputs.product_description,
      amount: inputs.amount,
      currency: inputs.currency,
      interval_time: inputs.interval_time,
      custom_domain_status: inputs.custom_domain_status,
      status: inputs.status,
      assert_size: convertFileSizeToBytes(
        inputs.assert_size + " " + inputs.assert_size_unit
      ),
      pages_version_count: inputs.pages_version_count,
      pages_count: inputs.pages_count,
      projects_version_count: inputs.projects_version_count,
      projects_count: inputs.projects_count,
      allowed_ai_token: inputs.allowed_ai_token,
      allowed_pexel_requests: inputs.allowed_pexel_requests,
      paid_templates_for_free: inputs.paid_templates_for_free,
      allowed_subscriber: inputs.allowed_subscriber,
      book_a_call_status: inputs.book_a_call_status,
      funnel_status: inputs.funnel_status,
      stripe_payment_status: inputs.stripe_payment_status,
      free_trial_status: inputs.free_trial_status,
      free_trial_days: inputs.free_trial_status ? inputs.free_trial_days : 0,
      notice_before_free_trial_end: inputs.free_trial_status
        ? inputs.notice_before_free_trial_end
        : 0,
      free_trial_grace_period_days: inputs.free_trial_status
        ? inputs.free_trial_grace_period_days
        : 0,
      plan_features: inputs.plan_features ? inputs.plan_features : "",
    };
    // console.log(postData, "postDataaaaaaaaaaaaaaaaaaaa");
    let resp = null;
    if (isAdd === "Add") {
      resp = await _plan_list_add(postData);
      if (resp.code === 200) {
        enqueueSnackbar("Plan added successfully", { variant: "success" });
        handleBack();
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    } else {
      resp = await _plan_list_edit({
        ...postData,
        _id: prev._id,
        order: prev.order,
      });
      if (resp.code === 200) {
        enqueueSnackbar("Plan updated successfully", { variant: "success" });
        handleBack();
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
    }
    setIsLoading(false);
    console.log(resp, "resultofPlaannsss");
  };

  const editPlanDetail = async () => {
    if (prev?._id) {
      setIsLoading(true);
      let resp = await _plan_detail(prev?._id);
      if (resp.code === 200) {
        console.log(resp, "edittemplateGroup");
        setInputs({
          product_name: resp.data.product_name,
          product_description: resp.data.product_description,
          amount: resp.data.amount,
          currency: resp.data.currency,
          interval_time: resp.data.interval_time,
          custom_domain_status: resp.data.custom_domain_status,
          status: resp.data.status,
          assert_size: sizeWithUnit.split(" ")[0],
          assert_size_unit: sizeWithUnit.split(" ")[1],
          pages_version_count: resp.data.pages_version_count,
          pages_count: resp.data.pages_count,
          projects_version_count: resp.data.projects_count,
          projects_count: resp.data.projects_count,
          allowed_ai_token: resp.data.allowed_ai_token,
          allowed_pexel_requests: resp.data.allowed_pexel_requests,
          paid_templates_for_free: resp.data.paid_templates_for_free,
          allowed_subscriber: resp.data.allowed_subscriber,
          book_a_call_status: resp.data.book_a_call_status,
          funnel_status: resp.data.funnel_status,
          stripe_payment_status: resp.data.stripe_payment_status,
          free_trial_status: resp.data.free_trial_status,
          free_trial_days: resp.data.free_trial_days,
          notice_before_free_trial_end: resp.data.notice_before_free_trial_end,
          free_trial_grace_period_days: resp.data.free_trial_grace_period_days,
          plan_features: resp.data.plan_features,
        });
        setIsAdd("Edit");
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    editPlanDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress style={{ marginTop: "20%", marginLeft: "45%" }} />;
  }

  return (
    <>
      <Box sx={{ p: 5 }}>
        <Stack direction="row" alignItems="center" mb={1.5}>
          <IconButton
            onClick={() => {
              handleBack();
            }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">{isAdd} Plan</Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ p: 3 }}>
            {/*  */}
            <Typography className="text-center" variant="h5">
              Plan Information
            </Typography>
            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Plan Name"
                  required
                  name="product_name"
                  value={inputs.product_name}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Plan Subtitle"
                  required
                  name="product_description"
                  value={inputs.product_description}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Currency
                  </InputLabel>

                  <Select
                    label="Currency"
                    required
                    name="currency"
                    value={inputs.currency}
                    onChange={handleChange}
                  >
                    <MenuItem value={"USD"}>USD</MenuItem>
                    <MenuItem value={"EUR"}>EUR</MenuItem>
                    <MenuItem value={"PKR"}>PKR</MenuItem>
                    <MenuItem value={"GBP"}>GBP</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Plan Amount"
                  required
                  name="amount"
                  type="test"
                  value={inputs.amount}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Time Interval
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={inputs.interval_time}
                    label="Time Interval"
                    onChange={handleChange}
                    name="interval_time"
                  >
                    <MenuItem value={"day"}>Daily</MenuItem>
                    <MenuItem value={"month"}>Monthly</MenuItem>
                    <MenuItem value={"year"}>Yearly</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                {/* <FormControlLabel
                  fullWidth
                  className="justify-content-between"
                  value="start"
                  control={
                    <Switch
                      checked={inputs.status}
                      value={inputs.status}
                      onChange={handleChangeStatus}
                      color="primary"
                      name="status"
                    />
                  }
                  label="Status"
                  labelPlacement="start"
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Plan Status
                  </InputLabel>
                  <Select
                    label="Plan Status"
                    required
                    name="status"
                    value={inputs.status}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row">
              <div
                style={{ display: "flex", justifyContent: "flex-end" }}
                className="col-md-3 mb-4 mb-sm-2 mb-md-0"
              >
                {/* <FormControlLabel
                  fullWidth
                  className="justify-content-between"
                  value="start"
                  control={
                    <Switch
                      checked={inputs.free_trial_status}
                      value={inputs.free_trial_status}
                      onChange={handleChangeStatus}
                      color="primary"
                      name="free_trial_status"
                    />
                  }
                  label="Trial Status"
                  labelPlacement="start"
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Free Trial Status
                  </InputLabel>
                  <Select
                    label="Free Trial Status"
                    required
                    name="free_trial_status"
                    value={inputs.free_trial_status}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Free Trail Days"
                  required
                  name="free_trial_days"
                  InputLabelProps={{ shrink: true }}
                  type="test"
                  // value={inputs.free_trial_days}
                  disabled={inputs.free_trial_status === false}
                  value={
                    inputs.free_trial_status === true
                      ? inputs.free_trial_days
                      : 0
                  }
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Notice Before Ending Trial"
                  required
                  name="notice_before_free_trial_end"
                  InputLabelProps={{ shrink: true }}
                  type="test"
                  // value={inputs.notice_before_free_trial_end}
                  disabled={inputs.free_trial_status === false}
                  value={
                    inputs.free_trial_status === true
                      ? inputs.notice_before_free_trial_end
                      : 0
                  }
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-md-3 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Grace Period Days"
                  required
                  name="free_trial_grace_period_days"
                  InputLabelProps={{ shrink: true }}
                  type="test"
                  // value={inputs.free_trial_grace_period_days}
                  disabled={inputs.free_trial_status === false}
                  value={
                    inputs.free_trial_status === true
                      ? inputs.free_trial_grace_period_days
                      : 0
                  }
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-2">
                <p style={{ fontWeight: "600" }}>Plan Features*</p>
                <GeneralCkeditor
                  className="mt-2"
                  inputs={inputs}
                  setInputs={setInputs}
                  name={"plan_features"}
                  editorHeight={350}
                  onChange={handleChange}
                />
              </div>
            </div>
            <Typography className="text-center" variant="h5">
              Plan Limitations
            </Typography>

            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Assert Size"
                  required
                  name="assert_size"
                  value={inputs.assert_size}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>

              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Size</InputLabel>
                  <Select
                    label=" Size"
                    required
                    name="assert_size_unit"
                    value={inputs.assert_size_unit}
                    onChange={handleChange}
                  >
                    <MenuItem value={"MB"}>MB</MenuItem>
                    <MenuItem value={"GB"}>GB</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  type="test"
                  label="Allowed Projects"
                  required
                  name="projects_count"
                  value={inputs.projects_count}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Project Versions"
                  required
                  name="projects_version_count"
                  type="test"
                  value={inputs.projects_version_count}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Pages"
                  required
                  name="pages_count"
                  type="test"
                  value={inputs.pages_count}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Page Versions"
                  required
                  name="pages_version_count"
                  type="test"
                  value={inputs.pages_version_count}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Pixel Request"
                  required
                  name="allowed_pexel_requests"
                  type="test"
                  value={inputs.allowed_pexel_requests}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>

              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed AI Token"
                  placeholder="e,g 5000, 10000, 15000"
                  required
                  name="allowed_ai_token"
                  type="test"
                  value={inputs.allowed_ai_token}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Paid Templates"
                  required
                  name="paid_templates_for_free"
                  type="test"
                  value={inputs.paid_templates_for_free}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>

              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                <TextField
                  fullWidth
                  label="Allowed Subscribers"
                  required
                  name="allowed_subscriber"
                  type="test"
                  value={inputs.allowed_subscriber}
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value.match(/^$|[0-9]+$/)) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                {/* <TextField
                  fullWidth
                  label="Book a Call Count"
                  required
                  name="book_a_call_status"
                  type="number"
                  value={inputs.book_a_call_status}
                  onChange={handleChange}
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Book a Call Status*
                  </InputLabel>
                  <Select
                    label="Book a Call Status*"
                    required
                    name="book_a_call_status"
                    value={inputs.book_a_call_status}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                {/* <TextField
                  fullWidth
                  label="Funnel Count"
                  required
                  name="funnel_status"
                  type="number"
                  value={inputs.funnel_status}
                  onChange={handleChange}
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Funnel Status*
                  </InputLabel>
                  <Select
                    label="Funnel Status*"
                    required
                    name="funnel_status"
                    value={inputs.funnel_status}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row justify-content-between">
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                {/* <FormControlLabel
                  fullWidth
                  className="justify-content-between"
                  value="start"
                  control={
                    <Switch
                      checked={inputs.custom_domain_status}
                      value={inputs.custom_domain_status}
                      onChange={handleChangeStatus}
                      color="primary"
                      name="custom_domain_status"
                    />
                  }
                  label="Custom Domain Status"
                  labelPlacement="start"
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Custom Domain*
                  </InputLabel>
                  <Select
                    label="Custom Domain*"
                    required
                    name="custom_domain_status"
                    value={inputs.custom_domain_status}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6 mb-4 mb-sm-2 mb-md-0">
                {/* <FormControlLabel
                  fullWidth
                  className="justify-content-between"
                  value="start"
                  control={
                    <Switch
                      checked={inputs.stripe_payment_status}
                      value={inputs.stripe_payment_status}
                      onChange={handleChangeStatus}
                      color="primary"
                      name="stripe_payment_status"
                    />
                  }
                  label="Stripe Payment Status"
                  labelPlacement="start"
                /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Stripe Payment Status*
                  </InputLabel>
                  <Select
                    label="Stripe Payment Status*"
                    required
                    name="stripe_payment_status"
                    value={inputs.stripe_payment_status}
                    onChange={handleChange}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div
              className="mt-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                variant="contained"
                type="submit"
                loading={isLoading}
              >
                {/* {isAdd} Plan */}
                {isAdd === "Edit" ? "Update Plan" : "Add Plan"}
              </LoadingButton>
            </div>
          </Stack>
        </form>
      </Box>
    </>
  );
}

export default AddEditPlans;
