import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

function CustomConfirmation({
  open,
  setOpen,
  handleAgree,
  title,
  loadingBtn,
  action_name,
}) {
  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          <p className="mb-0">
            {" "}
            {title ? title : "Are you sure you want to take this action?"}
          </p>
          <p className="mb-0"> {action_name}</p>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton loading={loadingBtn} onClick={handleAgree}>
            Yes, Continue
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
