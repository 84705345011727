/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Label from "src/components/Label";
import { purchased_templates } from "src/DAL/Templates/templates";
import { useSnackbar } from "notistack";
import moment from "moment";
import { s3baseUrl, sourceS3BaseUrl } from "src/config/config";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
  maxHeight: "70vh",
};

function PurchaseTemplates({ open, setOpen, data }) {
  const { enqueueSnackbar } = useSnackbar();
  const [purchasedTemplates, setPurchasedTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //   console.log(data?.user_id?._id, "customerrrrIIIDDDDDDD");

  const handleClose = () => {
    setOpen(false);
  };

  const preview_link = (row) => {
    window.open(`${sourceS3BaseUrl}${row.template_id}/index.html`);
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  const getPurchasedTemplates = async () => {
    setIsLoading(true);
    const respomse = await purchased_templates(data.user_id._id);
    setPurchasedTemplates(respomse.data);
    setIsLoading(false);
  };

  useEffect(() => {
    if (data?.user_id?._id) {
      getPurchasedTemplates();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        size="large"
      >
        {/* <Box sx={style} className="modal-theme"> */}
        <div
          style={{
            padding: "2% 4% 2% 4%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography noWrap variant="h6">
            Purchased Templates
          </Typography>
          <IconButton onClick={handleClose} style={{ color: "grey" }}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <TableContainer>
          <Table>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Template Name
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Currency
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Amount
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Payment Status
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Preview
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Transaction Date
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap", fontWeight: "bold" }}>
                Transaction Id
              </TableCell>
            </TableRow>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {purchasedTemplates.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      <Typography variant="subtitle2">
                        No Templates Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  purchasedTemplates.map((row, i) => {
                    const {
                      _id,
                      template_name,
                      transaction_id,
                      payment_status,
                    } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            className="text-capitalize"
                          >
                            {template_name}
                          </Stack>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {row?.transaction_id
                              ? transaction_id.currency.toUpperCase()
                              : "-"}
                          </Stack>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row?.transaction_id
                            ? `${getCurrencySymbol(
                                "en-US",
                                row?.transaction_id?.currency
                              )}${row?.transaction_id?.amount}`
                            : `${getCurrencySymbol(
                                "en-US",
                                row?.transaction_id?.currency
                              )}0`}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Label
                              variant="ghost"
                              color={
                                (payment_status === 1 && "success") || "warning"
                              }
                            >
                              {payment_status === 1 ? "Succeed" : "Pending"}
                            </Label>
                          </Stack>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Button
                              onClick={() => preview_link(row)}
                              size="small"
                              variant="outlined"
                            >
                              Preview
                            </Button>
                          </Stack>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {row.transaction_id
                              ? moment(
                                  row.transaction_id.transaction_date
                                ).format("LL")
                              : "-"}
                          </Stack>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            onDoubleClick={() => {
                              navigator.clipboard.writeText(
                                transaction_id?._id
                              );
                              enqueueSnackbar("ID copied to clipboard", {
                                variant: "success",
                              });
                            }}
                          >
                            {row?.transaction_id
                              ? row?.transaction_id?._id
                              : "-"}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* </Box> */}
      </Dialog>
    </>
  );
}

export default PurchaseTemplates;
