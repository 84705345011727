import { invokeApi } from "src/bl_libs/invokeApi";

export const _landing_page_detail = async () => {
    let requestObj = {
      path: `api/landing_page/landing_page_detail`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };