import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
  } from "@mui/material";
  import FolderIcon from "@mui/icons-material/Folder";
  import { Icon } from "@iconify/react";
import AccordionTable from "./AccordionTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  
  export default function JsFolderSection({ JsData }) {
    
   
    return (
      <div>
       
            <Accordion
              
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {/* {FolderName} */}
                 <FolderIcon style={{color:"skyblue"}}/> JS
                
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ marginLeft: ".7rem",marginTop:"-1rem" }}>
              <AccordionTable data={JsData}/>
              </AccordionDetails>
            </Accordion>
         
      </div>
    );
  }
  