import moment from "moment";

export const get_local_preview = (file) => {
  if (!file) {
    return "";
  }
  if (typeof file === "string") {
    return file; // Return the file path string directly
  }
  return URL.createObjectURL(file);
};

export const get_short_string = (str, limit = 30) => {
  return str && str.length < limit ? str : str?.slice(0, limit) + "...";
};

export const convertFileSizeToBytes = (size) => {
  const units = {
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
  };

  const [value, unit] = size.split(" ");
  return parseInt(value, 10) * units[unit];
};

export const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(0) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(0) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(0) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes == 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 bytes";
  }
  return bytes;
};

export const showAddedDataWithoutApi = (newData, previousData, setData) => {
  console.log(previousData, "acaslkcjaskch");
  setData([newData, ...previousData]);
};

export const showEditedDataWithoutApi = (
  index,
  newData,
  previousData,
  setData
) => {
  let x = previousData.filter((row, i) => i !== index);
  setData([newData, ...x]);
};

export const hideDeletedDataWithoutApi = (index, previousData, setData) => {
  let x = previousData.filter((row, i) => i !== index);
  setData([...x]);
};

export const localTime = (time) => {
  const timestamp = time;
  const date_chwck = new Date(timestamp * 1000);
  const date = moment.utc(date_chwck).local();
  console.log(date);
  return date;
};

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < `${string.length}`; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}`.toUpperCase(),
  };
}
