import * as React from 'react';
import { Icon } from '@iconify/react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { s3baseUrl } from 'src/config/config';
// import ImagePopover from '../Image-Popover/Image-Popover';


export default function ImagePopover({ open, setOpen, imgData }) {


    const handleClose = () => setOpen(false);

    return (
        
        <div>
           

            <Dialog
                open={open}
                onClose={handleClose}
                scroll='paper'
                maxWidth="sm"
                
            >
                <DialogTitle style={{padding:0}}>
                   
                        
                        <p onClick={handleClose} className='text-end close-btn ' ><Icon icon="maki:cross" /></p>
                       
                </DialogTitle>
                
                  <img src={`${s3baseUrl}/${imgData.profile_image}`} style={{height:"20rem"}}  alt="" /> 
                
            </Dialog>
        </div>
    );
}