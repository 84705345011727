/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Label from "src/components/Label";
import PropTypes from "prop-types";
import SearchNotFound from "src/components/SearchNotFound";
import { UserListHead } from "src/components/_dashboard/user";
import TrendingTempGroup from "./TrendingTempGroup";
import { s3baseUrl, sourceS3BaseUrl } from "src/config/config";
import { _trending_templates } from "src/DAL/Templates/templates";
import { toInteger } from "lodash";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  const { id } = useParams();
  const [data, setData] = useState([]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "mode", label: "Mode", alignRight: false },
  { id: "order", label: "Order", alignRight: false },
  { id: "use_count", label: "Usage Count", alignCenter: true },
  { id: "is_paid", label: "Is Paid", alignCenter: true },
  { id: "_id", label: "Template ID", alignRight: false },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((template) => {
      return template.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function TrendingTemplates() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [showData, setShowdata] = useState(false);
  const [showField, setShowField] = useState(false);
  const [showLimitField, setShowLimitField] = useState(false);
  const [topLimit, setTopLimit] = useState(1);
  const [lastDays, setLastDays] = useState(2);
  const [trendingTemplates, setTrendingTemplates] = useState([]);
  const [filterTrendingTemplates, setFilterTrendingTemplates] = useState({
    app_type: 1,
    end_date: "lastMonth",
    limit: 5,
  });

  const handleFilterTrendingTemp = async () => {
    if (showData) return;
    let startDate;
    if (filterTrendingTemplates.end_date === "lastWeek") {
      startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    } else if (filterTrendingTemplates.end_date === "lastMonth") {
      startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    } else if (!filterTrendingTemplates.end_date) {
      setLastDays(2);
      setShowField(false);
      startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    } else {
      startDate = moment(filterTrendingTemplates.end_date).format("YYYY-MM-DD");
    }
    console.log(startDate, "startDateeee");
    const data = {
      app_type: filterTrendingTemplates.app_type,
      end_date: startDate,
      limit: filterTrendingTemplates.limit,
    };
    console.log(data, "filtteeeeeeeerr");
    setLoading(true);
    const response = await _trending_templates(data);
    console.log(response, "respponnnsssssssss");
    if (response.code === 200) {
      setLoading(false);
      // debugger;
      setTrendingTemplates(response.data);
      // setFilterTrendingTemplates({ ...filterTrendingTemplates, ...data });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, "errorr");
    }
  };

  const handleShowTemplateGroupData = () => {
    console.log("show data");
    setFilterTrendingTemplates({
      ...filterTrendingTemplates,
      app_type: 1,
      end_date: "lastMonth",
      limit: 5,
    });
    setLastDays(2);
    setTopLimit(1);
    setShowdata(false);
    setShowField(false);
    setShowLimitField(false);
  };

  const handleShowTemplateData = () => {
    setFilterTrendingTemplates({
      ...filterTrendingTemplates,
      app_type: 1,
      end_date: "lastMonth",
      limit: 5,
    });
    setLastDays(2);
    setTopLimit(1);
    setShowdata(true);
    setShowField(false);
    setShowLimitField(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = trendingTemplates.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - trendingTemplates.length)
      : 0;

  const filteredUsers = applySortFilter(
    trendingTemplates,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  useEffect(() => {
    handleFilterTrendingTemp();
  }, [filterTrendingTemplates]);

  const handleShowField = () => {
    setShowField(true);
  };

  const handleShowLimitField = () => {
    setShowLimitField(true);
    setTopLimit(3);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Filter Trending Templates
        </Typography>
        <Card sx={{ p: 2 }}>
          {/* <Box sx={{ width: "100%" }}> */}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Stack
              direction={{ xs: "column", sm: "row", md: "row" }}
              alignItems="center"
              justifyContent="space-between"
              // spacing={2}
              // margin={2}
            >
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                alignItems="center"
                spacing={2}
                style={{
                  marginBottom: "10px",
                }}
              >
                <b

                // style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  Duration:
                </b>
                <Label
                  style={{
                    cursor: "pointer",
                    padding: "12px",
                    // marginLeft: "10px",
                  }}
                  onClick={() => {
                    setFilterTrendingTemplates({
                      ...filterTrendingTemplates,
                      end_date: "lastWeek",
                    });
                    console.log(
                      filterTrendingTemplates,
                      "filteeeeeeeeeerrrrrrrsssssssssssss"
                    );
                    setLastDays(1);
                    setShowField(false);
                  }}
                  className={lastDays === 1 ? "labelColor" : ""}
                >
                  7 Days
                </Label>
                <Label
                  style={{
                    cursor: "pointer",
                    padding: "12px",
                    // marginLeft: "10px",
                  }}
                  onClick={() => {
                    setFilterTrendingTemplates({
                      ...filterTrendingTemplates,
                      end_date: "lastMonth",
                    });
                    setLastDays(2);
                    setShowField(false);
                  }}
                  className={lastDays === 2 ? "labelColor" : ""}
                >
                  30 Days
                </Label>
                {showField === true ? (
                  <div>
                    <input
                      className="inputField"
                      size="small"
                      id="date"
                      label="Custom Date"
                      type="date"
                      value={filterTrendingTemplates.end_date}
                      onChange={(e) => {
                        setFilterTrendingTemplates({
                          ...filterTrendingTemplates,
                          end_date: e.target.value,
                        });
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                    />
                  </div>
                ) : (
                  <Label
                    style={{
                      cursor: "pointer",
                      padding: "12px",
                      // marginLeft: "10px",
                    }}
                    className={lastDays === 3 ? "labelColor" : ""}
                    onClick={() => {
                      setLastDays(3);
                      setShowField(true);
                    }}
                  >
                    Custom
                  </Label>
                )}
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row", md: "row" }}
                alignItems="center"
                spacing={2}
                style={{
                  marginBottom: "10px",
                }}
              >
                <b
                // style={{ marginLeft: "10px", marginRight: "10px" }}
                >
                  Limit:
                </b>
                <Label
                  style={{
                    cursor: "pointer",
                    padding: "12px",
                    // marginLeft: "10px",
                  }}
                  onClick={() => {
                    setFilterTrendingTemplates({
                      ...filterTrendingTemplates,
                      limit: 5,
                    });
                    setTopLimit(1);
                    setShowLimitField(false);
                  }}
                  className={topLimit === 1 ? "labelColor" : ""}
                >
                  Top 5
                </Label>
                <Label
                  style={{
                    cursor: "pointer",
                    padding: "12px",
                    // marginLeft: "10px",
                  }}
                  onClick={() => {
                    setFilterTrendingTemplates({
                      ...filterTrendingTemplates,
                      limit: 10,
                    });
                    setTopLimit(2);
                    setShowLimitField(false);
                  }}
                  className={topLimit === 2 ? "labelColor" : ""}
                >
                  Top 10
                </Label>
                {showLimitField === true ? (
                  <div>
                    <input
                      className="inputField"
                      size="small"
                      id="limit"
                      value={filterTrendingTemplates.limit}
                      label="Custom Limit"
                      onChange={(e) => {
                        if (e.target.value.match(/^$|[0-9]+$/)) {
                          setFilterTrendingTemplates({
                            ...filterTrendingTemplates,
                            limit: toInteger(e.target.value),
                          });
                        }
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                    />
                  </div>
                ) : (
                  <Label
                    style={{
                      cursor: "pointer",
                      padding: "12px",
                      // marginLeft: "10px",
                    }}
                    onClick={handleShowLimitField}
                    className={topLimit === 3 ? "labelColor" : ""}
                  >
                    Custom
                  </Label>
                )}
              </Stack>
            </Stack>
          </Box>

          <Stack
            direction={{ xs: "column", sm: "row", md: "row" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography noWrap variant="h5" sx={{ py: 1 }}>
              {showData === false
                ? "Template List"
                : showData === true
                ? "Template Group List"
                : "Template List"}
            </Typography>

            <div className="d-flex align-items-center gap-4">
              <Label
                style={{
                  cursor: "pointer",
                  padding: "15px",
                  margin: "0px",
                }}
                onClick={() => handleShowTemplateGroupData()}
                className={showData === false ? "labelColor" : ""}
              >
                Templates
              </Label>

              <Label
                style={{
                  cursor: "pointer",
                  padding: "15px",
                  margin: "0px",
                }}
                onClick={() => handleShowTemplateData()}
                className={showData === true ? "labelColor" : ""}
              >
                Templates Group
              </Label>
            </div>
          </Stack>
          <TableContainer>
            <>
              {showData === false ? (
                <>
                  {loading ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Table
                      sx={{ minWidth: 750 }}
                      size="large"
                      aria-label="a dense table"
                    >
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={trendingTemplates.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers.map((row, index) => {
                          const { _id, template_id } = row;
                          return (
                            <TableRow
                              key={_id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {template_id
                                  ? template_id?.name.charAt(0).toUpperCase() +
                                    template_id?.name?.slice(1)
                                  : "-"}
                              </TableCell>
                              <TableCell
                                style={{ whiteSpace: "nowrap" }}
                                className="pl-0"
                                align="left"
                              >
                                <div
                                  style={{
                                    minWidth: 100,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      `${sourceS3BaseUrl}${template_id?._id}/index.html`
                                    );
                                  }}
                                >
                                  <img
                                    onError={(e) => {
                                      if (
                                        e.target.src ===
                                        sourceS3BaseUrl + template_id?.image
                                      ) {
                                        e.target.src =
                                          s3baseUrl + "/" + template_id?.image;
                                      }
                                    }}
                                    src={
                                      template_id?.image
                                        ? `${sourceS3BaseUrl}${template_id?.image}`
                                        : "-"
                                    }
                                    style={{ height: 50, width: 75 }}
                                  />
                                </div>
                              </TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                {template_id
                                  ? template_id?.mode.charAt(0).toUpperCase() +
                                    template_id?.mode?.slice(1)
                                  : "-"}
                              </TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                {template_id ? template_id?.order : "-"}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <Label variant="ghost" color="warning">
                                  {row ? row?.total_count : "0"}
                                </Label>
                              </TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                <Label
                                  variant="ghost"
                                  color={
                                    (row.is_paid === true && "success") ||
                                    "error"
                                  }
                                >
                                  {row.is_paid ? "Yes" : "No"}
                                </Label>
                              </TableCell>
                              <TableCell
                                style={{ whiteSpace: "nowrap" }}
                                onDoubleClick={() => {
                                  navigator.clipboard.writeText(
                                    template_id?._id
                                  );
                                  enqueueSnackbar("ID copied to clipboard", {
                                    variant: "success",
                                  });
                                }}
                              >
                                {template_id?._id}
                                {console.log(
                                  _id,
                                  "idddddddddddddddddddddddddddd"
                                )}
                              </TableCell>
                              {/* <TableCell style={{ whiteSpace: "nowrap" }}>
                                {template_id
                                  ? moment(template_id?.createdAt).format("LLL")
                                  : "-"}
                              </TableCell>
                              <TableCell style={{ whiteSpace: "nowrap" }}>
                                {template_id
                                  ? moment(template_id?.updatedAt).format("LLL")
                                  : "-"}
                              </TableCell> */}
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && !loading && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={12}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  )}
                </>
              ) : (
                <TrendingTempGroup
                  filterTrendingTemplates={filterTrendingTemplates}
                  setLastDays={setLastDays}
                  setShowField={setShowField}
                />
              )}
            </>
          </TableContainer>
          {/* </Box> */}
        </Card>
      </Box>
    </>
  );
}

export default TrendingTemplates;
