import { invokeApi } from "src/bl_libs/invokeApi";

export const _site_creation_data = (data) => {
    const req_obj = {
        method:"POST",
        path:"api/landing_page/site_creation",
        headers:{"x-sh-auth":localStorage.getItem("token")},
        postData:data
    }
    return invokeApi(req_obj)
}