import { invokeApi } from "src/bl_libs/invokeApi";

export const _helping_categories_listing = async (page, limit) => {
  let requestObj = {
    path: `api/helping_article_category/list_helping_article_category`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _active_helping_categories = async () => {
  let requestObj = {
    path: `api/helping_article_category/list_helping_article_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_helping_category = async (id) => {
  let requestObj = {
    path: `api/helping_article_category/delete_helping_article_category/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_helping_category = async (data) => {
  let requestObj = {
    path: `api/helping_article_category/add_helping_article_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_helping_category = async (id, data) => {
  let requestObj = {
    path: `api/helping_article_category/update_helping_article_category/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
