import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Box,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _website_create } from "src/DAL/Website/WebsiteCreate";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import DescriptionModal from "../GeneralDialog/GeneralDialog";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { Icon } from "@iconify/react";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function WebsiteCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
  });

  const [webCreateMenuData, setWebCreateMenuData] = useState({});

  // const [menuDataArray, setMenuDataArray] = useState([]);

  console.log(webCreateMenuData, "aaaaaaaaa");

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, title: e.target.value };
    });
  };

  const websiteCreateDetail = async () => {
    setLoading(true);
    const websiteCreateData = await _landing_page_detail();
    if (websiteCreateData.code === 200) {
      setLoading(false);
      setWebCreateMenuData(websiteCreateData?.data?.more_then_create_website);
      setInputs((prev) => {
        return {
          ...prev,
          title: websiteCreateData?.data?.more_then_create_website?.title,
        };
      });
    }
  };

  useEffect(() => {
    websiteCreateDetail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempWebCreateMenuData = [...webCreateMenuData.menu_items];

    for (let i = 0; i < tempWebCreateMenuData.length; i++) {
      delete tempWebCreateMenuData[i]._id;
      if (typeof tempWebCreateMenuData[i].image === "string") {
        continue;
      } else {
        const formData = new FormData();

        formData.append("project_name", "upload_s3_files");
        formData.append("upload_file", tempWebCreateMenuData[i].image);

        const image_resp = await _upload_file_on_s3(formData);

        if (image_resp.code === 200) {
          tempWebCreateMenuData[i].image = image_resp.file_name;
        } else {
          tempWebCreateMenuData[i].image = "";
        }
        delete tempWebCreateMenuData[i].local_image;
      }
      // setWebCreateMenuData((prev)=>{
      //   return{...prev,menu_items:[...tempWebCreateMenuData]}
      // })
    }

    const postData = {
      title: inputs.title,
      menu_items: [...tempWebCreateMenuData],
    };

    const result = await _website_create(postData);
    if (result.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      websiteCreateDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }
    console.log(postData, "postDataaaaaa");
  };

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const handleConfirmDelete = (i) => {
    setWebCreateMenuData((prev) => {
      return {
        ...prev,
        menu_items: prev.menu_items.filter((ele, index) => index !== i),
      };
    });

    setOpenDelete(false);
  };

  const handleDelete = (i) => {
    setSelectedIndex(i);
    setOpenDelete(true);
  };

  const handleEdit = (row, i) => {
    setOpen(true);
    setSelectedData(row);
    setSelectedIndex(i);
  };

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div style={{ width: "94%", margin: "auto", marginTop: "3rem" }}>
            <DescriptionModal
              open={open}
              setOpen={setOpen}
              item={webCreateMenuData}
              setItem={setWebCreateMenuData}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              selectedIndex={selectedIndex}
              // menuDataArray={menuDataArray}
              // setMenuDataArray={setMenuDataArray}
            />
            <div>
              <h3 className="text-center pb-2">Website Creation</h3>

              {/* <div className="d-flex gap-3 pb-3"> */}
              <p>Title</p>
              <GeneralCkeditor
                inputs={inputs}
                setInputs={setInputs}
                onChange={handleChange}
                value={inputs}
                name="title"
              />
              <Button className="mt-3" onClick={handleMenuOpen}>
                Add MenuItem Data
              </Button>

              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  size="large"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {webCreateMenuData?.menu_items?.map((val, i) => {
                      console.log(val.image, "stfdysat");
                      return (
                        <>
                          <TableRow>
                            <TableCell>{val.title}</TableCell>
                            <TableCell>
                              {/* {console.log(URL.createObjectURL(val.image),"fstyfcty")} */}
                              <img
                                style={{ width: "4rem" }}
                                src={
                                  val.local_image
                                    ? val.local_image
                                    : s3baseUrl + "/" + val.image
                                }
                                alt=""
                              />
                            </TableCell>
                            <TableCell>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: val?.description,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Icon
                                onClick={() => handleEdit(val, i)}
                                style={{ color: "green", cursor: "pointer" }}
                                icon={"fa-regular:edit"}
                              />
                            </TableCell>
                            <TableCell>
                              <Icon
                                onClick={() => handleDelete(i)}
                                style={{ color: "red", cursor: "pointer" }}
                                icon={"ic:baseline-delete"}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className="text-end mt-3">
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </div>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are You sure you want to Delete ?"}
            handleAgree={() => handleConfirmDelete(selectedIndex)}
          />
        </>
      )}
    </>
  );
}
