import { invokeApi } from "src/bl_libs/invokeApi";

export const _customer_list = async (page, rowsPerPage, postData) => {
  let requestObj = {
    path: `api/admin/list_customer?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _customer_gallery = async (postData) => {
  console.log(postData, "postData--------");
  let requestObj = {
    path: `api/customer/get_user_gallery_for_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_customer = async (id) => {
  let requestObj = {
    path: `api/customer/delete_customer_for_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_customer_password = async (id, data) => {
  let requestObj = {
    path: `api/customer/update_customer_password_for_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_customer_profile = async (id, data) => {
  let requestObj = {
    path: `api/customer/edit_customer_for_admin/${id}`,
    method: "PUT",
    headers: {
      "Content-Type": "multipart/formdata",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_customer_by_admin = async (data) => {
  let requestObj = {
    path: `api/customer/add_customer_by_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_customer_token_for_login = async (data) => {
  let requestObj = {
    path: `api/app_api/get_token`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _details_against_userID = async (id, postData) => {
  let requestObj = {
    path: `api/project/project_list_against_user_id_for_admin_controller/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _user_activity_list = async (id, postData) => {
  let requestObj = {
    path: `api/user_activity/get_user_activities/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _customer_detail = async (id) => {
  let requestObj = {
    path: `api/admin/customer_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
