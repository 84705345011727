import { invokeApi } from "src/bl_libs/invokeApi";
import { sourceBaseUrl, sourceToken } from "src/config/config";

export const templates_list = async () => {
  let requestObj = {
    path: `api/template/list_template_all`,
    method: "GET",
    source_baseUrl: sourceBaseUrl,
    headers: {
      "x-sh-auth": sourceToken,
    },
  };
  return invokeApi(requestObj);
};

export const template_group_list = async () => {
  let requestObj = {
    path: `api/template_group/list_template_group`,
    method: "GET",
    source_baseUrl: sourceBaseUrl,
    headers: {
      "x-sh-auth": sourceToken,
    },
  };
  return invokeApi(requestObj);
};

export const _trending_templates = async (postData) => {
  let requestObj = {
    path: `api/template/get_trending_templates`,
    method: "POST",
    source_baseUrl: sourceBaseUrl,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _trending_templates_group = async (postData) => {
  let requestObj = {
    path: `api/template_group/get_trending_template_groups`,
    method: "POST",
    source_baseUrl: sourceBaseUrl,
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const templates_pages = async (id) => {
  let requestObj = {
    path: `api/template/source_template_pages/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const templates_group_pages = async (id) => {
  let requestObj = {
    path: `api/page/source_template_group_pages/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const purchased_templates = async (id) => {
  let requestObj = {
    path: `api/user_template/list_user_templates/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
