import { Icon } from "@iconify/react";
import { LogoutOutlined } from "@mui/icons-material";
import { Box, Button, CircularProgress, Input } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _add_brand_logo } from "src/DAL/Website/BrandLogo";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import CustomConfirmation from "src/components/CustomConfirmation";
import { s3baseUrl } from "src/config/config";
import { MdDragHandle } from "react-icons/md";
import LogoModal from "./BrandLogoDialog";

export default function BrandLogo() {
  const [previewImage, setPreviewImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [openChange, setOpenChange] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const handleBrandLogoUpload = (e) => {
    setPreviewImage((prev) => [
      ...prev,
      {
        file: e.target.files[0],
        image: URL.createObjectURL(e.target.files[0]),
      },
    ]);
  };
  console.log(selectedIndex, "sfdf");

  const BrandLogoDetail = async () => {
    setLoading(true);
    const logos = await _landing_page_detail();
    if (logos.code === 200) {
      setLoading(false);
      setPreviewImage(
        logos?.data?.brand_logo?.brand_logo.map((x) => {
          return {
            image: x.image,
          };
        })
      );
    }
  };

  console.log(previewImage, "tysdf");

  useEffect(() => {
    BrandLogoDetail();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempLogo = [...previewImage];
    console.log(tempLogo, "tasyfdstya");

    for (let i = 0; i < tempLogo.length; i++) {
      if (!tempLogo[i].file) {
        continue;
      }
      if (tempLogo[i]._id) {
        delete tempLogo[i]._id;
      }

      const formData = new FormData();

      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", tempLogo[i].file);

      const image_resp = await _upload_file_on_s3(formData);

      if (image_resp.code === 200) {
        tempLogo[i] = { ...tempLogo[i], image: image_resp.file_name };
      }
    }

    console.log(tempLogo, "tempLogo");
    const images = tempLogo.map((img) => {
      return { image: img.image };
    });

    console.log(images, "images");
    const postData = { brand_logo: [...images] };
    console.log(postData);
    const data = await _add_brand_logo(postData);
    console.log(data, "fstyadfyast");
    if (data.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      BrandLogoDetail();
    } else {
      enqueueSnackbar(data.message, { variant: "error" });
    }
  };
  const handleConfirmDelete = (i) => {
    setPreviewImage((prev) => prev.filter((val, index) => index !== i));
    setOpenDelete(false);
  };

  const handleDelete = (i) => {
    setSelectedIndex(i);
    setOpenDelete(true);
  };

  const handleDragEnd = (results) => {
    if (!results.destination) return;
    let tempLogos = [...previewImage];
    let [selectedLogo] = tempLogos.splice(results.source.index, 1);

    tempLogos.splice(results.destination.index, 0, selectedLogo);
    console.log(results, "saftyfdyt", selectedLogo);
    setPreviewImage(tempLogos);
  };

  const handleEdit = (row, i) => {
    console.log(row, "astydfsty");
    setSelectedIndex(i);
    setSelectedData(row);
    setOpenChange(true);
  };
  console.log(selectedIndex, "previewImagepreviewImage");
  return (
    <div style={{ width: "94%", margin: "auto" }}>
      <LogoModal
        open={openChange}
        setOpen={setOpenChange}
        selectedIndex={selectedIndex}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        dataArray={previewImage}
        setDataArray={setPreviewImage}
      />
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <h3 className="text-center pb-3">Add Brand Logo</h3>

            <label htmlFor="icon-button-file">
              <Input
                style={{ display: "none" }}
                accept="image/*"
                id="icon-button-file"
                type="file"
                name="file"
                onChange={handleBrandLogoUpload}
              />
              <Button
                aria-label="upload picture"
                component="span"
                className="upload-button mt-3"
              >
                Add Brand Logo
              </Button>
            </label>
          </div>
          <div>
            <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
              <Droppable droppableId="dropable1">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {previewImage?.map((val, i) => (
                      <Draggable
                        draggableId={`dragable-${i}`}
                        index={i}
                        key={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            onClick={() => setSelectedIndex(i)}
                            className={`${
                              snapshot.isDragging ? "shadow" : null
                            } row border m-1 mt-2  px-1 py-2 align-items-center`}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              background: snapshot.isDragging ? "white" : null,
                            }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div {...provided.droppableProps}>
                                <MdDragHandle size={25} />
                              </div>
                              <img
                                style={{
                                  width: "4rem",
                                  height: "3rem",
                                }}
                                src={
                                  val?.image?.startsWith("blob:")
                                    ? val?.image
                                    : s3baseUrl + "/" + val.image
                                }
                                alt=""
                              />

                              <div>
                                <Icon
                                  onClick={() => handleEdit(val, i)}
                                  style={{
                                    color: "green",
                                    cursor: "pointer",
                                    marginRight: "5rem",
                                  }}
                                  icon={"fa-regular:edit"}
                                />
                                <Icon
                                  onClick={() => handleDelete(i)}
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                  icon={"ic:baseline-delete"}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="text-end mt-3">
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </div>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete ?"}
            handleAgree={() => handleConfirmDelete(selectedIndex)}
          />
        </>
      )}
    </div>
  );
}
