import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { _project_subscriber_listing } from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
// import { s3baseUrl } from "src/config/config";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import dummyImage from "../../images/website-placeholder.png";

export default function DomainList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(true); // Loading state
  const [domain, setDomain] = useState("");
  const [projectName, setProjectName] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [status, setStatus] = useState("false");
  const pageSubscriberList = async () => {
    const project_list_response = await _project_subscriber_listing(id);
    setData(project_list_response.project_detail);
    setLoading(false);
    // console.log(project_list_response, "sublistdomain");
    setDomain(project_list_response.project.project_detail.domain);
    // console.log(data, "datattatat");

    setProjectName(project_list_response.project.project_detail.project_name);
    // console.log(
    //   project_list_response.project.project_detail.project_name,
    //   "projectname"
    // );

    setCardImage(project_list_response.project.project_detail.image);

    // console.log(project_list_response.project.project_detail.image, "image");

    setStatus(project_list_response.project.project_detail.ssl_status);
    // console.log(status, "sslstatus");
  };

  useEffect(() => {
    pageSubscriberList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = () => {
    // console.log("Delete page:", selectedPage);
    handleMenuClose();
  };
  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  return (
    <Card>
      <CardContent>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {cardImage && (
              <Box
                sx={{
                  width: 120,
                  height: 70,
                  border: "1px solid #ccc",
                  borderRadius: "0.4rem",
                  float: "left",

                  marginRight: "1rem",
                  marginBottom: "1.4rem",
                }}
              >
                {" "}
                {/* <img
                  src={s3baseUrl + "/" + cardImage}
                  alt="Page Image"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "0.4rem",
                  }}
                /> */}
                <img
                  src={cardImage ? s3baseUrl + "/" + cardImage : dummyImage}
                  alt={cardImage ? "Page Image" : ""}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = dummyImage;
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "0.4rem",
                  }}
                />
              </Box>
            )}
            {projectName && (
              <Typography
                gutterBottom
                sx={{ fontSize: "14px", fontWeight: 400 }}
              >
                <strong>{projectName}</strong>
              </Typography>
            )}

            {domain && domain.length > 0 ? (
              <>
                <Box
                  sx={{
                    color: "grey",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  <strong>Domain:</strong>
                  <span
                    className="ms-2"
                    onClick={() => {
                      if (domain && domain.length > 0)
                        window.open("http://" + domain);
                    }}
                  >
                    {domain}
                  </span>
                </Box>
              </>
            ) : (
              <Box
                textAlign="left"
                sx={{
                  color: "grey",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                <strong>Domain: &nbsp; </strong>
                No Domain Attached
              </Box>
            )}

            {status && (
              <Box
                textAlign="left"
                sx={{
                  color: "grey",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                <strong>SSL Status: </strong>
                {status}
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
