import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  // Box,
  Button,
  // CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function DescriptionModal({
  open,
  setOpen,
  item,
  setItem,
  selectedData,
  selectedIndex,
  setSelectedData,
}) {
  const [menuDataObj, setMenuDataObj] = useState({
    image: "",
    title: "",
    description: "",
  });

  const handleChange = (e) => {
    setMenuDataObj((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    if (selectedData) {
      setMenuDataObj(selectedData);
    }
  }, [selectedData]);
  const handleImageUpload = (e) => {
    setMenuDataObj((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      };
    });
  };

  const handleAddData = () => {
    let newArr = item.menu_items;
    newArr.push(menuDataObj);
    setItem({ ...item, menu_items: newArr });
    handleClose();
  };

  const handleEditData = () => {
    let newArr = item.menu_items;
    newArr[selectedIndex] = menuDataObj;

    setItem({ ...item, menu_items: newArr });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedData({ title: "", description: "", image: "" });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {selectedData?.image ||
              selectedData?.title ||
              selectedData?.description
                ? "Edit Menu Item Data"
                : "Menu Item Data"}
            </p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid className="mt-3">
            <>
              <TextField
                label="menu title"
                style={{ paddingBottom: "10px" }}
                fullWidth
                name="title"
                value={menuDataObj?.title}
                onChange={handleChange}
              />
              <p> Enter description</p>
              <GeneralCkeditor
                inputs={menuDataObj}
                name="description"
                value={menuDataObj?.description}
                setInputs={setMenuDataObj}
                onChange={handleChange}
              />

              <div className="text-center mt-3">
                <img
                  style={{
                    width: "15rem",
                    height: "10rem",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={
                    menuDataObj?.local_image
                      ? menuDataObj?.local_image
                      : s3baseUrl + "/" + selectedData?.image
                  }
                  alt="choose pic"
                />
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="image"
                    onChange={handleImageUpload}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button mt-3"
                  >
                    {selectedData?.image !== ""
                      ? "Change Image"
                      : "Upload MenuItem Image"}
                  </Button>
                </label>
              </div>
            </>
          </Grid>

          <div className="text-end mt-3">
            {selectedData?.image ||
            selectedData?.title ||
            selectedData?.description ? (
              <>
                <Button variant="contained" onClick={handleEditData}>
                  Edit Data
                </Button>
              </>
            ) : (
              <>
                <Button variant="contained" onClick={handleAddData}>
                  Add Data
                </Button>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
