// here we add all base urls and keys

// usama backend server
// export const baseUri = 'http://192.168.0.215:4605/';

// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;
export const clientBaseUri = process.env.REACT_APP_CLIENT_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const builderUrl = process.env.REACT_APP_EDITOR_URL;
export const sourceBaseUrl = process.env.REACT_APP_SOURCE_BASE_URL;
export const sourceToken = process.env.REACT_APP_TOKEN_SOURCE;
export const sourceS3BaseUrl = process.env.REACT_APP_SOURCE_BUCKET_BASE_URL;
export const editorApiKey = process.env.REACT_APP_EDITOR_API_KEY;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
