import { Icon } from "@iconify/react";
import "react-phone-input-2/lib/style.css";
// material
import {
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Switch,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { _edit_role } from "src/DAL/Management/role_api";
import {
  _add_sub_admin,
  _edit_sub_admin,
} from "src/DAL/Management/subadmin_api";
import Label from "src/components/Label";
import AddEditRole from "../Role/AddEditRole";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AddEditSubAdmin({
  isOpenDrawer,
  onCloseDrawer,
  getSubAdminListing,
  is_edit = false,
  is_detail = false,
  targetSubAdmin = {},
  roleList,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [showDetailModalOpen, setShowDetailModalOpen] = useState(false);
  const [targetRole, setTargetRole] = useState(false);

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    status: true,
    contact_number: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const [navbarListing, setNavbarListing] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeStatus = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: !inputs[e.target.name],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const postData = {
      role_id: inputs.role_id,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      email: inputs.email,
      password: inputs.password,
      status: inputs.status,
      contact_number: inputs.contact_number,
    };

    const edit_postData = {
      role_id: inputs.role_id,
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      status: inputs.status,
      contact_number: inputs.contact_number,
    };
    const resp = is_edit
      ? await _edit_sub_admin(targetSubAdmin._id, edit_postData)
      : await _add_sub_admin(postData);
    console.log(resp, "customeradded");
    if (resp.code == 200) {
      onCloseDrawer();
      getSubAdminListing();
      enqueueSnackbar(
        `Sub-Admin ${is_edit ? "updated" : "added"} successfully`,
        {
          variant: "success",
        }
      );
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const handleShowDetailModal = () => {
    setTargetRole(roleList.find((role) => role._id === inputs.role_id));
    setShowDetailModalOpen(true);
  };

  useEffect(() => {
    if (is_edit || is_detail) {
      setInputs({
        first_name: targetSubAdmin.first_name,
        last_name: targetSubAdmin.last_name,
        role_id: targetSubAdmin.role_id ? targetSubAdmin.role_id : "",
        email: targetSubAdmin.user_id.email,
        status: targetSubAdmin.status,
        contact_number: targetSubAdmin.contact_number,
      });
    } else {
      setInputs({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        status: true,
        role_id: "",
        contact_number: "",
      });
    }
    setIsLoading(false);
  }, [isOpenDrawer]);

  return (
    <>
      <AddEditRole
        isOpenDrawer={showDetailModalOpen}
        onCloseDrawer={() => setShowDetailModalOpen(false)}
        getRoleListing={() => {}}
        is_edit={false}
        is_detail={true}
        targetRole={targetRole}
      />
      <Dialog
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head" style={{ paddingBottom: "13px" }}>
            <span>
              {is_detail
                ? "Sub-Admin Details"
                : is_edit
                ? "Edit Sub-Admin"
                : "Add Sub-Admin"}
            </span>
            <Icon
              icon="maki:cross"
              onClick={onCloseDrawer}
              style={{ cursor: "pointer" }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="card-main mt-2 ">
            <form onSubmit={handleSubmit} className="w-100">
              <Stack spacing={3} padding={2}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="First name"
                    required
                    name="first_name"
                    value={inputs.first_name}
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      readOnly: is_detail,
                    }}
                  />

                  <TextField
                    fullWidth
                    label="Last name"
                    required
                    name="last_name"
                    value={inputs.last_name}
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      readOnly: is_detail,
                    }}
                  />
                </Stack>
                <TextField
                  fullWidth
                  label="Contact Number"
                  type="number"
                  name="contact_number"
                  value={inputs.contact_number}
                  onChange={(e) => handleChange(e)}
                  InputProps={{
                    readOnly: is_detail,
                  }}
                />
                {!is_edit && (
                  <TextField
                    fullWidth
                    type="email"
                    label="Email address"
                    required
                    value={inputs.email}
                    name="email"
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      readOnly: is_detail,
                    }}
                  />
                )}

                {!is_edit && !is_detail && (
                  <TextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    label="Password"
                    required
                    value={inputs.password}
                    name="password"
                    onChange={(e) => handleChange(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowPassword((prev) => !prev)}
                          >
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                {is_detail ? (
                  <TextField
                    fullWidth
                    label="Role"
                    required
                    value={
                      inputs.role_id
                        ? roleList.find((role) => role._id === inputs.role_id)
                            ?.title
                        : ""
                    }
                    name="role_list"
                    InputProps={{
                      readOnly: is_detail,
                    }}
                  />
                ) : (
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-label">
                      Select Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={inputs.role_id}
                      label="Select Role"
                      onChange={handleChange}
                      name="role_id"
                    >
                      {roleList.map((role) => (
                        <MenuItem value={role._id}>{role.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {is_detail ? (
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3">Status: </span>
                      <Label
                        variant="ghost"
                        color={(inputs.status && "success") || "error"}
                      >
                        {inputs.status ? "Active" : "Inactive"}
                      </Label>
                    </div>
                    <div>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => handleShowDetailModal()}
                      >
                        Role Details
                      </Button>
                    </div>
                  </div>
                ) : (
                  <FormControlLabel
                    className="justify-content-end m-0 mt-3"
                    value="start"
                    control={
                      <Switch
                        checked={inputs.status}
                        value={inputs.status}
                        onChange={handleChangeStatus}
                        color="primary"
                        name="status"
                      />
                    }
                    label="Status:"
                    labelPlacement="start"
                  />
                )}
              </Stack>

              {!is_detail && (
                <DialogActions>
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                  >
                    {is_edit ? "Update Sub-Admin" : "Add Sub-Admin"}
                  </LoadingButton>
                </DialogActions>
              )}
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
