import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";

const PreviewKey = ({ open, setIsPreviewKey, data }) => {
  console.log(data, "dattaaaaaaaaaaaaaa");
  const { enqueueSnackbar } = useSnackbar();
  const [isShowKey, setIsShowKey] = useState(false);

  const handlClose = () => {
    setIsPreviewKey(false);
    setIsShowKey(false);
  };

  if (!data || !data.value) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handlClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      size="large"
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1.5}
        >
          <Typography variant="h5">
            {" "}
            {data.key
              ?.split("_")
              ?.map((word) => word?.charAt(0).toUpperCase() + word.slice(1))
              ?.join(" ")}
          </Typography>
          <IconButton onClick={handlClose} style={{ marginLeft: 5 }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {" "}
            {isShowKey === false
              ? data.value.length > 12
                ? `${data.value.substring(0, 7)}${"*".repeat(
                    data.value.length - 12
                  )}${data.value.substring(data.value.length - 5)}`
                : data.value.length > 8
                ? "*".repeat(data.value.length)
                : "*".repeat(data.value.length)
              : data?.value}
          </div>
          <div>
            {isShowKey === false ? (
              <Button
                onClick={() => {
                  setIsShowKey(true);
                }}
                variant="outlined"
                // className="me-3"
              >
                Show Key
              </Button>
            ) : (
              <>
                {/* {data.value} */}
                <Button
                  onClick={() => {
                    setIsShowKey(false);
                  }}
                  variant="outlined"
                >
                  Hide Key
                </Button>
              </>
            )}
            <Button
              onClick={() => {
                navigator.clipboard.writeText(data.value);
                enqueueSnackbar("Key copied to clipboard", {
                  variant: "success",
                });
              }}
              variant="outlined"
              className="ms-2"
            >
              Copy Key
            </Button>
          </div>
        </div>
        {/* <div
          style={{
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              navigator.clipboard.writeText(data.value);
              enqueueSnackbar("Key copied to clipboard", {
                variant: "success",
              });
            }}
            variant="outlined"
          >
            Copy Key
          </Button>
        </div> */}
      </DialogContent>
    </Dialog>
  );
};

export default PreviewKey;
