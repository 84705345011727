import React from "react";
import { Icon } from "@iconify/react";

function Instructions() {
  return (
    <>
      <div>
        <div className="d-flex">
          <h5 className="me-2">Instructions:</h5>
          <p>
            {" "}
            Please follow the instructions below to use the module effectively.
          </p>
        </div>{" "}
        <Icon
          fontSize="25"
          icon="material-symbols:send-outline"
          className="mb-1"
          color="#367588"
        />{" "}
        Use the dropdown menu to select the type of information you want to
        search for. The available search types are:{" "}
        <ol>
          <li>
            <strong className="chnage-status-headings">Customer Email:</strong>{" "}
            Select this option to search for a customer & customer's project
            listing using their email address.
          </li>
          <li>
            <strong className="chnage-status-headings">
              Project Live Domain:
            </strong>{" "}
            Select this option to search for a customer's project using a valid
            domain.
          </li>
          <li>
            <strong className="chnage-status-headings">Project S3 Link:</strong>{" "}
            Select this option to search for a project's page using a valid S3
            link.
          </li>
          <li>
            <strong className="chnage-status-headings">Project ID:</strong>{" "}
            Select this option to search for a project using its unique project
            ID.
          </li>
        </ol>
      </div>
    </>
  );
}

export default Instructions;
