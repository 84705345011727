/* eslint-disable */
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import {
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import React, { useEffect, useState } from "react";
import { _add_subscription_by_admin, _plan_list } from "src/DAL/plans/plan";
import { useSnackbar } from "notistack";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import TimePicker from "src/components/DatePicker/TimePicker";

function AssignPlan({ isDrawerOpenAssign, handleCloseAssign, selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    plan_id: "",
    amount: "",
    subscription_end_date: "",
    // subscription_end_time: "",
    grace_period_days: "",
    transaction_status: true,
  });
  const [planList, setPlanList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const customer_name = selectedRow?.first_name + " " + selectedRow?.last_name;
  const email = selectedRow?.email;

  const handleChange = (e) => {
    setInputs((prev) => {
      let updatedInputs = { ...prev, [e.target.name]: e.target.value };
      if (e.target.name === "subscription_end_date") {
        updatedInputs["subscription_end_date"] = formatDate(e.target.value);
        console.log(formatDate(), "dateformat");
      } else if (e.target.name === "plan_id") {
        let plan = planList.find((plan) => plan._id === e.target.value);
        updatedInputs["amount"] = plan?.amount || "";
      }
      return updatedInputs;
    });
  };

  const handleChangeStatus = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: !inputs[e.target.name],
    });
  };

  const formatDate = (dateString) => {
    console.log(dateString, "dateeee");
    return moment(dateString).format("YYYY-MM-DD");
  };

  const getPlans = async () => {
    const response = await _plan_list();
    if (response.code === 200) {
      setPlanList(response.data);
      console.log(response.data, "planList");
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  };

  const getCurrencySymbol = (locale, currency) => {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  const handleSubmit = async () => {
    const postData = {
      plan_id: inputs.plan_id,
      amount: inputs.amount,
      subscription_end_date: moment(inputs.subscription_end_date).format(
        "MM-DD-YYYY"
      ),
      // subscription_end_time: moment(
      //   inputs.subscription_end_time,
      //   "h:mm:ss A"
      // ).format("HH:mm:ss"),
      grace_period_days: inputs.grace_period_days,
      transaction_status: inputs.transaction_status,
    };
    console.log(postData, "postdata");
    const id = selectedRow?.user_id?._id;
    setIsLoading(true);
    const response = await _add_subscription_by_admin(id, postData);
    console.log(response, "response");
    if (response.code === 200) {
      setInputs([]);
      handleCloseAssign();
      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, "planAssignError");
    }
  };

  // const options = [3, 5, 7, 11, 15, 17];
  const options = [
    { value: 1, label: "day" },
    { value: 3, label: "days" },
    { value: 5, label: "days" },
    { value: 7, label: "days" },
    { value: 9, label: "days" },
    { value: 11, label: "days" },
    { value: 13, label: "days" },
  ];

  useEffect(() => {
    getPlans();
    setInputs({
      plan_id: "",
      amount: "",
      subscription_end_date: "",
      grace_period_days: "",
      transaction_status: true,
    });
  }, [isDrawerOpenAssign]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isDrawerOpenAssign}
        onClose={handleCloseAssign}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Assign Plan
          </Typography>
          <IconButton onClick={handleCloseAssign}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />
        <Scrollbar>
          <div style={{ padding: "13px" }}>
            <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.8 }}>
              Customer Detail
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // padding: "10px",
              }}
            >
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.5, mr: 1 }}>
                  Name:
                </Typography>
                {customer_name}
              </div>
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.3, mr: 1 }}>
                  Email:
                </Typography>
                {email}
              </div>
            </div>
          </div>

          <Divider />

          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Stack spacing={3} sx={{ p: 3 }}>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose a plan*
                </InputLabel>
                <Select
                  required
                  label="Choose a plan"
                  name="plan_id"
                  value={inputs.plan_id}
                  onChange={handleChange}
                >
                  {planList
                    .filter((plan) => plan.amount > 0 && plan.status)
                    .map((plan) => (
                      <MenuItem key={plan?._id} value={plan?._id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{plan.product_name}</div>
                          <div>
                            {getCurrencySymbol("en-US", plan.currency)}
                            {plan.amount}
                            {`/${plan.interval_time}`}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <TextField
                  required
                  fullWidth
                  label="Amount"
                  name="amount"
                  value={inputs.amount}
                  onChange={handleChange}
                  // InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="End Date"
                  type="date"
                  value={inputs.subscription_end_date}
                  name="subscription_end_date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  inputProps={{ min: new Date().toISOString().split("T")[0] }}
                />
              </FormControl>
              {/* <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="End Time"
                  type="time"
                  // defaultValue={new Date()}
                  value={inputs.subscription_end_time}
                  name="subscription_end_time"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </FormControl> */}
              <FormControl fullWidth sx={{ mb: 1, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Grace Period Days*
                </InputLabel>
                <Select
                  value={inputs.grace_period_days}
                  isMulti
                  required
                  label="Grace Period Days"
                  name="grace_period_days"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      minHeight: 50,
                    }),
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${option.value} ${option.label}`}
                    </MenuItem>
                  ))}
                </Select>
                <p
                  style={{
                    color: "gray",
                    padding: "10px 10px 0px 10px ",
                    marginBottom: "0px",
                  }}
                >
                  ( The grace period starts after the end date. During this
                  grace period, the account functionality remains the same. At
                  that time user can pay again without losing any data or
                  access.)
                </p>
              </FormControl>

              <FormControlLabel
                sx={{ marginTop: "0px" }}
                className="justify-content-between"
                value="start"
                control={
                  <Checkbox
                    sx={{ marginTop: "0px" }}
                    checked={inputs.transaction_status}
                    value={inputs.transaction_status}
                    onChange={handleChangeStatus}
                    color="primary"
                    name="transaction_status"
                  />
                }
                label="Do you want to create transaction?"
                labelPlacement="start"
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Assign Plan
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

export default AssignPlan;
