import PropTypes from "prop-types";
// material
import { Paper, Typography } from "@mui/material";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({
  searchQuery = "",
  icon = false,
  ...other
}) {
  return (
    <Paper
      {...other}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography> */}
      {icon && (
        <div
          style={{
            fontSize: "60px",
            background: "#02337310",
            margin: "10px",
            padding: "10px",
            borderRadius: "50%",
            height: "100px",
            width: "100px",
            marginBottom: "20px",
          }}
        >
          <Icon
            icon={icon}
            style={{
              verticalAlign: "text-top",
            }}
          />
        </div>
      )}
      <Typography variant="h5" align="center">
        No data found
        {/* for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words. */}
      </Typography>
    </Paper>
  );
}
