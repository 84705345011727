import { invokeApi } from "src/bl_libs/invokeApi";

export const _website_create = (data) => {
    const req_obj = {
        method:"POST",
        path:"api/landing_page/more_then_create_website",
        headers:{"x-sh-auth":localStorage.getItem("token")},
        postData:data
    }
    return invokeApi(req_obj)
}