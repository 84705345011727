import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { _cancel_subscription } from "src/DAL/Subscription/Subscription";

function CancelSubscriptions({
  selectedRow,
  handleCloseCancel,
  isDialogOpenCancel,
  subscriptionListing,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState();

  const handleSubmit = async () => {
    const id = selectedRow?._id;
    const postData = {
      message: reason,
      user_id: selectedRow?.user_id?._id,
    };
    setLoading(true);
    const response = await _cancel_subscription(id, postData);
    if (response.code === 200) {
      setLoading(false);
      setReason(response.data.message);
      subscriptionListing();
      handleCloseCancel();
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, "errorInCancelSubs");
    }
  };

  const getCurrencySymbol = (currency, locale) => {
    try {
      return (0)
        .toLocaleString(locale, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
    } catch (error) {
      console.error("Error getting currency symbol:", error);
      return "";
    }
  };

  return (
    <>
      <Dialog
        open={isDialogOpenCancel}
        onClose={handleCloseCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        size="large"
      >
        <div
          style={{
            padding: "2% 4% 2% 4%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography noWrap variant="h6">
            Cancel Subscription Confirmation
          </Typography>
          <IconButton onClick={handleCloseCancel} style={{ color: "grey" }}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div
          style={{
            padding: "2% 4% 0% 4%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Typography
              style={{
                whiteSpace: "nowrap",
                fontSize: "0.875rem",
                fontWeight: "bold",
              }}
            >
              Plan Name:
            </Typography>
            <p
              style={{
                whiteSpace: "nowrap",
                fontSize: "0.875rem",
                padding: 0,
                margin: 0,
              }}
            >
              {selectedRow?.plan_obj?.product_name}
            </p>
          </div>
          <Divider className="divider" />
        </div>
        <div
          className="d-flex flex-column "
          style={{
            padding: "1.5% 4% 5% 4%",
          }}
        >
          <Stack gap={1} direction="column">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                }}
              >
                Amount:
              </Typography>
              <p
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  padding: 0,
                  margin: 0,
                }}
              >
                {selectedRow
                  ? getCurrencySymbol(selectedRow?.currency, "en-US")
                  : "$"}
                {selectedRow?.amount}
              </p>
            </div>
            <Divider className="divider" />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                }}
              >
                Subscription Start Date:
              </Typography>
              <p
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  padding: 0,
                  margin: 0,
                }}
              >
                {selectedRow?.subscription_start_date
                  ? moment(selectedRow?.subscription_start_date).format("LL")
                  : "-"}
              </p>
            </div>
            <Divider className="divider" />
            <div
              style={{
                whiteSpace: "nowrap",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                }}
              >
                Subscription End Date:
              </Typography>
              <p
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  padding: 0,
                  margin: 0,
                }}
              >
                {selectedRow?.subscription_end_date
                  ? moment(selectedRow?.subscription_end_date).format("LL")
                  : "-"}
              </p>
            </div>
            <Divider className="divider" />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  fontWeight: "bold",
                }}
              >
                Next Renewal Date:
              </Typography>
              <p
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "0.875rem",
                  padding: 0,
                  margin: 0,
                }}
              >
                {selectedRow?.subscription_renewal_date
                  ? moment(selectedRow?.subscription_renewal_date).format("LL")
                  : "-"}
              </p>
            </div>
            <Divider className="divider" />
          </Stack>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="mt-3">
              <Typography variant="h6" sx={{ marginBottom: "1.6%" }}>
                What is the reason for cancelling this subscription?
              </Typography>
              <TextField
                required
                fullWidth
                id="outlined-multiline-static"
                className="mb-3"
                label="Reason"
                multiline
                rows={4}
                placeholder="Enter your reason here..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <div className="d-flex justify-content-end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  size="lg"
                  fullWidth
                  type="submit"
                  style={{ paddingTop: "1.2%", paddingBottom: "1.2%" }}
                >
                  Cancel Subscription
                </LoadingButton>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}

export default CancelSubscriptions;
