/* eslint-disable react/jsx-no-target-blank */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import Label from "src/components/Label";
import { get_short_string } from "src/utils/utils";
import { Icon } from "@iconify/react";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { detail_project_for_admin } from "src/DAL/projects/projects";

function ProjectDetail({ isLoading, detail, pageslug }) {
  // console.log(pageslug, "prroooooooooppppppppSlug");
  const { enqueueSnackbar } = useSnackbar();
  const get_valid_link = (domain, ssl_status) => {
    let _domain = domain;
    _domain = _domain.replaceAll("https://", "");
    _domain = _domain.replaceAll("http://", "");
    if (ssl_status) {
      return "https://" + _domain;
    } else {
      return "http://" + _domain;
    }
  };
  const bgColor = (val) => {
    return (
      <div>
        <Label className="bg">{val}</Label>
      </div>
    );
  };

  const status = (val) => {
    return (
      <>
        <Label color={detail?.is_published === true ? "success" : "error"}>
          {val}
        </Label>
      </>
    );
  };

  const sslStatus = (val) => {
    return (
      <>
        <Label color={detail?.ssl_status === true ? "success" : "error"}>
          {val}
        </Label>
      </>
    );
  };

  const projectStatus = (val) => {
    return (
      <>
        <Label color={detail?.status === true ? "success" : "error"}>
          {val}
        </Label>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography className="mb-2 mt-3" variant="subtitle1">
            Project Detail:
          </Typography>
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="accordion-summary">
                <Stack direction="row">
                  {/* <div style={{ display: "flex" }}> */}
                  <div className="accordion-summary-content">
                    <Typography variant="body2" className="me-1">
                      <b>Project Name:</b>
                    </Typography>
                    <Typography variant="body2">
                      {detail ? detail?.project_name : "-"}
                    </Typography>
                  </div>
                  <div className="accordion-summary-content">
                    <Typography variant="body2" className="me-1">
                      <b>Pages Count:</b>
                    </Typography>
                    <Typography variant="body2">
                      {detail ? bgColor(detail.project_pages_count) : "-"}
                    </Typography>
                  </div>

                  <div className="accordion-summary-content">
                    <Typography variant="body2" className="me-1">
                      <b>Funnel Count:</b>
                    </Typography>
                    <Typography variant="body2">
                      {detail ? bgColor(detail.funel_count) : "-"}
                    </Typography>
                  </div>
                  {/* </div> */}
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  className="me-2"
                  spacing={0.5}
                >
                  <span>
                    <div style={{ color: "#cc4356", marginBottom: "3px" }}>
                      <Tooltip title="Status">
                        {detail
                          ? projectStatus(
                              detail?.status === true ? "Active" : "Inactive"
                            )
                          : "-"}
                      </Tooltip>
                    </div>
                  </span>
                  {/* <span>
                    {detail?.is_published === true ? (
                      <div style={{ color: "#367588" }}>
                        <Tooltip title="Published">
                          <Icon
                            icon="ic:baseline-published-with-changes"
                            height="25px"
                            width="25px"
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div style={{ color: "#367588" }}>
                        <Tooltip title="Unpublished">
                          <Icon
                            icon="material-symbols:unpublished-outline-rounded"
                            height="25px"
                            width="25px"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </span> */}
                  {/* <span>
                    {detail?.ssl_status === true ? (
                      <div style={{ color: "#367588" }}>
                        <Tooltip title="SSL Status">
                          <Icon
                            icon="mdi:unlocked"
                            height="25px"
                            width="25px"
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div style={{ color: "#367588" }}>
                        <Tooltip title="SSL Status">
                          <Icon
                            icon="material-symbols:lock"
                            height="25px"
                            width="25px"
                          />
                        </Tooltip>
                      </div>
                    )}
                  </span> */}

                  <Typography variant="body2" color="gray">
                    {detail.createdAt ? (
                      moment(detail.createdAt).format("LLL")
                    ) : (
                      <span>N/A</span>
                    )}
                  </Typography>
                </Stack>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="accordion-details">
                <TableContainer
                  className="DetailDialogBorder"
                  component={Paper}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Project Preview
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          <Button
                            // variant="outlined"
                            onClick={() => {
                              window.open(
                                `${s3baseUrl}/${detail?._id}/index.html`
                              );
                            }}
                          >
                            Preview
                          </Button>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Project Id
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          {detail ? detail?._id : "-"}{" "}
                          <Button
                            onClick={() => {
                              console.log(detail?._id, "projectIDdddddd");
                              navigator.clipboard?.writeText(detail?._id);
                              enqueueSnackbar("Email copied to clipboard", {
                                variant: "success",
                              });
                            }}
                            size="small"
                          >
                            <Icon
                              className="me-1"
                              height="20px"
                              width="20px"
                              icon="tabler:copy"
                            />
                            Copy Id
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          SSl Status
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          {detail
                            ? sslStatus(
                                detail?.ssl_status === true
                                  ? "Active"
                                  : "Inactive"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Published
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          {detail
                            ? status(
                                detail?.is_published === true ? "YES" : "NO"
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Subscriber Count
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          {detail ? bgColor(detail.subscriber_count) : "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Updated At
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          {detail ? (
                            moment(detail.updatedAt).format("LLL")
                          ) : (
                            <span>-</span>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Project Domain
                        </TableCell>
                        <TableCell align="start" className="value-section">
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {detail?.domain ? (
                              <a
                                href={get_valid_link(
                                  detail?.domain,
                                  detail?.ssl_status
                                )}
                                target="_blank"
                              >
                                {detail?.domain}
                              </a>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="name-section"
                        >
                          Edit Source Code
                        </TableCell>
                        <TableCell>
                          {detail ? (
                            <Button
                              // variant="outlined"
                              onClick={() => {
                                const projectId = detail?._id;
                                if (projectId) {
                                  const url = `/projects/edit-source-code/project/${projectId}`;
                                  window.open(url, "_blank");
                                }
                              }}
                            >
                              Source Code
                            </Button>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography className="mb-2 mt-3" variant="subtitle1">
                  Project Pages:
                </Typography>
                <TableContainer
                  className="DetailDialogBorder"
                  component={Paper}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          Page Name
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          Page Slug
                        </TableCell>

                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          Domain Preview
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          Update At
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {detail && detail.pages && detail.pages?.length > 0 ? (
                        detail.pages.map((dat, index) => (
                          <TableRow
                            key={dat.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              backgroundColor:
                                !!pageslug &&
                                dat.page_slug === pageslug.split(".")[0]
                                  ? "#f0bb41"
                                  : "transparent",
                            }}
                          >
                            {console.log(dat, "dataaaaaaaaaaaaaa")}
                            <TableCell component="th" scope="row">
                              {index + 1}
                            </TableCell>

                            <TableCell>
                              {dat.page_name &&
                                get_short_string(dat.page_name, 20)}
                            </TableCell>

                            <TableCell
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <a
                                href={`${s3baseUrl}/${detail?._id}/${dat.page_slug}.html`}
                                target="_blank"
                                style={{ color: "black" }}
                              >
                                /{dat.page_slug}
                              </a>
                            </TableCell>
                            <TableCell>
                              {detail?.domain ? (
                                <>
                                  <a
                                    href={get_valid_link(
                                      `${detail?.domain}/${dat.page_slug}.html`
                                    )}
                                    target="_blank"
                                    style={{ color: "black" }}
                                  >
                                    <Button>Preview</Button>
                                  </a>
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>

                            <TableCell style={{ whiteSpace: "nowrap" }}>
                              {moment(dat.updatedAt).format("LLL")}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={12} align="center">
                            No Pages Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
}

export default ProjectDetail;
