import * as React from "react";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Label from "../../components/Label";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useParams } from "react-router-dom";
import { get_short_string } from "src/utils/utils";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import dummyImage from "../../images/website-placeholder.png";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function FunelPagesModal({ open, setOpen, detail,payment_id = null }) {
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [openDelete, setOpenDelete] = useState(false);
  const location = useLocation();
  const rowData = location?.state;
  const get_valid_link = (domain, ssl_status) => {
    let _domain = domain;

    _domain = _domain.replaceAll("https://", "");
    _domain = _domain.replaceAll("http://", "");
    if (ssl_status) {
      return "https://" + _domain;
    } else {
      return "http://" + _domain;
    }
  };

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head">
            <p>Funels Page List</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Table sx={{ minWidth: 650,marginTop:2 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell></TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Page Name
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Image</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Page Slug
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Domain Preview
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Page Version Count
                </TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Update At
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Meta Title
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Payment Id
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
             
                  {detail && detail.length > 0 ? (
                detail.map((dat, index) => (
                  <TableRow
                    key={dat.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="page-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuOpen(e, dat)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="page-menu"
                        anchorEl={anchorEl}
                        open={Boolean(
                          anchorEl && selectedPage && selectedPage.id === dat.id
                        )}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                      </Menu>
                    </TableCell>

                    <TableCell>
                      {dat.page_name && get_short_string(dat.page_name, 20)}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          width: 120,
                          height: 70,
                          border: "1px solid #ccc",
                          borderRadius: "0.4rem",
                        }}
                      >
                        <img
                          src={
                            dat.image ? s3baseUrl + "/" + dat.image : dummyImage
                          }
                          alt={dat.image ? "Page Image" : ""}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = dummyImage;
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "0.4rem",
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell style={{ cursor: "pointer" }}>
                      <a
                        href={`${s3baseUrl}/${rowData?._id}/${dat.page_slug}.html`}
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        /{dat.page_slug}
                      </a>
                    </TableCell>

                    <TableCell style={{ cursor: "pointer" }}>
                      {rowData?.domain ? (
                        <>
                          <a
                            href={get_valid_link(
                              `${rowData?.domain}/${dat.page_slug}.html`
                            )}
                            target="_blank"
                            style={{ color: "black" }}
                          >
                            <Button>Preview</Button>
                          </a>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>

                    <TableCell style={{ textAlign: "center" }}>
                      {dat.page_version_count}
                    </TableCell>

                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {moment(dat.updatedAt).format("LLL")}
                    </TableCell>

                    <TableCell style={{ textAlign: "center" }}>
                      {dat.meta_title || "-"}
                    </TableCell>

                    <TableCell>
                  {payment_id ? (
                    <Label
                      variant="ghost"
                      color={payment_id !== dat._id ? "error" : "success"}
                    >
                      {payment_id === dat._id ? "True" : "False"}
                    </Label>
                  ) : (
                    "-"
                  )}
                </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Pages Detail Found
                  </TableCell>
                </TableRow>
              )}
                
              
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
}
