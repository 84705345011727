/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { _customer_detail } from "src/DAL/customer/customer";
import { useSnackbar } from "notistack";
import Label from "../Label";
import { s3baseUrl } from "src/config/config";
import ImagePopover from "src/pages/Image-Popover/Image-Popover";
import moment from "moment";

function DetailDialog({ open, handleClose, selectedRow }) {
  console.log(selectedRow, "detailllRoowwwwwiiddd");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState({});
  // const selectedRow_id = selectedRow?.user_id?._id;
  // console.log(selectedRow_id, "rooowwIddd");

  const detailOfCustomer = async () => {
    setLoading(true);
    const response = await _customer_detail(selectedRow);
    console.log(response, "responnsseeeCustomer");
    if (response.code === 200) {
      setLoading(false);
      setDetail(response.customer);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          className={detail.type == [3] ? "label" : ""}
          variant="ghost"
          color={
            detail.type == [1]
              ? "success"
              : detail.type == [2]
              ? "success"
              : "error"
          }
        >
          {Active}
        </Label>
      </>
    );
  };

  const bgColor = (val) => {
    return (
      <div>
        <Label className="bg">{val}</Label>
      </div>
    );
  };

  const SubscriptionTypeStatus = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            (detail.subscription?.subscription_type === 0 && "success") ||
            (detail.subscription?.subscription_type === 1 && "primary") ||
            "default"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const SubscriptionStatus = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            (detail.subscription_status === 0 && "success") ||
            (detail.subscription_status === 1 && "primary") ||
            (detail.subscription_status === 2 && "warning") ||
            (detail.subscription_status === 3 && "error_dar") ||
            (detail.subscription_status === 4 ? "error" : "default") ||
            "default"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const TransactionStatus = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            (detail.transaction?.transaction_status ===
              "payment_intent.succeeded" &&
              "success") ||
            (detail.transaction?.transaction_status ===
              "payment_intent.payment_failed" &&
              "error") ||
            (detail.transaction?.transaction_status ===
              "payment_intent.requires_action" &&
              "warning") ||
            (detail.transaction?.transaction_status ===
              "payment_intent.canceled" &&
              "warning") ||
            (detail.transaction?.transaction_status ===
            "customer.subscription.deleted"
              ? "warning"
              : "default") ||
            "default"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const FreeTrialStatusFunc = (val) => {
    return (
      <>
        <Label
          color={
            detail.subscription?.free_trial_status === 0
              ? "error"
              : detail.subscription?.free_trial_status === 1
              ? "success"
              : detail.subscription?.free_trial_status === 2
              ? "success"
              : "-"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const FreeTrialGraceStatusFunc = (val) => {
    return (
      <>
        <Label
          color={
            detail.subscription?.free_trial_status === 0
              ? "error"
              : detail.subscription?.free_trial_status === 1
              ? "success"
              : detail.subscription?.free_trial_status === 2
              ? "success"
              : ""
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const BookaCallStatus = (val) => {
    return (
      <>
        <Label
          color={
            detail.plan_id?.book_a_call_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const FunnelStatus = (val) => {
    return (
      <>
        <Label
          color={detail.plan_id?.funnel_status === true ? "success" : "error"}
        >
          {val}
        </Label>
      </>
    );
  };

  const DomainFunc = (val) => {
    return (
      <>
        <Label
          color={
            detail.plan_id?.custom_domain_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const StripeStatusFunc = (val) => {
    return (
      <>
        <Label
          color={
            detail.plan_id?.stripe_payment_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  useEffect(() => {
    detailOfCustomer();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head">
            <p>Details</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DialogContent>
            <Typography className="mb-2 mt-3" variant="subtitle1">
              Customer Information:{" "}
            </Typography>
            <TableContainer className="DetailDialogBorder" component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      First Name
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.first_name ? (
                        detail.first_name.charAt(0).toUpperCase() +
                        detail.first_name.slice(1)
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Last Name
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.last_name ? (
                        detail.last_name.charAt(0).toUpperCase() +
                        detail.last_name.slice(1)
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Contact No.
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.contact_number ? (
                        detail.contact_number
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Email
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.user_id ? detail.user_id?.email : <span>-</span>}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Customer Type
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {IsActiveFunc(
                        detail.type == [1]
                          ? "Inovate"
                          : detail.type == [2]
                          ? "Inovate"
                          : detail.type == [3]
                          ? "Vission"
                          : ""
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Business Name
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.business_name ? (
                        detail.business_name
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Business Type
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail?.business_type?.length > 0 ? (
                        detail.business_type + "  " + "  "
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Post Code
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.post_code ? detail.post_code : <span>-</span>}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Project Count
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.project_count ? (
                        bgColor(detail.project_count)
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Pages Count
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.page_count ? (
                        bgColor(detail.page_count)
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Funnel Count
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.funel_count ? (
                        bgColor(detail.funel_count)
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Total Asserts File Size
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {(detail.total_asserts_file_size / 1073741824).toFixed(
                        0
                      ) +
                        "    GB " +
                        "  " +
                        "    -    " +
                        (detail.total_asserts_file_size / 1048576).toFixed(0) +
                        "  MB" +
                        "  " +
                        "    -    " +
                        (detail.total_asserts_file_size / 1024).toFixed(0) +
                        "   KB"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Total Asserts File Count
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {bgColor(detail.total_asserts_file_count)}
                    </TableCell>
                    {/* <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Total Asserts File Size
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {(detail.total_asserts_file_size / 1073741824).toFixed(
                        0
                      ) +
                        "    GB " +
                        "  " +
                        "    -    " +
                        (detail.total_asserts_file_size / 1048576).toFixed(0) +
                        "  MB" +
                        "  " +
                        "    -    " +
                        (detail.total_asserts_file_size / 1024).toFixed(0) +
                        "   KB"}
                    </TableCell> */}
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Created At
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.createdAt ? (
                        moment(detail.createdAt).format("LLL")
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Created At
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.createdAt ? (
                        moment(detail.createdAt).format("LLL")
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell> */}
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Updated At
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.updatedAt ? (
                        moment(detail.updatedAt).format("LLL")
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className="mb-2 mt-3" variant="subtitle1">
              Customer Plan Information:{" "}
            </Typography>
            <TableContainer className="DetailDialogBorder" component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Plan Name
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id ? (
                        detail.plan_id.product_name
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Plan Subtitle
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.product_description
                        ? detail.plan_id?.product_description
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Currency
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.currency ? (
                        detail.plan_id?.currency.toUpperCase()
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Plan Amount
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id ? detail.plan_id?.amount : <span>-</span>}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Plan Interval
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id ? (
                        detail.plan_id?.interval_time.charAt(0).toUpperCase() +
                        detail.plan_id?.interval_time.slice(1)
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Subscription Type
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription
                        ? SubscriptionTypeStatus(
                            detail.subscription?.subscription_type === 0
                              ? "Stripe Subscription"
                              : detail.subscription?.subscription_type === 1
                              ? "Custom Subscription"
                              : "-"
                          )
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Subscription Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail
                        ? SubscriptionStatus(
                            detail.subscription_status === 0
                              ? "Active"
                              : detail.subscription_status === 1
                              ? "Soon To Expire"
                              : detail.subscription_status === 2
                              ? "Grace Period"
                              : detail.subscription_status === 3
                              ? "Expired"
                              : detail.subscription_status === 4
                              ? "Cancelled"
                              : detail.subscription_status
                          )
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Subscription Start Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription?.subscription_start_date
                        ? moment(
                            detail.subscription?.subscription_start_date
                          ).format("LL")
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Subscription Expiry Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription_obj?.subscription_end_date
                        ? moment(
                            detail.subscription_obj?.subscription_end_date
                          ).format("LL")
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Subscription Renewal Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription_obj?.subscription_renewal_date
                        ? moment(
                            detail.subscription_obj?.subscription_renewal_date
                          ).format("LL")
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Subscription Cancel Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription_obj?.subscription_cancel_date
                        ? moment(
                            detail.subscription_obj?.subscription_cancel_date
                          ).format("LL")
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Transaction Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.transaction
                        ? TransactionStatus(
                            detail.transaction?.transaction_status ===
                              "payment_intent.succeeded"
                              ? "Succeed"
                              : detail.transaction?.transaction_status ===
                                "payment_intent.payment_failed"
                              ? "Rejected"
                              : detail.transaction?.transaction_status ===
                                "payment_intent.requires_action"
                              ? "Pending"
                              : detail.transaction?.transaction_status ===
                                "payment_intent.canceled"
                              ? "Canceled"
                              : detail.transaction?.transaction_status ===
                                "customer.subscription.deleted"
                              ? "Deleted"
                              : detail.transaction?.transaction_status
                          )
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Transaction Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.transaction
                        ? detail.transaction?.transaction_date
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Card
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.transaction ? detail?.transaction?.card : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Card Brand
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.transaction
                        ? detail?.transaction?.card_brand
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Free Trial Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription
                        ? FreeTrialStatusFunc(
                            detail.subscription?.free_trial_status === 0
                              ? "No"
                              : detail.subscription?.free_trial_status === 1
                              ? "Yes"
                              : detail.subscription?.free_trial_status === 2
                              ? "Completed"
                              : ""
                          )
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Free Trial Grace Period Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription
                        ? FreeTrialGraceStatusFunc(
                            detail.subscription
                              ?.free_trial_grace_period_status === 0
                              ? "Not Started"
                              : detail.subscription
                                  ?.free_trial_grace_period_status === 1
                              ? "Started"
                              : detail.subscription
                                  ?.free_trial_grace_period_status === 2
                              ? "Completed"
                              : ""
                          )
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Free Trial Start Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription?.free_trial_start_date
                        ? moment(
                            detail.subscription?.free_trial_start_date
                          ).format("LL")
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Free Trial End Date
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.subscription?.free_trial_end_date
                        ? moment(
                            detail.subscription?.free_trial_end_date
                          ).format("LL")
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Stripe Customer Id
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.stripe_customer_id ? (
                        detail.stripe_customer_id
                      ) : (
                        <span>N/A</span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography className="mb-2 mt-3" variant="subtitle1">
              Customer Plan Limitations:{" "}
            </Typography>
            <TableContainer className="DetailDialogBorder" component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Assert Size
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {(detail.plan_id?.assert_size / 1073741824).toFixed(0) +
                        "    GB " +
                        "  " +
                        "    N/A    " +
                        (detail.plan_id?.assert_size / 1048576).toFixed(0) +
                        "  MB" +
                        "  " +
                        "    N/A    " +
                        (detail.plan_id?.assert_size / 1024).toFixed(0) +
                        "   KB"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Projects
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.projects_count
                        ? bgColor(detail.plan_id?.projects_count)
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Projects Version
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.projects_version_count
                        ? bgColor(detail.plan_id?.projects_version_count)
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Pages
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.pages_count
                        ? bgColor(detail.plan_id?.pages_count)
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Pages Version
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.pages_version_count
                        ? bgColor(detail.plan_id?.pages_version_count)
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed AI Token
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.allowed_ai_token
                        ? bgColor(detail.plan_id?.allowed_ai_token)
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Pixel Request
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.allowed_pexel_requests
                        ? bgColor(detail.plan_id?.allowed_pexel_requests)
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Paid Templates
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.paid_templates_for_free
                        ? bgColor(detail.plan_id?.paid_templates_for_free)
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Allowed Subscribers
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {detail.plan_id?.allowed_subscriber
                        ? bgColor(detail.plan_id?.allowed_subscriber)
                        : "N/A"}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Book a Call Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {BookaCallStatus(
                        detail.plan_id?.book_a_call_status === true
                          ? "Yes"
                          : "No"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Funnel Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {FunnelStatus(
                        detail.plan_id?.funnel_status === true ? "Yes" : "No"
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Custome Domain Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {DomainFunc(
                        detail.plan_id?.custom_domain_status === true
                          ? "Yes"
                          : "No"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Stripe Payment Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {StripeStatusFunc(
                        detail.plan_id?.stripe_payment_status === true
                          ? "Yes"
                          : "No"
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="name-section"
                    >
                      Custome Domain Status
                    </TableCell>
                    <TableCell align="start" className="value-section">
                      {DomainFunc(
                        detail.plan_id?.custom_domain_status === true
                          ? "Yes"
                          : "No"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}

export default DetailDialog;
