/* eslint-disable react-hooks/exhaustive-deps */
import {
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import closeFill from "@iconify/icons-eva/close-fill";
import { Icon } from "@iconify/react";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { _add_keys } from "src/DAL/Keys/keys";
import { useSnackbar } from "notistack";

function AddKeysDrawer({
  addKeysDrawer,
  setAddKeysDrawer,
  getKeys,
  selectedKeys,
  setSelectedKeys,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    key: "",
    value: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setInputs((inputs) => ({ ...inputs, [name]: trimmedValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (inputs.value === "") {
      enqueueSnackbar("Value is not allowed to be empty", { variant: "error" });
      setLoading(false);
      return;
    }
    const postData = {
      key: inputs.key,
      value: inputs.value,
    };
    try {
      const response = await _add_keys(postData);
      console.log(response);
      if (response.code === 200) {
        enqueueSnackbar("Key added successfully", { variant: "success" });
        setLoading(false);
        getKeys();
        handleCloseAddDrawer();
        setSelectedKeys([...selectedKeys, inputs.key]);
        localStorage.setItem("selectedKeys", JSON.stringify(selectedKeys));
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleCloseAddDrawer = () => {
    setAddKeysDrawer(false);
    setInputs({
      key: "",
      value: "",
    });
  };
  const keys = [
    { value: "pexel_key", label: "Pexel Key" },
    { value: "tinymc_key", label: "Tinymc Key" },
    { value: "stripe_public_key", label: "Stripe Public Key" },
  ];

  useEffect(() => {
    const storedKeys = JSON.parse(localStorage.getItem("selectedKeys")) || [];
    setSelectedKeys(storedKeys);
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedKeys", JSON.stringify(selectedKeys));
  }, [selectedKeys]);

  return (
    <>
      <Drawer
        anchor="right"
        open={addKeysDrawer}
        onClose={handleCloseAddDrawer}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Add Key
          </Typography>
          <IconButton onClick={handleCloseAddDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />
        <Scrollbar>
          <form>
            <Stack spacing={3} sx={{ p: 3 }}>
              <FormControl variant="outlined">
                <InputLabel id="Type">Key*</InputLabel>
                <Select
                  labelId="Type"
                  fullWidth
                  label="Key*"
                  required
                  value={inputs.key}
                  name="key"
                  onChange={handleChange}
                >
                  {keys.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={option.value}
                        disabled={selectedKeys.includes(option.value)}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                id="outlined-multiline-static"
                label="Value"
                value={inputs.value}
                name="value"
                onChange={handleChange}
              />
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                onClick={handleSubmit}
              >
                Add Key
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

export default AddKeysDrawer;
