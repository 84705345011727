/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import {
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { _plan_list } from "src/DAL/plans/plan";
import { useSnackbar } from "notistack";
import moment from "moment";
import { _edit_subscription } from "src/DAL/Subscription/Subscription";
import DatePickerField from "src/components/DatePicker/Datepicker";

function EditSubscriptions({
  handleCloseEdit,
  isDrawerOpenEdit,
  selectedRow,
  selectedIndex,
  subscriptionListing,
}) {
  const { enqueueSnackbar } = useSnackbar();
  // console.log(selectedRow, "selectedRow");

  const formatDate = (dateString) => {
    console.log(dateString, "dateeee");
    // return new Date(dateString);
    return moment(dateString).format("YYYY-MM-DD");
  };
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    plan_id: "",
    subscription_end_date: "",
    grace_period_days: "",
    user_id: "",
  });

  // console.log(inputs, "inputs");
  const [planList, setPlanList] = useState([]);
  const customer_name = selectedRow?.customer_obj
    ? selectedRow?.customer_obj?.first_name +
      " " +
      selectedRow?.customer_obj?.last_name
    : "-";
  const email = selectedRow?.user_id ? selectedRow?.user_id?.email : "-";

  const getPlans = async () => {
    const response = await _plan_list();
    if (response.code === 200) {
      setPlanList(response.data);
      // console.log(response.data, "planList");
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  };

  const handleSubmit = async () => {
    const id = selectedRow?._id;
    const postData = {
      plan_id: inputs.plan_id,
      subscription_end_date: moment(inputs.subscription_end_date).format(
        "MM-DD-YYYY"
      ),
      // subscription_end_time: moment(
      //   inputs.subscription_end_time,
      //   "h:mm:ss A"
      // ).format("HH:mm:ss"),
      grace_period_days: inputs.grace_period_days,
      user_id: selectedRow?.user_id?._id,
    };
    setLoading(true);
    const response = await _edit_subscription(id, postData);
    if (response.code === 200) {
      setLoading(false);
      setInputs({
        // ...selectedRow,
        ...inputs,
        plan_id: inputs.plan_id,
        subscription_end_date: inputs.subscription_end_date,
        grace_period_days: inputs.grace_period_days,
        user_id: inputs.user_id,
      });
      subscriptionListing();
      handleCloseEdit();
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, "errorInEditSubscriptions");
    }
  };

  const options = [
    { value: 1, label: "day" },
    { value: 3, label: "days" },
    { value: 5, label: "days" },
    { value: 7, label: "days" },
    { value: 9, label: "days" },
    { value: 11, label: "days" },
    { value: 13, label: "days" },
  ];

  const getCurrencySymbol = (locale, currency) => {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  const handleChange = (e) => {
    // console.log(e, "e.target.value????????");
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getPlans();
    setInputs({
      plan_id: selectedRow?.plan_obj?._id,
      subscription_end_date: formatDate(selectedRow?.subscription_end_date),
      grace_period_days: selectedRow?.grace_period_days,
    });
  }, [isDrawerOpenEdit]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isDrawerOpenEdit}
        onClose={handleCloseEdit}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Edit Subscription
          </Typography>
          <IconButton onClick={handleCloseEdit}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />
        <Scrollbar>
          <div style={{ padding: "13px" }}>
            <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.8 }}>
              Customer Detail
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // padding: "10px",
              }}
            >
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.5, mr: 1 }}>
                  Name:
                </Typography>
                {customer_name}
              </div>
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.3, mr: 1 }}>
                  Email:
                </Typography>
                {email}
              </div>
            </div>
          </div>

          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Stack spacing={3} sx={{ p: 3 }}>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose a plan*
                </InputLabel>
                <Select
                  required
                  label="Choose a plan"
                  name="plan_id"
                  value={inputs.plan_id}
                  onChange={handleChange}
                >
                  {planList
                    .filter((plan) => plan.amount > 0 && plan.status)
                    .map((plan) => (
                      <MenuItem key={plan?._id} value={plan?._id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{plan.product_name}</div>
                          <div>
                            {getCurrencySymbol("en-US", plan.currency)}
                            {plan.amount}
                            {`/${plan.interval_time}`}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="End Date"
                  type="date"
                  value={inputs.subscription_end_date}
                  name="subscription_end_date"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  inputProps={{ min: inputs.subscription_end_date }}
                />
              </FormControl>
              {/* <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <DatePickerField
                  label="End Date/Time"
                  name="subscription_end_date"
                  value={
                    inputs?.subscription_end_date ?? new Date().toISOString()
                  }
                  onChange={(value) => {
                    setInputs({ ...inputs, subscription_end_date: value });
                  }}
                  required
                />
              </FormControl> */}
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Grace Period Days*
                </InputLabel>
                <Select
                  value={inputs.grace_period_days}
                  isMulti
                  required
                  label="Grace Period Days"
                  name="grace_period_days"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      minHeight: 50,
                    }),
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${option.value} ${option.label}`}
                    </MenuItem>
                  ))}
                </Select>
                <p style={{ color: "gray", padding: "10px" }}>
                  ( The grace period starts after the end date. During this
                  grace period, the account functionality remains the same. At
                  that time user can pay again without losing any data or
                  access.)
                </p>
              </FormControl>
              <div style={{ marginTop: "30%" }}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Update Subscription
                </LoadingButton>
              </div>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

export default EditSubscriptions;
