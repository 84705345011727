import React, { useEffect, useState } from "react";
import { Box, Drawer, Typography, TextField, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { _update_admin_password } from "src/DAL/Admin/Admin";
import { useSnackbar } from "notistack";

const ChangePassword = ({
  isChangePasswordDrawerOpen,
  setIsChangePasswordDrawerOpen,
  handleClose,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleChangePassword();
  };

  const handleChangePassword = async () => {
    setIsLoading(true);

    const update_password = await _update_admin_password({
      new_password: password,
      confirm_password: confirmPassword,
    });
    if (update_password.code == 200) {
      setPassword("");
      setConfirmPassword("");
      setIsChangePasswordDrawerOpen(false);
      enqueueSnackbar(update_password.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(update_password.message, { variant: "error" });
    }

    setIsLoading(false);
  };

  if (isChangePasswordDrawerOpen) {
    handleClose();
  }

  useEffect(() => {
    if (!isChangePasswordDrawerOpen) {
      setPassword("");
      setConfirmPassword("");
    }
  }, [isChangePasswordDrawerOpen]);

  return (
    <Drawer
      anchor="right"
      open={isChangePasswordDrawerOpen}
      onClose={() => setIsChangePasswordDrawerOpen(false)}
    >
      <Box sx={{ width: 300, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Change Password
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            type={showPassword ? "text" : "password"}
            label="New Password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            required
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton onClick={toggleShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
          <TextField
            type={showConfirmPassword ? "text" : "password"}
            label="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            fullWidth
            required
            margin="normal"
            InputProps={{
              endAdornment: (
                <IconButton onClick={toggleShowConfirmPassword}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              ),
            }}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isLoading}
            // disabled={!password || password !== confirmPassword}
            sx={{ mt: 2 }}
          >
            Change Password
          </LoadingButton>
        </form>
      </Box>
    </Drawer>
  );
};

export default ChangePassword;
