import * as React from "react";
import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";

export default function FAQModal({
  open,
  setOpen,
  menuInputsObj,
  setMenuInputsObj,
  selectedIndex,
  selectedData,
  setSelectedData,
}) {
  const [menuInputs, setMenuInputs] = useState({
    title: "",
    description: "",
  });
  const handleClose = () => {
    setOpen(false);
    setSelectedData({ title: "", description: "" });
  };

  useEffect(() => {
    if (selectedData) {
      setMenuInputs(selectedData);
    }
  }, [selectedData]);
  const handleChange = (e) => {
    setMenuInputs((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleAddData = (e) => {
    let newArray = menuInputsObj.question_items;
    newArray.push(menuInputs);
    setMenuInputsObj({ ...menuInputsObj, question_items: newArray });
    handleClose();
  };

  const handleEditData = () => {
    let newArray = menuInputsObj.question_items;
    newArray[selectedIndex] = menuInputs;

    setMenuInputsObj({ ...menuInputsObj, question_items: newArray });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {selectedData.title || selectedData.description
                ? "Edit FAQ's"
                : "Add FAQ's"}
            </p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="mt-3">
              <TextField
                label="title"
                style={{ width: "40%" }}
                required
                value={menuInputs?.title}
                name="title"
                onChange={handleChange}
              />
            </div>
            <div className="mt-3">
              <p>Description</p>
              <GeneralCkeditor
                inputs={menuInputs}
                value={menuInputs?.description}
                setInputs={setMenuInputs}
                name="description"
                onChange={handleChange}
              />
            </div>

            <div className="text-end mt-3">
              {selectedData?.title || selectedData?.description ? (
                <>
                  <Button variant="contained" onClick={handleEditData}>
                    Edit Data
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="contained" onClick={handleAddData}>
                    Add Data
                  </Button>
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
