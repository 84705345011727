import { Box, Button, CircularProgress, Input, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { _result_devices_data } from "src/DAL/Website/ResultOnDevices";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function ResultDevices() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    image: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const resultDevicesDetail = async () => {
    setLoading(true);
    const ResultDevicesData = await _landing_page_detail();
    if (ResultDevicesData.code === 200) {
      setLoading(false);
      setInputs(ResultDevicesData?.data?.result_on_devices);
    }
  };

  useEffect(() => {
    resultDevicesDetail();
  }, []);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setInputs((prev) => {
      return { ...prev, image: file };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (typeof inputs.image === "string") {
      const postData = {
        title: inputs.title,
        image: inputs.image,
      };

      const result = await _result_devices_data(postData);
      if (result.code === 200) {
        enqueueSnackbar("data added successfully", { variant: "success" });
        resultDevicesDetail();
      } else {
        enqueueSnackbar("data not added successfully", { variant: "error" });
      }
    } else {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.image);

      const image_resp = await _upload_file_on_s3(formData);

      if (image_resp.code === 200) {
        const postData = {
          title: inputs.title,
          image: image_resp.file_name,
        };

        const result = await _result_devices_data(postData);
        console.log(result, "tsafdt");
        if (result.code === 200) {
          enqueueSnackbar("data added successfully", { variant: "success" });
          resultDevicesDetail();
        } else {
          enqueueSnackbar("data not added successfully", { variant: "error" });
        }
      }
    }
  };
  return (
    <div style={{ width: "94%", margin: "auto" }}>
      <div className="main mt-5">
        <h3 className="text-center pb-3">Result Devices</h3>
        {loading ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <p className="mt-2">Title</p>
            <GeneralCkeditor
              inputs={inputs}
              name="title"
              setInputs={setInputs}
              onChange={handleChange}
            />
            <div className="text-center">
             
                <img
                  style={{
                    width: "23rem",
                    height: "9rem",
                    marginLeft: "auto",
                    marginRight: "auto",

                    marginTop: "1rem",
                  }}
                  src={
                    typeof inputs.image === "string"
                      ?s3baseUrl+"/"+ inputs.image
                      : URL.createObjectURL(inputs.image)
                  }
                  alt=""
                />
            
              <label htmlFor="icon-button-file">
                <Input
                  style={{ display: "none" }}
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  value=""
                  onChange={handleImageUpload}
                />
                <Button
                  aria-label="upload picture"
                  component="span"
                  //   style={{ marginLeft: "21rem" }}
                  className="upload-button mt-3 "
                  fullWidth
                >
                  {inputs.image ? "Change Image" : "Upload Image"}
                </Button>
              </label>
            </div>
          </>
        )}

        <div className=" mt-3 text-end">
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
