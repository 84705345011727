/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { _subscription_listing } from "src/DAL/Subscription/Subscription";
import CustomConfirmation from "src/components/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import Label from "src/components/Label";
import DetailModal from "../Detail-Section/Detail-Section";
import { styled } from "@mui/styles";
import { useSnackbar } from "notistack";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.gray,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function StripeSubscription() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [stripeSubscriptions, setStripeSubscriptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [srtripeSubscriptionsDetailDialog, setStripeSubscriptionsDetailDialog] =
    useState(false);
  const [stripeSubscriptionsDetail, setStripeSubscriptionsDetail] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [filterSubscription, setFilterSubscription] = useState({
    subscription_type: 0,
    grace_period_status: 0,
    upcoming_expired: 0,
    subscription_status: 0,
    canceled_subscriptions: 0,
    active_subscriptions: 0,
  });

  const handleDeleteClick = (row) => {
    setSelectedRow(row.row);
    setOpenDelete(true);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ]);

  const tabs = [
    {
      label: "All",
      title: "Here you can see all subscriptions",
      postData: {
        subscription_type: 0,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
      },
    },
    {
      label: "Active",
      title: "Here you can see active subscriptions",
      postData: {
        subscription_type: 0,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 1,
      },
    },
    {
      label: "Grace Period",
      title:
        "Here you can see those subscriptions, that are start after the end date.During this grace period, the account functionality remains the same. At that time user can pay again without losing any data or access",
      postData: {
        subscription_type: 0,
        grace_period_status: 1,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
      },
    },
    {
      label: "Soon to expire",
      title:
        "Here you can see those subscriptions, that are expired within 7 days",
      postData: {
        subscription_type: 0,
        grace_period_status: 0,
        upcoming_expired: 1,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
      },
    },
    {
      label: "Expired",
      title: "Here you can see expired subscriptions",
      postData: {
        subscription_type: 0,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 1,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
      },
    },
    {
      label: "Cancelled",
      title: "Here you can see cancelled subscriptions",
      postData: {
        subscription_type: 0,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 1,
        active_subscriptions: 0,
      },
    },
  ];
  const [selectedButton, setSelectedButton] = useState(tabs[0].label);

  const handleStripeSubscriptions = (row) => {
    setSelectedRow(row.row);
    setStripeSubscriptionsDetailDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("handleChangeRowsPerPage", event.target.value);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          variant="ghost"
          color={selectedRow?.user_id?.type === 1 ? "success" : "error"}
        >
          {Active}
        </Label>
      </>
    );
  };

  const subscriptionListing = async () => {
    setLoading(true);
    const postData = filterSubscription;
    const response = await _subscription_listing(postData, page, rowsPerPage);
    // console.log(response, "listTransactions");
    if (response.code === 200) {
      setLoading(false);
      setStripeSubscriptions(response.data.subscriptions);

      setTotalCount(response.data.total_count);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  useEffect(() => {
    if (selectedRow) {
      setStripeSubscriptionsDetail([
        {
          name: "Customer Name",
          value: selectedRow?.customer_obj
            ? selectedRow?.customer_obj?.first_name.charAt(0).toUpperCase() +
              selectedRow?.customer_obj?.first_name.slice(1) +
              " " +
              selectedRow?.customer_obj?.last_name.charAt(0).toUpperCase() +
              selectedRow?.customer_obj?.last_name.slice(1)
            : "-",
        },
        {
          name: "Customer Type",
          value: IsActiveFunc(
            selectedRow?.user_id?.type === 1 ? "Inovate" : "-"
          ),
        },
        {
          name: "Customer Email",
          value: selectedRow.user_id ? (
            selectedRow.user_id?.email
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Currency",
          value: selectedRow.currency ? (
            selectedRow.currency.toUpperCase()
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Amount",
          value: selectedRow
            ? `${getCurrencySymbol("en-US", selectedRow?.currency)}${
                selectedRow?.amount
              }`
            : "-",
        },
        {
          name: "Product Name",
          value: selectedRow?.plan_obj?.product_name ? (
            selectedRow?.plan_obj?.product_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Product Description",
          value: selectedRow?.plan_obj?.product_description ? (
            selectedRow?.plan_obj?.product_description
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Grace Period Days",
          value: selectedRow?.grace_period_days
            ? selectedRow?.grace_period_days
            : "-",
        },
        {
          name: "Subscription Start Date",
          value: selectedRow?.subscription_start_date
            ? `${moment(selectedRow?.subscription_start_date).format("LL")}`
            : "-",
        },
        {
          name: "Expiry Date",
          value: selectedRow?.subscription_end_date
            ? `${moment(selectedRow?.subscription_end_date).format("LL")}`
            : "-",
        },
        {
          name: "Subscription Cancel Date",
          value: selectedRow?.subscription_cancel_date
            ? `${moment(selectedRow?.subscription_cancel_date).format("LL")}`
            : "-",
        },
        {
          name: "Subscription Renewal Date",
          value: selectedRow?.subscription_renewal_date
            ? `${moment(selectedRow?.subscription_renewal_date).format("LL")}`
            : "-",
        },
        // {
        //   name: "Transaction Status",
        //   value: TransactionStatusColor(
        //     selectedRow.transaction_status ? (
        //       selectedRow.transaction_status
        //     ) : (
        //       <span>-</span>
        //     )
        //   ),
        // },
        {
          name: "Created At",
          value: selectedRow.createdAt ? (
            moment(selectedRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedRow.updatedAt ? (
            moment(selectedRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
      // console.log(selectedRow, "rowdataa");
    }
  }, [selectedRow]);

  useEffect(() => {
    subscriptionListing();
  }, [page, rowsPerPage, filterSubscription]);

  return (
    <>
      <DetailModal
        open={srtripeSubscriptionsDetailDialog}
        setOpen={setStripeSubscriptionsDetailDialog}
        detail={stripeSubscriptionsDetail}
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Stripe Subscriptions
        </Typography>
        <Card sx={{ p: 1 }}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
            margin={2}
          >
            <Stack
              spacing={2}
              direction={{ xs: "column", sm: "row", lg: "row" }}
            >
              {tabs.map((tab) => (
                <LightTooltip title={tab.title} placement="top-start">
                  <Button
                    style={{ whiteSpace: "nowrap" }}
                    variant={
                      selectedButton === tab.label ? "contained" : "outlined"
                    }
                    className="filter"
                    onClick={() => {
                      setLoading(true);
                      setPage(0);
                      setFilterSubscription(tab.postData);
                      setSelectedButton(tab.label);
                    }}
                  >
                    {tab.label}
                  </Button>
                </LightTooltip>
              ))}
            </Stack>
          </Stack>

          <TableContainer>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      #
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Plan Name
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Amount
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Status
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Start Date
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Expiry Date
                    </TableCell>
                    {["All", "Cancelled", "Grace Period"].includes(
                      selectedButton
                    ) && (
                      <TableCell
                        align="left"
                        style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                      >
                        Cancel Date
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Renewal Date
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Updated At
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stripeSubscriptions && stripeSubscriptions.length > 0 ? (
                    stripeSubscriptions.map((row, index) => {
                      const {
                        _id,
                        amount,
                        currency,
                        subscription_start_date,
                        subscription_end_date,
                        subscription_cancel_date,
                        subscription_renewal_date,
                        customer_obj,
                        plan_obj,
                        updatedAt,
                        createdAt,
                      } = row;
                      return (
                        <TableRow
                          key={_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            className="hover-effect1"
                            // align="center"
                            style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={() =>
                              handleStripeSubscriptions({ row, index })
                            }
                          >
                            <Typography variant="subtitle2" noWrap>
                              {" "}
                              {customer_obj
                                ? customer_obj?.first_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer_obj?.first_name.slice(1) +
                                  " " +
                                  customer_obj?.last_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer_obj?.last_name.slice(1)
                                : "-"}
                            </Typography>{" "}
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {plan_obj
                              ? plan_obj?.product_name.charAt(0).toUpperCase() +
                                plan_obj?.product_name.slice(1)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {currency ? currency.toUpperCase() : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {amount
                              ? `${getCurrencySymbol(
                                  "en-US",
                                  currency
                                )}${amount}`
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            <Label
                              variant="ghost"
                              style={{ width: "100px" }}
                              color={
                                (row.subscription_status === 0 && "success") ||
                                (row.subscription_status === 1 && "primary") ||
                                (row.subscription_status === 2 && "warning") ||
                                (row.subscription_status === 3 &&
                                  "error_dar") ||
                                (row.subscription_status === 4
                                  ? "error"
                                  : "default")
                              }
                            >
                              {row.subscription_status === 0
                                ? "Active"
                                : row.subscription_status === 1
                                ? "Soon To Expire"
                                : row.subscription_status === 2
                                ? "Grace Period"
                                : row.subscription_status === 3
                                ? "Expired"
                                : row.subscription_status === 4
                                ? "Cancelled"
                                : row.subscription_status}
                            </Label>
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {subscription_start_date
                              ? moment(subscription_start_date).format("LL")
                              : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {subscription_end_date
                              ? moment(subscription_end_date).format("LL")
                              : "-"}
                          </TableCell>
                          {["All", "Cancelled", "Grace Period"].includes(
                            selectedButton
                          ) && (
                            <TableCell
                              align="center"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {subscription_cancel_date
                                ? moment(subscription_cancel_date).format("LL")
                                : "-"}
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {subscription_renewal_date
                              ? moment(subscription_renewal_date).format("LL")
                              : "-"}
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {createdAt ? moment(createdAt).format("LLL") : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {updatedAt ? moment(updatedAt).format("LLL") : "-"}
                          </TableCell>
                          {/* <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={menuOptions}
                            />
                          </TableCell> */}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        No Subscription Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this transaction?"}
            // handleAgree={handleDelete}
          />

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </>
  );
}

export default StripeSubscription;
