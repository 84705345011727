import { invokeApi } from "src/bl_libs/invokeApi";

export const _departments_listing = async (page, limit) => {
  let requestObj = {
    path: `api/department/list_department`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _active_departments = async () => {
  let requestObj = {
    path: `api/department/list_department_active`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_department = async (id) => {
  let requestObj = {
    path: `api/department/delete_department/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_department = async (data) => {
  let requestObj = {
    path: `api/department/add_department`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_department = async (id, data) => {
  let requestObj = {
    path: `api/department/edit_department/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
