import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { _admin_login } from "src/DAL/login/login";
import { useAppContext } from "src/Hooks/AppContext";

// ----------------------------------------------------------------------

export default function LoginForm({}) {
  const navigate = useNavigate();
  const { setUser, getRoleDetails } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // function getPathWithListTrue(dataArray) {
  //   for (const obj of dataArray) {
  //     if (obj.privileges.list) {
  //       return obj.path;
  //     }
  //   }
  //   // default return
  //   return "/dashboard";
  // }
  const getPathWithListTrue = (dataArray) => {
    for (const obj of dataArray) {
      if (obj.privileges && obj.privileges.list) {
        return obj.path;
      }
    }
    // default return
    return "/dashboard";
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const data = {
  //     email: email,
  //     password: password,
  //     type: 0,
  //   };
  //   setIsLoading(true);
  //   const response = await _admin_login(data);
  //   console.log(response, "response");
  //   if (response.code === 200) {
  //     localStorage.setItem("token", response.token);
  //     let _user = response.user;
  //     if (_user.admin_type === "sub_admin") {
  //       let resp = await getRoleDetails(_user.role_id);
  //       if (resp.code === 200) {
  //         localStorage.setItem("user", JSON.stringify(response.user));
  //         setUser(response.user);
  //         const pathWithListTrue = getPathWithListTrue(resp.role.roles);
  //         navigate(pathWithListTrue);
  //       } else {
  //         localStorage.clear();
  //         setIsLoading(false);
  //       }
  //     } else {
  //       localStorage.setItem("user", JSON.stringify(response.user));
  //       setUser(response.user);
  //       navigate("/dashboard");
  //     }
  //   } else {
  //     enqueueSnackbar(response.message, { variant: "error" });
  //     setIsLoading(false);
  //   }
  // };

  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const data = {
        email: email,
        password: password,
        type: 0,
      };
      const response = await _admin_login(data);
      console.log(response, "response");
      if (response.code === 200) {
        localStorage.setItem("token", response.token);
        let _user = response.user;
        if (_user.admin_type === "sub_admin") {
          let resp = await getRoleDetails(_user.role_id);
          console.log(resp, "reesppInLoginn"); // Logging response to check its structure
          if (resp.code === 200 && resp.role && resp.role.roles) {
            localStorage.setItem("user", JSON.stringify(response.user));
            setUser(response.user);
            const pathWithListTrue = getPathWithListTrue(resp.role.roles);
            console.log(pathWithListTrue, "patthhListt"); // Logging pathWithListTrue to check its value
            navigate(pathWithListTrue);
          } else {
            localStorage.clear();
            setIsLoading(false);
          }
        } else {
          localStorage.setItem("user", JSON.stringify(response.user));
          setUser(response.user);
          navigate("/dashboard");
        }
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setIsLoading(false);
      enqueueSnackbar("An error occurred. Please try again later.", {
        variant: "error",
      });
    }
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    // <FormikProvider value={formik}>
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="Email address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          // {...getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ my: 2 }}
      ></Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
      >
        Press to Login
      </LoadingButton>
    </form>
    // </FormikProvider>
  );
}
