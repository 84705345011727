const { invokeApi } = require("src/bl_libs/invokeApi");

export const _subadmins_list = async (data) => {
  let requestObj = {
    path: `api/admin/get_subadmins`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _add_sub_admin = async (data) => {
  let requestObj = {
    path: `api/admin/add_subadmin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_sub_admin = async (id, data) => {
  let requestObj = {
    path: `api/admin/update_subadmin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _delete_sub_admin = async (id) => {
  let requestObj = {
    path: `api/admin/delete_subadmin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _change_subadmin_password = async (id, data) => {
  let requestObj = {
    path: `api/admin/change_subadmin_password/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
