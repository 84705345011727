import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _add_free_trail, _get_free_trail_details } from "src/DAL/plans/plan";
import Page from "src/components/Page";

export default function FreeTrial() {
  const [loading, setLoading] = useState(false);
  const [submitionLoading, setSubmitionLoading] = useState(false);
  const [inputs, setInputs] = useState({
    trialDays: "",
    noticeDays: "",
    gracePeriod: "",
  });
  const [checked, setChecked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitionLoading(true);
    const postData = {
      days: inputs.trialDays,
      notice_before_end_days: inputs.noticeDays,
      grace_period_days: inputs.gracePeriod,
      status: checked,
    };
    const resp = await _add_free_trail(postData);
    if (resp.code === 200) {
      enqueueSnackbar(resp.message, { variant: "success" });
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setSubmitionLoading(false);
  };
  const getFreeTrailDetails = async () => {
    setLoading(true);

    const resp = await _get_free_trail_details();
    if (resp.code === 200) {
      let data = resp.data;
      setInputs({
        trialDays: data.days,
        noticeDays: data.notice_before_end_days,
        gracePeriod: data.grace_period_days,
      });
      setChecked(data.status);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getFreeTrailDetails();
  }, []);

  return (
    <div className="mt-5" style={{ width: "94%", margin: "auto" }}>
      <h3 className="text-center">Free Trial</h3>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title="Free Trial" className="mt-5">
            <form onSubmit={handleFormSubmit}>
              <Container maxWidth="xl">
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleChangeCheck}
                      sx={{ marginLeft: "16px" }}
                    />
                  }
                  label="Free Trial Active:"
                  labelPlacement="start"
                  sx={{ marginLeft: "0" }}
                />
                <div className="mt-3">
                  <TextField
                    fullWidth
                    required
                    label="Free Trial Days"
                    value={inputs.trialDays}
                    placeholder="days..."
                    name="trialDays"
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    label="Notice Before Ending Trial"
                    required
                    type="number"
                    helperText="Notify the User that trial ending in days"
                    value={inputs.noticeDays}
                    name="noticeDays"
                    placeholder="days..."
                    onChange={handleChange}
                  />
                </div>
                <div className="mt-3">
                  <TextField
                    fullWidth
                    label="Grace Period Days"
                    required
                    type="number"
                    helperText="Grace period start after trial ends"
                    value={inputs.gracePeriod}
                    name="gracePeriod"
                    placeholder="days..."
                    onChange={handleChange}
                  />
                </div>

                <div className="text-center mt-3">
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={submitionLoading}
                  >
                    Update Trial
                  </LoadingButton>
                </div>
              </Container>
            </form>
          </Page>
        </>
      )}
    </div>
  );
}
