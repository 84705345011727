/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Icon } from "@iconify/react";
import {
  Box,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Label from "src/components/Label";
import { sourceS3BaseUrl } from "src/config/config";
import LayersIcon from "@mui/icons-material/Layers";
import CustomPopover from "src/components/CustomPopover";
import TemplateGroupDialog from "./TemplateGroupDialog";
import { filter } from "lodash";
import Loader from "src/components/Loader/Loader";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import SearchNotFound from "src/components/SearchNotFound";
import { template_group_list } from "src/DAL/Templates/templates";
import DetailModal from "src/pages/Detail-Section/Detail-Section";

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "" },
  { id: "name", label: "Group Name", alignRight: false },
  { id: "group_type", label: "Group Type", alignRight: false },
  { id: "use_count", label: "Usage Count", alignRight: false },
  { id: "is_published", label: "Published", alignRight: false },
  { id: "is_paid", label: "Is Paid", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "discount", label: "Discount", alignRight: false },
  { id: "amount", label: "Final Amount", alignRight: false },
  { id: "pages", label: "Pages", alignRight: false },
  { id: "pages", label: "Template Group", alignRight: false },
  // { id: "_id", label: "Template Group ID", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Updated At", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((template) => {
      return template.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function TemplatesGroup() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [templatesGroup, setTemplates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [tempDetail, setTemoDetail] = useState();
  const [openTempDetail, setOpenTempDetail] = useState(false);
  const [openTempGroupDetailModal, setTempGroupDetailModal] = useState(false);

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          variant="ghost"
          color={selectedRow?.is_published === true ? "success" : "error"}
        >
          {Active}
        </Label>
      </>
    );
  };

  const handleOpenTempGrpDetail = () => {
    setTempGroupDetailModal(true);
  };

  const handleDetailTemplate = (row) => {
    handleOpenTempGrpDetail();
    setSelectedRow(row.row);
    console.log(selectedRow, "seleecteddRoowww");
  };

  const handleCloseTempGrpDetail = () => {
    setTempGroupDetailModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = templatesGroup.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Pages Against Templates",
      icon: "mdi:card-account-details-outline",
      handleClick: handleDetailTemplate,
    },
  ]);

  const template_group_listing = async () => {
    setLoading(true);
    const response = await template_group_list();
    console.log(response, "templateGrooupLIstinggggggggggggggggg");
    if (response.code === 200) {
      setLoading(false);
      setTemplates(response.template);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, { variant: "error" });
    }
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - templatesGroup.length)
      : 0;

  const filteredUsers = applySortFilter(
    templatesGroup,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleOpenTempDetail = (row) => {
    setSelectedRow(row.row);
    setOpenTempDetail(true);
  };

  const IsPaidFunc = (Active) => {
    return (
      <>
        <Label
          variant="ghost"
          color={selectedRow?.is_paid === true ? "success" : "error"}
        >
          {Active}
        </Label>
      </>
    );
  };
  const IsCountFunc = (val) => {
    return (
      <>
        <Label variant="ghost" color="warning">
          {val}
        </Label>
      </>
    );
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    if (currency === "Rs") {
      currency = "INR";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  useEffect(() => {
    if (selectedRow) {
      setTemoDetail([
        {
          name: "Name",
          value: selectedRow ? selectedRow?.name : "-",
        },
        {
          name: "Usage Count",
          value: IsCountFunc(selectedRow ? selectedRow?.use_count : "-"),
        },
        {
          name: "Published",
          value: IsActiveFunc(
            selectedRow?.is_published === true ? "Published" : "Unpublished"
          ),
        },
        {
          name: "Is Paid",
          value: IsPaidFunc(selectedRow?.is_paid === true ? "YES" : "NO"),
        },
        {
          name: "Currency",
          value: selectedRow ? selectedRow?.currency : "-",
        },
        {
          name: "Amount",
          value:
            selectedRow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedRow?.currency)}${
                  selectedRow?.total_amount
                }`
              : `${getCurrencySymbol("en-US", selectedRow?.currency)}0`,
        },
        {
          name: "Discount",
          value:
            selectedRow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedRow?.currency)}${
                  selectedRow?.discount
                }`
              : `${getCurrencySymbol("en-US", selectedRow?.currency)}0`,
        },
        {
          name: "Final Amount",
          value:
            selectedRow?.is_paid === true
              ? `Amount = Actual Amount (${getCurrencySymbol(
                  "en-US",
                  selectedRow?.currency
                )}${selectedRow?.total_amount}) - Discount (${getCurrencySymbol(
                  "en-US",
                  selectedRow?.currency
                )}${selectedRow?.discount}) = ${getCurrencySymbol(
                  "en-US",
                  selectedRow?.currency
                )}${selectedRow?.amount}`
              : `${getCurrencySymbol("en-US", selectedRow?.currency)}0`,
        },
        {
          name: "Ribbon",
          value: selectedRow ? selectedRow?.ribbon : "-",
        },
        {
          name: "Template Group Id",
          value: selectedRow ? selectedRow?._id : "-",
        },
        {
          name: "Created At",
          value: selectedRow.createdAt ? (
            moment(selectedRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedRow.updatedAt ? (
            moment(selectedRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
    }
  }, [selectedRow]);

  useEffect(() => {
    template_group_listing();
  }, []);

  return (
    <>
      <TemplateGroupDialog
        openTempGroupDetailModal={openTempGroupDetailModal}
        handleCloseTempGrpDetail={handleCloseTempGrpDetail}
        selectedRow={selectedRow}
      />
      <DetailModal
        open={openTempDetail}
        setOpen={setOpenTempDetail}
        detail={tempDetail}
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Template Group
        </Typography>
        <Card>
          <div style={{ paddingLeft: 0 }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </div>
          <TableContainer>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={templatesGroup.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {templatesGroup && templatesGroup.length > 0 ? ( */}
                  {filteredUsers.map((row, index) => {
                    const {
                      _id,
                      name,
                      image,
                      is_published,
                      use_count,
                      updatedAt,
                      createdAt,
                    } = row;
                    const rowNum = page * rowsPerPage + index + 1;
                    return (
                      <TableRow
                        key={_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rowNum}
                        </TableCell>
                        <TableCell align="right">
                          <CustomPopover
                            data={{ row, index }}
                            menu={menuOptions}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenTempDetail({ row, index })}
                        >
                          <Typography
                            className="hover-effect"
                            variant="subtitle2"
                            noWrap
                          >
                            {" "}
                            {name
                              ? name.charAt(0).toUpperCase() + name?.slice(1)
                              : "-"}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.group_type
                            ? row.group_type.charAt(0).toUpperCase() +
                              row.group_type?.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label variant="filled" color="warning">
                            {use_count ? use_count : "0"}
                          </Label>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label
                            variant="ghost"
                            color={
                              (is_published === true && "success") || "error"
                            }
                          >
                            {is_published === true
                              ? "Published"
                              : "Unpublished"}
                          </Label>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Label
                            variant="ghost"
                            color={
                              (row.is_paid === true && "success") || "error"
                            }
                          >
                            {row.is_paid ? "Yes" : "No"}
                          </Label>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row ? row.currency : "-"}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.is_paid === true
                            ? `${getCurrencySymbol("en-US", row.currency)}${
                                row.total_amount
                              }`
                            : `${getCurrencySymbol("en-US", row.currency)}0`}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.is_paid === true
                            ? `${getCurrencySymbol("en-US", row.currency)}${
                                row.discount
                              }`
                            : `${getCurrencySymbol("en-US", row.currency)}0`}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <span style={{ fontWeight: "500" }}>
                            {row.is_paid === true
                              ? `${getCurrencySymbol("en-US", row?.currency)}${
                                  row.amount
                                }`
                              : `${getCurrencySymbol("en-US", row.currency)}0`}
                          </span>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            {row.template_array
                              ? row.template_array.length
                              : "-"}
                            <LayersIcon
                              sx={{ marginInlineStart: "4px" }}
                              fontSize="small"
                              color="primary"
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "390px",
                              height: "120px",
                              overflowX: "scroll",
                            }}
                          >
                            {row.template_array.map((page) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginInlineEnd: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  window.open(
                                    sourceS3BaseUrl +
                                      page.template +
                                      "/" +
                                      "index.html"
                                  );
                                }}
                              >
                                <img
                                  src={sourceS3BaseUrl + page.image}
                                  height={"70px"}
                                  width={"120px"}
                                />
                                <span
                                  style={{
                                    textOverflow: "ellipsis",
                                    width: "120px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    marginTop: "4px",
                                  }}
                                >
                                  {page.name}
                                </span>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        {/* <TableCell
                          style={{ whiteSpace: "nowrap" }}
                          onDoubleClick={() => {
                            navigator.clipboard.writeText(_id);
                            enqueueSnackbar("ID copied to clipboard", {
                              variant: "success",
                            });
                          }}
                        >
                          {_id}
                          {console.log(_id, "idddddddddddddddddddddddddddd")}
                        </TableCell> */}
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {createdAt ? moment(createdAt).format("LLL") : "-"}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {updatedAt ? moment(updatedAt).format("LLL") : "-"}
                        </TableCell>
                        {/* <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={menuOptions}
                            />
                          </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {/* ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No Template Group Found
                    </TableCell>
                  </TableRow>
                )} */}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>
        </Card>
      </Box>
    </>
  );
}

export default TemplatesGroup;
