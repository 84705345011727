import { invokeApi } from "src/bl_libs/invokeApi";

export const _project_details_listing = async (id) => {
  // console.log(id,"idapi")

  let requestObj = {
    path: `api/project/detail_project_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

export const _page_group_list = async (id) => {
  let requestObj = {
    path: `api/page/list_page_group/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

// pages delete api
export const _delete_page = async (id) => {
  let requestObj = {
    path: `api/page/delete_project_page_by_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// subscriber delete api
export const _delete_subscriber = async (data) => {
  console.log(data, "data");
  let requestObj = {
    path: `api/active_compaign/delete_active_compaign_user_by_admin/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    postData: data,
  };
  return invokeApi(requestObj);
};

// transaction delete api

export const _delete_transaction = async (id) => {
  let requestObj = {
    path: `api/plan/delete_transaction_by_id_by_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// manage payment api delete
export const _delete_manage_payment = async (id) => {
  let requestObj = {
    path: `api/plan/delete_user_plan_by_id_by_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// version delete api
export const _delete_version = async (id) => {
  let requestObj = {
    path: `api/project/delete_project_version_by_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _project_subscriber_listing = async (id) => {
  let requestObj = {
    path: `api/project/all_data_against_project_for_admin_controller/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

export const _project_version_listing = async (id) => {
  let requestObj = {
    path: `api/project/all_data_against_project_for_admin_controller/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

// backup project
export const _backup_project = async (id) => {
  let requestObj = {
    path: `api/customer/list_backup_of_user/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: postData,
  };
  return invokeApi(requestObj);
};

// delete backup projects

export const _delete_backup_page = async (id) => {
  let requestObj = {
    path: `api/customer/delete_backup_by_admin/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

// download delete projects

export const _download_zip_backup_file = async (data) => {
  console.log(data, "data");
  let requestObj = {
    path: `api/project/make_zip_of_backup_for_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    postData: data,
  };
  return invokeApi(requestObj);
};

// delete download zip

export const _delete_zip_of_project = async (data) => {
  let requestObj = {
    path: `api/project/delete_zip_of_backup_for_admin`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },

    postData: data,
  };
  return invokeApi(requestObj);
};

// view s3 files
export const _get_project_file_on_s3 = async (data) => {
  let requestObj = {
    path: `api/project/get_project_file_from_s3`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
