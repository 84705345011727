/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { _find_user_profile } from "src/DAL/find/find";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import CustomerDetail from "./Types/CustomerDetail";
import ProjectDetail from "./Types/ProjectDetail";
import ProjectListing from "./Types/ProjectListing";
import IconButton from "@mui/material/IconButton";
import PasteIcon from "@mui/icons-material/ContentPaste";
import Instructions from "./Types/Instructions";
import { Icon } from "@iconify/react";

function FindProfile() {
  const { enqueueSnackbar } = useSnackbar();
  const [customerData, setCustomerData] = useState({});
  const [projectListing, setProjectListing] = useState([]);
  const [projectDetail, setProjectDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [pageslug, setPageslug] = useState("");
  const [instructions, setInstructions] = useState(true);
  const [previousType, setPreviousType] = useState(null);
  console.log(previousType, "PreviouussTyppppeeeee");
  const [inputs, setInputs] = useState({
    type: "",
    text: "",
  });
  // const projectCount = projectListing?.length;

  const findUserProfile = async () => {
    let postData = {
      type: inputs.type === 3 ? 2 : inputs.type,
      text: inputs.text.trim(),
    };
    console.log(postData, "postData");
    if (inputs.type === 1) {
      if (postData.text.startsWith("https://")) {
        postData.text = postData.text.slice(8);
      }
      if (postData.text.endsWith("/")) {
        postData.text = postData.text.slice(0, -1);
      }
      postData.text = postData.text.split("/")[0];
    }
    if (inputs.type === 2) {
      var page_id = inputs.text.split("/")[3];
      if (!!page_id) {
        postData.text = inputs.text.split("/")[3];
        setPageslug(inputs.text.split("/")[4]);
      }
    }
    setIsLoading(true);
    const resp = await _find_user_profile(postData);
    if (resp.code === 200) {
      setIsLoading(false);
      setInstructions(false);
      setHasSearched(true);
      setCustomerData(resp.data.customer);
      setProjectListing(resp.data.project);
      setProjectDetail(resp.data.project);
      setPreviousType(inputs.type);
      setError(null);
    } else {
      if (resp.code === 400) {
        setError(true);
      } else {
        enqueueSnackbar(resp.message, { variant: "error" });
      }
      setIsLoading(false);
      setInstructions(false);
      setHasSearched(false);
      setCustomerData({});
      setProjectListing([]);
      setProjectDetail({});
      setPreviousType(inputs.type);
    }
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    const trimmedValue = value.trim();
    setInputs((inputs) => ({ ...inputs, text: trimmedValue }));
  };

  const handleChangeType = (e) => {
    const { value } = e.target;
    console.log(value, "value");
    setInputs((inputs) => ({
      ...inputs,
      type: value,
    }));
    setPreviousType(inputs.type);
    setPageslug("");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    findUserProfile();
    setHasSearched(true);
  };

  // const handleSearch = () => {
  //   if (inputs.type === "") {
  //     enqueueSnackbar("Please select type", { variant: "error" });
  //   } else {
  //     findUserProfile();
  //     setHasSearched(true);
  //   }
  // };

  return (
    <>
      <Box sx={{ p: 2, mt: 3 }}>
        <form onSubmit={handleSearch} style={{ width: "100%" }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            mb={3}
            spacing={2}
          >
            <Typography variant="h4">Find Customer Information</Typography>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Type"
                required
                name="status"
                value={inputs.type}
                onChange={handleChangeType}
              >
                <MenuItem value={0}>Customer Email</MenuItem>
                <MenuItem value={1}>Project Live Domain</MenuItem>
                <MenuItem value={2}>Project S3 Link</MenuItem>
                <MenuItem value={3}>Project Id</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => {
                        navigator.clipboard.readText().then((text) => {
                          setInputs((inputs) => ({
                            ...inputs,
                            text: text,
                          }));
                        });
                      }}
                    >
                      <PasteIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
              id="outlined-required"
              placeholder="Search here..."
              value={inputs.text}
              onChange={handleChangeSearch}
              InputLabelProps={{ shrink: true }}
            />
            <LoadingButton
              fullWidth
              loading={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                !inputs.text || inputs.text === "" || inputs.type === ""
              }
              sx={{ height: "100%", mt: 2 }}
            >
              Search
            </LoadingButton>
          </Stack>
        </form>
        {!hasSearched && instructions && <Instructions />}
        {!hasSearched && error && previousType === 0 && (
          <Typography align="center" className="mt-5">
            <Typography variant="body1" align="left">
              <strong className="chnage-status-headings">
                Search Not Found
              </strong>
              <ol>
                <li>
                  Make sure that what you are trying to search for exists or
                  not.
                </li>
                <li>Kindly make sure that your selected type is correct.</li>
                <li>
                  Make sure that what you are searching for is according to your
                  type.
                </li>
              </ol>
            </Typography>
            <Icon
              fontSize="25px"
              className="mt-3"
              color="gray"
              height="80px"
              width="80px"
              icon="material-symbols-light:search-off"
            />
          </Typography>
        )}
        {!hasSearched && error && previousType === 1 && (
          <Typography align="center" className="mt-5">
            <Typography variant="body1" align="left">
              <strong className="chnage-status-headings">
                Search Not Found
              </strong>
              <ol>
                <li>
                  Make sure that what you are trying to search for exists or
                  not.
                </li>
                <li>Kindly make sure that your selected type is correct.</li>
                <li>
                  Make sure that what you are searching for is according to your
                  type.
                </li>
              </ol>
            </Typography>
            <Icon
              fontSize="25px"
              className="mt-3"
              color="gray"
              height="80px"
              width="80px"
              icon="material-symbols-light:search-off"
            />
          </Typography>
        )}
        {!hasSearched && error && previousType === 2 && (
          <Typography align="center" className="mt-5">
            <Typography variant="body1" align="left">
              <strong className="chnage-status-headings">
                Search Not Found
              </strong>
              <ol>
                <li>
                  Make sure that what you are trying to search for exists or
                  not.
                </li>
                <li>Kindly make sure that your selected type is correct.</li>
                <li>
                  Make sure that what you are searching for is according to your
                  type.
                </li>
              </ol>
            </Typography>
            <Icon
              fontSize="25px"
              className="mt-3"
              color="gray"
              height="80px"
              width="80px"
              icon="material-symbols-light:search-off"
            />
          </Typography>
        )}
        {!hasSearched && error && previousType === 3 && (
          <Typography align="center" className="mt-5">
            <Typography variant="body1" align="left">
              <strong className="chnage-status-headings">
                Search Not Found
              </strong>
              <ol>
                <li>
                  Make sure that what you are trying to search for exists or
                  not.
                </li>
                <li>Kindly make sure that your selected type is correct.</li>
                <li>
                  Make sure that what you are searching for is according to your
                  type.
                </li>
              </ol>
            </Typography>
            <Icon
              fontSize="25px"
              className="mt-3"
              color="gray"
              height="80px"
              width="80px"
              icon="material-symbols-light:search-off"
            />
          </Typography>
        )}
        {previousType === 0 && hasSearched === true && !!customerData && (
          <>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {" "}
                <div className="mb-1">
                  {" "}
                  <CustomerDetail detail={customerData} />
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  ></div>
                  <ProjectListing detail={projectListing} />
                </div>
              </>
            )}
          </>
        )}
        {previousType === 1 &&
          hasSearched === true &&
          !!projectDetail &&
          Object.keys(projectDetail).length > 0 && (
            <>
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <div className="mb-1">
                    <CustomerDetail detail={customerData} />
                  </div>
                  <ProjectDetail detail={projectDetail} />
                </>
              )}
            </>
          )}
        {(previousType === 2 || previousType === 3) &&
          hasSearched === true &&
          !!projectDetail &&
          Object.keys(projectDetail).length > 0 && (
            <>
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <div className="mb-1">
                    <CustomerDetail detail={customerData} />
                  </div>
                  <ProjectDetail detail={projectDetail} pageslug={pageslug} />
                </>
              )}
            </>
          )}
      </Box>
    </>
  );
}

export default FindProfile;
