/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "src/components/Loader/Loader";
import { s3baseUrl } from "src/config/config";
import Label from "../../components/Label";
import CustomPopover from "src/components/CustomPopover";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import {
  _fetch_file_from_s3,
  _save_files_by_admin,
} from "../../DAL/source-code/source-code";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import { detail_project_for_admin } from "src/DAL/projects/projects";
import Editor from "@monaco-editor/react";
import { Icon } from "@iconify/react";

function EditSourceCode(props) {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [openChangesDialog, setOpenChangesDialog] = useState(false);
  const [valueToSave, setValueToSave] = useState();

  const [CssFile, setCss] = useState("");
  const [htmlFile, setHtml] = useState("");
  const [JSFile, setJSFile] = useState("");
  const [Data, setData] = useState(location.state?.project);
  console.log(Data, "projectsDataa");
  const [DetailClick, setDetailClick] = useState(true);
  const [SelectedPage, setSelectedPage] = useState("index");
  const [selectedTab, setSelectedTab] = useState("html");
  const [PagesList, setPagesList] = useState([]);
  const [isBackPage, setIsBackPage] = useState(false);
  const [modifiedPages, setModifiedPages] = useState({});
  const [dotTabs, setDotTabs] = useState({
    html: false,
    css: false,
    javascript: false,
  });

  const handleBack = () => {
    if (isCodeChanged) {
      handleIsBackPage();
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async (e) => {
    setOpenChangesDialog(false);
    setIsCodeChanged(false);
    setDotTabs(false);
    e.preventDefault();
    let postData = {
      project_id: params.project_id,
      html: htmlFile,
      css: CssFile,
      javascript: JSFile,
      type: params.project_type,
      page_slug: SelectedPage,
    };
    setIsLoadingUpdate(true);
    const edit_file = await _save_files_by_admin(postData);
    if (edit_file.code == 200) {
      enqueueSnackbar("Source code updated successfully.", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(edit_file.message, { variant: "error" });
      setOpenChangesDialog(false);
      setIsCodeChanged(false);
    }
    setIsLoadingUpdate(false);
  };

  const fetch_files_from_s3 = async () => {
    const html_file_path = `${s3baseUrl}/${params.project_id}/${SelectedPage}.html`;
    const result_html = await _fetch_file_from_s3(html_file_path);
    setHtml(result_html);

    const css_file_path = `${s3baseUrl}/${params.project_id}/css/${
      SelectedPage == "index" ? "style" : SelectedPage
    }.css`;
    const result_css = await _fetch_file_from_s3(css_file_path);
    setCss(result_css);

    const js_file_path = `${s3baseUrl}/${params.project_id}/js/${SelectedPage}.js`;
    const result_js = await _fetch_file_from_s3(js_file_path);
    setJSFile(result_js);
    setIsLoading(false);
  };

  // const handleChangePageData = (value) => {
  //   setValueToSave(value);
  //   if (isCodeChanged) {
  //     handleOpenModifyChanges();
  //   } else {
  //     setSelectedTab("html");
  //     setIsLoading(true);
  //     setSelectedPage(value);
  //   }
  // };
  const handleChangePageData = async (value) => {
    setValueToSave(value);
    if (isCodeChanged) {
      handleOpenModifyChanges();
    } else {
      setSelectedTab("html");
      setIsLoading(true);
      setSelectedPage(value);
      if (!modifiedPages[value]) {
        setDotTabs({});
      }
    }
  };

  useEffect(async () => {
    fetch_files_from_s3();
  }, [SelectedPage]);

  useEffect(async () => {
    const resp = await detail_project_for_admin(params.project_id);
    console.log(params.project_id, resp, "mehoresp");
    setPagesList([...resp.project.pages]);
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  const handleMenuClick = () => {
    window.open(s3baseUrl + "/" + Data?._id + "/" + SelectedPage + ".html");
    setDetailClick(!DetailClick);
  };

  const handleOpenModifyChanges = () => {
    console.log("handleOpenModifyChanges");
    setOpenChangesDialog(true);
  };

  const handleIsBackPage = () => {
    setIsBackPage(true);
  };

  // const handleEditorChange = (tab) => {
  //   // if (tab === "html") {
  //   //   setHtmlModified(true);
  //   // } else if (tab === "css") {
  //   //   setCssModified(true);
  //   // } else if (tab === "javascript") {
  //   //   setJsModified(true);
  //   // }
  //   setIsCodeChanged(true);

  const handleEditorChange = (tab) => {
    setDotTabs((prevState) => ({
      ...prevState,
      [tab]: true,
    }));
    setModifiedPages((prevState) => ({
      ...prevState,
      [SelectedPage]: true,
    }));
    setIsCodeChanged(true);
  };

  // console.log(
  //   s3baseUrl + "/" + Data._id + "/" + SelectedPage + ".html",
  //   "{Data.preview_link}"
  // );
  const DETAIL_MENU = [
    {
      label: (
        <a
          className="previewTag"
          href={s3baseUrl + "/" + Data?._id + "/" + SelectedPage + ".html"}
          target="_blank"
        >
          Preview
        </a>
      ),
      icon: "ic:baseline-remove-red-eye",
      handleClick: handleMenuClick,
    },
    // {
    //   label: 'Delete Page',
    //   icon: 'ep:delete',
    //   handleClick: {},
    // },
  ];

  return (
    <Box sx={{ p: 2 }}>
      <div className="col-12 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <IconButton
            onClick={() => {
              handleBack();
            }}
            aria-label="delete"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{ marginLeft: "15px" }}
            className="text-capitalize"
            variant="h5"
          >
            {location.state?.project?.project_name}
            {console.log(
              location.state?.project?.project_name,
              "prooojectttnameeeeeeeeee"
            )}
          </Typography>
        </div>
        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={1} style={{ alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>Username :</Typography>
            <p>{location.state?.project?.user_name}</p>
          </Stack>
          <Stack direction="row" spacing={1} style={{ alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>Status :</Typography>
            <Label variant="ghost" color={Data?.status ? "success" : "error"}>
              {Data?.status ? "Active" : "Inactive"}
            </Label>
          </Stack>
          <Stack direction="row" spacing={1} style={{ alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>Published :</Typography>
            <Label
              variant="ghost"
              color={Data?.is_published ? "success" : "error"}
            >
              {Data?.is_published ? "Yes" : "No"}
            </Label>
          </Stack>
          <Stack direction="row" spacing={1} style={{ alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold" }}>SSL Config :</Typography>
            <Label
              variant="ghost"
              color={
                Data?.ssl_status
                  ? Data?.ssl_configured
                    ? "success"
                    : "info"
                  : "error"
              }
            >
              {Data?.is_published && Data?.ssl_status
                ? Data?.ssl_configured
                  ? "Yes"
                  : "Processing"
                : "No"}
            </Label>
          </Stack>
          <div className="p-1 d-flex justify-content-between">
            {/* {/ <CustomPopover menu={DETAIL_MENU} /> /} */}
            <CustomPopover menu={DETAIL_MENU} />
          </div>
        </Stack>
      </div>
      <Stack
        direction={{ xs: "column", sm: "row", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        sx={{ marginTop: "15px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          alignItems={{ xs: "center" }}
          spacing={2}
        >
          <FormControl>
            <InputLabel id="test-select-label">Page</InputLabel>
            <Select
              sx={{
                width: 150,
                fontSize: 14,
                color: "black",
              }}
              labelId="test-select-label"
              label="Page"
              value={SelectedPage}
              onChange={(e) => {
                handleChangePageData(e.target.value);
              }}
              className="border-end"
              size="small"
            >
              {PagesList &&
                PagesList.length > 0 &&
                PagesList.map((item) => (
                  <MenuItem value={item.page_slug}>{item.page_name}</MenuItem>
                ))}
            </Select>
          </FormControl>

          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            color="primary"
            size="medium"
          >
            <Button
              sx={{ width: "87px" }}
              variant={selectedTab === "html" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedTab("html");
              }}
            >
              HTML
              {dotTabs.html && modifiedPages[SelectedPage] && (
                <Icon icon="oui:dot" />
              )}
            </Button>
            <Button
              sx={{ width: "87px" }}
              variant={selectedTab === "css" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedTab("css");
              }}
            >
              CSS
              {dotTabs.css && modifiedPages[SelectedPage] && (
                <Icon icon="oui:dot" />
              )}
            </Button>
            <Button
              variant={selectedTab === "javascript" ? "contained" : "outlined"}
              onClick={() => {
                setSelectedTab("javascript");
              }}
            >
              Javascript
              {dotTabs.javascript && modifiedPages[SelectedPage] && (
                <Icon icon="oui:dot" />
              )}
            </Button>
          </ButtonGroup>
        </Stack>
        <Stack>
          <LoadingButton
            // type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            fullWidth
            sx={{ paddingLeft: "50px", paddingRight: "50px" }}
            variant="contained"
            color="primary"
            loading={isLoadingUpdate}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {selectedTab === "html" && (
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <div className="editorr">
                <Typography variant="subtitle2">HTML:</Typography>
                <Editor
                  height="80vh"
                  defaultLanguage="html"
                  defaultValue="// some comment"
                  value={htmlFile}
                  onChange={(newValue, event) => {
                    setHtml(newValue);
                    handleEditorChange("html");
                  }}
                />
              </div>
            </Grid>
          )}
          {selectedTab === "css" && (
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <div className="editorr">
                <Typography variant="subtitle2">CSS:</Typography>
                <Editor
                  height="80vh"
                  // theme="vs-dark"
                  defaultLanguage="css"
                  defaultValue="// some comment"
                  value={CssFile}
                  onChange={(newValue, event) => {
                    setCss(newValue);
                    handleEditorChange("css");
                  }}
                  name="css"
                />
              </div>
            </Grid>
          )}
          {selectedTab === "javascript" && (
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <div className="editorr">
                <Typography variant="subtitle2">Javascript:</Typography>
                <Editor
                  height="80vh"
                  defaultLanguage="javascript"
                  defaultValue="// some comment"
                  value={JSFile}
                  onChange={(newValue, event) => {
                    setJSFile(newValue);
                    handleEditorChange("javascript");
                  }}
                  name="js"
                />
              </div>
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              loading={isLoadingUpdate}
            >
              Update
            </LoadingButton>

            <Button
              className="mt-3"
              onClick={handleBack}
              fullWidth
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
          </Grid> */}
        </Grid>
      </form>

      <Dialog
        open={openChangesDialog}
        onClose={() => setOpenChangesDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "15px 20px 15px 20px" }}
        >
          You have unsaved changes. Would you like to save them?
        </DialogTitle>
        <DialogActions
          style={{
            padding: "15px 20px 20px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <stripe-buy-button
        buy-button-id="buy_btn_1"
        publishable-key="f86a18d59a7164956726651d9ab5ff7d7b2d0f87f143f523aff00bdaa16b7a809b073d28">
        </stripe-buy-button> */}
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setOpenChangesDialog(false);
            }}
            size="small"
          >
            Cancel
          </Button>
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenChangesDialog(false);
                setIsCodeChanged(false);
                setIsLoading(true);
                setSelectedPage(valueToSave);
                setSelectedTab("html");
                setDotTabs(false);
              }}
              size="small"
              className="me-2"
            >
              Discard Changes
            </Button>
            <Button
              variant="contained"
              onClick={async (e) => {
                setIsLoading(true);
                handleSubmit(e);
                setSelectedPage(valueToSave);
                setSelectedTab("html");
                setDotTabs(false);
              }}
              size="small"
            >
              Save Changes
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isBackPage}
        onClose={() => setIsBackPage(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "15px 20px 15px 20px" }}
        >
          You have unsaved changes. Would you like to save them?
        </DialogTitle>
        <DialogActions
          style={{
            padding: "15px 20px 20px 20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="error"
            variant="outlined"
            onClick={() => {
              setIsBackPage(false);
            }}
            size="small"
          >
            Cancel
          </Button>
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
                setIsBackPage(false);
                setIsCodeChanged(false);
                setIsLoading(true);
                setDotTabs(false);
              }}
              size="small"
              className="me-2"
            >
              Discard Changes
            </Button>
            <Button
              variant="contained"
              onClick={async (e) => {
                navigate(-1);
                setIsLoading(true);
                handleSubmit(e);
                setDotTabs(false);
              }}
              size="small"
            >
              Save Changes
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default EditSourceCode;
