import * as React from "react";
import DataTable from "./Data-Section";
import { Icon } from "@iconify/react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export default function DetailModal({ open, setOpen, detail }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head">
            <p>Details</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <DataTable detail={detail} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
