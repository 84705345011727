import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
// import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { _site_creation_data } from "src/DAL/Website/SiteCreation";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import MenuItemModal from "../MenuItemsDialog/MenuItem";
import DescriptionModal from "../GeneralDialog/GeneralDialog";
import { Icon } from "@iconify/react";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { useSnackbar } from "notistack";
import SiteDescriptionModal from "../DescriptionDialog/DescriptionDialog";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function SiteCreation() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteData, setSiteData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [rowData, setRowData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [Itemindex, setItemIndex] = useState();
  const [inputs, setInputs] = useState({
    title: "",
    text: "",
    link: "",
    description: "",
  });

  console.log(siteData, "tsydfsyfyus");
  // const [stepArray, setStepArray] = useState([]);

  const handleOpenModel = () => {
    setOpen(true);
  };

  const SiteDetail = async () => {
    setLoading(true);
    const siteDataDetail = await _landing_page_detail();
    if (siteDataDetail.code === 200) {
      setLoading(false);
      setSiteData(siteDataDetail?.data?.site_creation);
      setInputs((prev) => {
        return { ...prev, title: siteDataDetail?.data?.site_creation.title };
      });
      setInputs((prev) => {
        return {
          ...prev,
          text: siteDataDetail?.data?.site_creation.get_started_button.text,
        };
      });
      setInputs((prev) => {
        return {
          ...prev,
          link: siteDataDetail?.data?.site_creation.get_started_button.link,
        };
      });
    }
  };

  useEffect(() => {
    SiteDetail();
  }, []);

  const newArray = siteData?.steps?.map((obj) => {
    const { _id, ...rest } = obj;
    return rest;
  });
  console.log(newArray, "ftysaft");
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const postData = {
      title: inputs?.title,
      steps: newArray,

      get_started_button: {
        text: inputs?.text,
        link: inputs?.link,
      },
    };

    console.log(postData, "safdysagy");

    const result = await _site_creation_data(postData);
    console.log(result, "inputtttt");
    if (result.code === 200) {
      enqueueSnackbar(`data added successfully`, {
        variant: "success",
      });
      SiteDetail();
    } else {
      enqueueSnackbar(`data not added successfully`, {
        variant: "error",
      });
    }
  };

  const handleConfirmDelete = (i) => {
    let obj = siteData;
    obj.steps.splice(i, 1);

    setSiteData({ ...obj });
    setOpenDelete(false);
  };

  const handleDelete = (i) => {
    setItemIndex(i);
    setOpenDelete(true);
  };

  console.log(rowData, "tyfdtasdf");
  const handleEdit = (val, i) => {
    setItemIndex(i);
    setRowData(val);
    setOpen(true);
  };
  return (
    <div style={{ width: "94%", margin: "auto", marginTop: "3rem" }}>
      <SiteDescriptionModal
        open={open}
        setOpen={setOpen}
        stepData={siteData}
        setStepData={setSiteData}
        editData={rowData}
        setEditData={setRowData}
        dataIndex={Itemindex}
      />

      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <h3 className="text-center">Site Creation</h3>
          <form>
            <div>
              <p>Title</p>
              <GeneralCkeditor
                inputs={inputs}
                name="title"
                required
                setInputs={setInputs}
                onChange={handleChange}
              />
              <div className="row align-items-center mt-4">
                <div className="col-12 col-sm-12 col-md-5 mb-4 mb-sm-3 mb-md-0">
                  <TextField
                    // style={{ width: "30%" }}
                    fullWidth
                    label="Get Started Button Text"
                    required
                    name="text"
                    value={inputs?.text}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-5 mb-4 mb-sm-3 mb-md-0">
                  <TextField
                    // style={{ width: "30%" }}
                    fullWidth
                    label="Get Started Button link"
                    name="link"
                    required
                    value={inputs?.link}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-2">
                  <Button onClick={handleOpenModel}>Add Description</Button>
                </div>
              </div>
            </div>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
                className="mt-4"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {siteData.steps?.map((val, i) => {
                    return (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: val?.description,
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            <Icon
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                              onClick={() => handleEdit(val, i)}
                            />
                          </TableCell>
                          <TableCell>
                            <Icon
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                              onClick={() => handleDelete(i)}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="text-end mt-2">
              <Button variant="contained" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </form>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are You sure you want to Delete ?"}
            handleAgree={() => handleConfirmDelete(Itemindex)}
          />
        </>
      )}
    </div>
  );
}
