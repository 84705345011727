import React, { useEffect, useState } from "react";
import { Box, Drawer, Typography, TextField, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { _admin_details, _update_admin_info } from "src/DAL/Admin/Admin";
import { s3baseUrl } from "src/config/config";
import { useAppContext } from "src/Hooks/AppContext";

function EditAdmin({ open, onClose, handleClose, setProfile }) {
  const { user, setUser } = useAppContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [adminData, setAdminData] = useState(null);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [currentProfileImageURL, setCurrentProfileImageURL] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [imageStatus, setImageStatus] = useState(false);

  const handleFirstNameChange = (event) => {
    setAdminData((prevData) => ({
      ...prevData,
      first_name: event.target.value,
    }));
  };

  const handleLastNameChange = (event) => {
    setAdminData((prevData) => ({
      ...prevData,
      last_name: event.target.value,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    let inputValue = event.target.value;

    // Remove dashes and any non-digit characters
    inputValue = inputValue.replace(/[^0-9-]/g, "");
    setAdminData((prevData) => ({
      ...prevData,
      contact_number: inputValue,
    }));

    setPhoneNumber(inputValue);
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("profile_image", file);
    setProfilePicture(formData);
    setImageStatus(false);
  };

  const handleRemoveProfilePicture = () => {
    setProfilePicture(null);
    setImageStatus(true);
    setCurrentProfileImageURL("");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("first_name", adminData.first_name);
    formData.append("last_name", adminData.last_name);
    formData.append("contact_number", adminData.contact_number);
    if (profilePicture) {
      formData.append("profile_image", profilePicture.get("profile_image"));
    }

    if (imageStatus) {
      formData.append("image_status", imageStatus);
    } else {
      formData.append("image_status", false);
    }
    formData.append("status", true);

    try {
      const updateAdmin = await _update_admin_info(formData); // Assuming `adminData.id` represents the ID of the admin being edited

      console.log(updateAdmin, "adminfeids");

      if (updateAdmin.code === 200) {
        setUser(updateAdmin.adminUser);
        setProfile(updateAdmin.adminUser);
        onClose();
        enqueueSnackbar("Admin Updated Successfully", {
          variant: "success",
        });

        // Update the currentProfileImageURL with the new URL ----to show image after render
        setCurrentProfileImageURL(updateAdmin.adminUser.profile_image);
      } else {
        enqueueSnackbar(updateAdmin.message, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error updating admin info:", error);
      enqueueSnackbar("An error occurred while updating admin info", {
        variant: "error",
      });
    }
  };

  if (open) {
    handleClose();
  }

  const getAdminDetails = async () => {
    try {
      const response = await _admin_details();
      setAdminData(response.admin);

      setProfileImageURL(response.admin.profile_image);
      setCurrentProfileImageURL(response.admin.profile_image); // Set the current profile image URL
    } catch (error) {
      console.error("Error fetching admin details:", error);
      enqueueSnackbar("An error occurred while fetching admin details", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (open) {
      getAdminDetails();
    }
  }, [open]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 300, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          Edit Profile
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <div
            style={{
              width: 150,
              height: 150,
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            {profilePicture ? (
              <img
                src={
                  profilePicture
                    ? URL.createObjectURL(profilePicture.get("profile_image"))
                    : currentProfileImageURL || ""
                }
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : profileImageURL ? (
              <img
                src={`${s3baseUrl}/${currentProfileImageURL}`}
                alt="Profile"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#e0e0e0",
                  color: "#757575",
                  fontSize: 24,
                }}
              >
                No Photo
              </div>
            )}
          </div>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <label htmlFor="profile-picture-upload">
            <input
              id="profile-picture-upload"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleProfilePictureChange}
            />
            <Button variant="outlined" component="span" sx={{ mr: 1 }}>
              Upload Photo
            </Button>
          </label>
          {/* {profilePicture && ( */}
          <Button variant="outlined" onClick={handleRemoveProfilePicture}>
            Remove Photo
          </Button>
          {/* )} */}
        </Box>
        <Box sx={{ mt: 1 }}>
          <TextField
            label="First Name"
            value={
              adminData?.first_name.charAt(0).toUpperCase() +
                adminData?.first_name.slice(1) || ""
            }
            onChange={handleFirstNameChange}
            fullWidth
            margin="normal"
            required
          />
        </Box>
        <Box>
          <TextField
            label="Last Name"
            value={
              adminData?.last_name.charAt(0).toUpperCase() +
                adminData?.last_name.slice(1) || ""
            }
            onChange={handleLastNameChange}
            fullWidth
            margin="normal"
            required
          />
        </Box>
        <Box>
          <TextField
            label="Phone Number"
            value={adminData?.contact_number || ""}
            onChange={handlePhoneNumberChange}
            fullWidth
            margin="normal"
            // type="text"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9-]*",
            }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          sx={{ marginTop: 1 }}
        >
          Submit
        </Button>
      </Box>
    </Drawer>
  );
}

export default EditAdmin;
