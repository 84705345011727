import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import AccordionTable from "./AccordionTable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FilesSection({ FilesData }) {

  return (
    <div>
     
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
              
            >
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                
              <FolderIcon style={{color:"skyblue"}}/>  Files
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ marginLeft: ".7rem",marginTop:"-1rem" }}>
             <AccordionTable data={FilesData}/>
            </AccordionDetails>
          </Accordion>
        
    </div>
  );
}
