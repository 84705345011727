import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Box,
  CircularProgress,
} from "@mui/material";
// components
import Label from "../../../components/Label";
import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead } from "../../../components/_dashboard/user";
//
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import AddEditRole from "./AddEditRole";
import { _role_delete, _role_list } from "src/DAL/Management/role_api";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "", label: "", alignRight: false },
];

// ----------------------------------------------------------------------

export default function Role() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [targetRole, setTargetRole] = useState();

  const handleOpenAdd = () => {
    setIsOpenDialog(true);
  };

  const handleCloseAdd = () => {
    setIsOpenDialog(false);
    setIsEdit(false);
    setIsDetail(false);
  };

  const showDetail = async (row) => {
    setIsDetail(true);
    setTargetRole(row.row);
    setIsOpenDialog(true);
  };

  const handleDelete = async () => {
    setIsLoadingDelete(true);
    const delete_resp = await _role_delete(targetRole._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      setIsLoadingDelete(false);
      setData((prev) => {
        return prev.filter((item) => item._id != targetRole._id);
      });

      enqueueSnackbar("Role Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
      setIsLoadingDelete(false);
    }
  };

  const isUserNotFound = data.length === 0;

  const handleEditRole = (data) => {
    setIsEdit(true);
    setTargetRole(data.row);
    setIsOpenDialog(true);
  };

  const handleDeleteClick = (row) => {
    setTargetRole(row.row);
    setOpenDelete(true);
  };

  const MENU_OPTIONS = [
    {
      label: "Detail",
      icon: "mdi:card-account-details-outline",
      handleClick: showDetail,
    },
    {
      label: "Edit Role",
      icon: "akar-icons:edit",
      handleClick: handleEditRole,
    },
    {
      label: "Delete Role",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ];

  const getRoleListing = async () => {
    setIsLoading(true);
    const resp = await _role_list();
    if (resp.code === 200) {
      setData(resp.role);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getRoleListing();
  }, []);

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4" gutterBottom>
            Role
          </Typography>
          <Button
            onClick={handleOpenAdd}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Role
          </Button>
        </Stack>

        <Card>
          <>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {data.map((row, index) => {
                      const { _id, title, status } = row;

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{index + 1}</TableCell>

                          <TableCell className="pl-0" align="left">
                            <Typography
                              className="text-capitalize"
                              style={{ cursor: "pointer" }}
                              variant="subtitle2"
                              noWrap
                              onClick={() => showDetail({ row, index })}
                            >
                              {title}
                            </Typography>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={(status && "success") || "error"}
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={MENU_OPTIONS}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={12}
                          sx={{ py: 3, minHeight: "40vh" }}
                        >
                          <SearchNotFound
                            searchQuery={data}
                            icon={"mdi:notes-search-variant"}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            )}
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this role?"}
              handleAgree={handleDelete}
              loadingBtn={isLoadingDelete}
            />
          </>
        </Card>
      </Box>

      <AddEditRole
        isOpenDrawer={isOpenDialog}
        onCloseDrawer={handleCloseAdd}
        getRoleListing={getRoleListing}
        is_edit={isEdit}
        is_detail={isDetail}
        targetRole={targetRole}
      />
    </>
  );
}
