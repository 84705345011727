import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function NeedSectionModal({
  open,
  setOpen,
  menuItemDataObj,
  setMenuItemDataObj,
  selectedData,
  setSelectedData,
  selectedIndex,
}) {
  const [menuItems, setMenuItems] = useState({
    image: "",
    title: "",
    // description: "",
  });

  console.log(selectedIndex, "satfdytsafdya");

  const handleClose = () => {
    setOpen(false);
    setSelectedData({ title: "", image: "" });
  };

  useEffect(() => {
    if (selectedData) {
      setMenuItems(selectedData);
    }
  }, [selectedData]);

  const handleChange = (e) => {
    setMenuItems((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleImageUpload = (e) => {
    setMenuItems((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      };
    });
  };

  const handleAddMenuData = (e) => {
    e.preventDefault();
    let newArray = menuItemDataObj.menu_items;
    newArray.push(menuItems);
    setMenuItemDataObj({ ...menuItemDataObj, menu_items: newArray });
    handleClose();
  };

  const HandleEditData = (e) => {
    e.preventDefault();
    let newArray = menuItemDataObj.menu_items;
    newArray[selectedIndex] = menuItems;
    setMenuItemDataObj({ ...menuItemDataObj, menu_items: newArray });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>
              {selectedData?.title || selectedData.image
                ? "Edit Need Section Menu Items"
                : "Add Need Section Menu Items"}
            </p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form className="gap-3 mt-3">
                  <p>Menu Title</p>
                  <GeneralCkeditor
                    inputs={menuItems}
                    setInputs={setMenuItems}
                    name="title"
                    onChange={(e) => handleChange(e)}
                    value={menuItems?.title}
                  />
                 

                  <div className="d-flex flex-column justify-cotent-center align-items-center mt-3">
                    <img
                      style={{ width: "15rem" }}
                      src={
                        menuItems?.local_image
                          ? menuItems?.local_image
                          : s3baseUrl + "/" + menuItems?.image
                      }
                      alt=""
                    />
                    <label htmlFor="icon-button-file">
                      <Input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        name="image"
                        onChange={handleImageUpload}
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-3"
                      >
                        {selectedData?.image === ""
                          ? "Choose Image"
                          : "Upload Image"}
                      </Button>
                    </label>
                  </div>
                  {selectedData?.title || selectedData.image? (
                    <>
                      <div className="text-end mt-3">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={(e) => HandleEditData(e)}
                        >
                          Edit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-end mt-3">
                        <Button
                          variant="contained"
                          type="submit"
                          onClick={(e) => handleAddMenuData(e)}
                        >
                          Add
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
