import React from "react";
import { TableBody, TableCell, TableRow, Table } from "@mui/material";
import { Icon } from "@iconify/react";
import { s3baseUrl } from "src/config/config";

export default function AccordionTable({ data }) {
  return (
    <div>
      <Table>
        <TableBody >
          {data?.map((dat, i) => {
            let Arr = dat.Key.split("/");
            let fileName = Arr[Arr.length - 1];

            return (
              <TableRow >
                <TableCell style={{border:"none"}} >
                  <a
                    href={`${s3baseUrl}/${dat.Key}`}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                      gap: ".4rem",
                    }}
                  >
                    {fileName.endsWith("css") ? (
                      <Icon icon="logos:css-3" style={{ fontSize: "20px" }} />
                    ) : fileName.endsWith("js") ? (
                      <Icon
                        icon="vscode-icons:file-type-js"
                        style={{ fontSize: "20px" }}
                      />
                    ) : fileName.endsWith("zip") ? (
                      <Icon
                        icon="material-symbols:folder-zip"
                        style={{ fontSize: "20px" }}
                      />
                    ) : fileName.endsWith("html") ? (
                      <Icon icon="logos:html-5" style={{ fontSize: "20px" }} />
                    ) : fileName.endsWith("") ? (
                      <Icon icon="bi:image-fill" style={{ fontSize: "17px" }} />
                    ) : (
                      ""
                    )}
                    {fileName}
                  </a>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
