const { invokeApi } = require("src/bl_libs/invokeApi");

export const _role_list = async () => {
  let requestObj = {
    path: `api/role/get_roles`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _role_details = async (id) => {
  let requestObj = {
    path: `api/role/detail_role/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_role = async (data) => {
  let requestObj = {
    path: `api/role/add_role`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _edit_role = async (id, data) => {
  let requestObj = {
    path: `api/role/update_role/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _role_delete = async (id) => {
  let requestObj = {
    path: `api/role/delete_role/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
