import { invokeApi } from "src/bl_libs/invokeApi";

var myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");

var myInit = {
  method: "GET",
  headers: myHeaders,
};

export const _fetch_file_from_s3 = (url) => {
  return new Promise(async (resolve, reject) => {
    const resp = await fetch(url, myInit);
    if (resp.status === 200) {
      const data = await resp.text();
      resolve(data);
    } else {
      console.error(resp, "fetch_from_s3_error");
      resolve("");
    }
  });
};

export const _save_files_by_admin = async (data) => {
  let requestObj = {
    path: "api/admin/save_files_by_admin",
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
