import {
  Box,
  Button,
  CircularProgress,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _three_steps_data } from "src/DAL/Website/ThreeSteps";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
// import TextModal from "../AddTextDialog/TextDialog";
import StepsModal from "../StepsDialog/StepsDialog";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { Icon } from "@iconify/react";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function ThreeSteps() {
  // const [stepDataArray, setStepDataArray] = useState([]);
  const [ThreeStepDetailData, setThreeStepDetailData] = useState({});
  console.log(ThreeStepDetailData, "ascjklascklasjcklasjc");
  const [title, setTitle] = useState({
    title: "",
  });
  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [targetRow, setTargetRow] = useState("");
  const [rowIndex, setRowIndex] = useState();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  console.log(ThreeStepDetailData, "ThreeStepDetailData");

  const handleChange = (e) => {
    setTitle((prev) => {
      return { ...prev, title: e.target.value };
    });
  };

  const ThreeStepDetail = async () => {
    setLoading(true);
    const StepsDetail = await _landing_page_detail();
    if (StepsDetail.code === 200) {
      setLoading(false);
      setThreeStepDetailData(StepsDetail?.data?.three_steps);
      setTitle({ title: StepsDetail?.data?.three_steps.title });
    }

    console.log(StepsDetail, "tasydfy");
  };

  useEffect(() => {
    ThreeStepDetail();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tempSteps = [...ThreeStepDetailData.steps];
    for (let i = 0; i < tempSteps.length; i++) {
      if (tempSteps[i]._id) {
        delete tempSteps[i]._id;
      }
      if (typeof tempSteps[i].image == "string" && tempSteps[i].image !== "") {
        continue;
      }
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", tempSteps[i].image);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code === 200) {
        tempSteps[i].image = image_resp.file_name;
      } else {
        tempSteps[i].image = "";
      }
      delete tempSteps[i].local_image;
    }

    let postData = {
      title: title?.title,
      steps: [...tempSteps],
    };
    console.log(postData, "ftxysdf");

    const res = await _three_steps_data(postData);

    if (res.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      ThreeStepDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }
  };

  const handleConfirmDelete = (i) => {
    setThreeStepDetailData((prev) => {
      return {
        ...prev,
        steps: prev.steps.filter((step, index) => index !== i),
      };
    });

    setOpenDelete(false);
  };

  console.log(title, "ftsyf");
  const handleDelete = (row, i) => {
    setRowIndex(i);
    setOpenDelete(true);
  };

  const handleEdit = (row, i) => {
    console.log(row, "tfsydft");
    setTargetRow(row);
    setRowIndex(i);
    setOpen(true);
  };

  const handleStepModel = () => {
    setOpen(true);
  };

  return (
    <div style={{ width: "94%", margin: "auto", marginTop: "3rem" }}>
      <StepsModal
        open={open}
        setOpen={setOpen}
        data={ThreeStepDetailData}
        setData={setThreeStepDetailData}
        RowData={targetRow}
        setRowData={setTargetRow}
        rowIndex={rowIndex}
      />

      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <form>
            <h3 className="text-center pb-2">Three Steps</h3>
            <div className=" pb-3">
              <p>Title</p>

              <GeneralCkeditor
                inputs={title}
                setInputs={setTitle}
                value={title?.title}
                name="title"
                onChange={handleChange}
              />
            </div>

            <Button onClick={handleStepModel} className="mt-2">
              Add Steps Data
            </Button>

            <div className="mt-4">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Step Title</TableCell>
                    <TableCell>Step Decription</TableCell>
                    <TableCell>Step Button Text</TableCell>
                    <TableCell>Step Button Link</TableCell>
                    <TableCell>Step Image</TableCell>
                    <TableCell>Edit</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ThreeStepDetailData?.steps?.map((val, i) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell>{val.title}</TableCell>
                          <TableCell>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: val?.description,
                              }}
                            />
                          </TableCell>
                          <TableCell>{val.step_button.text}</TableCell>
                          <TableCell>{val.step_button.link}</TableCell>
                          <TableCell>
                            <img
                              style={{ width: "4.5rem", height: "2.5rem" }}
                              src={
                                val.local_image
                                  ? val.local_image
                                  : s3baseUrl + "/" + val.image
                              }
                              alt=""
                            />
                          </TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleEdit(val, i)}
                              style={{ color: "green", cursor: "pointer" }}
                              icon={"fa-regular:edit"}
                            />
                          </TableCell>
                          <TableCell>
                            <Icon
                              onClick={() => handleDelete(val, i)}
                              style={{ color: "red", cursor: "pointer" }}
                              icon={"ic:baseline-delete"}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className="text-start mt-3">
              <div className="text-end">
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </form>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are You sure you want to Delete ?"}
            handleAgree={() => handleConfirmDelete(rowIndex)}
          />
        </>
      )}
    </div>
  );
}
