import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  _delete_subscriber,
  _project_subscriber_listing,
} from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import CustomConfirmation from "src/components/CustomConfirmation";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { useSnackbar } from "notistack";
import Label from "../../components/Label";
import DetailModal from "../Detail-Section/Detail-Section";

export default function SubscriberList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(true); // Loading state

  const [openDelete, setOpenDelete] = useState(false);

  const [subscriberList, setSubscriberList] = useState([]);
  const [openSubscriberDetail, setOpenSubscriberDetail] = useState(false);
  const [subscriberDetail, setSubscriberDetail] = useState();

  const pageSubscriberList = async () => {
    const project_list_response = await _project_subscriber_listing(id);
    setData(project_list_response.project);
    console.log(project_list_response.project, "subscriber");
    setLoading(false);
    console.log(project_list_response, "sublist");

    // console.log(data, "datattatat");
  };

  useEffect(() => {
    pageSubscriberList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = async () => {
    const data_req = {
      project_id: selectedPage.project_id._id,
      subscriber_id: selectedPage._id,
    };

    const delete_resp = await _delete_subscriber(data_req);

    console.log("Subscriberpage:", delete_resp);
    console.log("Subscriberpage:", selectedPage._id);

    if (delete_resp.code == 200) {
      pageSubscriberList();

      setOpenDelete(false);
      enqueueSnackbar("Subscriber Deleted Successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };

  // console.log(s3baseUrl, "cjdjcdjncjdjjnc");

  const EmailStatusColor = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={subscriberList.email_status == 1 ? "success" : "error"}
        >
          {val}
        </Label>
      </div>
    );
  };

  const PaidStatusColor = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={subscriberList.is_paid == 0 ? "error" : "success"}
        >
          {val}
        </Label>
      </div>
    );
  };

  const StatusColor = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={subscriberList?.project_id?.status == 0 ? "error" : "success"}
        >
          {val}
        </Label>
      </div>
    );
  };

  const sslStatus = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            subscriberList?.project_id?.ssl_status == 0 ? "error" : "success"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const IsApproved = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            subscriberList?.project_id?.is_approved == 0 ? "error" : "success"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const IsCompleted = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            subscriberList?.project_id?.is_completed == 0 ? "error" : "success"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const IsPublished = (val) => {
    return (
      <div>
        <Label
          variant="ghost"
          color={
            subscriberList?.project_id?.is_published == 0 ? "error" : "success"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  useEffect(() => {
    if (subscriberList) {
      setSubscriberDetail([
        {
          name: "First Name",
          value: subscriberList?.first_name ? 
            subscriberList?.first_name
           : 
            "-"
          
        },
        {
          name: "Last Name",
          value: subscriberList?.last_name ? subscriberList?.last_name : "-",
        },
        {
          name: "Email",
          value: subscriberList.email ? subscriberList.email : <p>-</p>,
        },
        {
          name: "Email Status",
          value: subscriberList.email_status ? (
            EmailStatusColor(
              subscriberList.email_status == 1 ? "true" : "false"
            )
          ) : (
            <p>-</p>
          ),
        },
        {
          name: "Phone",
          value: subscriberList.phone ? subscriberList.phone : <p>-</p>,
        },
        {
          name: "Page Group Id",
          value: subscriberList.page_group_id ? (
            subscriberList.page_group_id
          ) : (
            <p>-</p>
          ),
        },
        {
          name: "Page Id",
          value: subscriberList.page_id ? subscriberList.page_id : <p>-</p>,
        },
        {
          name: "Paid Status",
          value: subscriberList.is_paid ? (
            PaidStatusColor(subscriberList.is_paid == 0 ? "false" : "true")
          ) : (
            <p>-</p>
          ),
        },
        {
          name: "Message",
          value: subscriberList.message ? subscriberList.message : <p>-</p>,
        },
        {
          name: "Created At",
          value: subscriberList.createdAt ? (
            moment(subscriberList.createdAt).format("LLL")
          ) : (
            <p>-</p>
          ),
        },
        {
          name: "Updated At",
          value: subscriberList.updatedAt ? (
            moment(subscriberList.updatedAt).format("LLL")
          ) : (
            <p>-</p>
          ),
        },
      ]);
    }
  }, [subscriberList]);

  const openDetailDialog = () => {
    setOpenSubscriberDetail(true);
  };

  const handleSubscriberDetail = (dat) => {
    console.log(dat.dat, "subscriberrowww");
    setSubscriberList(dat.dat);
    openDetailDialog();
  };

  return (
    <>
      <DetailModal
        open={openSubscriberDetail}
        setOpen={setOpenSubscriberDetail}
        detail={subscriberDetail}
      />
      <TableContainer component={Paper}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>Email</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  First Name
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Last Name
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Email Status
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Subscription Status
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Paid Status
                </TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Created At
                </TableCell>
                {/* <TableCell>Message</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.active_compaign_subscriber &&
              data.active_compaign_subscriber.length > 0 ? (
                data.active_compaign_subscriber.map((dat, index) => (
                  <TableRow
                    key={dat.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell
                      onClick={() => handleSubscriberDetail({ dat })}
                      style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                    >
                      {dat.email}
                    </TableCell>
                    <TableCell>{dat.first_name?dat.first_name:"-"}</TableCell>
                    <TableCell>{dat.last_name?dat.last_name:"-"} </TableCell>
                    <TableCell>
                      <Label
                        variant="ghost"
                        color={(!dat.email_status && "error") || "success"}
                      >
                        {dat.email_status ? "True" : "False"}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant="ghost"
                        color={
                          (!dat.subscription_status && "error") || "success"
                        }
                      >
                        {dat.subscription_status === true ? "True" : "False"}
                      </Label>
                    </TableCell>
                    <TableCell>
                      <Label
                        variant="ghost"
                        color={(!dat.is_paid && "error") || "success"}
                      >
                        {dat.is_paid === true ? "True" : "False"}
                      </Label>
                    </TableCell>

                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {dat.createdAt ? moment(dat.createdAt).format("LLL"):"-"}
                    </TableCell>
                    {/* <TableCell>{dat.message} </TableCell> */}

                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="page-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleMenuOpen(e, dat)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="page-menu"
                        anchorEl={anchorEl}
                        open={Boolean(
                          anchorEl &&
                            selectedPage &&
                            selectedPage.project_id === dat.project_id &&
                            selectedPage.subscriber_id === dat.subscriber_id
                        )}
                        onClose={handleMenuClose}
                      >
                        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    No Subscriber Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this customer?"}
        handleAgree={handleDeletePage}
      />
    </>
  );
}
