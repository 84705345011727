/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  _trending_templates,
  templates_list,
  templates_pages,
} from "src/DAL/Templates/templates";
import CustomPopover from "src/components/CustomPopover";
import Label from "src/components/Label";
import { sourceS3BaseUrl } from "src/config/config";
import TemplatesPagesDialog from "./TemplatesPagesDialog";
import { UserListHead, UserListToolbar } from "src/components/_dashboard/user";
import SearchNotFound from "src/components/SearchNotFound";
import { filter } from "lodash";
import DetailModal from "src/pages/Detail-Section/Detail-Section";

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "" },
  { id: "name", label: "Name", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  // { id: "mode", label: "Mode", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "use_count", label: "Usage Count", alignCenter: true },
  { id: "is_paid", label: "Is Paid", alignRight: false },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "discount", label: "Discount", alignRight: false },
  { id: "amount", label: "Final Amount", alignRight: false },
  // { id: "order", label: "Order", alignRight: false },
  // { id: "_id", label: "Template ID", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Updated At", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  console.log("Array received:", array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((templates) => {
      return templates?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function Templates() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templateType, setTemplateType] = useState("active");
  const [activeTemplates, setActiveTemplates] = useState([]);
  const [inactiveTemplates, setInactiveTemplates] = useState([]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [tempDetail, setTempDetail] = useState();
  const [openTempDetail, setOpenTempDetail] = useState(false);
  const [openTempDetailModal, setOpenTempDetailModal] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [selectedTemplatesData, setSelectedTemplatesData] = useState([]);

  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setTrendingTemplates({ ...trendingTemplates, [name]: value });
  };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          variant="ghost"
          color={selectedRow?.status === true ? "success" : "error"}
        >
          {Active}
        </Label>
      </>
    );
  };
  const IsPaidFunc = (Active) => {
    return (
      <>
        <Label
          variant="ghost"
          color={selectedRow?.is_paid === true ? "success" : "error"}
        >
          {Active}
        </Label>
      </>
    );
  };
  const IsCountFunc = (val) => {
    return (
      <>
        <Label variant="ghost" color="warning">
          {val}
        </Label>
      </>
    );
  };

  const handleOpenModal = () => {
    setOpenTempDetailModal(true);
  };

  const handleDetailTemplate = (row) => {
    handleOpenModal();
    setSelectedRow(row.row);
    console.log(selectedRow, "seleecteddRoowww");
  };

  const handleCloseTemoDetail = () => {
    setOpenTempDetailModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = templates.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - selectedTemplatesData.length)
      : 0;

  const filteredUsers = applySortFilter(
    selectedTemplatesData,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Pages Against Templates",
      icon: "mdi:card-account-details-outline",
      handleClick: handleDetailTemplate,
    },
  ]);

  const handleClickOpenPreview = (row) => {
    console.log(sourceS3BaseUrl, "newwaytoshow");
    window.open(`${sourceS3BaseUrl}${row._id}/index.html`);
  };

  const templates_listing = async () => {
    setLoading(true);
    const response = await templates_list();
    console.log(response, "templatessLIstinggggggggggggggggg");
    if (response.code === 200) {
      setTemplates(response.templates);
      const activeTemplates = response.templates.filter(
        (template) => template.status === true
      );
      setActiveTemplates(activeTemplates);
      setSelectedTemplatesData(activeTemplates);
      const inactiveTemplates = response.templates.filter(
        (template) => template.status === false
      );
      setInactiveTemplates(inactiveTemplates);
      setLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, { variant: "error" });
    }
  };

  const handleOpenTempDetail = (row) => {
    setSelectedRow(row.row);
    setOpenTempDetail(true);
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    if (currency === "Rs") {
      currency = "INR";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  useEffect(() => {
    templates_listing();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      setTempDetail([
        {
          name: "Name",
          value: selectedRow ? selectedRow?.name : "-",
        },
        {
          name: "Image",
          value: selectedRow?.image ? (
            <>
              <div style={{ minWidth: 100, cursor: "pointer" }}>
                <img
                  onClick={() => handleClickOpenPreview(selectedRow)}
                  src={
                    selectedRow?.image
                      ? `${sourceS3BaseUrl}${selectedRow?.image}`
                      : "-"
                  }
                  style={{ height: 50, width: 75 }}
                />
              </div>
            </>
          ) : (
            "-"
          ),
        },
        {
          name: "Order",
          value: selectedRow ? selectedRow?.order : "-",
        },
        {
          name: "Mode",
          value: selectedRow ? selectedRow?.mode : "-",
        },
        {
          name: "Published",
          value: IsActiveFunc(selectedRow?.status === true ? "YES" : "NO"),
        },
        {
          name: "Approved",
          value: IsActiveFunc(selectedRow?.is_approved === true ? "YES" : "NO"),
        },
        {
          name: "Usage Count",
          value: IsCountFunc(
            selectedRow ? selectedRow?.use_count : selectedRow?.use_count
          ),
        },
        {
          name: "Status",
          value: IsActiveFunc(
            selectedRow?.status === true ? "Active" : "Inactive"
          ),
        },

        {
          name: "Is Paid",
          value: IsPaidFunc(selectedRow?.is_paid === true ? "YES" : "NO"),
        },
        {
          name: "Currency",
          value: selectedRow ? selectedRow?.currency : "-",
        },
        {
          name: "Amount",
          value:
            selectedRow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedRow?.currency)}${
                  selectedRow?.total_amount
                }`
              : `${getCurrencySymbol("en-US", selectedRow?.currency)}0`,
        },
        {
          name: "Discount",
          value:
            selectedRow?.is_paid === true
              ? `${getCurrencySymbol("en-US", selectedRow?.currency)}${
                  selectedRow?.discount
                }`
              : `${getCurrencySymbol("en-US", selectedRow?.currency)}0`,
        },
        {
          name: "Final Amount",
          value:
            selectedRow?.is_paid === true
              ? `Amount = Actual Amount (${getCurrencySymbol(
                  "en-US",
                  selectedRow?.currency
                )}${selectedRow?.total_amount}) - Discount (${getCurrencySymbol(
                  "en-US",
                  selectedRow?.currency
                )}${selectedRow?.discount}) = ${getCurrencySymbol(
                  "en-US",
                  selectedRow?.currency
                )}${selectedRow?.amount}`
              : `${getCurrencySymbol("en-US", selectedRow?.currency)}0`,
        },
        {
          name: "Ribbon",
          value: selectedRow ? selectedRow?.ribbon : "-",
        },
        {
          name: "Template Id",
          value: selectedRow ? selectedRow?._id : "-",
        },
        {
          name: "Created At",
          value: selectedRow.createdAt ? (
            moment(selectedRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedRow.updatedAt ? (
            moment(selectedRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
    }
  }, [selectedRow]);

  const tabs = [
    {
      label: "active",
      title: "Active",
      colors: "success",
    },
    {
      label: "inactive",
      title: "Inactive",
      colors: "error",
    },
    {
      label: "all",
      title: "All",
      colors: "info",
    },
  ];

  return (
    <>
      <TemplatesPagesDialog
        selectedRow={selectedRow}
        openTempDetailModal={openTempDetailModal}
        handleCloseTemoDetail={handleCloseTemoDetail}
      />
      <DetailModal
        open={openTempDetail}
        setOpen={setOpenTempDetail}
        detail={tempDetail}
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Templates
        </Typography>
        <Card>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Stack
              spacing={2}
              direction={{ xs: "column", sm: "row", lg: "row" }}
              sx={{ padding: "20px" }}
            >
              {" "}
              {tabs.map((tab) => (
                <Button
                  key={tab.label}
                  variant={
                    templateType === tab.label ? "contained" : "outlined"
                  }
                  color="primary"
                  onClick={() => {
                    setTemplateType(tab.label);
                    setLoading(true);
                    if (tab.label === "all") {
                      setSelectedTemplatesData(templates);
                    } else if (tab.label === "active") {
                      setSelectedTemplatesData(activeTemplates);
                    } else if (tab.label === "inactive") {
                      setSelectedTemplatesData(inactiveTemplates);
                    }
                    setTimeout(() => {
                      setLoading(false);
                    }, 500);
                  }}
                >
                  {tab.title}
                </Button>
              ))}
            </Stack>

            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
          </Stack>

          <TableContainer>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={templates.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* {templates && templates.length > 0 ? ( */}
                  {filteredUsers.map((row, index) => {
                    const {
                      _id,
                      name,
                      image,
                      mode,
                      order,
                      status,
                      use_count,
                      updatedAt,
                      createdAt,
                    } = row;
                    {
                      /* const rowNum = page * rowsPerPage + index + 1; */
                    }
                    return (
                      <TableRow
                        key={_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell
                          style={{ whiteSpace: "nowrap" }}
                          align="right"
                        >
                          <CustomPopover
                            data={{ row, index }}
                            menu={menuOptions}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenTempDetail({ row, index })}
                        >
                          <Typography
                            className="hover-effect"
                            variant="subtitle2"
                            noWrap
                          >
                            {" "}
                            {name
                              ? name.charAt(0).toUpperCase() + name?.slice(1)
                              : "-"}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <div style={{ minWidth: 100, cursor: "pointer" }}>
                            <img
                              onClick={() => handleClickOpenPreview(row)}
                              src={image ? `${sourceS3BaseUrl}${image}` : "-"}
                              style={{ height: 50, width: 75 }}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label
                            variant="ghost"
                            color={(status === true && "success") || "error"}
                          >
                            {status === true ? "Active" : "Inactive"}
                          </Label>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label variant="filled" color="warning">
                            {use_count ? use_count : "0"}
                          </Label>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <Label
                            variant="ghost"
                            color={
                              (row.is_paid === true && "success") || "error"
                            }
                          >
                            {row.is_paid ? "Yes" : "No"}
                          </Label>
                        </TableCell>
                        {/* <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.mode
                            ? row.mode.charAt(0).toUpperCase() +
                              row.mode?.slice(1)
                            : "-"}
                        </TableCell> */}
                        {/* <TableCell style={{ whiteSpace: "nowrap" }}>
                          {order ? order : "-"}
                        </TableCell> */}
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.currency}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.is_paid === true
                            ? `${getCurrencySymbol(
                                "en-US",
                                selectedRow?.currency
                              )}${row.total_amount}`
                            : `${getCurrencySymbol("en-US", row.currency)}0`}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {row.is_paid === true
                            ? `${getCurrencySymbol(
                                "en-US",
                                selectedRow?.currency
                              )}${row.discount}`
                            : `${getCurrencySymbol("en-US", row.currency)}0`}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          <span style={{ fontWeight: "500" }}>
                            {row.is_paid === true
                              ? `${getCurrencySymbol(
                                  "en-US",
                                  selectedRow?.currency
                                )}${row.amount}`
                              : `${getCurrencySymbol("en-US", row.currency)}0`}
                          </span>
                        </TableCell>

                        {/* <TableCell
                          style={{ whiteSpace: "nowrap" }}
                          onDoubleClick={() => {
                            navigator.clipboard.writeText(_id);
                            enqueueSnackbar("ID copied to clipboard", {
                              variant: "success",
                            });
                          }}
                        >
                          {_id}
                        </TableCell> */}
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {createdAt ? moment(createdAt).format("LLL") : "-"}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {updatedAt ? moment(updatedAt).format("LLL") : "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {/* ) : (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No Templates Found
                    </TableCell>
                  </TableRow>
                )} */}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>
          {/* <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={templates?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Box>
    </>
  );
}

export default Templates;
