import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Icon } from "@iconify/react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export default function ArticleModal({ open, setOpen, ArticleData }) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head">
            <p>Details</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="model-data">
            <div className="main">
              <>
                <div className="article-name-section d-flex align-items-center mx-2">
                  <p className="headings">Name</p>
                  <p className="article-data">{ArticleData.name}</p>
                </div>
                <div className="description-section">
                  <p className="headings mx-2 mt-3">Description:</p>
                  <div
                    className="article-description"
                    dangerouslySetInnerHTML={{
                      __html: ArticleData.description,
                    }}
                  />
                </div>
              </>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
