import { Close } from "@mui/icons-material";
import { Dialog, Divider, IconButton, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

function CancelReason({
  selectedRow,
  isDialogOpenCancelReason,
  handleCloseCancelReason,
}) {
  const customer_name = selectedRow?.customer_obj
    ? selectedRow?.customer_obj?.first_name.charAt(0).toUpperCase() +
      selectedRow?.customer_obj?.first_name.slice(1) +
      " " +
      selectedRow?.customer_obj?.last_name.charAt(0).toUpperCase() +
      selectedRow?.customer_obj?.last_name.slice(1)
    : "-";

  const getCurrencySymbol = (currency, locale) => {
    try {
      return (0)
        .toLocaleString(locale, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
    } catch (error) {
      console.error("Error getting currency symbol:", error);
      return "";
    }
  };

  return (
    <>
      {" "}
      <Dialog
        open={isDialogOpenCancelReason}
        onClose={handleCloseCancelReason}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        size="large"
      >
        <div
          style={{
            padding: "2% 4% 2% 4%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Cancel Reasoon</Typography>
          <IconButton
            onClick={handleCloseCancelReason}
            style={{ color: "grey" }}
          >
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div style={{ padding: "2% 4% 2% 4%" }}>
          <Stack spacing={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                Customer Name
              </Typography>
              <p style={{ fontSize: "0.875rem", padding: 0, margin: 0 }}>
                {customer_name}
              </p>
            </div>
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                Plan Name
              </Typography>
              <p style={{ fontSize: "0.875rem", padding: 0, margin: 0 }}>
                {selectedRow?.plan_obj
                  ? selectedRow?.plan_obj?.product_name
                  : "-"}
              </p>
            </div>
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                Amount
              </Typography>
              <p style={{ fontSize: "0.875rem", padding: 0, margin: 0 }}>
                {selectedRow?.plan_obj
                  ? getCurrencySymbol(selectedRow?.currency, "en-US")
                  : "$"}
                {selectedRow?.amount}
              </p>
            </div>
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                Cancel Date
              </Typography>
              <p style={{ fontSize: "0.875rem", padding: 0, margin: 0 }}>
                {selectedRow?.subscription_cancel_date
                  ? moment(selectedRow?.subscription_cancel_date).format("LL")
                  : "-"}
              </p>
            </div>
            <Divider />
          </Stack>
          <div style={{ marginTop: "15px" }}>
            <Typography variant="h6">Reason:</Typography>
            <p>{selectedRow?.message}</p>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default CancelReason;
