/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Label from "src/components/Label";
import { templates_pages } from "src/DAL/Templates/templates";
import { useSnackbar } from "notistack";
import { s3baseUrl, sourceS3BaseUrl } from "src/config/config";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  //   borderRadius: 1,
  overflow: "auto",
  maxHeight: "70vh",
};

function TemplatesPagesDialog({
  openTempDetailModal,
  handleCloseTemoDetail,
  selectedRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [templatePages, setTemplatePages] = useState([]);
  const [loading, setLoading] = useState(false);
  // console.log(s3baseUrl, "s3baseUrl");
  // console.log(sourceS3BaseUrl, "s3baseUrl");
  // const basePicUrl = "https://builder-templates-app-bucket.s3.amazonaws.com/";
  const temName = selectedRow?.name;

  const currentuserTimezone = moment.tz.guess();

  const handleDoubleClick = (id = templatePages?._id) => {
    navigator.clipboard.writeText(id);
    enqueueSnackbar("ID copied to clipboard", { variant: "success" });
  };

  const templates_pages_listing = async () => {
    const tempId = selectedRow?._id;
    console.log(tempId, "templateeIddd");
    setLoading(true);
    const response = await templates_pages(tempId);
    console.log(response, "templatessPagesLIstinggggggggggggggggg");
    if (response.code === 200) {
      setLoading(false);
      setTemplatePages(response.data);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (selectedRow._id) {
      console.log(selectedRow._id, "sellectedRowIdd");
      templates_pages_listing();
    }
  }, [selectedRow._id]);

  return (
    <>
      <Dialog
        open={openTempDetailModal}
        onClose={handleCloseTemoDetail}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        size="large"
      >
        {/* <Box sx={style} className="modal-theme"> */}
        <div
          style={{
            padding: "2% 3% 0px 3%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{temName}</Typography>

          <IconButton onClick={handleCloseTemoDetail} style={{ color: "grey" }}>
            <Close />
          </IconButton>
        </div>
        {/* <div style={{ padding: "2% 4% 2% 4%" }}> */}
        <Card sx={{ margin: "0.5% 2% 3% 2%" }}>
          <TableContainer style={{ padding: "0px 2% 0px 2%" }}>
            <Table>
              <TableRow>
                <TableCell>
                  <Typography className="text-capitalize" variant="subtitle2">
                    #
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Page Name
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Project Name
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Page Image
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Page Id
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Project Id
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Choosed Date/Time
                  </Typography>
                </TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Customer Name
                  </Typography>
                </TableCell>

                <TableCell style={{ whiteSpace: "nowrap" }}>
                  <Typography className="text-capitalize" variant="subtitle2">
                    Customer Type
                  </Typography>
                </TableCell>
              </TableRow>
              {loading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {templatePages?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        <Typography variant="subtitle2">
                          No Templates Pages & Projects List Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    templatePages?.map((row, i) => {
                      const {
                        _id,
                        user,
                        page_name,
                        image,
                        project,
                        page_slug,
                        template_choose_datetime,
                      } = row;
                      console.log(row, "projectslisting");
                      return (
                        <TableRow key={_id} tabIndex={-1}>
                          <TableCell> {i + 1}</TableCell>

                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                            className="pl-0"
                            align="left"
                          >
                            {page_name
                              ? page_name.charAt(0).toUpperCase() +
                                page_name?.slice(1)
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                            className="pl-0"
                            align="left"
                          >
                            {project
                              ? project?.project_name.charAt(0).toUpperCase() +
                                project?.project_name.slice(1)
                              : "-"}
                          </TableCell>
                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                            className="pl-0"
                            align="left"
                          >
                            <div style={{ minWidth: 100, cursor: "pointer" }}>
                              <img
                                onError={(e) => {
                                  if (
                                    e.target.src ===
                                    sourceS3BaseUrl + image
                                  ) {
                                    e.target.src = s3baseUrl + "/" + image;
                                  }
                                }}
                                onClick={() => {
                                  window.open(
                                    `${s3baseUrl}/${project?._id}/${page_slug}.html`
                                  );
                                }}
                                src={image ? `${sourceS3BaseUrl}${image}` : "-"}
                                style={{ height: 50, width: 75 }}
                              />
                            </div>
                          </TableCell>

                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                            onDoubleClick={() => handleDoubleClick(_id)}
                          >
                            {_id}
                          </TableCell>
                          <TableCell
                            style={{ whiteSpace: "nowrap" }}
                            onDoubleClick={() => handleDoubleClick(_id)}
                          >
                            {project ? project._id : "-"}
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {template_choose_datetime
                              ? moment
                                  .tz(template_choose_datetime, "UTC")
                                  .tz(currentuserTimezone)
                                  .format("LLL")
                              : "-"}
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {user
                              ? user?.first_name.charAt(0).toUpperCase() +
                                user?.first_name.slice(1) +
                                " " +
                                user?.last_name.charAt(0).toUpperCase() +
                                user?.last_name.slice(1)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Label
                              variant="ghost"
                              color={
                                (user?.user_type === 1 && "success") ||
                                (user?.user_type === 3 && "primary") ||
                                "default"
                              }
                            >
                              {user?.user_type === 1
                                ? "Inovate"
                                : user?.user_type === 3
                                ? "Vission"
                                : "-"}
                            </Label>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        {/* </div> */}
        {/* </Box> */}
      </Dialog>
    </>
  );
}

export default TemplatesPagesDialog;
