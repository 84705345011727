/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";

import { Close } from "@mui/icons-material";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  Button,
  Avatar,
  Box,
  Input,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { _update_customer_profile } from "src/DAL/customer/customer";
import { get_local_preview, showEditedDataWithoutApi } from "src/utils/utils";
import { s3baseUrl } from "src/config/config";
import debounce from "../../../utils/debounce";

export default function UpdateCustomerProfileName({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  selectedRow,
  setSelectedRow,
  get_customer_list,
  selectedIndex,
  setData,
}) {
  console.log(selectedRow, "selectedRowselectedRow");
  const customer_name = selectedRow?.first_name + " " + selectedRow?.last_name;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [businessType, setBusinessType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    business_name: "",
    business_type: "",
    contact_number: "",
    profile_image: "",
    image_status: false,
    // file:"",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  console.log(isOpenDrawer, "isOpenDrawerisOpenDrawer");
  const handleChange = (e) => {
    if (e.target.name === "file") {
      setInputs({
        ...inputs,
        file: e.target.files[0],
        profile_image: "",
      });
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    setBusinessType(
      JSON.stringify(selectedOptions.map((option) => option.value))
    );
  };

  const handleImageChange = (e) => {
    if (e.target.name === "file") {
      setInputs({
        ...inputs,
        image_status: false,
        profile_image: "",
      });
      setSelectedImage(e.target.files[0]);
    } else {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangePhoneNumber = (phone) => {
    setInputs({
      ...inputs,
      contact_number: phone,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();

    formData.append("business_type", businessType);

    // console.log(typeof businessType, "type");

    if (selectedImage) {
      formData.append("profile_image", selectedImage);
    }

    if (inputs.image_status) {
      formData.append("image_status", inputs.image_status);
    } else {
      formData.append("image_status", false);
    }

    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("business_name", inputs.business_name);
    if (inputs.contact_number.length > 3) {
      formData.append("contact_number", inputs.contact_number);
    }
    handleUpdateProfile(formData);

    // prevent from uploading double images after edit and only show the image response from the api
  };

  const handleUpdateProfile = async (formData) => {
    const update_profile_resp = await _update_customer_profile(
      selectedRow._id,
      formData
    );
    if (update_profile_resp.code == 200) {
      const updatedCustomer = {
        ...selectedRow,
        first_name: inputs.first_name,
        last_name: inputs.last_name,
        business_name: inputs.business_name,
        business_type: inputs.business_type,
        contact_number: inputs.contact_number,
        profile_image: inputs.profile_image,
      };

      setData((prev) => {
        prev[selectedIndex] = updatedCustomer;
        return [...prev];
      });

      get_customer_list("", [1, 2]);

      setSelectedRow(updatedCustomer);

      onCloseDrawer();
      enqueueSnackbar("Profile updated successfully", {
        variant: "success",
      });
      setSelectedImage(null);
    } else {
      enqueueSnackbar(update_profile_resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpenDrawer) {
      setInputs({
        ...selectedRow,
        first_name: selectedRow?.first_name,
        last_name: selectedRow?.last_name,
        business_name: selectedRow?.business_name,
        business_type: selectedRow?.business_type,
        contact_number: selectedRow?.contact_number,
        profile_image: selectedRow?.profile_image,
      });
      if (selectedRow?.business_type) {
        let business_type = [];
        selectedRow?.business_type.map((type) => {
          business_type.push({ label: type, value: type });
        });

        setBusinessType(JSON.stringify(selectedRow?.business_type));
        setSelectedOptions([...business_type]);
      }
      setIsImageUploaded(false);
      setIsLoading(false);
    }
  }, [isOpenDrawer]);

  const options = [
    { value: "Fitness", label: "Fitness" },
    { value: "Motivational", label: "Motivational" },
    { value: "Travelling", label: "Travelling" },
    { value: "Education", label: "Education" },
    { value: "Business", label: "Business" },
  ];

  const handleClickRemove = () => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      image_status: true,
      profile_image: "",
    }));
    setSelectedImage(null);
    setIsImageUploaded(false);
  };

  const handleDrawerClose = () => {
    onCloseDrawer();
    if (isImageUploaded) {
      setInputs((prevInputs) => ({
        ...prevInputs,
        image_status: true,
        profile_image: "",
      }));
      setSelectedImage(null);
      setIsImageUploaded(false);
    }
    setSelectedImage(null);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpenDrawer}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Edit Customer
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              <TextField
                fullWidth
                label="First Name"
                required
                name="first_name"
                value={inputs.first_name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Last Name"
                required
                name="last_name"
                value={inputs.last_name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Business Name"
                required
                name="business_name"
                value={inputs.business_name}
                onChange={handleChange}
              />

              <Typography variant="subtitle2" color="textSecondary">
                Business Type *
                <Select
                  options={options}
                  value={selectedOptions}
                  isMulti
                  required
                  name="business_type"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleSelectChange}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      minHeight: 50,
                    }),
                  }}
                />
              </Typography>

              <Typography variant="subtitle2" color="textSecondary">
                Phone Number
                <PhoneInput
                  country={"us"}
                  value={inputs.contact_number}
                  onChange={(value) => {
                    handleChangePhoneNumber(value);
                  }}
                  className="mt-0"
                  inputStyle={{
                    height: 50,
                    width: "100%",
                  }}
                />
              </Typography>

              {inputs.file || inputs.profile_image || selectedImage ? (
                <>
                  {inputs.file && (
                    <img src={get_local_preview(inputs.file)} alt="" />
                  )}
                  {inputs.profile_image && (
                    <img src={`${s3baseUrl}/${inputs.profile_image}`} alt="" />
                  )}

                  {selectedImage && (
                    <img src={URL.createObjectURL(selectedImage)} alt="" />
                  )}
                </>
              ) : (
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    value=""
                    name="file"
                    onChange={handleImageChange}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              )}

              {(inputs.file || inputs.profile_image || selectedImage) && (
                <Button
                  onClick={handleClickRemove}
                  className="mt-1"
                  style={{ color: "red" }}
                  fullWidth
                >
                  Remove
                </Button>
              )}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update Profile
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
