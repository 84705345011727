import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Label from "../../../components/Label";
import moment from "moment";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { useNavigate } from "react-router-dom";
import { _details_against_userID } from "src/DAL/customer/customer";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
  maxHeight: "70vh",
};

export default function ProjectList({ open, setOpen, data, project_list_resp }) {
  const navigate = useNavigate();
  // console.log(data, "Ajhajschasjkca");
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => setOpen(false);
  const preview_link = (project) => {
    return s3baseUrl + "/" + project._id + "/index.html";
  };
  const [project_list, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProjectList = async () => {
    setIsLoading(true);
    const customer_list_resp = await _details_against_userID(data.user_id._id);
    // console.log(customer_list_resp, "projectnewlist");
    setProjectList(customer_list_resp.project);
    setIsLoading(false);
  };

  useEffect(() => {
    if (data?.user_id?._id) {
      getProjectList();
    }
  }, [open]);

  return (
    <div>
      <Modal
        disableTypography="true"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-theme">
          <div clo-12 className="text-end modalIcon">
            <CloseIcon onClick={handleClose} className="pointer" />
          </div>
          <Typography
            className=" "
            id="spring-modal-title"
            variant="h6"
            component="h6"
          >
            Project List
          </Typography>
          <TableContainer>
            <Table>
              <TableRow>
                <TableCell>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    #
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    Project Name
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    Published
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    SSL
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    Status
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    Preview
                  </Typography>
                </TableCell>

                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  <Typography
                    className="text-capitalize"
                    variant="subtitle2"
                  >
                    Project Detail
                  </Typography>
                </TableCell>
              </TableRow>
              {isLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {project_list.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <Typography variant="subtitle2">
                          No Project List Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    project_list.map((row, i) => {
                      const {
                        _id,
                        project_name,
                        user_name,
                        status,
                        is_published,
                        ssl_status,
                        updatedAt,
                        createdAt,
                        ssl_configured,
                      } = row;

                      // console.log(row, "projectslisting");

                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>{i + 1}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap' }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography
                                className="text-capitalize"
                                variant="subtitle2"
                                onClick={() => {
                                  navigate(`/projects/${_id}`);
                                }}
                                style={{cursor:"pointer"}}
                              >
                                {project_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={(!is_published && "error") || "success"}
                            >
                              {is_published ? "Yes" : "No"}
                            </Label>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={
                                is_published && ssl_status
                                  ? ssl_configured
                                    ? "success"
                                    : "info"
                                  : "error"
                              }
                            >
                              {is_published && ssl_status
                                ? ssl_configured
                                  ? "Yes"
                                  : "Processing"
                                : "No"}
                            </Label>
                          </TableCell>
                          <TableCell className="pl-0" align="left">
                            <Label
                              variant="ghost"
                              color={
                                (status === "banned" && "error") || "success"
                              }
                            >
                              {status ? "Active" : "Inactive"}
                            </Label>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <a href={preview_link(row)} target="_blank">
                              <Button>Preview</Button>
                            </a>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography
                              onClick={() => {
                                navigate(`/projects/${_id}`);
                              }}
                            >
                              <Button>Detail</Button>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}
