/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  _customer_listing,
  _customer_listing_for_search,
  _subscription_listing,
} from "src/DAL/Subscription/Subscription";
import CustomConfirmation from "src/components/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import Label from "src/components/Label";
import EditSubscriptions from "./Three-dots/EditSubscriptions";
import RenewSubscription from "./Three-dots/RenewSubscription";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CancelSubscriptions from "./Three-dots/CancelSubscriptions";
import { UserListToolbar } from "src/components/_dashboard/user";
import { Typeahead } from "react-bootstrap-typeahead";
import CancelReason from "./Three-dots/CancelReason";
import { Icon } from "@iconify/react";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import DetailModal from "../Detail-Section/Detail-Section";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.gray,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function LocalSubscription() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [localSubscriptions, setLocalSubscriptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [isDrawerOpenEdit, setIsDrawerOpenEdit] = useState(false);
  const [isDrawerOpenRenew, setIsDrawerOpenRenew] = useState(false);
  const [isDialogOpenCancel, setIsDialogOpenCancel] = useState(false);
  const [isDialogOpenCancelReason, setIsDialogOpenCancelReason] =
    useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [localSubscriptionsDetailDialog, setLocalSubscriptionsDetailDialog] =
    useState(false);
  const [localSubscriptionsDetail, setLocalSubscriptionsDetail] = useState();
  const [customerNames, setCustomerNames] = useState([]);
  console.log(customerNames, "listingggg");
  const [isFilter, setIsFilter] = useState(false);
  const [filterSubscription, setFilterSubscription] = useState({
    subscription_type: 1,
    grace_period_status: 0,
    upcoming_expired: 0,
    subscription_status: 0,
    canceled_subscriptions: 0,
    active_subscriptions: 0,
    user_id: "",
  });
  // console.log(filterSubscription, "filtersssssss");

  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  // const [isFliterClicked, setisFliterClicked] = useState(false);

  const handleOpenEditDrawer = () => {
    setIsDrawerOpenEdit(true);
  };

  const handleEditClick = (row) => {
    handleOpenEditDrawer();
    setSelectedRow(row.row);
    setSelectedIndex(row.index);
    // console.log(row, "rowww");
  };

  const handleCloseEdit = () => {
    setIsDrawerOpenEdit(false);
  };

  const handleOpenRenewDrawer = () => {
    setIsDrawerOpenRenew(true);
  };

  const handleRenewClick = (row) => {
    handleOpenRenewDrawer();
    setSelectedRow(row.row);
    setSelectedIndex(row.index);
    console.log(row, "rowww");
  };

  const handleCloseRenew = () => {
    setIsDrawerOpenRenew(false);
  };

  const handleOpenCancelDialog = () => {
    setIsDialogOpenCancel(true);
  };

  const handleOpenCancelReasonDialog = () => {
    setIsDialogOpenCancelReason(true);
  };

  const handleCancelClick = (row) => {
    handleOpenCancelDialog();
    setSelectedRow(row.row);
    setSelectedIndex(row.index);
    // console.log(row, "rowww");
  };

  const handleCancelReasonClick = (row) => {
    handleOpenCancelReasonDialog();
    setSelectedRow(row.row);
    setSelectedIndex(row.index);
    // console.log(row, "rowww");
  };

  const handleCloseCancel = () => {
    setIsDialogOpenCancel(false);
  };

  const handleCloseCancelReason = () => {
    setIsDialogOpenCancelReason(false);
  };

  const handleLocalSunbscriptionDetail = (row) => {
    setSelectedRow(row.row);
    setLocalSubscriptionsDetailDialog(true);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEditClick,
    },
    {
      label: "Renew",
      icon: "mdi:autorenew",
      handleClick: handleRenewClick,
    },
    {
      label: "Cancel",
      icon: "mdi:cancel",
      handleClick: handleCancelClick,
    },
    {
      label: "Cancel Reason",
      icon: "tabler:pencil-cancel",
      handleClick: handleCancelReasonClick,
    },
  ]);

  const tabs = [
    {
      label: "All",
      title: "Here you can see all subscriptions",
      colors: "info",
      postData: {
        subscription_type: 1,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
        user_id: filterSubscription.user_id,
      },
    },
    {
      label: "Active",
      title: "Here you can see active subscriptions",
      colors: "info",
      postData: {
        subscription_type: 1,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 1,
        user_id: filterSubscription.user_id,
      },
    },
    {
      label: "Grace Period",
      title:
        "Here you can see those subscriptions, that are start after the end date.During this grace period, the account functionality remains the same. At that time user can pay again without losing any data or access",
      colors: "warning",
      postData: {
        subscription_type: 1,
        grace_period_status: 1,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
        user_id: filterSubscription.user_id,
      },
    },
    {
      label: "Soon to expire",
      title:
        "Here you can see those subscriptions, that are expired within 7 days",
      colors: "primary",
      postData: {
        subscription_type: 1,
        grace_period_status: 0,
        upcoming_expired: 1,
        subscription_status: 0,
        active_subscriptions: 0,
        user_id: filterSubscription.user_id,
      },
    },
    {
      label: "Expired",
      title: "Here you can see expired subscriptions",
      colors: "error_dar",
      postData: {
        subscription_type: 1,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 1,
        canceled_subscriptions: 0,
        active_subscriptions: 0,
        user_id: filterSubscription.user_id,
      },
    },
    {
      label: "Cancelled",
      title: "Here you can see cancelled subscriptions",
      colors: "error",
      postData: {
        subscription_type: 1,
        grace_period_status: 0,
        upcoming_expired: 0,
        subscription_status: 0,
        canceled_subscriptions: 1,
        active_subscriptions: 0,
        user_id: filterSubscription.user_id,
      },
    },
  ];
  const [selectedButton, setSelectedButton] = useState(tabs[0].label);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("handleChangeRowsPerPage", event.target.value);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          variant="ghost"
          color={selectedRow?.user_id?.type === 1 ? "success" : "error"}
        >
          {Active}
        </Label>
      </>
    );
  };

  const _customerListing = async () => {
    const response = await _customer_listing();
    console.log(response, "responseresponseresponse");
    if (response.code === 200) {
      setCustomerNames(
        response.data.map((user) => {
          return {
            name:
              user.first_name.charAt(0).toUpperCase() +
              user.first_name.slice(1) +
              " " +
              user.last_name.charAt(0).toUpperCase() +
              user.last_name.slice(1),
            _id: user.user_id,
          };
        })
      );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    // console.log(response.data, "customerListinggg");
  };

  useEffect(() => {
    _customerListing();
  }, []);

  const subscriptionListing = async () => {
    setLoading(true);
    const postData = {
      ...filterSubscription,
      user_id: filterSubscription?.user_id ? filterSubscription?.user_id : "",
    };
    try {
      const response = await _subscription_listing(postData, page, rowsPerPage);
      // console.log(response, "listTransactions");
      if (response.code === 200) {
        setLoading(false);
        setLocalSubscriptions(response.data.subscriptions);
        setTotalCount(response.data.total_count);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching subscription listings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterClick = () => {
    if (!filterSubscription.user_id) {
      enqueueSnackbar("Select Customer Name to filter", { variant: "error" });
      return;
    }
    setPage(0);
    subscriptionListing();
  };

  // console.log(selectedCustomerName, "filterSubscriptionfilterSubscription");

  const handleClearFilterClick = () => {
    setLoading(true);
    setIsFilter(false);
    // setisFliterClicked(false);
    setSelectedCustomerName("");
    setPage(0);
    delete filterSubscription.user_id;
    setFilterSubscription({
      ...filterSubscription,
    });
    subscriptionListing();
  };

  // const handleTabClick = debounce((tab) => {
  //   setLoading(true);
  //   setPage(0);
  //   setFilterSubscription((prevFilterSubscription) => ({
  //     ...prevFilterSubscription,
  //     ...tab.postData,
  //     user_id: filterSubscription.user_id,
  //   }));
  //   setSelectedButton(tab.label);
  // }, 500);

  const handleClicked = (tab) => {
    setLoading(true);
    setPage(0);
    setFilterSubscription((prevFilterSubscription) => ({
      ...prevFilterSubscription,
      ...tab.postData,
      user_id: filterSubscription.user_id,
    }));
    setSelectedButton(tab.label);
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  useEffect(() => {
    if (selectedRow) {
      setLocalSubscriptionsDetail([
        {
          name: "Customer Name",
          value: selectedRow?.customer_obj
            ? selectedRow?.customer_obj?.first_name.charAt(0).toUpperCase() +
              selectedRow?.customer_obj?.first_name.slice(1) +
              " " +
              selectedRow?.customer_obj?.last_name.charAt(0).toUpperCase() +
              selectedRow?.customer_obj?.last_name.slice(1)
            : "-",
        },
        {
          name: "Customer Type",
          value: IsActiveFunc(
            selectedRow?.user_id?.type === 1 ? "Inovate" : "-"
          ),
        },
        {
          name: "Customer Email",
          value: selectedRow.user_id ? (
            selectedRow.user_id?.email
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Action By",
          value: selectedRow?.added_by_obj
            ? selectedRow.added_by_obj?.first_name.charAt(0).toUpperCase() +
              selectedRow?.added_by_obj?.first_name.slice(1) +
              " " +
              selectedRow.added_by_obj?.last_name.charAt(0).toUpperCase() +
              selectedRow?.added_by_obj?.last_name.slice(1)
            : "-",
        },
        {
          name: "Admin Email",
          value: selectedRow?.added_by_obj
            ? selectedRow.added_by_obj?.email
            : "-",
        },
        {
          name: "Currency",
          value: selectedRow.currency ? (
            selectedRow.currency.toUpperCase()
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Amount",
          value: selectedRow
            ? `${getCurrencySymbol("en-US", selectedRow?.currency)}${
                selectedRow?.amount
              }`
            : "-",
        },
        {
          name: "Product Name",
          value: selectedRow?.plan_obj?.product_name ? (
            selectedRow?.plan_obj?.product_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Product Description",
          value: selectedRow?.plan_obj?.product_description ? (
            selectedRow?.plan_obj?.product_description
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Grace Period Days",
          value: selectedRow?.grace_period_days
            ? selectedRow?.grace_period_days
            : "-",
        },
        {
          name: "Subscription Start Date",
          value: selectedRow?.subscription_start_date
            ? `${moment(selectedRow?.subscription_start_date).format("LL")}`
            : "-",
        },
        {
          name: "Expiry Date",
          value: selectedRow?.subscription_end_date
            ? `${moment(selectedRow?.subscription_end_date).format("LL")}`
            : "-",
        },
        {
          name: "Subscription Cancel Date",
          value: selectedRow?.subscription_cancel_date
            ? `${moment(selectedRow?.subscription_cancel_date).format("LL")}`
            : "-",
        },
        {
          name: "Subscription Renewal Date",
          value: selectedRow?.subscription_renewal_date
            ? `${moment(selectedRow?.subscription_renewal_date).format("LL")}`
            : "-",
        },
        {
          name: "Created At",
          value: selectedRow.createdAt ? (
            moment(selectedRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectedRow.updatedAt ? (
            moment(selectedRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        selectedRow?.subscription_status === 4
          ? {
              name: "Cancel Reason",
              value: selectedRow?.message || <span>-</span>,
            }
          : "N/A",
      ]);
      console.log(selectedRow, "rowdataa");
    }
  }, [selectedRow]);

  useEffect(() => {
    // setLoading(false);
  }, [filterSubscription]);

  useEffect(() => {
    subscriptionListing().catch((error) =>
      console.error("Error fetching subscription listings:", error)
    );
  }, [page, rowsPerPage, selectedButton]);

  return (
    <>
      <DetailModal
        open={localSubscriptionsDetailDialog}
        setOpen={setLocalSubscriptionsDetailDialog}
        detail={localSubscriptionsDetail}
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Custom Subscriptions
        </Typography>
        <Card sx={{ p: 1 }}>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            margin={2}
          >
            <Stack
              spacing={2}
              direction={{ xs: "column", sm: "row", lg: "row" }}
            >
              {tabs.map((tab) => (
                <LightTooltip title={tab.title} placement="top-start">
                  <Button
                    style={{ whiteSpace: "nowrap" }}
                    variant={
                      selectedButton === tab.label ? "contained" : "outlined"
                    }
                    className="filter"
                    onClick={() =>
                      tab.label !== selectedButton &&
                      loading === false &&
                      handleClicked(tab)
                    }
                  >
                    {tab.label}
                  </Button>
                </LightTooltip>
              ))}
            </Stack>

            <FormControl sx={{ mb: 0, mt: 0 }}>
              <Typeahead
                id="basic-example"
                className="react-typeAhead"
                placeholder="Select Customer"
                options={customerNames.map((customer) => customer.name)}
                value={selectedCustomerName}
                onChange={(selected) => {
                  setIsFilter(true);
                  const customer = customerNames.find(
                    (customer) => customer.name === selected[0]
                  );
                  setSelectedCustomerName(selected[0] || "");
                  setFilterSubscription({
                    ...filterSubscription,
                    user_id: customer ? customer._id : "",
                  });
                }}
                selected={
                  isFilter && selectedCustomerName ? [selectedCustomerName] : []
                }
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ xs: "row", sm: "row", md: "row" }}
            alignItems="center"
            justifyContent={{ xs: "center", sm: "center", md: "flex-end" }}
            spacing={3}
            margin={2}
          >
            <Button
              variant="contained"
              className="filter"
              onClick={handleFilterClick}
              endIcon={<Icon icon="tdesign:filter" />}
            >
              Filter
            </Button>
            <p
              onClick={handleClearFilterClick}
              className={
                isFilter === true
                  ? "showClearFilter"
                  : isFilter === false
                  ? "clearFilter"
                  : ""
              }
            >
              Clear Filter
            </p>
          </Stack>
          <TableContainer>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      #
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      // align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Plan Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Action By
                    </TableCell>

                    <TableCell
                      // align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Start Date
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Expiry Date
                    </TableCell>
                    {["All", "Cancelled", "Grace Period"].includes(
                      selectedButton
                    ) && (
                      <TableCell
                        align="left"
                        style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                      >
                        Cancel Date
                      </TableCell>
                    )}
                    {/* <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Cancel Date
                    </TableCell> */}
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Renewal Date
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Updated At
                    </TableCell>
                  </TableRow>
                </TableHead>

                {localSubscriptions && localSubscriptions.length > 0 ? (
                  localSubscriptions.map((row, index) => {
                    const {
                      _id,
                      amount,
                      currency,
                      subscription_start_date,
                      subscription_end_date,
                      subscription_cancel_date,
                      subscription_renewal_date,
                      added_by_obj,
                      customer_obj,
                      plan_obj,
                      updatedAt,
                      createdAt,
                    } = row;
                    return (
                      <TableBody>
                        <TableRow
                          key={_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={
                                row.subscription_status === 4
                                  ? menuOptions.filter(
                                      (item) =>
                                        item.label === "Renew" ||
                                        item.label === "Cancel Reason"
                                    )
                                  : row.subscription_status === 3
                                  ? menuOptions.filter(
                                      (item) => item.label === "Renew"
                                    )
                                  : menuOptions.filter(
                                      (item) =>
                                        item.label === "Edit" ||
                                        item.label === "Renew" ||
                                        item.label === "Cancel"
                                    )
                              }
                            />
                          </TableCell>
                          <TableCell
                            className="hover-effect1"
                            // align="center"
                            style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={() =>
                              handleLocalSunbscriptionDetail({ row, index })
                            }
                          >
                            <Typography variant="subtitle2" noWrap>
                              {customer_obj
                                ? customer_obj?.first_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer_obj?.first_name.slice(1) +
                                  " " +
                                  customer_obj?.last_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer_obj?.last_name.slice(1)
                                : "-"}
                            </Typography>{" "}
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {plan_obj
                              ? plan_obj?.product_name.charAt(0).toUpperCase() +
                                plan_obj?.product_name.slice(1)
                              : "-"}
                          </TableCell>
                          <TableCell
                            // align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {added_by_obj
                              ? added_by_obj?.first_name
                                  .charAt(0)
                                  .toUpperCase() +
                                added_by_obj?.first_name.slice(1) +
                                " " +
                                added_by_obj?.last_name
                                  .charAt(0)
                                  .toUpperCase() +
                                added_by_obj?.last_name.slice(1)
                              : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {currency ? currency.toUpperCase() : "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {amount
                              ? `${getCurrencySymbol(
                                  "en-US",
                                  currency
                                )}${amount}`
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            <Label
                              variant="ghost"
                              style={{ width: "100px" }}
                              color={
                                (row.subscription_status === 0 && "success") ||
                                (row.subscription_status === 1 && "primary") ||
                                (row.subscription_status === 2 && "warning") ||
                                (row.subscription_status === 3 &&
                                  "error_dar") ||
                                (row.subscription_status === 4
                                  ? "error"
                                  : "default")
                              }
                            >
                              {row.subscription_status === 0
                                ? "Active"
                                : row.subscription_status === 1
                                ? "Soon To Expire"
                                : row.subscription_status === 2
                                ? "Grace Period"
                                : row.subscription_status === 3
                                ? "Expired"
                                : row.subscription_status === 4
                                ? "Cancelled"
                                : row.subscription_status}
                            </Label>
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(subscription_start_date).format("LL")}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(subscription_end_date).format("LL")}
                          </TableCell>
                          {["All", "Cancelled", "Grace Period"].includes(
                            selectedButton
                          ) && (
                            <TableCell
                              align="center"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {subscription_cancel_date
                                ? moment(subscription_cancel_date).format("LL")
                                : "-"}
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {subscription_renewal_date
                              ? moment(subscription_renewal_date).format("LL")
                              : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {createdAt ? moment(createdAt).format("LLL") : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {updatedAt ? moment(updatedAt).format("LLL") : "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={15} align="center">
                        No Subscription Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this transaction?"}
            // handleAgree={handleDelete}
          />

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
      <EditSubscriptions
        isDrawerOpenEdit={isDrawerOpenEdit}
        handleCloseEdit={handleCloseEdit}
        selectedRow={selectedRow}
        selectedIndex={selectedIndex}
        subscriptionListing={subscriptionListing}
      />
      <RenewSubscription
        isDrawerOpenRenew={isDrawerOpenRenew}
        handleCloseRenew={handleCloseRenew}
        selectedRow={selectedRow}
        selectedIndex={selectedIndex}
        subscriptionListing={subscriptionListing}
      />
      <CancelSubscriptions
        isDialogOpenCancel={isDialogOpenCancel}
        handleCloseCancel={handleCloseCancel}
        selectedRow={selectedRow}
        subscriptionListing={subscriptionListing}
      />
      <CancelReason
        selectedRow={selectedRow}
        isDialogOpenCancelReason={isDialogOpenCancelReason}
        handleCloseCancelReason={handleCloseCancelReason}
      />
    </>
  );
}

export default LocalSubscription;
