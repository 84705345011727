// // import React, { useEffect, useState } from "react";
// // import { useParams } from "react-router-dom";
// // import { _get_project_file_on_s3 } from "src/DAL/ProjectDetails/projectdetails";
// // import moment from "moment";
// // import FolderIcon from "@mui/icons-material/Folder";
// // import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// // import { Icon } from "@iconify/react";
// // import {
// //   Accordion,
// //   AccordionSummary,
// //   AccordionDetails,
// //   Breadcrumbs,
// //   Link,
// //   Typography,
// //   Card,
// // } from "@mui/material";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// // // import { useNavigate } from 'react-router-dom';
// // const ViewS3files = () => {
// //   const { id } = useParams();
// //   // const navigate = useNavigate();

// //   const [loading, setLoading] = useState(true); // Loading state
// //   const [data, setData] = useState([]);
// //   const [folderStructure, setFolderStructure] = useState({});
// //   const [currentPath, setCurrentPath] = useState(""); // Current folder path for breadcrumbs

// //   const ViewS3Files = async () => {
// //     const formData = new FormData();
// //     formData.append("project", id);

// //     const view_s3_response = await _get_project_file_on_s3(formData);

// //     setLoading(false);
// //     setData(view_s3_response);
// //     setFolderStructure(buildFolderStructure(view_s3_response.project_files));
// //   };

// //   const buildFolderStructure = (files) => {
// //     const folderStructure = {};
// //     console.log(folderStructure,"folderStructure")
// //     files.forEach((file) => {
// //       file.Key.split("/").reduce(function (r, e) {
// //         return (r[e] = r[e] || {});
// //       }, folderStructure);
// //     });
// //     return folderStructure;
// //   };

// //   const handleItemClick = (key) => {
// //     const newFolderStructure = { ...folderStructure };
// //     const keys = key.split("/");
// //     let currFolder = newFolderStructure;
// //     let path = "";
// //     for (let i = 0; i < keys.length; i++) {
// //       currFolder = currFolder[keys[i]];
// //       path += `/${keys[i]}`;
// //     }
// //     setCurrentPath(path);
// //     setFolderStructure(currFolder);
// //   };

// //   useEffect(() => {
// //     ViewS3Files();
// //   }, [id]);

// //   return (
// //     <>
// //       {/* Render the breadcrumbs */}
// //       {currentPath && (
// //         <Breadcrumbs aria-label="breadcrumb">
// //           {currentPath.split("/").map((folder, index) => {
// //             console.log(currentPath, "currentPath");

// //             const folderPath = currentPath
// //               .split("/")
// //               .slice(0, index + 1)
// //               .join("/");
// //             console.log(folderPath, "folderPath");

// //             return (
// //               <Link
// //                 key={folderPath}
// //                 color="inherit"
// //                 onClick={() => handleItemClick(folderPath)}
// //               >
// //                 {folder}
// //               </Link>
// //             );
// //           })}
// //         </Breadcrumbs>
// //       )}

// //       {/* Render the folder hierarchy */}
// //       {Object.keys(folderStructure).length > 0 ? (
// //         <FolderHierarchy
// //           folderStructure={folderStructure}
// //           handleItemClick={handleItemClick}
// //           getIcon={getIcon}
// //           getType={getType}
// //           getKeyName={getKeyName}
// //         />
// //       ) : (
// //         <div>No S3 data Found</div>
// //       )}
// //     </>
// //   );
// // };

// // const getType = (path) => {
// //   const fileName = path.split("/").pop();
// //   return fileName.includes(".")
// //     ? fileName.split(".").pop().toLowerCase()
// //     : "folder";
// // };

// // const getIcon = (type) => {
// //   if (type === "folder") {
// //     return <FolderIcon />;
// //   } else if (type === "css") {
// //     return <Icon icon="teenyicons:css3-solid" />;
// //   } else if (type === "html") {
// //     return <Icon icon="logos:html-5" />;
// //   } else if (type === "js") {
// //     return <Icon icon="vscode-icons:file-type-js" />;
// //   } else if (type === "xml") {
// //     return <Icon icon="mdi:file-xml-box" />;
// //   } else if (type === "txt") {
// //     return <Icon icon="grommet-icons:document-txt" />;
// //   } else if (
// //     type === "jpg" ||
// //     type === "jpeg" ||
// //     type === "png" ||
// //     type === "gif"
// //   ) {
// //     return <InsertDriveFileIcon />;
// //   } else {
// //     return <FolderIcon />;
// //   }
// // };

// // const getKeyName = (path) => {
// //   const fileName = path.split("/")[1];
// //   return getType(path) === "folder" ? `/${fileName}` : fileName;
// // };

// // // Component to render the folder hierarchy
// // const FolderHierarchy = ({
// //   folderStructure,
// //   handleItemClick,
// //   getIcon,
// //   getType,
// //   getKeyName,
// // }) => {
// //   return (
// //     <>
// //       {Object.entries(folderStructure).map(([name, content]) => (
// //         <Accordion key={name}>
// //           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
// //             <div style={{ display: "flex", alignItems: "center" }}>
// //               <FolderIcon />
// //               <span onClick={() => handleItemClick(name)}>{name}</span>
// //             </div>
// //           </AccordionSummary>
// //           <AccordionDetails>
// //             <div style={{ marginLeft: "16px" }}>
// //               {content.folder && (
// //                 <FolderHierarchy
// //                   folderStructure={content.folder}
// //                   handleItemClick={handleItemClick}
// //                   getIcon={getIcon}
// //                   getType={getType}
// //                   getKeyName={getKeyName}
// //                 />
// //               )}
// //               {content.file && (
// //                 <Card sx={{ p: 2 }}>
// //                   <div>
// //                     {Object.keys(content.file).map((fileName) => (
// //                       <div key={fileName}>
// //                         <div>
// //                           {getIcon(getType(fileName))}
// //                           <span>{getKeyName(fileName)}</span>
// //                         </div>
// //                       </div>
// //                     ))}
// //                   </div>
// //                 </Card>
// //               )}
// //             </div>
// //           </AccordionDetails>
// //         </Accordion>
// //       ))}
// //     </>
// //   );
// // };

// // export default ViewS3files;

// // import React, { useEffect, useState } from "react";
// // import { useParams } from "react-router-dom";
// // import { _get_project_file_on_s3 } from "src/DAL/ProjectDetails/projectdetails";
// // import moment from "moment";
// // import FolderIcon from "@mui/icons-material/Folder";
// // import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// // import { Icon } from "@iconify/react";
// // import {
// //   Accordion,
// //   AccordionSummary,
// //   AccordionDetails,
// //   Breadcrumbs,
// //   Link,
// //   Typography,
// //   Card,
// // } from "@mui/material";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// // const ViewS3files = () => {
// //   const { id } = useParams();

// //   const [loading, setLoading] = useState(true); // Loading state
// //   const [data, setData] = useState([]);
// //   const [folderStructure, setFolderStructure] = useState({});
// //   const [breadcrumbPath, setBreadcrumbPath] = useState([]); // Complete breadcrumb path

// //   const ViewS3Files = async () => {
// //     const formData = new FormData();
// //     formData.append("project", id);

// //     const view_s3_response = await _get_project_file_on_s3(formData);

// //     setLoading(false);
// //     setData(view_s3_response);
// //     setFolderStructure(buildFolderStructure(view_s3_response.project_files));
// //   };

// //   const buildFolderStructure = (files) => {
// //     const folderStructure = {};
// //     files.forEach((file) => {
// //       file.Key.split("/").reduce(function (r, e) {
// //         return (r[e] = r[e] || {});
// //       }, folderStructure);
// //     });
// //     return folderStructure;
// //   };

// //   const handleItemClick = (key) => {
// //     setBreadcrumbPath((prevPath) => {
// //       const breadcrumbIndex = prevPath.indexOf(key);
// //       if (breadcrumbIndex === -1) {
// //         // If the key is not found in the breadcrumb path, add it to the path
// //         return [...prevPath, key];
// //       } else {
// //         // If the key is found in the breadcrumb path, truncate the path from that point
// //         return prevPath.slice(0, breadcrumbIndex + 1);
// //       }
// //     });
// //   };

// //   useEffect(() => {
// //     ViewS3Files();
// //   }, [id]);

// //   // Helper functions for rendering folder hierarchy
// //   const getType = (path) => {
// //     const fileName = path.split("/").pop();
// //     return fileName.includes(".")
// //       ? fileName.split(".").pop().toLowerCase()
// //       : "folder";
// //   };

// //   const getIcon = (type) => {
// //     if (type === "folder") {
// //       return <FolderIcon />;
// //     } else if (type === "css") {
// //       return <Icon icon="teenyicons:css3-solid" />;
// //     } else if (type === "html") {
// //       return <Icon icon="logos:html-5" />;
// //     } else if (type === "js") {
// //       return <Icon icon="vscode-icons:file-type-js" />;
// //     } else if (type === "xml") {
// //       return <Icon icon="mdi:file-xml-box" />;
// //     } else if (type === "txt") {
// //       return <Icon icon="grommet-icons:document-txt" />;
// //     } else if (
// //       type === "jpg" ||
// //       type === "jpeg" ||
// //       type === "png" ||
// //       type === "gif"
// //     ) {
// //       return <InsertDriveFileIcon />;
// //     } else {
// //       return <FolderIcon />;
// //     }
// //   };

// //   const getKeyName = (path) => {
// //     const fileName = path.split("/")[1];
// //     return getType(path) === "folder" ? `/${fileName}` : fileName;
// //   };

// //   // Helper function to build the complete breadcrumb path
// //   const buildBreadcrumbPath = (currFolder, path = "") => {
// //     if (Object.keys(currFolder).length === 0) {
// //       return [path];
// //     }

// //     return Object.keys(currFolder).flatMap((folder) => {
// //       const newPath = `${path}/${folder}`;
// //       return buildBreadcrumbPath(currFolder[folder], newPath);
// //     });
// //   };

// //   // Build the complete breadcrumb path
// //   useEffect(() => {
// //     const initialBreadcrumbPath = buildBreadcrumbPath(folderStructure);
// //     setBreadcrumbPath(initialBreadcrumbPath);
// //   }, [folderStructure]);

// //   // Component to render the folder hierarchy
// //   const FolderHierarchy = ({
// //     folderStructure,
// //     handleItemClick,
// //     getIcon,
// //     getType,
// //     getKeyName,
// //   }) => {
// //     return (
// //       <>
// //         {Object.entries(folderStructure).map(([name, content]) => (
// //           <Accordion key={name}>
// //             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
// //               <div style={{ display: "flex", alignItems: "center" }}>
// //                 <FolderIcon />
// //                 <span onClick={() => handleItemClick(name)}>{name}</span>
// //               </div>
// //             </AccordionSummary>
// //             <AccordionDetails>
// //               <div style={{ marginLeft: "16px" }}>
// //                 {content.folder && (
// //                   <FolderHierarchy
// //                     folderStructure={content.folder}
// //                     handleItemClick={handleItemClick}
// //                     getIcon={getIcon}
// //                     getType={getType}
// //                     getKeyName={getKeyName}
// //                   />
// //                 )}
// //                 {content.file && (
// //                   <Card sx={{ p: 2 }}>
// //                     <div>
// //                       {Object.keys(content.file).map((fileName) => (
// //                         <div key={fileName}>
// //                           <div>
// //                             {getIcon(getType(fileName))}
// //                             <span>{getKeyName(fileName)}</span>
// //                           </div>
// //                         </div>
// //                       ))}
// //                     </div>
// //                   </Card>
// //                 )}
// //               </div>
// //             </AccordionDetails>
// //           </Accordion>
// //         ))}
// //       </>
// //     );
// //   };

// //   return (
// //     <>
// //       {/* Render the breadcrumbs */}
// //       {breadcrumbPath.length > 0 && (
// //         <Breadcrumbs aria-label="breadcrumb">
// //           {breadcrumbPath.map((folderPath, index) => {

// //             // const folderName = folderPath.split("/").pop();
// //             const parts = folderPath.split("/");

// //             const filenameWithExtension = parts[parts.length - 1];
// //             console.log(filenameWithExtension, "parts");
// //             // console.log(index, "index");
// //             return (
// //               <Link
// //                 key={filenameWithExtension}
// //                 color="inherit"
// //                 onClick={() => handleItemClick(filenameWithExtension)}
// //               >
// //                 {/* {index === 0 ? "/" : ""} */}
// //                 {filenameWithExtension}
// //               </Link>
// //             );
// //           })}
// //         </Breadcrumbs>
// //       )}

// //       {/* Render the folder hierarchy */}
// //       {Object.keys(folderStructure).length > 0 ? (
// //         <FolderHierarchy
// //           folderStructure={folderStructure}
// //           handleItemClick={handleItemClick}
// //           getIcon={getIcon}
// //           getType={getType}
// //           getKeyName={getKeyName}
// //         />
// //       ) : (
// //         <div>No S3 data Found</div>
// //       )}
// //     </>
// //   );
// // };

// // export default ViewS3files;

// // import React, { useEffect, useState } from "react";
// // import { useParams } from "react-router-dom";
// // import { _get_project_file_on_s3 } from "src/DAL/ProjectDetails/projectdetails";
// // import moment from "moment";
// // import FolderIcon from "@mui/icons-material/Folder";
// // import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// // import { Icon } from "@iconify/react";
// // import {
// //   Accordion,
// //   AccordionSummary,
// //   AccordionDetails,
// //   Breadcrumbs,
// //   Link,
// //   Typography,
// //   Card,
// // } from "@mui/material";
// // import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// // const ViewS3files = () => {
// //   const { id } = useParams();

// //   const [loading, setLoading] = useState(true);
// //   const [data, setData] = useState([]);
// //   const [folderStructure, setFolderStructure] = useState({});
// //   const [currentPath, setCurrentPath] = useState(""); // Current folder path for breadcrumbs

// //   const ViewS3Files = async () => {
// //     const formData = new FormData();
// //     formData.append("project", id);

// //     const view_s3_response = await _get_project_file_on_s3(formData);

// //     setLoading(false);
// //     setData(view_s3_response);
// //     setFolderStructure(buildFolderStructure(view_s3_response.project_files));
// //   };

// //   const buildFolderStructure = (files) => {
// //     const folderStructure = {};
// //     files.forEach((file) => {
// //       file.Key.split("/").reduce(function (r, e) {
// //         return (r[e] = r[e] || {});
// //       }, folderStructure);
// //     });
// //     return folderStructure;
// //   };

// //   const handleItemClick = (key) => {
// //     const newFolderStructure = { ...folderStructure };
// //     const keys = key.split("/");
// //     let currFolder = newFolderStructure;
// //     let path = "";
// //     for (let i = 0; i < keys.length; i++) {
// //       currFolder = currFolder[keys[i]];
// //       path += `/${keys[i]}`;
// //     }
// //     setCurrentPath(path);
// //     setFolderStructure(currFolder);
// //   };

// //   useEffect(() => {
// //     ViewS3Files();
// //   }, [id]);

// //   return (
// //     <>
// //       {/* Render the breadcrumbs */}
// //       {currentPath && (
// //         <Breadcrumbs aria-label="breadcrumb">
// //           {currentPath.split("/").map((folder, index) => {
// //             const folderPath = currentPath
// //               .split("/")
// //               .slice(0, index + 1)
// //               .join("/");
// //             return (
// //               <Link
// //                 key={folderPath}
// //                 color="inherit"
// //                 onClick={() => handleItemClick(folderPath)}
// //               >
// //                 {folder}
// //               </Link>
// //             );
// //           })}
// //         </Breadcrumbs>
// //       )}

// //       {/* Render the folder hierarchy */}
// //       {Object.keys(folderStructure).length > 0 ? (
// //         <FolderHierarchy
// //           folderStructure={folderStructure}
// //           handleItemClick={handleItemClick}
// //           getIcon={getIcon}
// //           getType={getType}
// //           getKeyName={getKeyName}
// //         />
// //       ) : (
// //         <div>No S3 data Found</div>
// //       )}
// //     </>
// //   );
// // };

// // const getType = (path) => {
// //   const fileName = path.split("/").pop();
// //   return fileName.includes(".") ? fileName.split(".").pop().toLowerCase() : "folder";
// // };

// // const getIcon = (type) => {
// //   if (type === "folder") {
// //     return <FolderIcon />;
// //   } else if (type === "css") {
// //     return <Icon icon="teenyicons:css3-solid" />;
// //   } else if (type === "html") {
// //     return <Icon icon="logos:html-5" />;
// //   } else if (type === "js") {
// //     return <Icon icon="vscode-icons:file-type-js" />;
// //   } else if (type === "xml") {
// //     return <Icon icon="mdi:file-xml-box" />;
// //   } else if (type === "txt") {
// //     return <Icon icon="grommet-icons:document-txt" />;
// //   } else if (["jpg", "jpeg", "png", "gif"].includes(type)) {
// //     return <InsertDriveFileIcon />;
// //   } else {
// //     return <FolderIcon />;
// //   }
// // };

// // const getKeyName = (path) => {
// //   const fileName = path.split("/").pop();
// //   return getType(fileName) === "folder" ? fileName : fileName;
// // };

// // // Component to render the folder hierarchy
// // const FolderHierarchy = ({
// //   folderStructure,
// //   handleItemClick,
// //   getIcon,
// //   getType,
// //   getKeyName,
// // }) => {
// //   return (
// //     <>
// //       {Object.entries(folderStructure).map(([name, content]) => (
// //         <Accordion key={name}>
// //           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
// //             <div style={{ display: "flex", alignItems: "center" }}>
// //               {getIcon(getType(name))}
// //               <span onClick={() => handleItemClick(name)}>{getKeyName(name)}</span>
// //             </div>
// //           </AccordionSummary>
// //           <AccordionDetails>
// //             <div style={{ marginLeft: "16px" }}>
// //               {content.folder && (
// //                 <FolderHierarchy
// //                   folderStructure={content.folder}
// //                   handleItemClick={handleItemClick}
// //                   getIcon={getIcon}
// //                   getType={getType}
// //                   getKeyName={getKeyName}
// //                 />
// //               )}
// //               {content.file && (
// //                 <Card sx={{ p: 2 }}>
// //                   <div>
// //                     {Object.keys(content.file).map((fileName) => (
// //                       <div key={fileName}>
// //                         <div>
// //                           {getIcon(getType(fileName))}
// //                           <span>{getKeyName(fileName)}</span>
// //                         </div>
// //                       </div>
// //                     ))}
// //                   </div>
// //                 </Card>
// //               )}
// //             </div>
// //           </AccordionDetails>
// //         </Accordion>
// //       ))}
// //     </>
// //   );
// // };

// // export default ViewS3files;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { _get_project_file_on_s3 } from "src/DAL/ProjectDetails/projectdetails";
// import moment from "moment";
// import FolderIcon from "@mui/icons-material/Folder";
// import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import { Icon } from "@iconify/react";
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Breadcrumbs,
//   Link,
//   Typography,
//   Card,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// const ViewS3files = () => {
//   const { id } = useParams();

//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);
//   const [folderStructure, setFolderStructure] = useState({});
//   const [currentPath, setCurrentPath] = useState(""); // Current folder path for breadcrumbs

//   const ViewS3Files = async () => {
//     const formData = new FormData();
//     formData.append("project", id);

//     const view_s3_response = await _get_project_file_on_s3(formData);

//     setLoading(false);
//     setData(view_s3_response);
//     setFolderStructure(buildFolderStructure(view_s3_response.project_files));
//   };

//   const buildFolderStructure = (files) => {
//     const folderStructure = {};
//     files.forEach((file) => {
//       file.Key.split("/").reduce(function (r, e) {
//         return (r[e] = r[e] || {});
//       }, folderStructure);
//     });
//     return folderStructure;
//   };

//   const handleItemClick = (key) => {
//     const newFolderStructure = { ...folderStructure };
//     const keys = key.split("/");
//     let currFolder = newFolderStructure;
//     let path = "";
//     for (let i = 0; i < keys.length; i++) {
//       currFolder = currFolder[keys[i]];
//       path += `/${keys[i]}`;

//       // If this is the last iteration, set the current path and folder structure
//       if (i === keys.length - 1) {
//         setCurrentPath(path);
//         setFolderStructure(currFolder);
//       }
//     }
//   };

//   useEffect(() => {
//     ViewS3Files();
//   }, [id]);

//   return (
//     <>
//       {/* Render the breadcrumbs */}
//       {currentPath && (
//         <Breadcrumbs aria-label="breadcrumb">
//           {/* Add a link to go back to the previous path */}
//           <Link
//             key={"home"}
//             color="inherit"
//             onClick={() => handleItemClick(currentPath.split("/").slice(0, -1).join("/"))}
//           >
//             {"<"}
//           </Link>
//           {currentPath.split("/").map((folder, index) => {
//             const folderPath = currentPath.split("/").slice(0, index + 1).join("/");
//             return (
//               <Link
//                 key={folderPath}
//                 color="inherit"
//                 onClick={() => handleItemClick(folderPath)}
//               >
//                 {folder}
//               </Link>
//             );
//           })}
//         </Breadcrumbs>
//       )}

//       {/* Render the folder hierarchy */}
//       {Object.keys(folderStructure).length > 0 ? (
//         <FolderHierarchy
//           folderStructure={folderStructure}
//           handleItemClick={handleItemClick}
//           getIcon={getIcon}
//           getType={getType}
//           getKeyName={getKeyName}
//         />
//       ) : (
//         <div>No S3 data Found</div>
//       )}
//     </>
//   );
// };

// const getType = (path) => {
//   const fileName = path.split("/").pop();
//   return fileName.includes(".") ? fileName.split(".").pop().toLowerCase() : "folder";
// };

// const getIcon = (type) => {
//   if (type === "folder") {
//     return <FolderIcon />;
//   } else if (type === "css") {
//     return <Icon icon="teenyicons:css3-solid" />;
//   } else if (type === "html") {
//     return <Icon icon="logos:html-5" />;
//   } else if (type === "js") {
//     return <Icon icon="vscode-icons:file-type-js" />;
//   } else if (type === "xml") {
//     return <Icon icon="mdi:file-xml-box" />;
//   } else if (type === "txt") {
//     return <Icon icon="grommet-icons:document-txt" />;
//   } else if (["jpg", "jpeg", "png", "gif"].includes(type)) {
//     return <InsertDriveFileIcon />;
//   } else {
//     return <FolderIcon />;
//   }
// };

// const getKeyName = (path) => {
//   const fileName = path.split("/").pop();
//   return getType(fileName) === "folder" ? fileName : fileName;
// };

// // Component to render the folder hierarchy
// const FolderHierarchy = ({
//   folderStructure,
//   handleItemClick,
//   getIcon,
//   getType,
//   getKeyName,
// }) => {
//   return (
//     <>
//       {Object.entries(folderStructure).map(([name, content]) => (
//         <Accordion key={name}>
//           <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//             <div style={{ display: "flex", alignItems: "center" }}>
//               {getIcon(getType(name))}
//               <span onClick={() => handleItemClick(name)}>{getKeyName(name)}</span>
//             </div>
//           </AccordionSummary>
//           <AccordionDetails>
//             <div style={{ marginLeft: "16px" }}>
//               {content.folder && (
//                 <FolderHierarchy
//                   folderStructure={content.folder}
//                   handleItemClick={handleItemClick}
//                   getIcon={getIcon}
//                   getType={getType}
//                   getKeyName={getKeyName}
//                 />
//               )}
//               {content.file && (
//                 <Card sx={{ p: 2 }}>
//                   <div>
//                     {Object.keys(content.file).map((fileName) => (
//                       <div key={fileName}>
//                         <div>
//                           {getIcon(getType(fileName))}
//                           <span>{getKeyName(fileName)}</span>
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </Card>
//               )}
//             </div>
//           </AccordionDetails>
//         </Accordion>
//       ))}
//     </>
//   );
// };

// export default ViewS3files;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { _get_project_file_on_s3 } from "src/DAL/ProjectDetails/projectdetails";
import moment from "moment";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Icon } from "@iconify/react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  Grid,
  Paper,
  IconButton,
  CircularProgress,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import FilesSection from "./fileSection";
import AssertsFolderSection from "./AssertsfolderSection";
import CssFolderSection from "./CssFolderSection";
import JsFolderSection from "./JsFolderSection";

const ViewS3files = () => {
  const { id } = useParams();
  // const [s3Folders, setS3Folders] = useState({});
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  // const [folderStructure, setFolderStructure] = useState({});
  const [filterFilesData, setFilterFilesData] = useState({});

  let navigate = useNavigate();

  const ViewS3Files = async () => {
    const formData = new FormData();
    formData.append("project", id);

    const view_s3_response = await _get_project_file_on_s3(formData);

    // console.log(view_s3_response, "view_s3_response");

    if (view_s3_response.code === 200) {
      setData(view_s3_response);
      // let folders = {};
      // view_s3_response?.project_files?.map((file) => {
      //   let Arr = file.Key.split("/");
      //   let folder = Arr[Arr.length - 2];
      //   let File = Arr[Arr.length - 1];
      //   folders = {
      //     ...folders,
      //     [folder]: folders[folder] ? [...folders[folder], File] : [File],
      //   };
      // });
      // setS3Folders(folders);

      // console.log(folders, "rrrrrr");

      setLoading(false);

      // setFolderStructure(buildFolderStructure(view_s3_response.project_files));
      // console.log(
      //   buildFolderStructure(view_s3_response.project_files),
      //   "asdcuadcuioadciodaccas"
      // );

      const jsFiles = filterFolderFiles(view_s3_response.project_files, "/js/");

      const cssFiles = filterFolderFiles(
        view_s3_response.project_files,
        "/css/"
      );
      const assertsFiles = filterFolderFiles(
        view_s3_response.project_files,
        "/asserts/"
      );

      const Files = s3Files(view_s3_response.project_files);

      setFilterFilesData({
        js: jsFiles,
        css: cssFiles,
        asserts: assertsFiles,
        files: Files,
      });
    }
  };

  const filterFolderFiles = (resp, string) => {
    let arr = [];
    resp.map((key) => {
      if (key.Key.includes(string)) {
        arr.push(key);
      }
    });
    return arr;
  };
  const s3Files = (resp) => {
    let arr = [];
    resp.map((key) => {
      if (
        !key.Key.includes("/js/") &&
        !key.Key.includes("/css/") &&
        !key.Key.includes("/asserts/")
      ) {
        arr.push(key);
      }
    });
    return arr;
  };

  // const buildFolderStructure = (files) => {
  //   const folderStructure = {};
  //   files.forEach((file) => {
  //     file.Key.split("/").reduce(function (r, e) {
  //       return (r[e] = r[e] || {});
  //     }, folderStructure);
  //   });
  //   return folderStructure;
  // };

  // const handleItemClick = (key) => {

  //   const newFolderStructure = { ...folderStructure };
  //   const keys = key.split("/");

  //   let currFolder = newFolderStructure;
  //   let path = "";
  //   for (let i = 0; i < keys.length; i++) {
  //     currFolder = currFolder[keys[i]];
  //     path += `/${keys[i]}`;

  //     // If this is the last iteration, set the current path and folder structure
  //     if (i === keys.length - 1) {
  //       setCurrentPath(path);
  //       setFolderStructure(currFolder);
  //     }
  //   }
  // };

  //   const buildFolderStructure1 = (files) => {
  //     const root = {};
  //       // console.log(files,"filesfiles");

  //     files.forEach(file => {
  //         let currentLevel = root;
  //         const parts = file.Key.split('/');

  //         parts.forEach((part, index) => {
  //             if (index === parts.length - 1) {
  //                 // It's a file
  //                 currentLevel[part] = file.Key;
  //             } else {
  //                 // It's a folder
  //                 currentLevel[part] = currentLevel[part] || {};
  //                 currentLevel = currentLevel[part];
  //             }
  //         });
  //     });

  //     return root;
  // };

  // const File = ({ name }) => <li>{name}</li>;

  // const Folder = ({ name, children }) => (
  //     <div>
  //         <strong>{name}</strong>
  //         <ul>
  //             {Object.entries(children).map(([key, value]) =>
  //                 typeof value === 'string' ? <File key={key} name={key} /> : <Folder key={key} name={key} children={value} />
  //             )}
  //         </ul>
  //     </div>
  // );

  // const FolderStructure1 = ({ files }) => {
  //     const structure = buildFolderStructure1(files);

  //     return (
  //         <div>
  //             {Object.entries(structure).map(([key, value]) => (
  //                 <Folder key={key} name={key} children={value} />
  //             ))}
  //         </div>
  //     );
  // };

  useEffect(() => {
    ViewS3Files();
  }, [id]);

  // const jsFiles = filterFilesData;
  //  jsFiles.map((val)=>console.log(val,"valll"))

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="d-flex align-items-center gap-3 pb-4">
        <IconButton onClick={handleBack} aria-label="Go back">
          <ArrowBackIcon />
        </IconButton>

        <h4>S3 Files</h4>
      </div>
      {loading === true ? (
        <Box 
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
        
      ) : (
        <>
          <AssertsFolderSection AssertsData={filterFilesData.asserts} />
          <CssFolderSection CssData={filterFilesData.css} />
          <JsFolderSection JsData={filterFilesData.js} />
          <FilesSection FilesData={filterFilesData.files} />
        </>
      )}

      {/* {
        Object.keys(folderStructure).map((elem) => {
        console.log(elem,"structureee",folderStructure[elem])
          if(folderStructure[elem].Key !== ""){
          return (
           <FolderSection folderName={folderStructure[elem]}/>
          )
          }
        })}  */}
    </>
    // <Grid container spacing={3}>
    //   {/* Left Grid: Display the folder hierarchy */}
    //   <Grid item xs={6}>
    //     {/* Render the breadcrumbs */}
    //     {currentPath && (
    //       <Breadcrumbs aria-label="breadcrumb">
    //         {/* Add a link to go back to the previous path */}
    //         {/* <Link
    //           key={"home"}
    //           color="inherit"
    //           onClick={() =>
    //             handleItemClick(currentPath.split("/").slice(0, -1).join("/"))
    //           }
    //         >
    //           {"<"}
    //         </Link>
    //         {currentPath.split("/").map((folder, index) => {
    //           const folderPath = currentPath
    //             .split("/")
    //             .slice(0, index + 1)
    //             .join("/");
    //           return (
    //             <Link
    //               key={folderPath}
    //               color="inherit"
    //               onClick={() => handleItemClick(folderPath)}
    //             >
    //               {folder}
    //             </Link>
    //           );
    //         })} */}
    //       </Breadcrumbs>
    //     )}

    //     {/* Render the folder hierarchy */}
    //     {/* {Object.keys(folderStructure).length > 0 ? (
    //       <FolderHierarchy
    //         folderStructure={folderStructure}
    //         handleItemClick={handleItemClick}
    //         getIcon={getIcon}
    //         getType={getType}
    //         getKeyName={getKeyName}
    //       />
    //     ) : (
    //       <div>No S3 data Found</div>
    //     )} */}
    //   </Grid>

    //   {/* Right Grid: Display the response data */}
    //   <Grid item xs={6}>
    //     <Paper style={{ padding: "16px" }}>
    //       {/* Display the response data here */}
    //       {/* Replace the placeholders with actual response data */}
    //       <Typography variant="h5">Type: {data.type}</Typography>
    //       <Typography variant="h5">Standard: {data.standard}</Typography>
    //       {/* You can display more information from 'data' here as needed */}
    //     </Paper>
    //   </Grid>
    // </Grid>
  );
};

// const getType = (path) => {
//   const fileName = path.split("/").pop();
//   return fileName.includes(".")
//     ? fileName.split(".").pop().toLowerCase()
//     : "folder";
// };

// const getIcon = (type) => {
//   if (type === "folder") {
//     return <FolderIcon />;
//   } else if (type === "css") {
//     return <Icon icon="teenyicons:css3-solid" />;
//   } else if (type === "html") {
//     return <Icon icon="logos:html-5" />;
//   } else if (type === "js") {
//     return <Icon icon="vscode-icons:file-type-js" />;
//   } else if (type === "xml") {
//     return <Icon icon="mdi:file-xml-box" />;
//   } else if (type === "txt") {
//     return <Icon icon="grommet-icons:document-txt" />;
//   } else if (["jpg", "jpeg", "png", "gif"].includes(type)) {
//     return <InsertDriveFileIcon />;
//   } else {
//     return <FolderIcon />;
//   }
// };

// const getKeyName = (path) => {
//   const fileName = path.split("/").pop();
//   return getType(fileName) === "folder" ? fileName : fileName;
// };

// Component to render the folder hierarchy
const FolderHierarchy = ({
  folderStructure,
  handleItemClick,
  getIcon,
  getType,
  getKeyName,
}) => {
  return (
    <>
      {/*/////////////////////////// Main Accordian1///////////////////////////// */}

      {/* {Object.entries(folderStructure).map(([name, content]) => (
        <Accordion key={name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {getIcon(getType(name))}
              <span onClick={() => handleItemClick(name)}>
                {getKeyName(name)}
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ marginLeft: "16px" }}>
              {content.folder && (
                <FolderHierarchy
                  folderStructure={content.folder}
                  handleItemClick={handleItemClick}
                  getIcon={getIcon}
                  getType={getType}
                  getKeyName={getKeyName}
                />
              )}
              {content.file && (
                <Card sx={{ p: 2 }}>
                  <div>
                    {Object.keys(content.file).map((fileName) => (
                      <div key={fileName}>
                        <div>
                          {getIcon(getType(fileName))}
                          <span style={{ color: "red" }}>
                            {getKeyName(fileName)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Card>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      ))} */}
    </>
  );
};

export default ViewS3files;
