import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { _delete_version, _project_version_listing } from "src/DAL/ProjectDetails/projectdetails";
import { useParams } from "react-router-dom";
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import { useSnackbar } from "notistack";

export default function VersionList() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for menu
  const [selectedPage, setSelectedPage] = useState(null); // Selected page for the menu
  const [loading, setLoading] = useState(true); // Loading state

  const { enqueueSnackbar } = useSnackbar();

  const [openDelete, setOpenDelete] = useState(false);

  const pageVersionList = async () => {
    const project_list_response = await _project_version_listing(id);
    setData(project_list_response.project);
    setLoading(false);
    // console.log(project_list_response, "Versionlist");

    // console.log(data, "datattatat");
  };


  

  useEffect(() => {
    pageVersionList();
  }, [id]);

  const handleMenuOpen = (event, page) => {
    setAnchorEl(event.currentTarget);
    setSelectedPage(page);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedPage(null);
  };

  const handleDeletePage = async () => {
    const delete_resp = await _delete_version(selectedPage._id);
    // console.log("Version page:", delete_resp);

    if (delete_resp.code == 200) {
      setOpenDelete(false);
      pageVersionList();
      enqueueSnackbar("Version Deleted Successfully", { variant: "success" });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
    }

    handleMenuClose();
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
  };

  return (
  <>

<TableContainer component={Paper}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Version</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.project_versions && data.project_versions.length > 0 ? (
              data.project_versions.map((dat,index) => (
                <TableRow
                  key={dat.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell  >
                    {dat.version_name}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      aria-controls="page-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleMenuOpen(e, dat)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="page-menu"
                      anchorEl={anchorEl}
                      open={Boolean(
                        anchorEl &&
                          selectedPage &&
                          selectedPage.id === dat.id
                      )}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12} align="center">
                  No Version List Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </TableContainer>



    <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this customer?"}
        handleAgree={handleDeletePage}
      />
  </>
  );
}
