import { invokeApi } from "src/bl_libs/invokeApi";

export const _helping_articles_listing = async (page, limit) => {
  let requestObj = {
    path: `api/helping_article/list_helping_article`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_helping_article = async (id) => {
  let requestObj = {
    path: `api/helping_article/delete_helping_article/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_helping_article = async (data) => {
  let requestObj = {
    path: `api/helping_article/add_helping_article`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _update_helping_article = async (id, data) => {
  let requestObj = {
    path: `api/helping_article/update_helping_article/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
