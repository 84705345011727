import { Icon } from "@iconify/react";
import "react-phone-input-2/lib/style.css";
// material
import {
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { _add_role, _edit_role } from "src/DAL/Management/role_api";
import Label from "src/components/Label";
// ----------------------------------------------------------------------

const roles_list = [
  {
    name: "Dashboard",
    path: "/dashboard",
    privileges: {
      list: true,
    },
  },
  {
    path: "/customers",
    name: "Customers",
    privileges: {
      list: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  {
    path: "/projects",
    name: "Projects",
    privileges: {
      list: false,
      edit: false,
      delete: false,
    },
  },
  {
    path: "/helping-categories",
    name: "Help Categories",
    privileges: {
      list: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  {
    path: "/helping-articles",
    name: "Help Articles",
    privileges: {
      list: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  {
    name: "Landing Page Setting",
    path: "/brand-logo",
    privileges: {
      list: false,
    },
  },

  {
    path: "/support-departments",
    name: "Support Departments",
    privileges: {
      list: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  {
    name: "Plans",
    path: "/plans",
    privileges: {
      list: false,
      add: false,
      edit: false,
      delete: false,
    },
  },

  {
    name: "Transactions",
    path: "/transaction",
    privileges: {
      list: false,
      delete: false,
    },
  },
  {
    name: "Subscription",
    path: "/stripe-subscription",
    privileges: {
      list: false,
      edit: false,
    },
  },
  {
    name: "Template",
    path: "/templates",
    privileges: {
      list: false,
    },
  },
  {
    name: `Keys`,
    path: "/keys",
    privileges: {
      list: false,
    },
  },
  {
    name: "Team Management",
    path: "/sub-admin",
    privileges: {
      list: false,
    },
  },
];
// ----------------------------------------------------------------------

export default function AddEditRole({
  isOpenDrawer,
  onCloseDrawer,
  getRoleListing,
  is_edit = false,
  is_detail = false,
  targetRole = {},
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [inputs, setInputs] = useState({
    title: "",
    status: true,
  });

  const [navbarListing, setNavbarListing] = useState();
  const [selectAll, setSelectAll] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeStatus = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: !inputs[e.target.name],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    let newArray = navbarListing;

    let selectedField = false;
    for (const obj of newArray) {
      if (obj.privileges.list) {
        selectedField = true;
        break;
      }
    }

    if (!selectedField) {
      enqueueSnackbar("At least one access is mandatory.", { variant: "info" });
      setIsLoading(false);
      return;
    }

    if (is_edit) {
      newArray = navbarListing.map(({ name, privileges, path }) => ({
        name,
        privileges,
        path,
      }));
    }

    const postData = {
      title: inputs.title,
      description: "description",
      roles: newArray,
      status: inputs.status,
    };

    const resp = is_edit
      ? await _edit_role(targetRole._id, postData)
      : await _add_role(postData);
    console.log(resp, "customeradded");
    if (resp.code == 200) {
      onCloseDrawer();
      getRoleListing();
      enqueueSnackbar(`Role ${is_edit ? "updated" : "added"} successfully`, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = (event, title) => {
    const updatedPrivileges = navbarListing.map((list) => {
      if (list.name === title) {
        if (event.target.checked) {
          return {
            ...list,
            privileges: {
              ...list.privileges,
              [event.target.name]: event.target.checked,
              list: event.target.checked,
            },
          };
        } else {
          if (event.target.name !== "list") {
            return {
              ...list,
              privileges: {
                ...list.privileges,
                [event.target.name]: event.target.checked,
              },
            };
          } else {
            let obj = {
              list: event.target.checked,
            };
            // CHECK AND change status of Add in privilige
            if (list.privileges.add === true || list.privileges.add === false) {
              obj = {
                ...obj,
                add: event.target.checked,
              };
            }
            // CHECK AND change status of Edit in privilige
            if (
              list.privileges.edit === true ||
              list.privileges.edit === false
            ) {
              obj = {
                ...obj,
                edit: event.target.checked,
              };
            }
            // CHECK AND change status of Delete in privilige
            if (
              list.privileges.delete === true ||
              list.privileges.delete === false
            ) {
              obj = {
                ...obj,
                delete: event.target.checked,
              };
            }

            return {
              ...list,
              privileges: obj,
            };
          }
        }
      }
      return list;
    });
    setNavbarListing(updatedPrivileges);
  };

  const handleChangeSelectAll = (event) => {
    const updatedPrivileges = navbarListing.map((list) => {
      let obj = {
        list: event.target.checked,
      };
      // CHECK AND change status of Add in privilige
      if (list.privileges.add === true || list.privileges.add === false) {
        obj = {
          ...obj,
          add: event.target.checked,
        };
      }
      // CHECK AND change status of Edit in privilige
      if (list.privileges.edit === true || list.privileges.edit === false) {
        obj = {
          ...obj,
          edit: event.target.checked,
        };
      }
      // CHECK AND change status of Delete in privilige
      if (list.privileges.delete === true || list.privileges.delete === false) {
        obj = {
          ...obj,
          delete: event.target.checked,
        };
      }

      return {
        ...list,
        privileges: obj,
      };
    });
    setNavbarListing(updatedPrivileges);
    setSelectAll(event.target.checked);
  };
  const mergeArrays = (arr1, arr2) => {
    const uniqueNames = new Set([
      ...arr1.map((obj) => obj.name),
      ...arr2.map((obj) => obj.name),
    ]);

    return Array.from(uniqueNames, (name) => {
      const obj1 = arr1.find((obj) => obj.name === name);
      const obj2 = arr2.find((obj) => obj.name === name);

      return obj2 || obj1; // If obj2 exists, use it; otherwise, use obj1
    });
  };

  useEffect(() => {
    if (isOpenDrawer) {
      if (is_edit || is_detail) {
        setInputs({
          title: targetRole.title,
          status: targetRole.status,
        });
        // Merge the arrays
        const mergedArray = mergeArrays(roles_list, targetRole.roles);
        setNavbarListing([...mergedArray]);
      } else {
        setInputs({
          title: "",
          status: true,
        });
        setNavbarListing([...roles_list]);
      }
      setSelectAll(false);
    }
    setIsLoading(false);
  }, [isOpenDrawer]);

  return (
    <>
      <Dialog
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head" style={{ paddingBottom: "13px" }}>
            <span>
              {is_detail ? "Role Details" : is_edit ? "Edit Role" : "Add Role"}
            </span>
            <Icon
              icon="maki:cross"
              onClick={onCloseDrawer}
              style={{ cursor: "pointer" }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="card-main mt-2 ">
            <form onSubmit={handleSubmit} className="w-100">
              <Stack sx={{ p: 2 }}>
                <TextField
                  fullWidth
                  label="Title"
                  required
                  size="small"
                  name="title"
                  InputProps={{
                    readOnly: is_detail,
                  }}
                  value={inputs.title}
                  onChange={handleChange}
                />
              </Stack>
              <Stack sx={{ px: 2, pb: 2 }}>
                {is_detail ? (
                  <>
                    <div className="d-flex align-items-center mt-0">
                      <span className="me-3" style={{ fontWeight: "600" }}>
                        Status:
                      </span>
                      <Label
                        variant="ghost"
                        color={(inputs.status && "success") || "error"}
                      >
                        {inputs.status ? "Active" : "Inactive"}
                      </Label>
                    </div>
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-between">
                    <FormControlLabel
                      className="justify-content-end m-0"
                      value="start"
                      control={
                        <Switch
                          checked={inputs.status}
                          value={inputs.status}
                          onChange={handleChangeStatus}
                          color="primary"
                          name="status"
                        />
                      }
                      label={
                        <span style={{ fontWeight: "600" }}>Status: </span>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAll}
                          onChange={(event) => handleChangeSelectAll(event)}
                          color="primary"
                          name="selectAll"
                        />
                      }
                      label={
                        <span style={{ fontWeight: "600" }}>Select All: </span>
                      }
                      labelPlacement="start"
                    />
                  </div>
                )}
              </Stack>
              <Stack sx={{ px: 2, pb: 2 }}>
                {navbarListing?.map((list) => (
                  <div className="border-bottom">
                    <div className="d-flex align-items-center justify-content-between">
                      <span style={{ fontWeight: "600" }}>{list.name}</span>
                      <div className="row" style={{ width: "70%" }}>
                        {(list.privileges.list === true ||
                          list.privileges.list === false) && (
                          <>
                            {is_detail ? (
                              <div className="d-flex align-items-center col-3 m-0 my-2">
                                <Icon
                                  icon={`${
                                    list.privileges.list === true
                                      ? "emojione-v1:left-check-mark"
                                      : "twemoji:cross-mark"
                                  }`}
                                  width="14"
                                />
                                <span className="ms-2">List</span>
                              </div>
                            ) : (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={list.privileges.list}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, list.name)
                                    }
                                    color="primary"
                                    name="list"
                                  />
                                }
                                sx={{
                                  pointerEvents: is_detail && "none",
                                }}
                                label="Listing"
                                labelPlacement="end"
                                className="col-3 m-0"
                              />
                            )}
                          </>
                        )}

                        {(list.privileges.add === true ||
                          list.privileges.add === false) && (
                          <>
                            {is_detail ? (
                              <div className="d-flex align-items-center col-3 m-0 my-2">
                                <Icon
                                  icon={`${
                                    list.privileges.add === true
                                      ? "emojione-v1:left-check-mark"
                                      : "twemoji:cross-mark"
                                  }`}
                                  width="14"
                                />
                                <span className="ms-2">Add</span>
                              </div>
                            ) : (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={list.privileges.add}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, list.name)
                                    }
                                    color="primary"
                                    name="add"
                                  />
                                }
                                sx={{
                                  pointerEvents: is_detail && "none",
                                }}
                                label="Add"
                                labelPlacement="end"
                                className="col-3 m-0"
                              />
                            )}
                          </>
                        )}

                        {/* {(list.privileges.add === true ||
                          list.privileges.add === false) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={list.privileges.add}
                                onChange={(event) =>
                                  handleCheckboxChange(event, list.name)
                                }
                                color="primary"
                                name="add"
                              />
                            }
                            sx={{
                              pointerEvents: is_detail && "none",
                            }}
                            label="Add"
                            labelPlacement="end"
                            className="col-3 m-0"
                          />
                        )} */}

                        {(list.privileges.edit === true ||
                          list.privileges.edit === false) && (
                          <>
                            {is_detail ? (
                              <div className="d-flex align-items-center col-3 m-0 my-2">
                                <Icon
                                  icon={`${
                                    list.privileges.edit === true
                                      ? "emojione-v1:left-check-mark"
                                      : "twemoji:cross-mark"
                                  }`}
                                  width="14"
                                />
                                <span className="ms-2">Edit</span>
                              </div>
                            ) : (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={list.privileges.edit}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, list.name)
                                    }
                                    color="primary"
                                    name="edit"
                                  />
                                }
                                sx={{
                                  pointerEvents: is_detail && "none",
                                }}
                                label="Edit"
                                labelPlacement="end"
                                className="col-3 m-0"
                              />
                            )}
                          </>
                        )}

                        {/* {(list.privileges.edit === true ||
                          list.privileges.edit === false) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={list.privileges.edit}
                                onChange={(event) =>
                                  handleCheckboxChange(event, list.name)
                                }
                                color="primary"
                                name="edit"
                              />
                            }
                            sx={{
                              pointerEvents: is_detail && "none",
                            }}
                            label="Edit"
                            labelPlacement="end"
                            className="col-3 m-0"
                          />
                        )} */}

                        {(list.privileges.delete === true ||
                          list.privileges.delete === false) && (
                          <>
                            {is_detail ? (
                              <div className="d-flex align-items-center col-3 m-0 my-2">
                                <Icon
                                  icon={`${
                                    list.privileges.delete === true
                                      ? "emojione-v1:left-check-mark"
                                      : "twemoji:cross-mark"
                                  }`}
                                  width="14"
                                />
                                <span className="ms-2">Delete</span>
                              </div>
                            ) : (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={list.privileges.delete}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, list.name)
                                    }
                                    color="primary"
                                    name="delete"
                                  />
                                }
                                sx={{
                                  pointerEvents: is_detail && "none",
                                }}
                                label="Delete"
                                labelPlacement="end"
                                className="col-3 m-0"
                              />
                            )}
                          </>
                        )}

                        {/* {(list.privileges.delete === true ||
                          list.privileges.delete === false) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={list.privileges.delete}
                                onChange={(event) =>
                                  handleCheckboxChange(event, list.name)
                                }
                                color="primary"
                                name="delete"
                              />
                            }
                            sx={{
                              pointerEvents: is_detail && "none",
                            }}
                            label="Delete"
                            labelPlacement="end"
                            className="col-3 m-0"
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                ))}
              </Stack>

              {!is_detail && (
                <DialogActions>
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                  >
                    {is_edit ? "Update Role" : "Add Role"}
                  </LoadingButton>
                </DialogActions>
              )}
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
