import { Box, Button, CircularProgress, Input, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { _website_setting } from "src/DAL/Website/WebsiteSetting";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function WebsiteSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    slug: "",
    meta_title: "",
    brand_favicon: "",
    meta_description: "",
    meta_keywords: "",
    copy_right_text: "",
    terms_and_conditions: {
      text: "",
      link: "",
    },
    // terms_and_condition_link: "",
    privacy_and_policy: {
      text: "",
      link: "",
    },
    // privacy_and_policy_link: "",
  });

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleImageUpload = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        brand_favicon: e.target.files[0],
      };
    });
  };

  const GetWebsiteSettingDetail = async () => {
    setLoading(true);
    const WebsiteSettingData = await _landing_page_detail();
    if (WebsiteSettingData.code === 200) {
      setLoading(false);
      setInputs(WebsiteSettingData?.data?.website_setting);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let image_file = "";

    if (typeof inputs?.brand_favicon === "string") {
      image_file = inputs?.brand_favicon;
    } else {
      const formData = new FormData();

      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs?.brand_favicon);

      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code === 200) {
        delete inputs.local_image;
        image_file = image_resp.file_name;
       
      }
    }

    const postData = {
      slug: inputs?.slug,
      brand_favicon: image_file,
      meta_title: inputs?.meta_title,
      meta_description: inputs?.meta_description,
      meta_keywords: inputs?.meta_keywords,
      copy_right_text: inputs?.copy_right_text,
      terms_and_conditions: {
        text: inputs?.terms_and_conditions?.text,
        link: inputs?.terms_and_conditions?.link,
      },
      privacy_and_policy: {
        text: inputs?.privacy_and_policy?.text,
        link: inputs?.privacy_and_policy?.link,
      },
    };

    const resp = await _website_setting(postData);
    if (resp.code === 200) {
      enqueueSnackbar("data added successfully", { variant: "success" });
      GetWebsiteSettingDetail();
    } else {
      enqueueSnackbar("data not added successfully", { variant: "error" });
    }
  };

  useEffect(() => {
    GetWebsiteSettingDetail();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div className="mt-5" style={{ width: "94%", margin: "auto" }}>
            <h3 className="text-center">Website Setting</h3>
            <form className="d-flex flex-column justify-content-center align-items-center gap-3 mt-5">
              <div className="col-12 d-flex gap-3">
                <TextField
                  label="enter slug"
                  name="slug"
                  required
                  value={inputs?.slug}
                  fullWidth
                  onChange={handleChange}
                />
                <TextField
                  label="meta title"
                  required
                  value={inputs?.meta_title}
                  name="meta_title"
                  fullWidth
                  onChange={handleChange}
                />
              </div>
              <div className="col-12">
                <p>Meta Description</p>
                <GeneralCkeditor
                  inputs={inputs}
                  value={inputs?.meta_description}
                  name="meta_description"
                  setInputs={setInputs}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 d-flex gap-3">
                <TextField
                  fullWidth
                  required
                  onChange={handleChange}
                  value={inputs?.meta_keywords}
                  name="meta_keywords"
                  label="meta keyword"
                />
                <TextField
                  fullWidth
                  required
                  onChange={handleChange}
                  value={inputs?.copy_right_text}
                  name="copy_right_text"
                  label="copy right text"
                />
              </div>
              <div className="col-12 d-flex gap-3">
                <TextField
                  fullWidth
                  required
                  value={inputs?.terms_and_conditions?.text}
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        terms_and_conditions: {
                          ...prev.terms_and_conditions,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                  name="text"
                  label="terms and condition text"
                />
                <TextField
                  fullWidth
                  required
                  value={inputs?.terms_and_conditions?.link}
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        terms_and_conditions: {
                          ...prev.terms_and_conditions,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                  name="link"
                  label="terms and condition link"
                />
              </div>
              <div className="col-12 d-flex gap-3">
                <TextField
                  fullWidth
                  required
                  value={inputs?.privacy_and_policy?.text}
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        privacy_and_policy: {
                          ...prev.privacy_and_policy,
                          text: e.target.value,
                        },
                      };
                    })
                  }
                  name="text"
                  label="privacy and policy text"
                />
                <TextField
                  fullWidth
                  required
                  value={inputs?.privacy_and_policy?.link}
                  onChange={(e) =>
                    setInputs((prev) => {
                      return {
                        ...prev,
                        privacy_and_policy: {
                          ...prev.privacy_and_policy,
                          link: e.target.value,
                        },
                      };
                    })
                  }
                  name="link"
                  label="privacy and policy link"
                />
              </div>

              <div className="d-flex flex-column justify-cotent-center align-items-center mt-3">
                <img
                  style={{ width: "15rem" }}
                  src={
                    inputs.local_image
                      ? inputs.local_image
                      : s3baseUrl + "/" + inputs.brand_favicon
                  }
                  alt=""
                />
                <label htmlFor="icon-button-file">
                  <Input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    name="brand_favicon"
                    onChange={handleImageUpload}
                  />
                  <Button
                    aria-label="upload picture"
                    component="span"
                    className="upload-button mt-3"
                  >
                    Brand FavIcon
                  </Button>
                </label>
              </div>

              <div className=" col-12 text-end mt-3">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}
