import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import { _admin_dashboard } from "src/DAL/login/login";
import { useAppContext } from "src/Hooks/AppContext";
import { s3baseUrl } from "src/config/config";
import { stringAvatar, stringToColor } from "src/utils/utils";
// import { useAppContext } from "src/Hooks/AppContext";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { dashboard } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const {user,setProfile} = useAppContext();

  const [waitingResponseCount, setWaitingResponseCount] = useState(
    dashboard?.support_ticket_count
  );
  const { user } = useAppContext();
  // const get_dashboard_data = async () => {
  //   const resp = await _admin_dashboard();

  //   if (resp.code == 200) {
  //     const _dashboard = resp.Dashboard;
  //     setWaitingResponseCount(_dashboard.support_ticket_count);
  //   } else {
  //     enqueueSnackbar(resp.message, { variant: "error" });
  //   }
  // };

  // useEffect(() => {
  //   get_dashboard_data();
  // }, [pathname]);

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2 }}>
        <AccountStyle>
          <Box
            sx={{ ml: 4, display: "flex", alignItems: "center", gap: "10px" }}
          >
            {/* <Avatar
              sx={{ width: 25, height: 25 }}
              src={`${s3baseUrl}/${user?.profile_image}`}
              alt="photoURL"
            /> */}

            {user?.profile_image !== "" ? (
              <Avatar
                sx={{ width: 25, height: 25 }}
                src={`${s3baseUrl}/${user?.profile_image}`}
                alt={user?.first_name.toUpperCase()}
              />
            ) : (
              <Avatar
                {...stringAvatar(user?.first_name)}
                sx={{
                  width: 25,
                  height: 25,
                  background: stringToColor(user?.first_name),
                }}
              />
            )}

            <Typography
              className="text-capitalize"
              variant="subtitle2"
              sx={{ color: "text.primary" }}
            >
              {user?.first_name + " " + user?.last_name}
            </Typography>
          </Box>
        </AccountStyle>
      </Box>

      <NavSection navConfig={sidebarConfig({ waitingResponseCount })} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Button fullWidth onClick={() => handleLogout()} variant="contained">
          Logout
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
