import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function StepsModal({
  open,
  setOpen,
  //   stepDataObj,
  //   setStepDataObj,
  rowIndex,
  RowData,
  setRowData,
  data,
  setData,
}) {
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    image: "",
    step_button: {
      text: "",
      link: "",
    },
  });
  console.log(RowData, "acasocjsocjlaskcj");
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleImageUpload = (e) => {
    setInputs((prev) => {
      return {
        ...prev,
        local_image: URL.createObjectURL(e.target.files[0]),
        image: e.target.files[0],
      };
    });
  };

  const handleEditData = (e) => {
    e.preventDefault();
    let array = data.steps;
    array[rowIndex] = inputs;
    setData({ ...data, steps: array });
    handleClose();
    // setDataObj()
  };

  useEffect(() => {
   
      setInputs({...RowData});
    
  }, [open]);

  const AddStepData = () => {
    let array = data.steps;
    console.log(array, "fusyafg");
    array.push(inputs);
    setData({ ...data, steps: array });
    // setStepDataObj([...stepDataObj, inputs]);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setRowData(null);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>{RowData ? "Edit Steps Data" : "Add Steps Data"}</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid>
            <>
              <div>
                <form className="mt-3">
                  <TextField
                    label="steps title"
                    required
                    name="title"
                    value={inputs.title}
                    style={{ width: "50%" }}
                    onChange={handleChange}
                  />

                  <p className="mt-3">Enter Step Description</p>
                  <GeneralCkeditor
                    inputs={inputs}
                    setInputs={setInputs}
                    value={inputs.description}
                    name="description"
                    onChange={handleChange}
                  />

                  <div className="d-flex gap-3 mt-3">
                    <TextField
                      label="Step Button Text"
                      name="text"
                      required
                      value={inputs?.step_button?.text}
                      style={{ width: "50%" }}
                      onChange={(e) =>
                        setInputs((prev) => {
                          return {
                            ...prev,
                            step_button: {
                              ...prev.step_button,
                              text: e.target.value,
                            },
                          };
                        })
                      }
                    />
                    <TextField
                      label="Step Button Link "
                      name="link"
                      required
                      value={inputs?.step_button?.link}
                      style={{ width: "50%" }}
                      onChange={(e) => {
                        setInputs((prev) => {
                          return {
                            ...prev,
                            step_button: {
                              ...prev.step_button,
                              link: e.target.value,
                            },
                          };
                        });
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center text-align-center mt-4">
                    <img
                      style={{ width: "15rem", height: "8rem" }}
                      src={
                        inputs.local_image
                          ? inputs?.local_image
                          : s3baseUrl + "/" + RowData?.image
                      }
                      alt=""
                    />
                    <label htmlFor="icon-button-file" className="mt-3">
                      <Input
                        style={{ display: "none" }}
                        accept="image/*"
                        id="icon-button-file"
                        type="file"
                        name="image"
                        onChange={handleImageUpload}
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-3"
                      >
                        {inputs.image
                          ? "Change Steps Image"
                          : "Upload Steps Image"}
                      </Button>
                    </label>
                  </div>
                  <div className="text-end">
                    {RowData ? (
                      <>
                        <Button variant="contained" type="submit" onClick={handleEditData}>
                          Edit Data
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button variant="contained" type="submit" onClick={AddStepData}>
                          Add Data
                        </Button>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
