import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { detail_project_for_admin } from "src/DAL/projects/projects";
import BackupPage from "./BackupPage";

import {
  Pages,
  People,
  Domain,
  CompareArrows,
  Payment,
  CreditCard,
} from "@mui/icons-material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

import Card from "@mui/material/Card";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Page from "./BackupPage";
import Project from "./Project";
import { _backup_project } from "src/DAL/ProjectDetails/projectdetails";
import PublishProject from "./PublishProject";
// import BackupPage from "./BackupPage";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BackupPublicProject = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [tabType, setTabType] = useState("page");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pageBackup = async () => {
    console.log(id, "idddddddd");
    const page_backup_response = await _backup_project(id);
    setData(page_backup_response.backup);
    setLoading(false);
    console.log(page_backup_response, "pagesbackuplis");

    console.log(data, "datattatat");
  };

  useEffect(() => {
    pageBackup();
  }, [id]);

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div style={{ padding: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton onClick={handleGoBack} aria-label="Go back">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">Backup Publish Projects</Typography>
        </Box>

        <Card sx={{ p: 2 }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {" "}
                <Tab label="Project" {...a11yProps(0)} />
                <Tab label="Page" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Typography variant="h5" sx={{ py: 1 }}>
                Project
              </Typography>
              <Project
                setData={setData}
                data={data?.filter((item) => item.type === "Project")}
                setLoading={setLoading}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography variant="h5" sx={{ py: 1 }}>
                Page
              </Typography>
              <BackupPage
                setData={setData}
                data={data?.filter((item) => item.type === "Page")}
                setLoading={setLoading}
                loading={loading}
              />
            </TabPanel>
          </Box>
        </Card>
      </div>
    </>
  );
};

export default BackupPublicProject;
