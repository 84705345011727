import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import plusFill from "@iconify/icons-eva/plus-fill";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomConfirmation from "src/components/CustomConfirmation";
import CustomPopover from "src/components/CustomPopover";
import { Visibility } from "@mui/icons-material";
import PreviewKey from "../Keys/Preview-Key/PreviewKey";
import AddKeysDrawer from "../Keys/Add-Keys-Drawer/AddKeysDrawer";
import { _delete_keys, _get_keys } from "src/DAL/Keys/keys";
import AddTestKey from "./AddTestKey";
import { UserListHead } from "src/components/_dashboard/user";

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "key", label: "Key", alignRight: false },
  { id: "value", label: "Value", alignRight: false },
  { id: "reveal_key", label: "Reveal Key", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "updatedAt", label: "Updated Ats", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

function KeysListing() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState([]);
  const [isPreviewKey, setIsPreviewKey] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  console.log(selectedRow, "seleecteedRowwwwwwwwwwwwwwwwwwwwwwwww");
  const [addKeysDrawer, setAddKeysDrawer] = useState(false);
  const [editKeysDrawer, setEditKeysDrawer] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleAddKeyDrawer = () => {
    setAddKeysDrawer(true);
  };

  const handleRevealkey = (row) => {
    setSelectedRow(row.row);
    setIsPreviewKey(true);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Edit Key",
      icon: "akar-icons:edit",
      //   handleClick: handleEditKeyDrawer,
    },
    {
      label: "Delete Key",
      icon: "ant-design:delete-twotone",
      //   handleClick: handleDeleteClick,
    },
  ]);

  const getKeys = async () => {
    setLoading(true);
    try {
      const response = await _get_keys();
      console.log(response);
      if (response.code === 200) {
        setLoading(false);
        setKeys(response.data);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    setLoadingBtn(true);
    console.log(selectedRow, "selectedRow");
    const keyToDelete = selectedRow?._id;
    try {
      const response = await _delete_keys(keyToDelete);
      if (response.code === 200) {
        setLoadingBtn(false);
        setOpenDelete(false);
        setKeys((prev) => {
          return prev.filter((item) => item._id !== keyToDelete);
        });
        enqueueSnackbar("Key deleted successfully", { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setLoadingBtn(false);
        console.log(response.message, "errorInKeys");
      }
    } catch (error) {
      setLoadingBtn(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getKeys();
  }, []);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4">Keys</Typography>
          {/* {(user.admin_type === "super_admin" ||
                rolePrivilegesObj?.add) && ( */}
          <Button
            onClick={handleAddKeyDrawer}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Key
          </Button>
          {/* )} */}
        </Stack>{" "}
        <Card>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer sx={{ minWidth: 700 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                {keys && keys.length > 0 ? (
                  keys.map((row, index) => {
                    return (
                      <TableBody>
                        <TableRow
                          key={row?._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell sx={{ maxWidth: 100 }} align="left">
                            <Typography noWrap>
                              {" "}
                              {row.key
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ maxWidth: 200 }}
                            align="left"
                            className="value-section"
                          >
                            <Typography noWrap>
                              {row.value.length > 12
                                ? `${row.value.substring(0, 7)}${"*".repeat(
                                    row.value.length - 12
                                  )}${row.value.substring(
                                    row.value.length - 5
                                  )}`
                                : row.value.length > 8
                                ? "*".repeat(row.value.length)
                                : "*".repeat(row.value.length)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ maxWidth: 100 }}
                            // align="center"
                            className="value-section"
                          >
                            <Typography>
                              <IconButton
                                onClick={() => {
                                  handleRevealkey({ row });
                                }}
                              >
                                <Visibility />
                              </IconButton>
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ maxWidth: 100 }}
                            align="left"
                            className="value-section"
                          >
                            <Typography>
                              {" "}
                              {moment(row.createdAt).format("LLL")}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ maxWidth: 100 }}
                            align="left"
                            className="value-section"
                          >
                            <Typography>
                              {moment(row.updatedAt).format("LLL")}
                            </Typography>
                          </TableCell>
                          {menuOptions.length > 0 ? (
                            <TableCell>
                              <CustomPopover data={row} menu={menuOptions} />
                            </TableCell>
                          ) : (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    );
                  })
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No Keys Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this key?"}
            action_name={selectedRow?.key
              ?.split("_")
              ?.map((word) => word?.charAt(0).toUpperCase() + word.slice(1))
              ?.join(" ")}
            handleAgree={handleDelete}
            loadingBtn={loadingBtn}
          />
          <AddTestKey
            addKeysDrawer={addKeysDrawer}
            setAddKeysDrawer={setAddKeysDrawer}
          />
        </Card>
      </Box>
    </>
  );
}

export default KeysListing;
