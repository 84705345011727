/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import plusFill from "@iconify/icons-eva/plus-fill";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import { _delete_keys, _get_keys } from "src/DAL/Keys/keys";
import { useSnackbar } from "notistack";
import PreviewKey from "../Preview-Key/PreviewKey";
import AddKeysDrawer from "../Add-Keys-Drawer/AddKeysDrawer";
import CustomPopover from "src/components/CustomPopover";
import EditKeysDrawer from "../Edit-Keys-Drawer/EditKeysDrawer";
import CustomConfirmation from "src/components/CustomConfirmation";

function Keys() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState([]);
  const [isPreviewKey, setIsPreviewKey] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  console.log(selectedRow, "seleecteedRowwwwwwwwwwwwwwwwwwwwwwwww");
  const [addKeysDrawer, setAddKeysDrawer] = useState(false);
  const [editKeysDrawer, setEditKeysDrawer] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  console.log(selectedKeys, "selecteddKeysss");

  const handleAddKeyDrawer = () => {
    setAddKeysDrawer(true);
  };

  const handleCloseAddDrawer = () => {
    setAddKeysDrawer(false);
  };

  const handleOpenEdit = () => {
    setEditKeysDrawer(true);
  };

  const handleEditKeyDrawer = (row) => {
    handleOpenEdit();
    setSelectedRow(row);
    console.log(row, "rooowwwwwwwwww");
  };

  const handleCloseEditDrawer = () => {
    setEditKeysDrawer(false);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Edit Key",
      icon: "akar-icons:edit",
      handleClick: handleEditKeyDrawer,
    },
    {
      label: "Delete Key",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ]);

  const getKeys = async () => {
    setLoading(true);
    try {
      const response = await _get_keys();
      console.log(response);
      if (response.code === 200) {
        setLoading(false);
        setKeys(response.data);
        setSelectedKeys(response.data.map((key) => key.key));
        let storedKeys = JSON.parse(localStorage.getItem("selectedKeys"));
        if (!storedKeys) {
          storedKeys = response.data.map((key) => key.key);
          localStorage.setItem("selectedKeys", JSON.stringify(storedKeys));
        }
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    setLoadingBtn(true);
    console.log(selectedRow, "selectedRow");
    const keyToDelete = selectedRow?._id;
    try {
      const response = await _delete_keys(keyToDelete);
      if (response.code === 200) {
        setLoadingBtn(false);
        setOpenDelete(false);
        setKeys((prev) => {
          return prev.filter((item) => item._id !== keyToDelete);
        });
        setSelectedKeys((prevKeys) =>
          prevKeys.filter((key) => key !== selectedRow?.key)
        );
        const storedKeys =
          JSON.parse(localStorage.getItem("selectedKeys")) || [];
        const updatedKeys = storedKeys.filter((key) => key !== selectedRow.key);
        localStorage.setItem("selectedKeys", JSON.stringify(updatedKeys));
        enqueueSnackbar("Key deleted successfully", { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setLoadingBtn(false);
        console.log(response.message, "errorInKeys");
      }
    } catch (error) {
      setLoadingBtn(false);
      console.error(error);
    }
  };

  const handleRevealkey = (row) => {
    setSelectedRow(row.row);
    setIsPreviewKey(true);
  };

  useEffect(() => {
    getKeys();
  }, []);

  return (
    <>
      <PreviewKey
        open={isPreviewKey}
        setIsPreviewKey={setIsPreviewKey}
        data={selectedRow}
      />
      <AddKeysDrawer
        addKeysDrawer={addKeysDrawer}
        setAddKeysDrawer={setAddKeysDrawer}
        getKeys={getKeys}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
      />
      <EditKeysDrawer
        editKeysDrawer={editKeysDrawer}
        setEditKeysDrawer={setEditKeysDrawer}
        selectedRow={selectedRow}
        getKeys={getKeys}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this key?"}
        action_name={selectedRow?.key
          ?.split("_")
          ?.map((word) => word?.charAt(0).toUpperCase() + word.slice(1))
          ?.join(" ")}
        handleAgree={handleDelete}
        loadingBtn={loadingBtn}
      />
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4">Keys</Typography>
          {/* {(user.admin_type === "super_admin" ||
                rolePrivilegesObj?.add) && ( */}
          <Button
            onClick={handleAddKeyDrawer}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Key
          </Button>
          {/* )} */}
        </Stack>{" "}
        {loading ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "500px",
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <Card>
              <TableContainer style={{ overflowX: "auto" }}>
                <Table
                  sx={{ minWidth: 750 }}
                  size="large"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className="name-section"
                      >
                        Key
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className="name-section"
                      >
                        Value
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className="name-section"
                      >
                        Reveal Key
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className="name-section"
                      >
                        Created At
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        className="name-section"
                      >
                        Updated At
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        className="name-section"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {keys && keys.length > 0 ? (
                    keys.map((row, index) => {
                      return (
                        <TableBody>
                          <TableRow
                            hover
                            key={row._id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell className="pl-0">{index + 1}</TableCell>
                            <TableCell
                              className="pl-0"
                              sx={{ maxWidth: 100 }}
                              align="left"
                            >
                              {" "}
                              {row.key
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}
                            </TableCell>
                            <TableCell
                              className="pl-0"
                              sx={{ maxWidth: 200 }}
                              align="left"
                            >
                              <Typography noWrap>
                                {row.value.length > 12
                                  ? `${row.value.substring(0, 7)}${"*".repeat(
                                      row.value.length - 12
                                    )}${row.value.substring(
                                      row.value.length - 5
                                    )}`
                                  : row.value.length > 8
                                  ? "*".repeat(row.value.length)
                                  : "*".repeat(row.value.length)}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className="pl-0"
                              sx={{ maxWidth: 100 }}
                              align="center"
                            >
                              <Typography noWrap>
                                <IconButton
                                  onClick={() => {
                                    handleRevealkey({ row });
                                  }}
                                >
                                  <Visibility />
                                </IconButton>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className="pl-0"
                              sx={{ maxWidth: 150 }}
                              align="left"
                            >
                              {" "}
                              {moment(row.createdAt).format("LLL")}
                            </TableCell>
                            <TableCell
                              className="pl-0"
                              sx={{ maxWidth: 150 }}
                              align="left"
                            >
                              {moment(row.updatedAt).format("LLL")}
                            </TableCell>
                            {menuOptions.length > 0 ? (
                              <TableCell className="pl-0">
                                <CustomPopover data={row} menu={menuOptions} />
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      );
                    })
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={15} align="center">
                          No Keys Found
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Card>
          </>
        )}
      </Box>
    </>
  );
}

export default Keys;
