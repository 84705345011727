/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import {
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Scrollbar from "src/components/Scrollbar";
import closeFill from "@iconify/icons-eva/close-fill";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { _plan_list } from "src/DAL/plans/plan";
import moment from "moment";
import { _renew_subscription } from "src/DAL/Subscription/Subscription";
import DatePickerField from "src/components/DatePicker/Datepicker";

function RenewSubscription({
  selectedRow,
  selectedIndex,
  handleCloseRenew,
  isDrawerOpenRenew,
  subscriptionListing,
}) {
  const formatDate = (dateString) => {
    // console.log(dateString, "dateeee");
    return moment(dateString).format("YYYY-MM-DD");
  };
  // const formatDate = (dateString) => {
  //   return new Date(dateString);
  // };

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [inputs, setInputs] = useState({
    plan_id: "",
    amount: "",
    subscription_end_date: "",
    grace_period_days: "",
    user_id: "",
    transaction_status: true,
  });
  // console.log(inputs, "renewInputsss");

  const customer_name = selectedRow?.customer_obj
    ? selectedRow?.customer_obj?.first_name +
      " " +
      selectedRow?.customer_obj?.last_name
    : "-";
  const email = selectedRow?.user_id ? selectedRow?.user_id?.email : "-";

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeStatus = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: !inputs[e.target.name],
    });
  };

  const getPlans = async () => {
    const response = await _plan_list();
    if (response.code === 200) {
      setPlanList(response.data);
      // console.log(response.data, "planList");
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
      });
    }
  };

  const handleSubmit = async () => {
    const id = selectedRow?._id;
    const postData = {
      plan_id: inputs.plan_id,
      amount: inputs.amount,
      subscription_end_date: moment(inputs.subscription_end_date).format(
        "MM-DD-YYYY"
      ),
      grace_period_days: inputs.grace_period_days,
      user_id: selectedRow?.user_id?._id,
      transaction_status: inputs.transaction_status,
    };
    setLoading(true);
    const response = await _renew_subscription(id, postData);
    if (response.code === 200) {
      setLoading(false);
      setInputs({
        ...inputs,
        plan_id: inputs.plan_id,
        amount: inputs.amount,
        // subscription_end_date: moment(inputs.subscription_end_date).format(
        //   "MM-DD-YYYY"
        // ),
        subscription_end_date: inputs.subscription_end_date,
        grace_period_days: inputs.grace_period_days,
        user_id: inputs.user_id,
        transaction_status: inputs.transaction_status,
      });
      // console.log(inputs, "renewInputss");
      subscriptionListing();
      handleCloseRenew();
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      setLoading(false);
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, "errorInRenewSubs");
    }
  };

  const getCurrencySymbol = (locale, currency) => {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  const options = [
    { value: 1, label: "day" },
    { value: 3, label: "days" },
    { value: 5, label: "days" },
    { value: 7, label: "days" },
    { value: 9, label: "days" },
    { value: 11, label: "days" },
    { value: 13, label: "days" },
  ];

  useEffect(() => {
    getPlans();
    setInputs({
      plan_id: selectedRow?.plan_obj?._id,
      amount: selectedRow?.amount,
      subscription_end_date: formatDate(selectedRow?.subscription_end_date),
      grace_period_days: selectedRow?.grace_period_days,
      transaction_status: true,
    });
  }, [isDrawerOpenRenew]);

  return (
    <>
      <Drawer
        anchor="right"
        open={isDrawerOpenRenew}
        onClose={handleCloseRenew}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Renew Subscription
          </Typography>
          <IconButton onClick={handleCloseRenew}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>

        <Divider />
        <Scrollbar>
          <div style={{ padding: "13px" }}>
            <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.8 }}>
              Customer Detail
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
                // padding: "10px",
              }}
            >
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.5, mr: 1 }}>
                  Name:
                </Typography>
                {customer_name}
              </div>
              <div className="d-flex">
                <Typography variant="subtitle1" sx={{ ml: 1, mb: 0.3, mr: 1 }}>
                  Email:
                </Typography>
                {email}
              </div>
            </div>
          </div>

          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Stack spacing={3} sx={{ p: 3 }}>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Choose a plan*
                </InputLabel>
                <Select
                  required
                  label="Choose a plan"
                  name="plan_id"
                  value={inputs.plan_id}
                  onChange={handleChange}
                >
                  {planList
                    .filter((plan) => plan.amount > 0 && plan.status)
                    .map((plan) => (
                      <MenuItem key={plan?._id} value={plan?._id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{plan.product_name}</div>
                          <div>
                            {getCurrencySymbol("en-US", plan.currency)}
                            {plan.amount}
                            {`/${plan.interval_time}`}
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <TextField
                  required
                  fullWidth
                  label="Amount"
                  name="amount"
                  value={inputs.amount}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="End Date"
                  type="date"
                  defaultValue={new Date()}
                  value={inputs.subscription_end_date}
                  onChange={handleChange}
                  name="subscription_end_date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: inputs.subscription_end_date }}
                />
              </FormControl>
              {/* <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <DatePickerField
                  label="End Date/Time"
                  name="subscription_end_date"
                  value={
                    inputs?.subscription_end_date ?? new Date().toISOString()
                  }
                  onChange={(value) => {
                    setInputs({ ...inputs, subscription_end_date: value });
                  }}
                  required
                />
              </FormControl> */}
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Grace Period Days*
                </InputLabel>
                <Select
                  value={inputs.grace_period_days}
                  isMulti
                  required
                  label="Grace Period Days"
                  name="grace_period_days"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handleChange}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      minHeight: 50,
                    }),
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {`${option.value} ${option.label}`}
                    </MenuItem>
                  ))}
                </Select>
                <p
                  style={{
                    color: "gray",
                    padding: "10px 10px 0px 10px ",
                    marginBottom: "0px",
                  }}
                >
                  ( The grace period starts after the end date. During this
                  grace period, the account functionality remains the same. At
                  that time user can pay again without losing any data or
                  access.)
                </p>
              </FormControl>
              <FormControlLabel
                sx={{ marginTop: "0px" }}
                className="justify-content-between"
                value="start"
                control={
                  <Checkbox
                    defaultChecked
                    sx={{ marginTop: "0px" }}
                    checked={inputs.transaction_status}
                    value={inputs.transaction_status}
                    onChange={handleChangeStatus}
                    color="primary"
                    name="transaction_status"
                  />
                }
                label="Do you want to create transaction?"
                labelPlacement="start"
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
              >
                Renew Subscription
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

export default RenewSubscription;
