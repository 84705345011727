import { invokeApi } from "src/bl_libs/invokeApi";


export const _update_admin_password = async (data) => {
    let requestObj = {
      path: `api/app_api/change_password`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };



  export const _update_admin_info = async (data) => {
    let requestObj = {
      path: `api/admin/edit_admin`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/formdata",
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };



  export const _admin_details = async () => {
    let requestObj = {
      path: `api/admin/detail_admin`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      // postData: postData,
    };
    return invokeApi(requestObj);
  };



