import { invokeApi } from "src/bl_libs/invokeApi";

export const _everything_you_need = (data) => {
  const req_obj = {
    method: "POST",
    path: "api/landing_page/everything_you_need",
    headers: { "x-sh-auth": localStorage.getItem("token") },
    postData: data,
  };
  return invokeApi(req_obj);
};
