/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// import { Link as  useNavigate } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Box,
  Tooltip,
} from "@mui/material";
// components
import Label from "../../../components/Label";
import moment from "moment";
import { UserListHead } from "../../../components/_dashboard/user";
import { useSnackbar } from "notistack";
import Loader from "src/components/Loader/Loader";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
// import UpdateHelpingCategory from "../Update-Support-Department/UpdateSupportDepartment";
// import AddCustomer from "../Add-Support-Department/AddSupportDepartment";
import { _plan_list, _plan_list_dell } from "src/DAL/plans/plan";
import { formatSizeUnits } from "src/utils/utils";
import { useAppContext } from "src/Hooks/AppContext";
import DetailModel from "../../Detail-Section/Detail-Section";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "" },
  { id: "product_name", label: "Plan Name", alignRight: false },
  {
    id: "product_description",
    label: "Plan Subtitle",
    alignRight: false,
  },
  { id: "currency", label: "Currency", alignRight: false },
  { id: "amount", label: "Plan Amount", alignRight: false },
  { id: "interval_time", label: "Time Interval", alignRight: false },
  { id: "free_trial_status", label: "Free Trial Status", alignRight: false },
  {
    id: "free_trial_days",
    label: "Allowed Free Trial Days",
    alignRight: false,
  },
  {
    id: "notice_before_free_trial_end",
    label: "Notice Before Ending Trial",
    alignRight: false,
  },
  {
    id: "free_trial_grace_period_days",
    label: "Grace Period Days",
    alignRight: false,
  },
  { id: "status", label: "Plan Status", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      return (
        _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.short_description.toLowerCase().indexOf(query.toLowerCase()) !==
          -1
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Listing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { user, getObjOfPrivileges, subAdminRole } = useAppContext();
  const [rolePrivilegesObj, setRolePrivilegesObj] = useState({});

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenAdd, setIsDrawerOpenAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectRowDetail, setSelectRowDetail] = useState([]);
  const [rowData, setRowData] = useState();
  const [openPlanModel, setOpenPlanModel] = useState(false);

  const [product, setProduct] = useState({});

  // const handleOpenChangePassword = () => {
  //   setIsDrawerOpen(true);
  // };

  // const handleCloseChangePassword = () => {
  //   setIsDrawerOpen(false);
  // };

  // const handleOpenAdd = () => {
  //   setIsDrawerOpenAdd(true);
  // };

  const handleOpenAdd = () => {
    navigate("/plans/add-plans");
  };

  const handleOpenEdit = (row) => {
    navigate(`/plans/edit-plans/${row._id}`, {
      state: row,
    });
  };

  // const handleCloseAdd = () => {
  //   setIsDrawerOpenAdd(false);
  // };

  const handleDelete = async () => {
    console.log(selectedRow);
    setIsLoadingDelete(true);
    const delete_resp = await _plan_list_dell(selectedRow._id);
    if (delete_resp.code == 200) {
      setOpenDelete(false);
      setIsLoadingDelete(false);
      enqueueSnackbar("Plan deleted successfully", { variant: "success" });
      // get_customer_list(page, rowsPerPage);
      handleRefresh();
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
      setIsLoadingDelete(false);
    }
  };

  // const handleUpdatePasswordClick = (row) => {
  //   setSelectedRow(row);
  //   handleOpenChangePassword();
  // };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDelete(true);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Edit Plan",
      icon: "akar-icons:edit",
      handleClick: handleOpenEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ]);

  const handleRefresh = () => {
    fetchProducts(page, rowsPerPage);
  };

  const fetchProducts = async () => {
    const resp = await _plan_list();
    if (resp.code == 200) {
      setProduct([...resp.data]);
      setData([...resp.data]);
      setIsLoading(false);
      console.log(resp.data, "DataDataDataDataDataDataDataData plan list");
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const bgColor = (val) => {
    return (
      <div>
        <Label className="bg">{val}</Label>
      </div>
    );
  };

  const DomainFunc = (val) => {
    return (
      <>
        <Label
          color={
            selectRowDetail.custom_domain_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const StatusFunc = (val) => {
    return (
      <>
        <Label color={selectRowDetail.status === true ? "success" : "error"}>
          {val}
        </Label>
      </>
    );
  };

  const StripeStatusFunc = (val) => {
    return (
      <>
        <Label
          color={
            selectRowDetail.stripe_payment_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const FreeTrialStatusFunc = (val) => {
    return (
      <>
        <Label
          color={
            selectRowDetail.free_trial_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const BookaCallStatus = (val) => {
    return (
      <>
        <Label
          color={
            selectRowDetail.book_a_call_status === true ? "success" : "error"
          }
        >
          {val}
        </Label>
      </>
    );
  };

  const FunnelStatus = (val) => {
    return (
      <>
        <Label
          color={selectRowDetail.funnel_status === true ? "success" : "error"}
        >
          {val}
        </Label>
      </>
    );
  };

  useEffect(() => {
    if (selectRowDetail) {
      setRowData([
        {
          name: "Plan Name",
          value: selectRowDetail.product_name
            ? selectRowDetail.product_name
            : "-",
        },
        {
          name: "Plan Subtitle",
          value: selectRowDetail.product_description
            ? selectRowDetail.product_description
            : "-",
        },
        {
          name: "Currency",
          value: selectRowDetail.currency ? (
            selectRowDetail.currency.toUpperCase()
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Plan Amount",
          value: selectRowDetail.amount ? selectRowDetail.amount : "-",
        },
        {
          name: "Interval Time",
          value:
            selectRowDetail.interval_time === "day"
              ? "Daily"
              : selectRowDetail.interval_time === "month"
              ? "Monthly"
              : selectRowDetail.interval_time === "year"
              ? "Yearly"
              : "-",
        },
        {
          name: "Plan Status",
          value: StatusFunc(
            selectRowDetail.status === 0 ? "InActive" : "Active"
          ),
        },
        {
          name: "Free Trail Status",
          value: FreeTrialStatusFunc(
            selectRowDetail.free_trial_status === true ? "Yes" : "No"
          ),
        },
        {
          name: "Free Trial Days",
          value: selectRowDetail.free_trial_days
            ? bgColor(selectRowDetail.free_trial_days)
            : "-",
        },
        {
          name: "Notice Before Ending Trial",
          value: selectRowDetail.notice_before_free_trial_end
            ? bgColor(selectRowDetail.notice_before_free_trial_end)
            : "-",
        },
        {
          name: "Grace Period Days",
          value: selectRowDetail.free_trial_grace_period_days
            ? bgColor(selectRowDetail.free_trial_grace_period_days)
            : "-",
        },
        {
          name: "Allowed Assert Size",
          value:
            (selectRowDetail.assert_size / 1073741824).toFixed(0) +
            "    GB " +
            "  " +
            "    -    " +
            (selectRowDetail.assert_size / 1048576).toFixed(0) +
            "  MB" +
            "  " +
            "    -    " +
            (selectRowDetail.assert_size / 1024).toFixed(0) +
            "   KB",
        },
        {
          name: "Allowed Projects",
          value: selectRowDetail.projects_count
            ? bgColor(selectRowDetail.projects_count)
            : "-",
        },
        {
          name: "Allowed Projects Version",
          value: selectRowDetail.projects_version_count
            ? bgColor(selectRowDetail.projects_version_count)
            : "-",
        },

        {
          name: "Allowed Pages",
          value: selectRowDetail.pages_count
            ? bgColor(selectRowDetail.pages_count)
            : "-",
        },
        {
          name: "Allowed Pages Version",
          value: selectRowDetail.pages_version_count
            ? bgColor(selectRowDetail.pages_version_count)
            : "-",
        },
        {
          name: "Allowed AI Token",
          value: selectRowDetail.allowed_ai_token
            ? bgColor(selectRowDetail.allowed_ai_token)
            : "-",
        },
        {
          name: "Allowed Pixel Request",
          value: selectRowDetail.allowed_pexel_requests
            ? bgColor(selectRowDetail.allowed_pexel_requests)
            : "-",
        },
        {
          name: "Allowed Paid Templates",
          value: selectRowDetail.paid_templates_for_free
            ? bgColor(selectRowDetail.paid_templates_for_free)
            : "-",
        },
        {
          name: "Allowed Subscribers",
          value: selectRowDetail.allowed_subscriber
            ? bgColor(selectRowDetail.allowed_subscriber)
            : "-",
        },
        {
          name: "Book a Call Status",
          value: BookaCallStatus(
            selectRowDetail.book_a_call_status === true ? "Yes" : "No"
          ),
        },
        {
          name: "Funnel Status",
          value: FunnelStatus(
            selectRowDetail.funnel_status === true ? "Yes" : "No"
          ),
        },
        {
          name: "Custome Domain Status",
          value: DomainFunc(
            selectRowDetail.custom_domain_status === true ? "Yes" : "No"
          ),
        },
        {
          name: "Stripe Payment Status",
          value: StripeStatusFunc(
            selectRowDetail.stripe_payment_status === true ? "Yes" : "No"
          ),
        },
        { name: "Order", value: bgColor(selectRowDetail.order) },
        {
          name: " Product Id",
          value: selectRowDetail.product_id ? selectRowDetail.product_id : "-",
        },
        {
          name: " Plan Id",
          value: selectRowDetail.plan_id ? selectRowDetail.plan_id : "-",
        },
        {
          name: "Created At",
          value: selectRowDetail.createdAt
            ? moment(selectRowDetail.createdAt).format("LLL")
            : "-",
        },
        {
          name: "Updated At",
          value: selectRowDetail.updatedAt
            ? moment(selectRowDetail.updatedAt).format("LLL")
            : "-",
        },
      ]);
    }
  }, [selectRowDetail]);

  const handlePlansDetail = (row) => {
    setSelectRowDetail(row.row);
    setOpenPlanModel(true);
  };

  useEffect(() => {
    if (user && user.admin_type === "sub_admin" && subAdminRole.roles) {
      let obj = getObjOfPrivileges("Plans");
      setRolePrivilegesObj(obj);
      let _array = menuOptions;
      if (!obj.edit) {
        _array = [..._array.filter((option) => option.label != "Edit Plan")];
      }
      if (!obj.delete) {
        _array = [..._array.filter((option) => option.label != "Delete")];
      }

      setMenuOptions([..._array]);
    }
  }, [subAdminRole]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DetailModel
        open={openPlanModel}
        setOpen={setOpenPlanModel}
        detail={rowData}
      />
      <Box sx={{ p: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h4">Plans</Typography>
          {(user.admin_type === "super_admin" || rolePrivilegesObj?.add) && (
            <Button
              onClick={handleOpenAdd}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Plan
            </Button>
          )}
        </Stack>

        <Card>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
          /> */}

          <>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
              >
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={data.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {data.map((row, i) => {
                    const {
                      _id,
                      product_name,
                      product_description,
                      amount,
                      currency,
                      interval_time,
                      domains_count,
                      assert_size,
                      pages_version_count,
                      pages_count,
                      projects_version_count,
                      projects_count,
                      custom_domain_status,
                      status,
                      allowed_ai_token,
                      allowed_pexel_requests,
                      paid_templates_for_free,
                      allowed_subscriber,
                      allowed_book_a_call,
                      allowed_funnel,
                      stripe_payment_status,
                      free_trial_status,
                      free_trial_days,
                      notice_before_free_trial_end,
                      free_trial_grace_period_days,
                    } = row;

                    // function formatSizeUnits(bytes) {
                    //   if (bytes >= 1073741824) {
                    //     bytes = (bytes / 1073741824).toFixed(0) + " GB";
                    //   } else if (bytes >= 1048576) {
                    //     bytes = (bytes / 1048576).toFixed(0) + " MB";
                    //   } else if (bytes >= 1024) {
                    //     bytes = (bytes / 1024).toFixed(0) + " KB";
                    //   } else if (bytes > 1) {
                    //     bytes = bytes + " bytes";
                    //   } else if (bytes == 1) {
                    //     bytes = bytes + " byte";
                    //   } else {
                    //     bytes = "0 bytes";
                    //   }
                    //   return bytes;
                    // }
                    return (
                      <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                        <TableCell>{i + 1}</TableCell>
                        {menuOptions.length > 0 ? (
                          <TableCell align="right">
                            <CustomPopover data={row} menu={menuOptions} />
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography
                              className="text-capitalize"
                              variant="subtitle2"
                              noWrap
                              style={{ cursor: "pointer" }}
                              onClick={() => handlePlansDetail({ row, i })}
                            >
                              {product_name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell sx={{ maxWidth: 150 }}>
                          <Tooltip
                            title={
                              product_description.charAt(0).toUpperCase() +
                              product_description.slice(1)
                            }
                          >
                            <Typography variant="body2" noWrap>
                              {product_description.charAt(0).toUpperCase() +
                                product_description.slice(1)}
                            </Typography>
                          </Tooltip>
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {currency}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {amount}
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          {interval_time === "day"
                            ? "Daily"
                            : interval_time === "month"
                            ? "Monthly"
                            : "Yearly"}
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label
                            variant="ghost"
                            color={free_trial_status ? "success" : "error"}
                          >
                            {free_trial_status ? "Yes" : "No"}
                          </Label>
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label variant="filled" color="warning">
                            {free_trial_days ? free_trial_days : "0"}
                          </Label>
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label variant="filled" color="warning">
                            {notice_before_free_trial_end
                              ? notice_before_free_trial_end
                              : "0"}
                          </Label>
                        </TableCell>

                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label variant="filled" color="warning">
                            {free_trial_grace_period_days
                              ? free_trial_grace_period_days
                              : "0"}
                          </Label>
                        </TableCell>

                        <TableCell className="pl-0" align="left">
                          <Label
                            variant="ghost"
                            color={status ? "success" : "error"}
                          >
                            {status ? "Active" : "Inactive"}
                          </Label>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomConfirmation
              open={openDelete}
              setOpen={setOpenDelete}
              title={"Are you sure you want to delete this Plan?"}
              action_name={
                selectedRow?.product_name?.charAt(0).toUpperCase() +
                selectedRow?.product_name?.slice(1)
              }
              handleAgree={handleDelete}
              loadingBtn={isLoadingDelete}
            />
          </>

          {/* <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Box>
      {/* <UpdatePlans
        isOpenDrawer={isDrawerOpen}
        onOpenDrawer={handleOpenChangePassword}
        onCloseDrawer={handleCloseChangePassword}
        selectedRow={selectedRow}
        handleRefresh={handleRefresh}
      /> */}

      {/* <AddPlans
        isOpenDrawer={isDrawerOpenAdd}
        onOpenDrawer={handleOpenAdd}
        onCloseDrawer={handleCloseAdd}
        handleRefresh={handleRefresh}
      /> */}
    </>
  );
}
