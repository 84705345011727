import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";

export default function SiteDescriptionModal({
  open,
  setOpen,
  stepData,
  setStepData,
  setEditData,
  editData,

  dataIndex,
}) {
  //   const handleClose = () => setOpen(false);
  const handleClose = () => {
    setEditData({ description: "" });
    setOpen(false);
  };
  const [descriptionObj, setDescriptionObj] = useState({
    description: "",
  });

  //   console.log(editData,"stydfastyf")
  const handledescriptionChange = (e) => {
    setDescriptionObj((prev) => {
      return { ...prev, description: e.target.value };
    });
  };

  const handleEdit = () => {
    let array = stepData?.steps;
    array[dataIndex] = descriptionObj;

    setStepData({ ...stepData, steps: array });
    handleClose();
  };

  useEffect(() => {
    if (editData) {
      setDescriptionObj(editData);
    }
  }, [editData]);

  const handleAddDescription = () => {
    let array = stepData?.steps;
    array.push(descriptionObj);
    setStepData({ ...stepData, steps: array });
    setDescriptionObj({ description: "" });
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="lg"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>{editData?.description!== "" ? "Edit Site Description" : "Site Description"}</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid className="mt-3">
            <p>Enter Description</p>
            <GeneralCkeditor
              inputs={descriptionObj}
              name="description"
              value={descriptionObj?.description}
              setInputs={setDescriptionObj}
              onChange={handledescriptionChange}
            />
          </Grid>

          <div className="text-end mt-3">
            {editData?.description !== "" ? (
              <Button variant="contained" onClick={handleEdit}>
                Edit
              </Button>
            ) : (
              <Button variant="contained" onClick={handleAddDescription}>
                Add
              </Button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
