/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Close } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { templates_group_pages } from "src/DAL/Templates/templates";
import Label from "src/components/Label";
import { s3baseUrl, sourceS3BaseUrl } from "src/config/config";

function TemplateGroupDialog({
  selectedRow,
  openTempGroupDetailModal,
  handleCloseTempGrpDetail,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [templateGroupPages, setTemplateGroupPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);

  const currentuserTimezone = moment.tz.guess();

  const temName = selectedRow?.name;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleDoubleClick = (id) => {
    console.log(id, "iidddddddddd");
    navigator.clipboard.writeText(id);
    enqueueSnackbar("ID copied to clipboard", { variant: "success" });
  };

  const templates_group_pages_listing = async () => {
    const tempId = selectedRow?._id;
    console.log(tempId, "templateeIddd");
    setLoading(true);
    const response = await templates_group_pages(tempId);
    console.log(response, "templatessGroupPagesLIstingggggggggg");
    if (response.code === 200) {
      setLoading(false);
      setTemplateGroupPages(response.data);
      setTotalCount(response.data.total_count);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (selectedRow._id) {
      console.log(selectedRow._id, "sellectedRowIdd");
      templates_group_pages_listing();
    }
  }, [selectedRow._id]);

  return (
    <Dialog
      open={openTempGroupDetailModal}
      onClose={handleCloseTempGrpDetail}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      size="large"
    >
      <div
        style={{
          padding: "1% 3% 2% 3%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{temName}</Typography>
        <IconButton
          onClick={handleCloseTempGrpDetail}
          style={{ color: "grey" }}
        >
          <Close />
        </IconButton>
      </div>
      <Card sx={{ margin: "0.5% 2% 3% 2%" }}>
        <TableContainer style={{ padding: "0px 2% 0px 2%" }}>
          <Table>
            <TableRow>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  #
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Page Group Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Project Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Page Group
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Total Pages
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Page Group Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Choosed Date/Time
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Project Id
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  noWrap
                  className="text-capitalize"
                  variant="subtitle2"
                >
                  Template Group Id
                </Typography>
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>
                <Typography className="text-capitalize" variant="subtitle2">
                  Customer Name
                </Typography>
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>
                <Typography className="text-capitalize" variant="subtitle2">
                  Customer Type
                </Typography>
              </TableCell>
              <TableCell style={{ whiteSpace: "nowrap" }}>
                <Typography className="text-capitalize" variant="subtitle2">
                  Customer Email
                </Typography>
              </TableCell>
            </TableRow>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {templateGroupPages?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <Typography variant="subtitle2">
                        No Template Group Pages & Projects List Found
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  templateGroupPages?.map((row, i) => {
                    const {
                      _id,
                      name,
                      page_group_type,
                      user,
                      page_name,
                      pages_number,
                      image,
                      project,
                      project_id,
                      user_id,
                      template_group_id,
                      template_choose_datetime,
                    } = row;
                    console.log(row, "projectslisting");
                    return (
                      <TableRow key={_id} tabIndex={-1}>
                        <TableCell> {i + 1}</TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {" "}
                          {name
                            ? name?.charAt(0).toUpperCase() + name?.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {" "}
                          {project_id
                            ? project_id?.project_name.charAt(0).toUpperCase() +
                              project_id?.project_name.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              maxWidth: "330px",
                              height: "120px",
                              overflowX: "scroll",
                            }}
                          >
                            {row.pages.map((page) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginInlineEnd: "10px",
                                  cursor: "pointer",
                                  //   width: "100px",
                                  //   height: "100px",
                                }}
                                onClick={() => {
                                  console.log(
                                    row?.project_id?._id,
                                    "reooowwwIdd"
                                  );
                                  window.open(
                                    `${s3baseUrl}/${row?.project_id?._id}/${page?.page_slug}.html`
                                  );
                                }}
                              >
                                <img
                                  onError={(e) => {
                                    if (
                                      e.target.src ===
                                      sourceS3BaseUrl + page?.image
                                    ) {
                                      e.target.src =
                                        s3baseUrl + "/" + page?.image;
                                    }
                                    // console.log(
                                    //   e.target.src,
                                    //   "soourceeeeeeeUrl"
                                    // );
                                    // console.log(page.image, "pageeImagee");
                                  }}
                                  src={`${sourceS3BaseUrl}${page?.image}`}
                                  height={"70px"}
                                  width={"100px"}
                                />
                                <span
                                  style={{
                                    textOverflow: "ellipsis",
                                    width: "100px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    marginTop: "4px",
                                  }}
                                >
                                  {page ? page?.page_name : "-"}
                                </span>
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{ whiteSpace: "nowrap" }}
                          align="center"
                        >
                          {pages_number ? pages_number : "-"}
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {page_group_type
                            ? page_group_type?.charAt(0).toUpperCase() +
                              page_group_type?.slice(1)
                            : "-"}
                        </TableCell>

                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {template_choose_datetime
                            ? moment
                                .tz(template_choose_datetime, "UTC")
                                .tz(currentuserTimezone)
                                .format("LLL")
                            : "-"}
                        </TableCell>
                        <TableCell
                          style={{ whiteSpace: "nowrap" }}
                          onDoubleClick={() => {
                            navigator.clipboard.writeText(project_id?._id);
                            enqueueSnackbar("ID copied to clipboard", {
                              variant: "success",
                            });
                          }}
                        >
                          {project_id ? project_id?._id : "-"}
                          {console.log(
                            project_id?._id,
                            "idddddddddddddddddddddddddddd"
                          )}
                        </TableCell>
                        <TableCell
                          style={{ whiteSpace: "nowrap" }}
                          onDoubleClick={() => {
                            navigator.clipboard.writeText(template_group_id);
                            enqueueSnackbar("ID copied to clipboard", {
                              variant: "success",
                            });
                          }}
                        >
                          {template_group_id}
                          {console.log(
                            template_group_id,
                            "idddddddddddddddddddddddddddd"
                          )}
                        </TableCell>

                        <TableCell style={{ whiteSpace: "nowrap" }}>
                          {project_id?.user
                            ? project_id?.user?.first_name
                                .charAt(0)
                                .toUpperCase() +
                              project_id?.user?.first_name.slice(1) +
                              " " +
                              project_id?.user?.last_name
                                .charAt(0)
                                .toUpperCase() +
                              project_id?.user?.last_name.slice(1)
                            : "-"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <Label
                            variant="ghost"
                            color={
                              (project_id?.user?.user_type === 1 &&
                                "success") ||
                              (project_id?.user?.user_type === 3 &&
                                "primary") ||
                              "default"
                            }
                          >
                            {project_id?.user?.user_type === 1
                              ? "Inovate"
                              : project_id?.user?.user_type === 3
                              ? "Vission"
                              : "-"}
                          </Label>
                        </TableCell>
                        <TableCell>{user_id ? user_id?.email : "-"}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
    </Dialog>
  );
}

export default TemplateGroupDialog;
