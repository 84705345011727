/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Label from "src/components/Label";
import SearchNotFound from "src/components/SearchNotFound";
import { UserListHead } from "src/components/_dashboard/user";
import LayersIcon from "@mui/icons-material/Layers";
import { s3baseUrl, sourceS3BaseUrl } from "src/config/config";
import { _trending_templates_group } from "src/DAL/Templates/templates";

const TABLE_HEAD = [
  { id: "", label: "#", alignRight: false },
  { id: "name", label: "Group Name", alignRight: false },
  //   { id: "group_type", label: "Group Type", alignRight: false },
  { id: "use_count", label: "Usage Count", alignRight: false },
  { id: "is_published", label: "Published", alignRight: false },
  { id: "is_paid", label: "Is Paid", alignRight: false },
  { id: "pages", label: "Pages", alignRight: false },
  { id: "pages", label: "Template Group", alignRight: false },
  { id: "_id", label: "Template Group ID", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((template) => {
      return template.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

function TrendingTempGroup({
  filterTrendingTemplates,
  setShowField,
  setLastDays,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [trendingTemplatesGroup, setTrendingTemplatesGroup] = useState([]);

  const handleFilterTrendingTemp = async () => {
    let data = filterTrendingTemplates;
    let startDate;
    if (filterTrendingTemplates.end_date === "lastWeek") {
      startDate = moment().subtract(1, "weeks").format("YYYY-MM-DD");
    } else if (filterTrendingTemplates.end_date === "lastMonth") {
      startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    } else if (!filterTrendingTemplates.end_date) {
      setLastDays(2);
      setShowField(false);
      startDate = moment().subtract(1, "months").format("YYYY-MM-DD");
    } else {
      startDate = moment(filterTrendingTemplates.end_date).format("YYYY-MM-DD");
    }
    data = {
      app_type: filterTrendingTemplates.app_type,
      end_date: startDate,
      limit: filterTrendingTemplates.limit,
    };
    setLoading(true);
    const response = await _trending_templates_group(data);
    console.log(response, "respponnnsssssssss");
    if (response.code === 200) {
      setLoading(false);
      setTrendingTemplatesGroup(response.data);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      console.log(response.message, "errorr");
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = trendingTemplatesGroup.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - trendingTemplatesGroup.length)
      : 0;

  const filteredUsers = applySortFilter(
    trendingTemplatesGroup,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  useEffect(() => {
    handleFilterTrendingTemp();
  }, [filterTrendingTemplates]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table sx={{ minWidth: 750 }} size="large" aria-label="a dense table">
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={trendingTemplatesGroup.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {filteredUsers.map((row, index) => {
              const { _id, template_group_id } = row;
              const rowNum = page * rowsPerPage + index + 1;
              return (
                <TableRow
                  key={_id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {rowNum}
                  </TableCell>

                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {template_group_id
                      ? template_group_id?.name.charAt(0).toUpperCase() +
                        template_group_id?.name?.slice(1)
                      : "-"}
                  </TableCell>

                  <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                    <Label variant="ghost" color="warning">
                      {row ? row?.total_count : "0"}
                    </Label>
                  </TableCell>

                  <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                    <Label
                      variant="ghost"
                      color={
                        (template_group_id?.is_published === true &&
                          "success") ||
                        "error"
                      }
                    >
                      {template_group_id?.is_published === true
                        ? "Published"
                        : "Unpublished"}
                    </Label>
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <Label
                      variant="ghost"
                      color={(row.is_paid === true && "success") || "error"}
                    >
                      {row.is_paid ? "Yes" : "No"}
                    </Label>
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {template_group_id
                        ? template_group_id?.template_array.length
                        : "-"}
                      <LayersIcon
                        sx={{ marginInlineStart: "4px" }}
                        fontSize="small"
                        color="primary"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        maxWidth: "480px",
                        height: "120px",
                        overflowX: "scroll",
                      }}
                    >
                      {template_group_id.template_array.map((page) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginInlineEnd: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              sourceS3BaseUrl +
                                page.template +
                                "/" +
                                "index.html"
                            );
                          }}
                        >
                          <img
                            onError={(e) => {
                              if (
                                e.target.src ===
                                sourceS3BaseUrl + page?.image
                              ) {
                                e.target.src = s3baseUrl + "/" + page?.image;
                              }
                            }}
                            src={sourceS3BaseUrl + page.image}
                            height={"70px"}
                            width={"150px"}
                          />
                          <span
                            style={{
                              textOverflow: "ellipsis",
                              width: "150px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              marginTop: "4px",
                            }}
                          >
                            {page.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell
                    style={{ whiteSpace: "nowrap" }}
                    onDoubleClick={() => {
                      navigator.clipboard.writeText(template_group_id?._id);
                      enqueueSnackbar("ID copied to clipboard", {
                        variant: "success",
                      });
                    }}
                  >
                    {template_group_id?._id}
                    {console.log(_id, "idddddddddddddddddddddddddddd")}
                  </TableCell>
                  {/* <TableCell align="right">
                            <CustomPopover
                              data={{ row, index }}
                              menu={menuOptions}
                            />
                          </TableCell> */}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isUserNotFound && !loading && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      )}
    </>
  );
}

export default TrendingTempGroup;
