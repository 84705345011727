import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { _add_banner_data } from "src/DAL/Website/AddBanner";
import { _landing_page_detail } from "src/DAL/Website/LandingPageDetail";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import Page from "src/components/Page";
import { s3baseUrl } from "src/config/config";
import { MdOndemandVideo } from "react-icons/md";
import TextModal from "./TextDialog";
import { Icon } from "@iconify/react";
import CustomConfirmation from "src/components/CustomConfirmation";

export default function AddBanner() {
  const [inputs, setInputs] = useState({
    banner_button: {
      text: "",
      link: "",
    },
    banner_description: "",
    banner_rating_text: "",
    banner_text_h1: "",
    banner_text_h3: "",
    banner_video: "",
  });

  const [secondText, setSecondText] = useState({});
  const [loading, setLoading] = useState(false);
  const [textItemIndex, setTextItemIndex] = useState();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const newSegmentName = "video";
  const handleChange = (e) => {
    console.log(e.target.value, "array---");
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  console.log(inputs.banner_video, "ftsayfdt");
  // const handleTextChange = (e) => {
  //   setSecondTextObj((prev) => {
  //     return { ...prev, [e.target.name]: e.target.value };
  //   });
  // };
  const handleVideoUpload = async (e) => {
    const video = e.target.files[0];
    setInputs((prev) => {
      return { ...prev, banner_video: video };
    });
  };

  const BannerDetail = async () => {
    setLoading(true);
    const BannerDetailList = await _landing_page_detail();
    if (BannerDetailList.code === 200) {
      setLoading(false);
      setInputs(BannerDetailList?.data?.banner);
      setSecondText(BannerDetailList?.data?.banner);
    }
  };

  useEffect(() => {
    BannerDetail();
  }, []);

  const newArray = secondText.banner_text_h2?.map((obj) => {
    const { _id, ...rest } = obj;
    return rest;
  });

  console.log(newArray, "fstafdt");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setSecondTextArray([...secondTextArray,secondTextObj]);
    if (typeof inputs.banner_video === "string") {
      const postData = {
        banner_text_h1: inputs.banner_text_h1,
        banner_text_h2: newArray,
        banner_text_h3: inputs.banner_text_h3,
        banner_description: inputs.banner_description,
        banner_video: inputs.banner_video,
        banner_rating_text: inputs.banner_rating_text,
        banner_button: {
          text: inputs.banner_button.text,
          link: inputs.banner_button.link,
        },
      };
      // console.log(postData, "tys");
      const result = await _add_banner_data(postData);
      console.log(result, "tys");
      if (result.code === 200) {
        enqueueSnackbar(`data added successfully`, {
          variant: "success",
        });
        BannerDetail();
      } else {
        enqueueSnackbar(`data not added successfully`, {
          variant: "error",
        });
      }
    } else {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.banner_video);
      const video_resp = await _upload_file_on_s3(formData);
      console.log(video_resp, "tftyf");
      if (video_resp.code == 200) {
        const postData = {
          banner_text_h1: inputs.banner_text_h1,
          banner_text_h2: newArray,
          banner_text_h3: inputs.banner_text_h3,
          banner_description: inputs.banner_description,
          banner_video: video_resp.file_name,
          banner_rating_text: inputs.banner_rating_text,
          banner_button: {
            text: inputs.banner_button.text,
            link: inputs.banner_button.link,
          },
        };
        const result = await _add_banner_data(postData);
        console.log(result, "tys");
        if (result.code === 200) {
          enqueueSnackbar(`data added successfully`, {
            variant: "success",
          });
          BannerDetail();
        } else {
          enqueueSnackbar(`data not added successfully`, {
            variant: "error",
          });
        }
      }
    }
  };

  const handleEdit = (row, i) => {
    console.log(row, "ftsayf");
    setData(row);
    setTextItemIndex(i);
    setOpen(true);
  };

  const handleConfirmDelete = (i) => {
    console.log(i, "cstyadfastydf");
    setSecondText((prev) => {
      return {
        ...prev,
        banner_text_h2: prev.banner_text_h2.filter((ele, index) => index !== i),
      };
    });
    setOpenDelete(false);
  };

  const handleDelete = (row, i) => {
    setTextItemIndex(i);
    setOpenDelete(true);

    // headerDetailList();
  };

  const handleAddText = () => {
    setOpen(true);
  };
  return (
    <div className="mt-5" style={{ width: "94%", margin: "auto" }}>
      <TextModal
        open={open}
        setOpen={setOpen}
        item={secondText}
        setItem={setSecondText}
        textIndex={textItemIndex}
        data={data}
        setData={setData}
      />
      <h3 className="text-center">Add Banner</h3>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Page title="Add Banner" className="mt-5">
            <form>
              <Container maxWidth="xl">
                <Grid item xl={12}>
                  <div className="row ">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-4 mb-sm-3 mb-md-0">
                      <TextField
                        // style={{ width: "33%" }}
                        fullWidth
                        required
                        label="Banner Text h1"
                        value={inputs.banner_text_h1}
                        name="banner_text_h1"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                      <TextField
                        // style={{ width: "33%" }}
                        fullWidth
                        label="Banner Text h3"
                        required
                        value={inputs.banner_text_h3}
                        name="banner_text_h3"
                        onChange={handleChange}
                      />
                    </div>
                    {/* <div style={{ width: "2%" }}></div> */}
                  </div>

                  <p className="mt-3">Description</p>
                  <GeneralCkeditor
                    className="mt-2"
                    inputs={inputs}
                    setInputs={setInputs}
                    name={"banner_description"}
                    editorHeight={350}
                    onChange={handleChange}
                  />
                  <p className="mt-3">Button Rating Text</p>
                  <GeneralCkeditor
                    className="mt-2"
                    inputs={inputs}
                    setInputs={setInputs}
                    name={"banner_rating_text"}
                    editorHeight={350}
                    onChange={handleChange}
                  />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 mt-4 mt-sm-4 mt-md-4">
                      <TextField
                        // style={{ width: "33%" }}
                        fullWidth
                        label="Banner Button Text"
                        required
                        value={inputs.banner_button.text}
                        name="banner_button.text"
                        onChange={(e) =>
                          setInputs((prev) => {
                            return {
                              ...prev,
                              banner_button: {
                                ...prev.banner_button,
                                text: e.target.value,
                              },
                            };
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 mb-4 mb-sm-3 mb-md-0 mt-4 mt-sm-4 mt-md-4">
                      <TextField
                        // style={{ width: "33%" }}
                        fullWidth
                        label="Banner Button Link"
                        required
                        value={inputs.banner_button.link}
                        name="banner_button.link"
                        onChange={(e) =>
                          setInputs((prev) => {
                            return {
                              ...prev,
                              banner_button: {
                                ...prev.banner_button,
                                link: e.target.value,
                              },
                            };
                          })
                        }
                      />
                    </div>
                    {/* <div style={{ width: "2%" }}></div> */}
                  </div>
                  <Button className="mt-3" onClick={handleAddText}>
                    Banner Text h2
                  </Button>
                  <div className="d-flex flex-column text-center justify-content-center align-items-center mt-3">
                    {typeof inputs.banner_video === "string" ? (
                      <>
                        <div>
                          <MdOndemandVideo />

                          <p>
                            {/* {inputs.banner_video
                              ? inputs.banner_video
                                  .split("/")
                                  .slice(0, -1)
                                  .concat(newSegmentName)
                                  .join("/")
                              : null} */}
                            {/* {inputs.banner_video
                              ? inputs.banner_video
                                  .split("/")
                                  .map(() => newSegmentName)
                                  .join("/")
                              : null} */}
                            {/* {inputs.banner_video
                              ? `${
                                  inputs.banner_video.split("/").length > 0
                                    ? newSegmentName
                                    : ""
                                }`
                              : null} */}
                            {
                              inputs.banner_video.split("/")[
                                inputs.banner_video.split("/").length - 1
                              ]
                            }
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>{inputs.banner_video.name}</p>
                      </>
                    )}

                    <label htmlFor="icon-button-file">
                      <Input
                        style={{ display: "none" }}
                        accept="video/*"
                        id="icon-button-file"
                        type="file"
                        value=""
                        name="banner_video"
                        onChange={handleVideoUpload}
                      />
                      <Button
                        aria-label="upload picture"
                        component="span"
                        className="upload-button mt-1 "
                        // disabled={isDisable}
                      >
                        {inputs.banner_video
                          ? "change banner video"
                          : "Upload Banner Video"}
                      </Button>
                    </label>
                  </div>

                  <Grid xs={12}></Grid>
                </Grid>
              </Container>

              <div>
                <Table className="mt-5">
                  <TableHead>
                    <TableRow>
                      <TableCell>Banner Text H2</TableCell>
                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {secondText?.banner_text_h2?.map((val, i) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>{val.text}</TableCell>

                            <TableCell>
                              <Icon
                                onClick={() => handleEdit(val, i)}
                                style={{ color: "green", cursor: "pointer" }}
                                icon={"fa-regular:edit"}
                              />
                            </TableCell>
                            <TableCell>
                              <Icon
                                onClick={() => handleDelete(val, i)}
                                style={{ color: "red", cursor: "pointer" }}
                                icon={"ic:baseline-delete"}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              <div className="text-end mt-3">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </Page>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to Delete ?"}
            handleAgree={() => handleConfirmDelete(textItemIndex)}
          />
        </>
      )}
    </div>
  );
}
