import { useSnackbar } from "notistack";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { _role_details } from "src/DAL/Management/role_api";
import { _admin_dashboard } from "src/DAL/login/login";
const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);

export function ContextApi({ children }) {
  // states
  const { pathname } = useLocation();

  const [dashboard, setDashboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCount, setOpenCount] = useState("");
  const [test, setTest] = useState("arslna ashiq");
  const getProfile = () => {
    if (localStorage.getItem("token")) {
      return JSON.parse(localStorage.getItem("user"));
    }
    return null;
  };

  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [subAdminRole, setSubAdminRole] = useState({});

  const setProfile = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  const [user, setUser] = useState(getProfile());
  // functions and handlers

  const { enqueueSnackbar } = useSnackbar();

  function getObjOfPrivileges(targetName) {
    for (const obj of subAdminRole?.roles) {
      if (obj.name.toLowerCase() === targetName.toLowerCase()) {
        return obj.privileges;
      }
    }
    return false; // Default value if the target name is not found
  }

  const getRoleDetails = async (id) => {
    const resp = await _role_details(id);
    if (resp.code === 200) {
      setIsSubAdmin(true);
      setSubAdminRole(resp.role);
      console.log(resp, "roleresponcerespnenck");
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    return resp;
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    if (user && user.admin_type === "sub_admin") {
      getRoleDetails(user.role_id);
    }
  }, []);

  // const get_dashboard_data = async () => {
  //   const resp = await _admin_dashboard();

  //   if (resp.code == 200) {
  //     const _dashboard = resp.Dashboard;

  //     setDashboard(_dashboard);
  //     setIsLoading(false);
  //   } else {
  //     enqueueSnackbar(resp.message, { variant: "error" });
  //   }
  // };

  // useEffect(() => {
  //   get_dashboard_data();

  // }, [pathname]);
  //   export collection
  const collection = {
    // export states
    test,
    setTest,
    getProfile,
    setProfile,
    //export  handlers
    setUser,
    setOpenCount,
    openCount,
    user,
    isLoading,
    setIsLoading,
    dashboard,
    setDashboard,
    isSubAdmin,
    setIsSubAdmin,
    getRoleDetails,
    subAdminRole,
    getObjOfPrivileges,
  };
  return (
    <AppContext.Provider value={collection}>{children}</AppContext.Provider>
  );
}
