import { invokeApi } from "src/bl_libs/invokeApi";

export const _get_api_keys = async () => {
  let requestObj = {
    path: `api/key/get_keys`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_api_keys = async (postData) => {
  let requestObj = {
    path: `api/key/add_key`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _update_api_keys = async (id, postData) => {
  let requestObj = {
    path: `api/key/update_key_value/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_api_keys = async (id) => {
  let requestObj = {
    path: `api/key/delete_key/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _get_keys = async () => {
  let requestObj = {
    path: `api/frontend_key/get_frontend_keys`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _add_keys = async (postData) => {
  let requestObj = {
    path: `api/frontend_key/add_frontend_key`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _delete_keys = async (id) => {
  let requestObj = {
    path: `api/frontend_key/delete_frontend_key/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _update_keys = async (id, postData) => {
  let requestObj = {
    path: `api/frontend_key/update_frontend_key/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};
