import * as React from "react";
import { useState, useEffect } from "react";
// import DataTable from './Data-Section';
import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  TextField,
} from "@mui/material";
import GeneralCkeditor from "src/components/GeneralComponents/GeneralCKeditor";
import { s3baseUrl } from "src/config/config";

export default function LogoModal({
  open,
  setOpen,
  selectedData,
  setSelectedData,
  selectedIndex,
  dataArray,
  setDataArray,
}) {
  console.log(selectedData, "tsaydftyas");

  const handleClose = () => {
    setSelectedData({ image: "" });
    setOpen(false);
  };
  const [brandLogo, setBrandLogo] = useState({
    image: "",
  });

  const handleLogoChange = (e) => {
    let temp = [...dataArray]
    temp.splice(selectedIndex,1,{image:URL.createObjectURL(e.target.files[0]), file:e.target.files[0]})
    setDataArray([...temp])
    setBrandLogo((prev) => {
      return {
        ...prev,
        image: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      };
    });
  };

  const handleEdit = () => {
   
    handleClose();
  };

  useEffect(() => {
    if (selectedData) {
      if (String(selectedData?.image).startsWith("blob") || typeof selectedData?.image !== "string") {
        setBrandLogo(selectedData);
      } else {
        setBrandLogo({
          ...selectedData,
          image: s3baseUrl + "/" + selectedData?.image,

        });
      }
    }
  }, [selectedData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="xs"
        fullWidth
        disableEnforceFocus={true}
      >
        <DialogTitle style={{ padding: 0 }}>
          <div className="model-head ">
            <p>Edit Brand Logo</p>
            <p onClick={handleClose}>
              <Icon icon="maki:cross" />
            </p>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="d-flex flex-column justify-content-center align-items-center mt-4">
            <img style={{width:"15rem",height:"7rem"}} src={brandLogo?.image} alt="" />
            <label htmlFor="icon-button-file-4">
              <Input
                style={{ display: "none" }}
                accept="image/*"
                id="icon-button-file-4"
                type="file"
                name="file"
                onChange={handleLogoChange}
              />
              <Button
                aria-label="upload picture"
                component="span"
                className="upload-button mt-3"
              >
                Change Logo
              </Button>
            </label>
          </div>

          <div className="text-end mt-3">
            <Button variant="contained" onClick={handleEdit}>
              Edit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
