/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import Label from "../../../components/Label";
import moment from "moment";
import Label from "src/components/Label";
import {
  _delete_transaction_by_admin,
  _transaction_list_by_admin,
} from "src/DAL/Transactions/Transaction";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import DetailModal from "../Detail-Section/Detail-Section";
import { Typeahead } from "react-bootstrap-typeahead";
import { _customer_listing } from "src/DAL/Subscription/Subscription";
import { en } from "faker/lib/locales";
import { useAppContext } from "src/Hooks/AppContext";

function AdminTransactions() {
  const { user, getObjOfPrivileges, subAdminRole } = useAppContext();
  const [rolePrivilegesObj, setRolePrivilegesObj] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [adminTransactions, setAdminTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [selectTransactionRow, setSelectTransactionRow] = useState([]);
  const [openTransactionDetail, setOpenTransactionDetail] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState();
  const [customerNames, setCustomerNames] = useState([]);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    user_id: "",
  });

  const handleDeleteClick = (row) => {
    setSelectedRow(row.row);
    setOpenDelete(true);
  };

  const [menuOptions, setMenuOptions] = useState([
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ]);

  const TransactionStatusColor = (val) => {
    return (
      <div>
        <Label
          color={
            selectTransactionRow?.transaction_status === "succeeded"
              ? "success"
              : "error"
          }
        >
          {val}
        </Label>
      </div>
    );
  };

  const IsActiveFunc = (Active) => {
    return (
      <>
        <Label
          // className={
          //   selectTransactionRow?.customer_object?.type === 1 ? "label" : ""
          // }
          variant="ghost"
          color={
            selectTransactionRow?.customer_object?.type === 1
              ? "success"
              : "error"
          }
        >
          {Active}
        </Label>
      </>
    );
  };

  const getCurrencySymbol = (locale, currency) => {
    if (!currency) {
      currency = "USD";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("handleChangeRowsPerPage", event.target.value);
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };

  const _customerListing = async () => {
    const response = await _customer_listing();
    // console.log(response, "responseresponseresponse");
    if (response.code === 200) {
      setCustomerNames(
        response.data.map((user) => {
          return {
            name:
              user.first_name.charAt(0).toUpperCase() +
              user.first_name.slice(1) +
              " " +
              user.last_name.charAt(0).toUpperCase() +
              user.last_name.slice(1),
            _id: user.user_id,
          };
        })
      );
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    // console.log(response.data, "customerListinggg");
  };

  useEffect(() => {
    _customerListing();
  }, []);

  const transactionsByAdmin = async (filter = false) => {
    console.log(filters, "caleed");
    setLoading(true);
    setIsFilter(filter);
    const postData = filter
      ? {
          start_date: filters.start_date
            ? moment(filters.start_date).format("MM-DD-YYYY")
            : "",
          end_date: filters.end_date
            ? moment(filters.end_date).format("MM-DD-YYYY")
            : "",
          user_id: filters?.user_id ? filters?.user_id : "",
        }
      : {
          start_date: "",
          end_date: "",
          user_id: "",
        };
    // console.log(postData, "postdataaaa");
    const response = await _transaction_list_by_admin(
      postData,
      page,
      rowsPerPage
    );
    if (response.code === 200) {
      setLoading(false);
      setAdminTransactions(response.data.transaction);
      // console.log(response.data.transaction, "transactionsByAdmin");
      setTotalCount(response.data.total_transaction);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleFilterClick = () => {
    setPage(0);
    if (!filters.start_date && !filters.end_date && !filters.user_id) {
      enqueueSnackbar("Select at least one field to filter", {
        variant: "error",
      });
    } else if (filters.start_date && !filters.end_date) {
      enqueueSnackbar("Enter End Date ", { variant: "error" });
    } else if (!filters.start_date && filters.end_date) {
      enqueueSnackbar("Enter Start Date ", { variant: "error" });
    } else if (filters.start_date > filters.end_date) {
      enqueueSnackbar("Start Date is Greater ", { variant: "error" });
    } else {
      transactionsByAdmin(true);
    }
  };

  const handleClearFilterClick = () => {
    setFilters({
      start_date: "",
      end_date: "",
      // user_id: "",
    });
    setSelectedCustomerName("");
    delete filters.user_id;
    // setFilters({
    //   ...filters,
    // });
    transactionsByAdmin(false);
  };

  const handleDelete = async () => {
    // console.log(selectedRow, "selectedRow");
    setIsLoadingDelete(true);
    const delete_resp = await _delete_transaction_by_admin(selectedRow._id);
    if (delete_resp.code === 200) {
      setOpenDelete(false);
      setIsLoadingDelete(false);
      setAdminTransactions((prev) => {
        return prev.filter((item) => item._id !== selectedRow._id);
      });
      enqueueSnackbar(delete_resp.message, {
        variant: "success",
      });
    } else {
      enqueueSnackbar(delete_resp.message, { variant: "error" });
      setIsLoadingDelete(false);
      // console.log(delete_resp, "deleteTransactionByAdminError");
    }
  };

  useEffect(() => {
    if (selectTransactionRow) {
      setTransactionDetail([
        {
          name: "Customer Name",
          value: selectTransactionRow?.customer_object
            ? selectTransactionRow.customer_object?.first_name
                .charAt(0)
                .toUpperCase() +
              selectTransactionRow?.customer_object?.first_name.slice(1) +
              " " +
              selectTransactionRow.customer_object?.last_name
                .charAt(0)
                .toUpperCase() +
              selectTransactionRow?.customer_object?.last_name.slice(1)
            : "-",
        },
        {
          name: "Customer Type",
          value: IsActiveFunc(
            selectTransactionRow?.customer_object?.type === 1 ? "Inovate" : "-"
          ),
        },
        {
          name: "Customer Email",
          value: selectTransactionRow?.customer_object
            ? selectTransactionRow.customer_object?.email
            : "-",
        },
        {
          name: "Currency",
          value: selectTransactionRow.currency ? (
            selectTransactionRow.currency
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Amount",
          value: selectTransactionRow
            ? `${getCurrencySymbol("en-US", selectTransactionRow?.currency)}${
                selectTransactionRow?.amount
              }`
            : "-",
        },
        {
          name: "Transaction Date",
          value: selectTransactionRow?.transaction_date
            ? `${moment(selectTransactionRow?.transaction_date).format("LLL")}`
            : "-",
        },
        {
          name: "Transaction Status",
          value: TransactionStatusColor(
            selectTransactionRow.transaction_status ? (
              selectTransactionRow.transaction_status
            ) : (
              <span>-</span>
            )
          ),
        },
        {
          name: "Product Name",
          value: selectTransactionRow?.plan_object?.product_name ? (
            selectTransactionRow?.plan_object?.product_name
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Product Description",
          value: selectTransactionRow?.plan_object?.product_description ? (
            selectTransactionRow?.plan_object?.product_description
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Created At",
          value: selectTransactionRow.createdAt ? (
            moment(selectTransactionRow.createdAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
        {
          name: "Updated At",
          value: selectTransactionRow.updatedAt ? (
            moment(selectTransactionRow.updatedAt).format("LLL")
          ) : (
            <span>-</span>
          ),
        },
      ]);
      console.log(selectTransactionRow, "rowdataa");
    }
  }, [selectTransactionRow]);

  const handleShowTransactionDetail = (row) => {
    setSelectTransactionRow(row.row);
    setOpenTransactionDetail(true);
  };

  useEffect(() => {
    // console.log(filters, "filterss");
  }, [filters]);

  useEffect(() => {
    transactionsByAdmin(isFilter);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (user && user.admin_type === "sub_admin" && subAdminRole.roles) {
      let obj = getObjOfPrivileges("Transactions");
      setRolePrivilegesObj(obj);
      let _array = menuOptions;
      if (!obj.delete) {
        _array = [..._array.filter((option) => option.label != "Delete")];
      }

      setMenuOptions([..._array]);
    }
  }, [subAdminRole]);

  return (
    <>
      <DetailModal
        open={openTransactionDetail}
        setOpen={setOpenTransactionDetail}
        detail={transactionDetail}
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Admin Transactions
        </Typography>
        <Card sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
              margin={2}
            >
              <TextField
                id="start-date"
                label="Start Date"
                name="start_date"
                type="date"
                defaultValue=""
                sx={{ width: "100%" }}
                value={moment(filters.start_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setIsFilter(true);
                  setFilters({
                    ...filters,
                    start_date: moment(e.target.value).format("MM/DD/YYYY"),
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="end-date"
                label="End Date"
                name="end_date"
                type="date"
                defaultValue=""
                sx={{ width: "100%" }}
                value={moment(filters.end_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setIsFilter(true);
                  setFilters({
                    ...filters,
                    end_date: moment(e.target.value).format("MM/DD/YYYY"),
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              spacing={3}
              margin={2}
            >
              <FormControl sx={{ mb: 0, mx: 2 }}>
                <Typeahead
                  id="basic-example"
                  className="react-typeAhead"
                  placeholder="Select Customer"
                  options={customerNames.map((customer) => customer.name)}
                  value={selectedCustomerName}
                  onChange={(selected) => {
                    console.log(selected, "valuwndjn");
                    setIsFilter(true);
                    const selectedCustomer = customerNames.find(
                      (customer) => customer.name === selected[0]
                    );
                    setSelectedCustomerName(selected[0] || "");
                    setFilters({
                      ...filters,
                      user_id: selectedCustomer?._id,
                    });
                  }}
                  selected={
                    isFilter && selectedCustomerName
                      ? [selectedCustomerName]
                      : []
                  }
                />
              </FormControl>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  className="filter"
                  onClick={handleFilterClick}
                  endIcon={<Icon icon="tdesign:filter" />}
                >
                  Filter
                </Button>
                <p
                  style={{ marginTop: "9%", paddingLeft: "10px" }}
                  onClick={() => {
                    setPage(0);
                    handleClearFilterClick();
                  }}
                  className={
                    isFilter === true
                      ? "showClearFilter"
                      : isFilter === false
                      ? "clearFilter"
                      : ""
                  }
                >
                  Clear Filter
                </p>
              </div>
            </Stack>
          </form>
          <TableContainer>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                size="large"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      #
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Customer Type
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Currency
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Amount
                    </TableCell>

                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Transaction Date
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Transaction Status
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      style={{ whiteSpace: "nowrap", fontWeight: "bold" }}
                    >
                      Updated At
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminTransactions && adminTransactions.length > 0 ? (
                    adminTransactions.map((row, index) => {
                      const {
                        _id,
                        customer_object,
                        amount,
                        currency,
                        transaction_date,
                        transaction_status,
                        updatedAt,
                        createdAt,
                      } = row;
                      return (
                        <TableRow
                          key={_id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            className="hover-effect1"
                            style={{ whiteSpace: "nowrap", cursor: "pointer" }}
                            onClick={() =>
                              handleShowTransactionDetail({ row, index })
                            }
                          >
                            <Typography variant="subtitle2" noWrap>
                              {" "}
                              {customer_object
                                ? customer_object?.first_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer_object?.first_name.slice(1) +
                                  " " +
                                  customer_object?.last_name
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer_object?.last_name.slice(1)
                                : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Label variant="ghost" color="success">
                              {customer_object?.type === 1 ? "inovate" : "-"}
                            </Label>
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {currency ? currency : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {amount
                              ? `${getCurrencySymbol(
                                  "en-US",
                                  currency
                                )}${amount}`
                              : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {moment(transaction_date).format("LL")}
                            {/* {transaction_date} */}
                          </TableCell>

                          <TableCell align="center">
                            <Label
                              variant="ghost"
                              color={
                                (transaction_status === "succeeded" &&
                                  "success") ||
                                "error"
                              }
                            >
                              {transaction_status === "succeeded"
                                ? "Succeed"
                                : "Failed"}
                            </Label>
                          </TableCell>

                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {createdAt ? moment(createdAt).format("LLL") : "-"}
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            {updatedAt ? moment(updatedAt).format("LLL") : "-"}
                          </TableCell>
                          <TableCell align="right">
                            {/* <CustomPopover
                              data={{ row, index }}
                              menu={menuOptions}
                            /> */}
                            {menuOptions.length > 0 && (
                              <TableCell align="right">
                                <CustomPopover
                                  data={{ row, index }}
                                  menu={menuOptions}
                                />
                              </TableCell>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        No Transaction Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <CustomConfirmation
            open={openDelete}
            setOpen={setOpenDelete}
            title={"Are you sure you want to delete this transaction?"}
            handleAgree={handleDelete}
            loadingBtn={isLoadingDelete}
          />

          <TablePagination
            className="table-pagination"
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Box>
    </>
  );
}

export default AdminTransactions;
