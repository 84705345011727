import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
// material
import {
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Input,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import Scrollbar from "src/components/Scrollbar";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  _add_customer_by_admin,
  _update_customer_password,
} from "src/DAL/customer/customer";
import {
  _active_helping_categories,
  _add_helping_category,
} from "src/DAL/helping-categories/helping_categories";
import { get_local_preview } from "src/utils/utils";
import { _upload_file_on_s3 } from "src/DAL/upload_s3/upload_s3";
import {
  _add_helping_article,
  _update_helping_article,
} from "src/DAL/helping-articles/helping_articles";
import { s3baseUrl } from "src/config/config";
import CkEditor from "src/components/editor/CKEditor";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AddHelpingCategory({
  isOpenDrawer,
  onOpenDrawer,
  onCloseDrawer,
  handleRefresh,
  selectedRow,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = useState({
    name: "",
    image: "",
    short_description: "",
    help_article_category: "",
    description: "",
    file: "",
    status: true,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [helpingCategories, setHelpingCategories] = useState([]);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleUpdatePassword();
  };

  const handleUpdatePassword = async () => {
    // upload image
    if (inputs.file) {
      const formData = new FormData();
      formData.append("project_name", "upload_s3_files");
      formData.append("upload_file", inputs.file);
      const image_resp = await _upload_file_on_s3(formData);
      if (image_resp.code == 200) {
        const {
          name,
          short_description,
          description,
          help_article_category,
          status,
        } = inputs;
        const postData = {
          name,
          short_description,
          description,
          help_article_category,
          status,
          image: image_resp.file_name,
        };
        const add_customer_resp = await _update_helping_article(
          selectedRow._id,
          postData
        );
        if (add_customer_resp.code == 200) {
          onCloseDrawer();
          enqueueSnackbar(`Help article added successfully`, {
            variant: "success",
          });
          handleRefresh();
        } else {
          enqueueSnackbar(add_customer_resp.message, { variant: "error" });
        }
        setIsLoading(false);
      } else {
        enqueueSnackbar(image_resp.message, { variant: "error" });
        setIsLoading(false);
        return;
      }
    } else {
      const {
        name,
        short_description,
        description,
        help_article_category,
        status,
        image,
      } = inputs;
      const postData = {
        name,
        short_description,
        description,
        help_article_category,
        status,
        image,
      };

      const add_customer_resp = await _update_helping_article(
        selectedRow._id,
        postData
      );
      if (add_customer_resp.code == 200) {
        onCloseDrawer();
        enqueueSnackbar(`Help category added successfully`, {
          variant: "success",
        });
        handleRefresh();
      } else {
        enqueueSnackbar(add_customer_resp.message, { variant: "error" });
      }
      setIsLoading(false);
    }
  };

  const handleClickRemove = (country) => {
    setInputs({
      ...inputs,
      image: "",
      file: "",
    });
  };

  const getHelpingCategories = async () => {
    const resp = await _active_helping_categories();
    if (resp.code == 200) {
      setHelpingCategories(resp.helping_article_category);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (isOpenDrawer === true) {
      getHelpingCategories();
    }

    const {
      name,
      short_description,
      description,
      help_article_category,
      status,
      image,
    } = selectedRow;

    console.log(selectedRow, "selectedRow");

    setInputs({
      name,
      short_description,
      description,
      help_article_category: help_article_category
        ? help_article_category?._id
        : "",
      status,
      image,
    });
    setIsLoading(false);
  }, [isOpenDrawer]);

  return (
    <>
      <Drawer
        disableEnforceFocus={true}
        anchor="right"
        open={isOpenDrawer}
        // onClose={onCloseDrawer}
        PaperProps={{
          sx: { width: "80vw", border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          sx={{ px: 1, py: 2 }}
        >
          <IconButton onClick={onCloseDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Edit Help Article
          </Typography>
        </Stack>

        <Divider />

        <Scrollbar>
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ p: 3 }}>
              {/*  */}
              <TextField
                fullWidth
                label="Name"
                required
                name="name"
                value={inputs.name}
                onChange={handleChange}
              />

              <TextField
                fullWidth
                label="Short Description"
                required
                name="short_description"
                multiline
                rows={5}
                value={inputs.short_description}
                onChange={handleChange}
              />

              <div className="text-secondary">Description :</div>

              <CkEditor
                content={inputs.description}
                setContent={(value) =>
                  setInputs({
                    ...inputs,
                    description: value,
                  })
                }
              />

              <FormControl fullWidth>
                <InputLabel
                  shrink={!!inputs.help_article_category}
                  variant="standard"
                  htmlFor="uncontrolled-native"
                >
                  Please choose a help article category
                </InputLabel>
                <NativeSelect
                  value={inputs.help_article_category}
                  onChange={handleChange}
                  inputProps={{
                    name: "help_article_category",
                    id: "uncontrolled-native",
                  }}
                >
                  {inputs.help_article_category === "" && (
                    <option value=""></option>
                  )}
                  {helpingCategories.map((category) => (
                    <option value={category._id}>{category.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>

              <FormControlLabel
                className="justify-content-end"
                value="start"
                control={
                  <Switch
                    checked={inputs.status}
                    value={inputs.status}
                    onChange={handleChange}
                    color="primary"
                    name="status"
                  />
                }
                label="Status"
                labelPlacement="start"
              />

              {/* <div>
                {inputs.file || inputs.image ? (
                  <>
                    {inputs.file && (
                      <img
                        style={{ maxWidth: "50%" }}
                        className="m-auto"
                        src={get_local_preview(inputs.file)}
                        alt=""
                      />
                    )}
                    {inputs.image && (
                      <img
                        style={{ maxWidth: "50%" }}
                        className="m-auto"
                        src={s3baseUrl + "/" + inputs.image}
                        alt=""
                      />
                    )}
                  </>
                ) : (
                  <label htmlFor="icon-button-file">
                    <Input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file"
                      type="file"
                      value=""
                      name="file"
                      onChange={handleChange}
                    />
                    <Button
                      aria-label="upload picture"
                      component="span"
                      className="upload-button"
                      fullWidth
                    >
                      Upload Image
                    </Button>
                  </label>
                )}

                {(inputs.file || inputs.image) && (
                  <Button
                    onClick={handleClickRemove}
                    className="mt-1"
                    style={{ color: "Red" }}
                    fullWidth
                  >
                    Remove
                  </Button>
                )}
              </div> */}
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                Update Help Article
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}
