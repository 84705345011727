import { invokeApi } from "src/bl_libs/invokeApi";

export const _project_list = async (page, rowsPerPage, postData) => {
  let requestObj = {
    path: `api/project/project_list_for_admin?page=${page}&limit=${rowsPerPage}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _edit_project_for_admin = async (id, postData) => {
  let requestObj = {
    path: `api/project/edit_project_for_admin/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};



export const _delete_project = async (id) => {
  let requestObj = {
    path: `api/project/delete_project/${id}`,
    method: "Delete",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _version_list = async (project_id) => {
  let requestObj = {
    path: `api/project/get_version_list_by_project/${project_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _delete_project_version = async (id) => {
  let requestObj = {
    path: `api/project/delete_version/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const detail_project_for_admin = async (id) => {
  console.log(id,"idfromadmin")
  let requestObj = {
    path: `api/project/detail_project_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};



export const _project_backup_restore = async (postData,id) => {
  let requestObj = {
    path: `api/project/restore_project_backup_by_admin/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const _page_backup_restore = async (postData,id) => {
  let requestObj = {
    path: `api/page/restore_page_backup/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};