import { invokeApi } from "src/bl_libs/invokeApi";

export const _add_banner_data=(data)=>{
const req_obj={
    method:"POST",
    path:"api/landing_page/add_banner",
    headers:{"x-sh-auth":localStorage.getItem("token")},
    postData:data
}
return invokeApi(req_obj)
}