import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import { _support_tickets_listing } from "src/DAL/support_tickets/support_tickets";
import { useEffect, useState } from "react";
import { useAppContext } from "src/Hooks/AppContext";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarConfig = () => {
  const { openCount, user, subAdminRole } = useAppContext();

  let sideBarArray = [
    {
      title: "dashboard",
      path: "/dashboard",
      icon: getIcon("raphael:piechart"),
    },
    {
      title: "Customers",
      path: "/customers",
      icon: getIcon("clarity:users-line"),
    },
    {
      title: "Projects",
      path: "/projects",
      icon: getIcon("el:website"),
    },
    {
      title: "Help Categories",
      path: "/helping-categories",
      icon: getIcon("carbon:collapse-categories"),
    },
    {
      title: "Help Articles",
      path: "/helping-articles",
      icon: getIcon("ooui:articles-rtl"),
    },
    {
      title: "Landing Page Setting",
      icon: getIcon("lets-icons:setting-line-duotone-line"),
      children: [
        {
          title: "Brand Logo",
          path: "/brand-logo",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Header",
          path: "/header",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Add Banner",
          path: "/banner",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Three Steps",
          path: "/three-steps",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Result Devices",
          path: "/result-device",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Site creation",
          path: "/site-creation",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Website Create",
          path: "/web-create",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Secure Website Create",
          path: "/secure-website-create",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Website Builder",
          path: "/web-builder",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Dynamic Website Builder",
          path: "/dynamic-builder",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Website Setting",
          path: "/website-setting",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Ready To Grow",
          path: "/ready-to-grow",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "EveryThing You Need",
          path: "/every-thing-you-need",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "FAQ",
          path: "/question-answer",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Help Form",
          path: "/help-form",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Footer",
          path: "/add-footer",
          icon: getIcon("ooui:articles-rtl"),
        },
      ],
    },

    {
      title: "Support Departments",
      path: "/support-departments",
      icon: getIcon("carbon:category"),
    },
    {
      title: "Plans Settings",
      icon: getIcon("ph:bank-light"),
      children: [
        {
          title: "Plans",
          path: "/plans",
          icon: getIcon("game-icons:take-my-money"),
        },
        {
          title: "Free Trial",
          path: "/free-trial",
          icon: getIcon("fluent-emoji-high-contrast:free-button"),
        },
      ],
    },

    {
      title: "Transactions",
      icon: getIcon("icon-park-outline:transaction"),
      children: [
        {
          title: "Stripe Transactions",
          path: "/transaction",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Admin Transactions",
          path: "/admin-transaction",
          icon: getIcon("ooui:articles-rtl"),
        },
      ],
    },
    {
      title: "Subscriptions",
      icon: getIcon("streamline:subscription-cashflow"),
      children: [
        {
          title: "Stripe Subscriptions",
          path: "/stripe-subscription",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Custom Subscriptions",
          path: "/custom-subscription",
          icon: getIcon("ooui:articles-rtl"),
        },
      ],
    },
    {
      title: "Template",
      icon: getIcon("tdesign:template"),
      children: [
        {
          title: "Templates",
          path: "/single-templates",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Template Group",
          path: "/templates-group",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Trending Templates",
          path: "trending-templates",
          icon: getIcon("ooui:articles-rtl"),
        },
      ],
    },
    {
      title: `Support Tickets ${
        openCount.length > 0 ? "(" + openCount.length + ")" : ""
      }`,
      path: "/support-tickets",
      icon: getIcon("bx:support"),
    },
    {
      title: "Keys",
      path: "/keys",
      icon: getIcon("ph:key"),
    },
    // {
    //   title: "TestKeys",
    //   path: "/test-keys",
    //   icon: getIcon("ph:key"),
    // },
    {
      title: "Team Management",
      icon: getIcon("fluent-mdl2:recruitment-management"),
      children: [
        {
          title: "Sub-Admin",
          path: "/sub-admin",
          icon: getIcon("ooui:articles-rtl"),
        },
        {
          title: "Role",
          path: "/role",
          icon: getIcon("ooui:articles-rtl"),
        },
      ],
    },
    {
      title: "Find",
      path: "/find",
      icon: getIcon("map:search"),
    },
  ];

  const filterArray1 = (arr1, arr2) => {
    return arr1.filter((obj1) => {
      const matchingObj = arr2?.find(
        (obj2) =>
          obj1.title.toLowerCase().includes(obj2.name.toLowerCase()) &&
          obj2.privileges.list
      );

      return matchingObj;
    });
  };

  if (user.admin_type === "sub_admin") {
    sideBarArray = filterArray1(sideBarArray, subAdminRole.roles);
  }

  return sideBarArray;
};

export default SidebarConfig;
