/* eslint-disable react-hooks/exhaustive-deps */
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbar from "src/components/Scrollbar";
import closeFill from "@iconify/icons-eva/close-fill";
import { _update_keys } from "src/DAL/Keys/keys";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function EditKeysDrawer({
  editKeysDrawer,
  setEditKeysDrawer,
  selectedRow,
  getKeys,
}) {
  console.log(selectedRow, "seleetccteddrooww");
  const { enqueueSnackbar } = useSnackbar();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [inputs, setInputs] = useState({
    value: "",
  });

  const handleCloseEditDrawer = () => {
    setShowKey(false);
    setEditKeysDrawer(false);
  };

  const toggleKey = () => {
    setShowKey((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setInputs((inputs) => ({ ...inputs, [name]: trimmedValue }));
  };

  const handleSubmit = async () => {
    setLoadingBtn(true);
    if (inputs.value === "") {
      enqueueSnackbar("Value is not allowed to be empty", { variant: "error" });
      setLoadingBtn(false);
      return;
    }
    try {
      const id = selectedRow?._id;
      const postData = {
        value: inputs.value,
      };
      // console.log(id, "iiddddddddddddddddddddddddddddddddddddd");
      const response = await _update_keys(id, postData);
      console.log(response);
      if (response.code === 200) {
        setInputs({
          value: inputs.value,
        });
        handleCloseEditDrawer(false);
        setLoadingBtn(false);
        setShowKey(false);
        getKeys();
        enqueueSnackbar("Key updated successfully", { variant: "success" });
      } else {
        setLoadingBtn(false);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      setLoadingBtn(false);
    }
  };

  useEffect(() => {
    setInputs({
      key: selectedRow?.key,
      value: selectedRow?.value,
    });
  }, [editKeysDrawer]);

  const keys = [
    { value: "pexel_key", label: "Pexel Key" },
    { value: "tinymc_key", label: "Tinymc Key" },
    { value: "stripe_public_key", label: "Stripe Public Key" },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        open={editKeysDrawer}
        onClose={handleCloseEditDrawer}
        PaperProps={{
          sx: { width: 430, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Edit Key
          </Typography>
          <IconButton onClick={handleCloseEditDrawer}>
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Stack spacing={3} sx={{ p: 3 }}>
              <FormControl variant="outlined">
                <InputLabel id="Type">Key*</InputLabel>
                <Select
                  labelId="Type"
                  fullWidth
                  label="Key*"
                  required
                  value={inputs.key}
                  name="key"
                  disabled
                  // onChange={handleChange}
                >
                  {keys.map((option, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={option.value}
                        // disabled={selectedKeys.includes(option.value)}
                      >
                        {option.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                required
                fullWidth
                id="outlined-multiline-static"
                // label={
                //   selectedRow.key?.charAt(0).toUpperCase() +
                //   selectedRow.key?.slice(1).replace(/_/g, " ")
                // }
                label="Value"
                value={inputs.value}
                name="value"
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                type={showKey ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={toggleKey}>
                      {showKey ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loadingBtn}
              >
                Update Key
              </LoadingButton>
            </Stack>
          </form>
        </Scrollbar>
      </Drawer>
    </>
  );
}

export default EditKeysDrawer;
